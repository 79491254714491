import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MessageError, TextTag } from '@vecindario/vecindario-suite-components';
import {
	postActivateIntegrationWithProject as postActivateContactIntegrationWithProject,
	setActiveProject as setActiveProjectContact,
	setError as setErrorContact,
} from '../../../../application/slices/sincoContactIntegration';
import {
	activeProjectSelector,
	errorSelector,
	loadingSelector,
	projectsSelector,
} from '../../../../application/selector/sincoIntegration';
import { ERROR_MESSAGES_SELECT_PROJECT } from '../../../../../../shared/application/constants/messages/error-messages';
import Divider from '../../../../../../shared/presentation/components/Divider';

const Step2 = ({ slug }) => {
	const dispatch = useDispatch();

	const activeProject = useSelector(activeProjectSelector);
	const projects = useSelector(projectsSelector);
	const loading = useSelector(loadingSelector);
	const error = useSelector(errorSelector);

	const handleClick = () => {
		if (!activeProject) {
			dispatch(setErrorContact(ERROR_MESSAGES_SELECT_PROJECT));
		} else {
			dispatch(postActivateContactIntegrationWithProject({ slug, activeProject }));
		}
	};

	const handleProjectClick = (project) => {
		dispatch(setActiveProjectContact(project));
	};

	return (
		<>
			<TextTag tag="p" fw="bold" className="text-center">
				Elige el proyecto que deseas integrar
			</TextTag>
			<Divider />
			<div className="container-cards">
				{projects?.map((project) => (
					<div
						key={`project${project.IdEmpresa}`}
						className={`project-card ${project === activeProject ? '-active' : ''}`}
						onClick={() => handleProjectClick(project)}
					>
						<i className="icon ri-building-line"></i>
						<TextTag tag="p" variant="-body-sm">
							{project.nombre}
						</TextTag>
					</div>
				))}
			</div>
			<div className="action-buttons">
				{loading ? (
					<Button variant="bordered" disabled>
						<i className="fal fa-spinner fa-spin"></i>
					</Button>
				) : (
					<>
						{error !== '' && <MessageError message={error} showIcon={true} />}
						<Button variant="default" text="Continuar" onClick={() => handleClick()} />
					</>
				)}
			</div>
		</>
	);
};

Step2.propTypes = {
	slug: PropTypes.string.isRequired,
};

export default Step2;
