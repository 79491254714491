import moment from 'moment/moment';
import {
	PROPERTY_AVAILABLE,
	PROPERTY_OPTIONED,
	STAGE_CONTACT,
	STAGE_EXTERNAL_CONTACT_ADS,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_OPTIONED_PROPERTY,
	STAGE_QUOTED,
	STAGE_SCHEDULE,
	STAGE_SIMULATE,
} from '../constants/opportunityHistory';
import { getFullName } from '../../../../shared/application/helpers/common-functions';
import { HOUR_FORMAT, ISO_8601_FORMAT } from '../../../../shared/application/constants/dates';
import { OPTION_STATE_ACTIVE, OPTION_STATE_PENDING } from '../constants/optionedInformation';
import { TABLE_ROWS } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';

const isActionSimulationOrQuotation = (actionType) =>
	[STAGE_SIMULATE, STAGE_QUOTED, STAGE_OPTIONED_PROPERTY].includes(actionType);

export const isActionSchedule = (actionType) => {
	return [
		STAGE_SCHEDULE,
		STAGE_SIMULATE,
		STAGE_CONTACT,
		STAGE_QUOTED,
		STAGE_EXTERNAL_CONTACT_REQUEST,
		STAGE_EXTERNAL_CONTACT_ADS,
	].includes(actionType);
};

export const isPropertyAvailable = (item) => {
	return (
		[PROPERTY_AVAILABLE, PROPERTY_OPTIONED].includes(item?.action_instance?.property?.availability) &&
		isActionSimulationOrQuotation(item?.action_type)
	);
};

const isActiveOptionState = (item) =>
	[OPTION_STATE_PENDING, OPTION_STATE_ACTIVE].includes(item?.action_instance?.option_state);

export const showPaymentButton = (item) => {
	const property = item?.action_instance?.property;
	const allowedState = isActionSchedule(item?.action_type);
	return isActiveOptionState(item) || (property ? property?.option_snapshots_count === 0 && allowedState : allowedState);
};

export const movedBy = (value) => `Movido por: ${getFullName(value)}`;

export const snapshotHasUserIdentification = (item) => {
	const userSnapshotDetail = item?.action_instance?.user_snapshot_detail;
	if (item?.action_type === STAGE_QUOTED) {
		return !!(userSnapshotDetail?.identification && userSnapshotDetail?.document_type);
	}

	if (item?.action_type === STAGE_OPTIONED_PROPERTY) {
		return !!(item?.action_instance?.identification && item?.action_instance?.document_type);
	}

	return !!(userSnapshotDetail?.identification && userSnapshotDetail?.identification_type);
};

export const opportunityHistoryIncludeProperty = (item) => !!item?.action_instance?.property;

const extractPropertyFields = (property) => {
	return {
		availability: property?.availability || PROPERTY_AVAILABLE,
		name: property?.name,
		reference: property?.reference,
	};
};

export const extractUserSnapshotDetail = (action_instance, action_type, userSnapshotDetail = {}) => {
	if (![STAGE_QUOTED, STAGE_OPTIONED_PROPERTY].includes(action_type)) return action_instance?.user_snapshot_detail;

	if (action_type === STAGE_OPTIONED_PROPERTY) {
		return {
			identification: action_instance?.identification,
			identification_type: action_instance?.document_type,
			email: action_instance?.user_email,
			first_name: userSnapshotDetail?.first_name,
			last_name: userSnapshotDetail?.last_name,
			mobile: userSnapshotDetail?.mobile,
		};
	}

	return {
		identification: action_instance?.user_snapshot_detail?.identification,
		identification_type: action_instance?.user_snapshot_detail?.document_type,
		first_name: action_instance?.user_snapshot_detail?.name,
		last_name: action_instance?.user_snapshot_detail?.lastname,
		email: action_instance?.user_snapshot_detail?.email,
		mobile: action_instance?.user_snapshot_detail?.mobile,
	};
};

export const generateLinkBodyRequest = (currentProject, item, userSnapshotDetail = {}) => {
	return {
		opportunity_history_id: item.id,
		action_instance: {
			id: item.action_instance.id,
			separation_value: item?.action_instance?.separation_value || item?.action_instance?.property?.separation_value,
			property: extractPropertyFields(item.action_instance.property),
			user_snapshot_detail: extractUserSnapshotDetail(item?.action_instance, item?.action_type, userSnapshotDetail),
		},
		action_type: item.action_type,
		commercial_opportunity_id: item.commercial_opportunity_id,
		payment_method_name: item.payment_method_name,
		payment_method_type: item.payment_method_type,
		project_id: currentProject?.id,
		project_title: currentProject?.title,
	};
};

export const getDateHourDate = (dateString, projectTZ = 'America/Bogota') => {
	const date = moment(dateString).tz(projectTZ || 'America/Bogota');
	return `${date.format(ISO_8601_FORMAT)} - ${date.format(HOUR_FORMAT)}`;
};

export const getPropertyParsed = (actionInstance, t) => {
	const towerName = actionInstance?.tower?.name || actionInstance?.property?.tower?.name;
	if (towerName && actionInstance?.property?.reference) {
		return `${towerName} - ${actionInstance?.property?.reference}`;
	}

	return t(TABLE_ROWS.NO_PROPERTY, { ns: DOMAIN_NAME });
};
