import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { history as navigate } from '../../../../shared/application/helpers/history';
import { deleteNote, getListNotes, postCreateNote, putEditNote } from '../../infrastructure/api/notes';
import { requestOpportunityHistory } from '../../../opportunityHistory/application/slices/history';
import { opportunityRoute } from '../../../opportunities/infrastructure/routes';

export const initialState = {
	list: [],
	isLoading: false,
	action: null,
	selectedNote: {},
	openDelete: false,
};

export const requestListNotes = createAsyncThunk(
	'request/notes/listNotes',
	async ({ slug, commercial_opportunity_id }, { rejectWithValue, dispatch }) => {
		try {
			return await getListNotes(slug, commercial_opportunity_id);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			navigate.push(opportunityRoute(slug));
			return rejectWithValue(error);
		}
	},
);

export const requestCreateNote = createAsyncThunk(
	'request/notes/createNote',
	async ({ slug, payload }, { rejectWithValue, dispatch, getState }) => {
		try {
			const { commercial_opportunity_id } = payload;
			const {
				opportunityHistory: { history },
			} = getState();

			const response = await postCreateNote(slug, payload);

			if (history?.asesor === null) {
				dispatch(requestOpportunityHistory({ slug, commercial_opportunity_id }));
			}
			return response;
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const requestEditNote = createAsyncThunk(
	'request/notes/editNote',
	async ({ slug, opportunity_note_id, payload }, { rejectWithValue, dispatch }) => {
		try {
			return await putEditNote(slug, opportunity_note_id, payload);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const requestDeleteNote = createAsyncThunk(
	'request/notes/deleteNote',
	async ({ slug, opportunity_note_id }, { rejectWithValue, dispatch }) => {
		try {
			return await deleteNote(slug, opportunity_note_id);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

const Notes = createSlice({
	name: 'notes',
	initialState,
	reducers: {
		setAction(state, { payload }) {
			state.action = payload;
		},
		setSelectedNote(state, { payload }) {
			state.selectedNote = payload;
		},
		setOpenDelete(state, { payload }) {
			state.openDelete = payload;
		},
	},
	extraReducers: {
		[requestListNotes.pending](state) {
			state.isLoading = true;
			state.list = [];
			state.selectedNote = {};
		},
		[requestListNotes.rejected](state) {
			state.isLoading = true;
		},
		[requestListNotes.fulfilled](state, { payload }) {
			state.list = payload;
			state.isLoading = false;
			state.selectedNote = {};
		},
		[requestCreateNote.pending](state) {
			state.isLoading = true;
		},
		[requestCreateNote.fulfilled](state, { payload }) {
			state.action = null;
			state.isLoading = false;
			state.list = payload;
			state.selectedNote = {};
		},
		[requestEditNote.pending](state) {
			state.isLoading = true;
		},
		[requestEditNote.fulfilled](state, { payload }) {
			state.action = null;
			state.isLoading = false;
			state.list = payload;
			state.selectedNote = payload.find((item) => item.id === state.selectedNote?.id);
		},
		[requestDeleteNote.pending](state) {
			state.isLoading = true;
		},
		[requestDeleteNote.fulfilled](state, { payload }) {
			state.action = null;
			state.isLoading = false;
			state.list = payload;
			state.selectedNote = {};
			state.openDelete = false;
		},
	},
});

export const { setAction, setSelectedNote, setOpenDelete } = Notes.actions;

export default Notes.reducer;
