import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { SERVER_SIDE_ERROR } from '../../../../shared/application/constants/messages/error-messages';

import {
	activateSicoIntegration,
	activeSicoIntegration,
	deactivateSicoIntegration,
} from '../../infrastructure/Api/integrations';

export const initialState = {
	sicoToken: null,
};

export const postActivateSicoIntegration = createAsyncThunk(
	'sicoIntegration/activateIntegration',
	async ({ slug, token }, { rejectWithValue, dispatch }) => {
		try {
			return await activateSicoIntegration(slug, { token });
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const deleteDeactivateSicoIntegration = createAsyncThunk(
	'sicoIntegration/deactivateSicoIntegration',
	async ({ slug }, { rejectWithValue }) => {
		try {
			return await deactivateSicoIntegration(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getActiveSicoIntegration = createAsyncThunk(
	'sicoIntegration/activeSicoIntegration',
	async ({ slug }, { rejectWithValue, dispatch }) => {
		try {
			return await activeSicoIntegration(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const sicoContactIntegration = createSlice({
	name: 'sicoContactIntegration',
	initialState,
	reducers: {},
	extraReducers: {
		[postActivateSicoIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postActivateSicoIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.sicoToken = payload?.id || null;
		},
		[postActivateSicoIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
		[deleteDeactivateSicoIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[deleteDeactivateSicoIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.sicoToken = null;
		},
		[deleteDeactivateSicoIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
		[getActiveSicoIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[getActiveSicoIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.sicoToken = payload?.id || null;
		},
		[getActiveSicoIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
	},
});

export default sicoContactIntegration.reducer;
