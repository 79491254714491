import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { CONFIRMATION_OPTIONS, TRANSLATE_ACTION } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityHistoryRoute, opportunityOptioneddetailsRoute } from '../../../infrastructure/routes';
import { selectIsLoading, selectOptionedData } from '../../../application/selector/history';
import { createOption } from '../../../application/slices/history';

import './ConfirmationOptions.scss';

const ConfirmationOptions = ({ opportunity, setShowSelectPropertyModal }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const optionedData = useSelector(selectOptionedData);
	const currentProject = useSelector(getCurrentProject);
	const isLoading = useSelector(selectIsLoading);

	const handleCreateOption = async () => {
		const response = await dispatch(
			createOption({
				slug: currentProject?.slug,
				propertyId: optionedData?.property?.id,
				data: {
					document_type: optionedData?.document_type,
					identification: optionedData?.identification,
					user_email: opportunity?.user_email,
					project_id: currentProject?.id,
					commercial_opportunity_id: opportunity?.id,
				},
			}),
		);
		if (!response.error) {
			history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id));
		}
	};

	return (
		<div className="container-confirmation-options">
			<TextTag variant="-body-sm" fw="bold">
				{optionedData?.property?.option_snapshots_count === 1
					? t(CONFIRMATION_OPTIONS.TEXT_1, {
							ns: DOMAIN_NAME,
							action: t(TRANSLATE_ACTION[optionedData?.property?.lastAction], { ns: DOMAIN_NAME }),
							towerName: optionedData?.property?.tower_name,
							propertyName: optionedData?.property?.name,
					  })
					: t(
							optionedData?.property?.option_snapshots_count === 0
								? CONFIRMATION_OPTIONS.TEXT_1_2
								: CONFIRMATION_OPTIONS.TEXT_1_1,
							{
								ns: DOMAIN_NAME,
								action: t(TRANSLATE_ACTION[optionedData?.property?.lastAction], { ns: DOMAIN_NAME }),
								towerName: optionedData?.property?.tower_name,
								propertyName: optionedData?.property?.name,
								numPeople: optionedData?.property?.option_snapshots_count,
							},
					  )}
			</TextTag>

			<TextTag variant="-body-sm">{t(CONFIRMATION_OPTIONS.TEXT_2, { ns: DOMAIN_NAME })}</TextTag>

			<section className="actions">
				<Button onClick={handleCreateOption} disabled={isLoading}>
					{isLoading ? <Spinner /> : t(CONFIRMATION_OPTIONS.TEXT_3, { ns: DOMAIN_NAME })}
				</Button>
				<Button onClick={() => setShowSelectPropertyModal(true)} variant="bordered">
					{t(CONFIRMATION_OPTIONS.TEXT_4, { ns: DOMAIN_NAME })}
				</Button>
			</section>

			<span
				className="container-close"
				onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
			>
				<Icon icon={ICON_CLOSE_LINE} aditionalClassName="icon-close" />
			</span>
		</div>
	);
};

ConfirmationOptions.propTypes = {
	opportunity: PropTypes.object,
	setShowSelectPropertyModal: PropTypes.func,
};

export default ConfirmationOptions;
