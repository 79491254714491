import { moduleLinks } from '@vecindario/suite-library';
import { PROD_ENV } from '../../../../shared/application/constants/env';
import { INVENTORY_KEY } from '../../../../shared/application/constants/modules_keys';
import { STAGE } from '../../../opportunities/application/constants/opportunities';

export const DATE_ROW = 'date';
export const STAGE_ROW = 'stage';
export const PROPERTY_ROW = 'property';
export const DETAIL_ROW = 'detail';
export const ACTIONS_ROW = 'actions';

export const DATE_ROW_VALUE = 'Fecha';
export const STAGE_ROW_VALUE = 'Estado';
export const PROPERTY_ROW_VALUE = 'Inmueble';
export const DETAIL_ROW_VALUE = 'Detalle';
export const ACTIONS_ROW_VALUE = 'Acciones';

export const NO_PROPERTY_ROW_VALUE = 'No aplica';
export const SEARCHER_PROPERTIES_NOT_FOUND = 'No se encuentran inmuebles que coincidan con los términos de búsqueda';
export const PROPERTIES_NOT_FOUND = 'No hay inmuebles disponibles para generar reservas';

export const DEFAULT_TABLE_HEADER = {
	[DATE_ROW]: DATE_ROW_VALUE,
	[STAGE_ROW]: STAGE_ROW_VALUE,
	[PROPERTY_ROW]: PROPERTY_ROW_VALUE,
	[DETAIL_ROW]: DETAIL_ROW_VALUE,
};

export const STAGE_RESERVED = 'reserved';
export const STAGE_SIMULATE = 'simulate';
export const STAGE_QUOTED = 'quoted';
export const STAGE_SCHEDULE = 'schedule';
export const STAGE_DISCARDED = 'discarded';
export const STAGE_VISITED = 'visited';
export const STAGE_SEPARATED = 'separated';
export const STAGE_SEPARATED_BY_OPTION = 'separated_by_option';
export const STAGE_OPTIONED_PROPERTY = 'optioned_property';
export const STAGE_DONE = 'done';
export const STAGE_MOVE_TO_INTERESTED = 'moved_to_interested';
export const STAGE_MOVE_TO_VISITED = 'moved_to_visited';
export const STAGE_MOVE_TO_RESERVED = 'moved_to_reserved';
export const STAGE_MOVE_TO_SEPARATED = 'moved_to_separated';
export const STAGE_MOVE_TO_DISCARDED = 'moved_to_discarded';
export const STAGE_DESISTED_WITH_REFUND = 'desisted_with_refund';
export const STAGE_DESISTED_WITHOUT_REFUND = 'desisted_without_refund';
export const STAGE_DESISTED_BUSINESS = 'desisted_business';
export const TRANSACTION_STAGE_READY_TO_DISPERSE = 'ready_to_disperse';
export const TRANSACTION_STAGE_DISPERSED = 'dispersed';
export const STAGE_CONTACT = 'contact';
export const STAGE_VERIFYING_PAYMENT = 'verifying_payment';
export const STAGE_EXTERNAL_CONTACT_REQUEST = 'external_contact_request';
export const STAGE_EXTERNAL_CONTACT_REQUEST_LABEL = 'external_contact_request_label';
export const STAGE_EXTERNAL_CONTACT_REQUEST_TEXT = 'external_contact_request_text';
export const STAGE_EXTERNAL_CONTACT_ADS = 'external_contact_snapshot';
export const STAGE_LEADS_FACEBOOK = 'facebook_business';
export const STAGE_LEADS_GOOGLE = 'google_ads';

export const MOVE_ACTIONS = [
	STAGE_MOVE_TO_RESERVED,
	STAGE_MOVE_TO_SEPARATED,
	STAGE_MOVE_TO_INTERESTED,
	STAGE_MOVE_TO_VISITED,
	STAGE_MOVE_TO_DISCARDED,
];

export const STAGES = {
	[STAGE_RESERVED]: 'Reservó',
	[STAGE_SIMULATE]: 'Simuló',
	[STAGE_QUOTED]: 'Cotizó',
	[STAGE_SCHEDULE]: 'Agendó',
	[STAGE_DISCARDED]: 'Descartado',
	[STAGE_VISITED]: 'Visitó',
	[STAGE_SEPARATED]: 'Separó',
	[STAGE_SEPARATED_BY_OPTION]: 'Separó',
	[STAGE_MOVE_TO_SEPARATED]: 'Separó',
	[STAGE_OPTIONED_PROPERTY]: 'Opcionó',
	[STAGE_DONE]: 'Atendida',
	[STAGE_MOVE_TO_INTERESTED]: 'Simuló',
	[STAGE_MOVE_TO_VISITED]: 'Visitó',
	[STAGE_MOVE_TO_RESERVED]: 'Compró',
	[STAGE_MOVE_TO_DISCARDED]: 'Descartado',
	[STAGE_DESISTED_WITH_REFUND]: 'Desistido con devolución',
	[STAGE_DESISTED_WITHOUT_REFUND]: 'Desistido sin devolución',
	[STAGE_DESISTED_BUSINESS]: 'Negocio desistido',
	[TRANSACTION_STAGE_READY_TO_DISPERSE]: 'Listo para dispersión',
	[TRANSACTION_STAGE_DISPERSED]: 'Dispersado',
	[STAGE_CONTACT]: 'Contactar',
	[STAGE_EXTERNAL_CONTACT_REQUEST]: 'Contactar',
	[STAGE_VERIFYING_PAYMENT]: 'Verificando pago',
	[STAGE_QUOTED]: 'Cotizó',
};

export const STAGE_CLASSES = {
	[STAGE_RESERVED]: 'badge-success',
	[STAGE_SIMULATE]: 'badge-lighter-info',
	[STAGE_SCHEDULE]: 'badge-info',
	[STAGE_DISCARDED]: 'badge-error',
	[STAGE_VISITED]: 'badge-warning',
	[STAGE_SEPARATED]: 'badge-success',
	[STAGE_SEPARATED_BY_OPTION]: 'badge-success',
	[STAGE_MOVE_TO_SEPARATED]: 'badge-success',
	[STAGE_OPTIONED_PROPERTY]: 'badge-purple',
	[STAGE_DONE]: 'badge-success',
	[STAGE_MOVE_TO_INTERESTED]: 'badge-info',
	[STAGE_MOVE_TO_VISITED]: 'badge-warning',
	[STAGE_MOVE_TO_RESERVED]: 'badge-success',
	[STAGE_MOVE_TO_DISCARDED]: 'badge-error',
	[STAGE_DESISTED_WITH_REFUND]: 'badge-purple',
	[STAGE_DESISTED_WITHOUT_REFUND]: 'badge-purple',
	[STAGE_DESISTED_BUSINESS]: 'badge-purple',
	[TRANSACTION_STAGE_READY_TO_DISPERSE]: 'badge-dark-warning',
	[TRANSACTION_STAGE_DISPERSED]: 'badge-light-info',
	[STAGE_CONTACT]: 'badge-magenta',
	[STAGE_EXTERNAL_CONTACT_REQUEST]: 'badge-magenta',
	[STAGE_VERIFYING_PAYMENT]: 'badge-dark-warning',
	[STAGE_QUOTED]: 'badge-lighter-blue-mint',
	[STAGE_EXTERNAL_CONTACT_ADS]: 'badge-magenta',
};

export const COPY_TEXT_PAYMENT_LINK = 'Copiar';
export const GENERATE_PAYMENT_LINK = 'Generar';
export const GENERATE_IN_PROGRESS_PAYMENT_LINK = 'Generando';

export const SEARCH_KEYS = ['name', 'tower_name'];

export const PROPERTY_AVAILABLE = 'available';
export const PROPERTY_OPTIONED = 'optioned';

export const urlPropertyOnInventory = (slug, towerId, propertyId) =>
	`${moduleLinks[INVENTORY_KEY][PROD_ENV]}proyectos/${slug}/torres/${towerId}/inmuebles/${propertyId}/editar`;

export const TRANSACTION_APPROVED_STATE = 'approved';
export const TRANSACTION_DESISTED_STATE = 'desisted';

export const SEPARATION_FIDUCIARY_METHOD = 'Separación fiduciaria';
export const SEPARATION_TYPE = 'separation';

export const INTERESTED_STATUS = 'Interesado';
export const VISITED_STATUS = 'Visitado';
export const RESERVED_STATUS = 'Reservado';
export const SEPARATED_STATUS = 'Separado';
export const DISCARTED_STATUS = 'Descartado';
export const DESISTED_STATUS = 'Desistido';

export const ALLOWED_TRANSACTION_STAGES = [
	STAGE_DESISTED_WITH_REFUND,
	STAGE_DESISTED_WITHOUT_REFUND,
	STAGE_DESISTED_BUSINESS,
	TRANSACTION_STAGE_READY_TO_DISPERSE,
	TRANSACTION_STAGE_DISPERSED,
];

export const PAYMENT_LINK_MODAL_PROVIDER = 'payment-link';
export const GENERAL_MODAL_PROVIDER = 'general';

export const UTM_KEY_LIST = [
	'utm_id',
	'utm_source',
	'utm_medium',
	'utm_campaign',
	'utm_content',
	'utm_term',
	'document_referer',
	'source',
	'source_url',
	'action_performer',
	'user_id_email',
];

export const EXTERNAL_CONTACT_KEY_LIST = [
	'request_type',
	'campaign_id',
	'campaign_name',
	'lead_registration_date',
	'lead_id',
	'advertisement_name',
	'ad_group_name',
	'form_id',
];

export const CLOSED_OPPORTUNITY_STAGES = [STAGE_RESERVED, STAGE_DISCARDED, STAGE.DESISTED, STAGE_SEPARATED];

export const DESISTED_OPPORTUNITY_STAGES = [
	STAGE_DESISTED_WITH_REFUND,
	STAGE_DESISTED_WITHOUT_REFUND,
	STAGE_DESISTED_BUSINESS,
];

export const CONTACT_QUESTION_SLUGS = {
	ECONOMIC_ACTIVITY: 'economic_activity',
	CITY_OF_RESIDENCE: 'city_of_residence',
	MONTHLY_PAYMENT_CAPACITY: 'monthly_payment_capacity',
};

export const SCHEDULE_APPOINTMENT_TYPES = {
	visit: 'visit',
	video_call: 'video_call',
};

export const STAGE_SOCIAL_LEADS = {
	[STAGE_LEADS_FACEBOOK]: 'facebook_business',
	[STAGE_LEADS_GOOGLE]: 'google_ads',
};
