import {
	GENERIC_DESCRIPTION,
	GENERIC_INPUT_LABEL,
	GENERIC_WEBHOOK,
	HUBSPOT,
	HUBSPOT_DESCRIPTION,
	HUBSPOT_INPUT_LABEL,
	META,
	META_DESCRIPTION,
	META_INPUT_LABEL,
	SICO,
	SICO_DESCRIPTION,
	SICO_INPUT_LABEL,
	SINCO,
	SINCO_DESCRIPTION,
	SINCO_INPUT_LABEL,
	SUITE,
	SUITE_DESCRIPTION,
	SUITE_INPUT_LABEL,
	ZAPIER_DESCRIPTION,
	ZAPIER_INPUT_LABEL,
	ZAPIER_WEBHOOK,
	ZOHO,
	ZOHO_DESCRIPTION,
	ZOHO_INPUT_LABEL,
} from '../../application/constants/inventory';

export const INTEGRATIONS = Object.freeze({
	TITLE: 'title',
	TEXT_INFO: 'textInfo',
	[SUITE]: SUITE,
	[META]: META,
	[HUBSPOT]: HUBSPOT,
	[ZAPIER_WEBHOOK]: ZAPIER_WEBHOOK,
	[GENERIC_WEBHOOK]: GENERIC_WEBHOOK,
	[SINCO]: SINCO,
	[ZOHO]: ZOHO,
	[SICO]: SICO,
	[SUITE_DESCRIPTION]: SUITE_DESCRIPTION,
	[META_DESCRIPTION]: META_DESCRIPTION,
	[HUBSPOT_DESCRIPTION]: HUBSPOT_DESCRIPTION,
	[ZAPIER_DESCRIPTION]: ZAPIER_DESCRIPTION,
	[GENERIC_DESCRIPTION]: GENERIC_DESCRIPTION,
	[SINCO]: SINCO,
	[SINCO_DESCRIPTION]: SINCO_DESCRIPTION,
	[ZOHO_DESCRIPTION]: ZOHO_DESCRIPTION,
	[SICO_DESCRIPTION]: SICO_DESCRIPTION,
	[SUITE_INPUT_LABEL]: SUITE_INPUT_LABEL,
	[META_INPUT_LABEL]: META_INPUT_LABEL,
	[HUBSPOT_INPUT_LABEL]: HUBSPOT_INPUT_LABEL,
	[ZAPIER_INPUT_LABEL]: ZAPIER_INPUT_LABEL,
	[GENERIC_INPUT_LABEL]: GENERIC_INPUT_LABEL,
	[SINCO_INPUT_LABEL]: SINCO_INPUT_LABEL,
	[ZOHO_INPUT_LABEL]: ZOHO_INPUT_LABEL,
	[SICO_INPUT_LABEL]: SICO_INPUT_LABEL,
	SINCO_USERNAME_LABEL: 'sincoUsernameLabel',
	SINCO_PASSWORD_LABEL: 'sincoPasswordLabel',
	INTEGRATE_TEXT_BUTTON: 'integrateTextButton',
	ACTIVATE_TEXT_BUTTON: 'activateteTextButton',
	ACTIVATE_TEXT_HUBSPOT_BUTTON: 'activateteTextHubspotButton',
	GENERATE_TOKEN_TEXT_BUTTON: 'generateTokenTextButton',
	SET_UP_TEXT_BUTTON: 'setUpTextButton',
	SEE_INTEGRATION_TEXT_BUTTON: 'seeIntegrationTextButton',
	COPY_TO_CLIPBOARD: 'copyToClipboard',
	REQUEST_INTEGRATION: 'requestIntegration',
	INTEGRATED_TEXT: 'integrateText',
});

export const SYNC = Object.freeze({
	DESACTIVATE: 'desactivate',
	SURE: 'sure',
	YES: 'yes',
	NO: 'no',
});

export const INTEGRATION_HUBSPOT = Object.freeze({
	ENTER_TOKEN: 'integration_hubspot_enter_token',
	PLACEHOLDER_INPUT_TOKEN: 'integration_hubspot_placeholder_input_token',
	VERIFY_BUTTON: 'integration_hubspot_verify_button',
	VERIFIED_STATE_BUTTON: 'integration_hubspot_verified_state_button',
	VERIFICATION_ERROR: 'integration_hubspot_verification_error',
	SELECT_PIPELINES_TITLE: 'integration_hubspot_select_pipelines_title',
	OPPORTUNITY_TYPE: 'integration_hubspot_opportunity_type',
	PIPELINE: 'integration_hubspot_pipeline',
	STAGE: 'integration_hubspot_stage',
	SAVE_BUTTON: 'integration_hubspot_save_button',
	SCHEDULE_TYPE: 'integration_hubspot_schedule_type',
	QUOTER_TYPE: 'integration_hubspot_quoter_type',
	SIMULATION_TYPE: 'integration_hubspot_simulation_type',
	CONTACT_REQUESTS_TYPE: 'integration_hubspot_contact_requests_type',
	CANCEL_BUTTON: 'integration_hubspot_cancel_button',
	EDIT_TOKEN: 'integration_hubspot_edit_token',
	TITLE_CARD_CONTACT_VARIABLES: 'integration_hubspot_title_card_contact_variables',
	TTILE_CARD_BUSINESS_VARIABLES: 'integration_hubspot_ttile_card_business_variables',
	SUBTITLE_CARD_CONTACT_VARIABLES: 'integration_hubspot_subtitle_card_contact_variables',
	SUBTTILE_CARD_BUSINESS_VARIABLES: 'integration_hubspot_subttile_card_business_variables',
	ADD_VARIABLE_BUTTON: 'integration_hubspot_add_variable_button',
	LABEL_INPUT_VECINDARIO_VARIABLE: 'integration_hubspot_label_input_vecindario_variable',
	LABEL_INPUT_HUBSPOT_VARIABLE: 'integration_hubspot_label_input_hubspot_variable',
	FINIS_SETUP_BUTTON: 'integration_hubspot_finis_setup_button',
	CARD_ERROR_TITLE: 'integration_hubspot_card_error_title',
	CARD_ERROR_BODY: 'integration_hubspot_card_error_body',
	CARD_ERROR_FOOTER: 'integration_hubspot_card_error_footer',
	SHOW_INTEGRATION_BUTTON_CARD: 'integration_hubspot_card_show_integration',
	SUCCESS_INTEGRATION: 'integration_hubspot_success_integration',
	UPDATE_INTEGRATION_BUTTON: 'integration_hubspot_update_integration_button',
	SUCCESS_SAVE_DATA: 'integration_hubspot_success_save_data',
	SUBTITLE_EDIT_INTEGRATION: 'integration_hubspot_subtitle_edit_integration',
	CARD_PROPERTY_DOESNT_EXIST_ERROR: 'property_doesnt_exist',
	CARD_READ_ONLY_VALUE_ERROR: 'read_only_value',
	CARD_INVALID_LONG_ERROR: 'invalid_long',
	DATE_VARIABLE_DISCLAIMER: 'date_variable_disclaimer',
});

export const MODAL_EDIT_TOKEN_HUBSPOT = Object.freeze({
	TITLE: 'modalEditTokenHubSpotTitle',
	BODY: 'modalEditTokenHubSpotBody',
	GO_BACK_BUTTON: 'modalEditTokenHubSpotGoBackButton',
	SUBMIT_BUTTON: 'modalEditTokenHubSpotSubmitButton',
});
