import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Table } from '@vecindario/vecindario-suite-components';
import moment from 'moment-timezone';
import {
	DATE_ROW,
	DESISTED_OPPORTUNITY_STAGES,
	DETAIL_ROW,
	ACTIONS_ROW,
	STAGE_ROW,
} from '../../../application/constants/opportunityHistory';
import { TABLE_HEADER } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import ColumnStateBadge from './ColumnStateBadge';
import { ISO_8601_FORMAT_WITH_TIME } from '../../../../../shared/application/constants/dates';
import ColumnAction from './ColumnAction';
import ColumnDetail from './ColumnDetail';

const HistoryTable = ({ opportunity, projectReservation, transactionHistories }) => {
	const { t } = useTranslation();

	const tableHeaders = {
		[DATE_ROW]: t(TABLE_HEADER[DATE_ROW], { ns: DOMAIN_NAME }),
		[STAGE_ROW]: t(TABLE_HEADER[STAGE_ROW], { ns: DOMAIN_NAME }),
		[DETAIL_ROW]: t(TABLE_HEADER[DETAIL_ROW], { ns: DOMAIN_NAME }),
		[ACTIONS_ROW]: t(TABLE_HEADER[ACTIONS_ROW], { ns: DOMAIN_NAME }),
	};

	const sortCompare = (a, b) => {
		if (new Date(a.created_at) < new Date(b.created_at)) {
			return 1;
		}
		if (new Date(a.created_at) > new Date(b.created_at)) {
			return -1;
		}
		return 0;
	};

	const getHistoryTableData = useMemo(() => {
		const { opportunity_histories } = opportunity;

		const data = [
			...(opportunity_histories?.filter((elem) => !DESISTED_OPPORTUNITY_STAGES.includes(elem.action_type)) || []),
			...transactionHistories,
		];

		if (data.length > 0) {
			data?.sort(sortCompare);
			return data?.map((item) => {
				const row = {
					[DATE_ROW]: moment(item?.created_at).format(ISO_8601_FORMAT_WITH_TIME),
					[STAGE_ROW]: (
						<ColumnStateBadge stage={item?.action_type} source={item?.action_instance?.external_source_information?.source} />
					),
					[DETAIL_ROW]: <ColumnDetail item={item} />,
					[ACTIONS_ROW]: (
						<ColumnAction
							item={item}
							opportunity={opportunity}
							transactionHistories={transactionHistories}
							projectReservation={projectReservation}
						/>
					),
				};

				return row;
			});
		}

		return null;
	}, [opportunity, transactionHistories, projectReservation]);

	return (
		<div className="user-card-history">
			<Table
				striped={false}
				stripedColor="dark"
				itemsPerPage={8}
				tableData={getHistoryTableData || []}
				tableHeaders={tableHeaders}
				tableKeys={Object.keys(tableHeaders)}
			/>
		</div>
	);
};

HistoryTable.propTypes = {
	opportunity: PropTypes.object,
	projectReservation: PropTypes.object,
	transactionHistories: PropTypes.array,
};

export default HistoryTable;
