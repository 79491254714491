import React, { useEffect } from 'react';
import { Helmet } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { accountFBRoute } from '../../../infrastructure/routes';
import { selectAccount, selectCampaign } from '../../../application/selector/facebook';
import AlertCard from '../../components/AlertCard';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { setInitialState, updateConnectionCampaign } from '../../../application/slices/facebook';
import { removeValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';

const DisconnectCampaignPage = () => {
	const { slug } = useParams();
	const history = useHistory();
	const prevSlug = history.location.state?.slug;
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const account = useSelector(selectAccount);
	const campaign = useSelector(selectCampaign);
	const historyState = { slug };

	useEffect(() => {
		if (prevSlug !== slug) {
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(setInitialState());
			history.push(opportunityRoute(slug));
		}
	}, [prevSlug, slug, dispatch, history]);

	const handleCancelDisconnect = async () => {
		history.push(accountFBRoute(slug), historyState);
	};

	const handleDisconnectCampaign = () => {
		dispatch(
			updateConnectionCampaign({
				slug,
				provider_account_id: account?.account_id,
				campaignId: campaign?.id,
			}),
		);
		history.push(accountFBRoute(slug), historyState);
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Facebook Leads - Desconectar campaña'} />
			<AlertCard
				cardTitle={t(FACEBOOK.DISCONNECT_CAMPAIGN_TITLE, { ns: DOMAIN_NAME, campaignName: campaign?.campaign_name })}
				buttonActionText={t(FACEBOOK.DISCONNECT_CAMPAIGN_BUTTON, { ns: DOMAIN_NAME })}
				handleCancel={handleCancelDisconnect}
				handleAction={handleDisconnectCampaign}
			>
				<p>{t(FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_0, { ns: DOMAIN_NAME })}</p>
				<br />
				<p>{t(FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_1, { ns: DOMAIN_NAME })}</p>
				<br />
				<ul>
					<li>{t(FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_0, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_1, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_2, { ns: DOMAIN_NAME })}</li>
				</ul>
			</AlertCard>
		</>
	);
};

export default DisconnectCampaignPage;
