import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityHistoryRoute, opportunityOptioneddetailsRoute } from '../../../infrastructure/routes';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { OPTIONS_STATE, OPTION_DETAILS_PAGE, USER_ROLES } from '../../../infrastructure/locales/translation_keys';
import { ASESOR_ROLE, DIRECTOR_ROLE } from '../../../../integrations/application/constants/inventory';
import { selectCurrentOptionSelected } from '../../../application/selector/history';
import {
	OPTION_ACTIONS_ID,
	OPTION_ACTION_CANCEL_OPTION,
	OPTION_ACTION_CONFIRM_PURCHASE,
	OPTION_ACTION_CREATE_OPTION,
	OPTION_ACTION_EXTEND_EXPIRATION,
	OPTION_STATE_ACTIVE,
	OPTION_STATE_CANCELED,
	OPTION_STATE_CONFIRMED,
	OPTION_STATE_PENDING,
} from '../../../application/constants/optionedInformation';

import './OptionDetails.scss';
import ItemDetail from './ItemDetail';
import { getDateHourDate } from '../../../application/helpers/opportunityHistory';
import { capitalize, getFirstWord } from '../../../../../shared/application/helpers/common-functions';
import { optionsStockList } from '../../../application/helpers/optionsSystem';
import { propertyOptionSnapshotsQueue } from '../../../../property/infrastructure/routes';
import { OPTION_NO_PERFORMER_USER } from '../../../../property/infrastructure/locales/translation_keys';
import { DOMAIN_NAME as PROPERTY_DOMAIN_NAME } from '../../../../property/infrastructure/locales/index';

const OptionDetails = ({ opportunity }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentUser = useSelector(getCurrentUser);
	const currentProject = useSelector(getCurrentProject);
	const currentOption = useSelector(selectCurrentOptionSelected);

	const createOptionAction =
		currentOption?.option_actions?.find((option) => option?.action === OPTION_ACTION_CREATE_OPTION) ?? {};

	const arrayOptionsStock = useMemo(
		() =>
			optionsStockList({
				currentOption,
				currentProject,
				currentUser,
				opportunity,
				dispatch,
				t,
			}),
		[dispatch, currentOption, currentProject, currentUser, opportunity, t],
	);

	const nameWhoOptioned = () => {
		const firstName = capitalize(getFirstWord(createOptionAction?.performing_user?.user_detail?.name));
		const lastName = capitalize(getFirstWord(createOptionAction?.performing_user?.user_detail?.lastname));

		let roleText =
			createOptionAction?.performing_user?.role === DIRECTOR_ROLE
				? t(USER_ROLES.DIRECTOR_ROLE, { ns: DOMAIN_NAME })
				: null;
		if (createOptionAction?.performing_user?.role === ASESOR_ROLE) {
			roleText = t(USER_ROLES.ASESOR_ROLE, { ns: DOMAIN_NAME });
		}

		return `${firstName} ${lastName} ${roleText ? `(${roleText})` : ''}`;
	};

	const lastActionExtend =
		currentOption?.option_actions
			?.filter((optionAction) => optionAction.action === OPTION_ACTION_EXTEND_EXPIRATION)
			?.pop() ?? undefined;

	const nameWhoExtend = () => {
		if (currentOption?.option_state === OPTION_STATE_ACTIVE) {
			return `${capitalize(getFirstWord(lastActionExtend?.performing_user?.user_detail?.name))} ${capitalize(
				getFirstWord(lastActionExtend?.performing_user?.user_detail?.lastname),
			)} (${
				lastActionExtend?.performing_user?.role === DIRECTOR_ROLE
					? t(USER_ROLES.DIRECTOR_ROLE, { ns: DOMAIN_NAME })
					: t(USER_ROLES.ASESOR_ROLE, { ns: DOMAIN_NAME })
			})`;
		}
		return '';
	};

	const nameWhoCanceled = () => {
		// Filter option_action that action is "cancel_option" if option_state is "canceled"
		if (currentOption?.option_state === OPTION_STATE_CANCELED) {
			const lastActionCanceled = currentOption?.option_actions
				?.filter((optionAction) => optionAction.action === OPTION_ACTION_CANCEL_OPTION)
				?.pop();

			// If exists performing_user return his full name and role if is an asesor or director
			if (lastActionCanceled?.performing_user) {
				// Define roleText
				let roleText = null;

				// Add roleText translation if performir_user role is "director"
				if (lastActionCanceled?.performing_user?.role === DIRECTOR_ROLE) {
					roleText = t(USER_ROLES.DIRECTOR_ROLE, { ns: DOMAIN_NAME });
				}

				// If performir_user role is "asesor" add roleText translation
				if (lastActionCanceled?.performing_user?.role === ASESOR_ROLE) {
					roleText = t(USER_ROLES.ASESOR_ROLE, { ns: DOMAIN_NAME });
				}

				// Return parsed user name
				return `${capitalize(getFirstWord(lastActionCanceled?.performing_user?.user_detail?.name))} ${capitalize(
					getFirstWord(lastActionCanceled?.performing_user?.user_detail?.lastname),
				)} ${roleText ? `(${roleText})` : ''}`;
			}

			// Return no_user_performer translated if performing_user not exists
			return t(OPTION_NO_PERFORMER_USER[lastActionCanceled?.no_user_performer], { ns: PROPERTY_DOMAIN_NAME });
		}

		// Return empty space if option state is not cancel_option
		return '';
	};

	const nameWhoConfirmed = () => {
		if (currentOption?.option_state === OPTION_STATE_CONFIRMED) {
			const lastActionConfirmed = currentOption?.option_actions
				?.filter((optionAction) => optionAction.action === OPTION_ACTION_CONFIRM_PURCHASE)
				?.pop();

			return `${capitalize(getFirstWord(lastActionConfirmed?.performing_user?.user_detail?.name))} ${capitalize(
				getFirstWord(lastActionConfirmed?.performing_user?.user_detail?.lastname),
			)} (${
				lastActionConfirmed?.performing_user?.role === DIRECTOR_ROLE
					? t(USER_ROLES.DIRECTOR_ROLE, { ns: DOMAIN_NAME })
					: t(USER_ROLES.ASESOR_ROLE, { ns: DOMAIN_NAME })
			})`;
		}
		return '';
	};

	const lastActualizationState = currentOption?.history?.option_actions
		?.filter((optionAction) => optionAction.action !== OPTION_ACTION_EXTEND_EXPIRATION)
		?.pop().updated_at;

	const showPositionInLineOrNameOptioned = () => {
		return currentOption?.option_state === OPTION_STATE_ACTIVE || currentOption?.option_state === OPTION_STATE_PENDING;
	};

	const showVidityTimeAndExtend = () => {
		return currentOption?.option_state === OPTION_STATE_ACTIVE;
	};

	const handleRedirect = () => {
		const urlRedirect = `${window.location.origin}${opportunityOptioneddetailsRoute(
			opportunity?.project_slug,
			opportunity?.id,
		)}`;
		history.push(propertyOptionSnapshotsQueue(opportunity?.project_slug, currentOption?.property?.id, urlRedirect));
	};

	return (
		<div className="container-option-details">
			<div className="header">
				<div className="option-section">
					<div className={`optioned-badge`}>
						<TextTag variant="-body-xs" fw="bold" type="label">
							{t(OPTION_DETAILS_PAGE.OPTIONED_WORD, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
					<Button className="text" variant="transparent" onClick={handleRedirect}>
						<TextTag variant="-body-sm" fw="bold">
							{t(OPTION_DETAILS_PAGE.VIEW_OPTION, { ns: DOMAIN_NAME })}
						</TextTag>
					</Button>
				</div>
				<Icon
					aditionalClassName="icon-close"
					icon={ICON_CLOSE_LINE}
					onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
				/>
			</div>
			<div className="body">
				<ItemDetail
					textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_1, { ns: DOMAIN_NAME })}
					description={t(OPTIONS_STATE[currentOption?.option_state?.toUpperCase()], { ns: DOMAIN_NAME })}
				/>

				<ItemDetail
					textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_2, { ns: DOMAIN_NAME })}
					description={getDateHourDate(lastActualizationState, currentProject?.time_zone)}
				/>

				{showPositionInLineOrNameOptioned() && (
					<ItemDetail textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_3, { ns: DOMAIN_NAME })} description={nameWhoOptioned()} />
				)}

				{showPositionInLineOrNameOptioned() && (
					<ItemDetail
						textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_4, { ns: DOMAIN_NAME })}
						description={currentOption?.order}
					/>
				)}

				{showVidityTimeAndExtend() && (
					<ItemDetail
						textTitle={t(lastActionExtend ? OPTION_DETAILS_PAGE.BODY_TEXT_5_1 : OPTION_DETAILS_PAGE.BODY_TEXT_5, {
							ns: DOMAIN_NAME,
						})}
						description={getDateHourDate(currentOption?.expiration_date, currentProject?.time_zone)}
					/>
				)}

				{showVidityTimeAndExtend() && lastActionExtend && (
					<ItemDetail
						textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_6, { ns: DOMAIN_NAME })}
						description={`${nameWhoExtend()}`}
					/>
				)}

				{currentOption?.option_state === OPTION_STATE_CONFIRMED && (
					<ItemDetail textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_7, { ns: DOMAIN_NAME })} description={nameWhoConfirmed()} />
				)}

				{currentOption?.option_state === OPTION_STATE_CANCELED && (
					<ItemDetail textTitle={t(OPTION_DETAILS_PAGE.BODY_TEXT_8, { ns: DOMAIN_NAME })} description={nameWhoCanceled()} />
				)}
			</div>

			{arrayOptionsStock && (
				<div className="actions">
					{arrayOptionsStock.map((item) => {
						if (OPTION_ACTIONS_ID.CAN_CONFIRM_PURCHASE === item.id) {
							return (
								<Button onClick={() => item.onClick()} className="button-purchase" key={item.id}>
									{item.text}
								</Button>
							);
						}
						return (
							<button className="action-button" onClick={() => item.onClick()} key={item.id}>
								{item.text}
							</button>
						);
					})}
				</div>
			)}
		</div>
	);
};

OptionDetails.propTypes = {
	opportunity: PropTypes.object,
};

export default OptionDetails;
