import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Icon, Input, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import { moduleKeys, moduleLinks } from '@vecindario/suite-library';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PAYMENT_MODAL_ILLUSTRATION } from '../../../../../shared/application/constants/images';
import './ModalPaymentLink.scss';
import {
	formatCurrency,
	formattedTimeInMinutes,
	getFullName,
} from '../../../../../shared/application/helpers/common-functions';
import { SEPARATION_FIDUCIARY_METHOD, SEPARATION_TYPE } from '../../../application/constants/opportunityHistory';
import { postGeneratePaymentLink } from '../../../infrastructure/api/history';
import { generateLinkBodyRequest } from '../../../application/helpers/opportunityHistory';
import { selectProjectReservation, selectRequestHistory } from '../../../application/selector/history';
import { appendOpportunityHistories, appendTransaction } from '../../../application/slices/history';
import { calculateTimeLeft } from '../../../application/helpers/commercialOpportunity';
import { PAYMENT_METHODS_OPTIONS } from '../../../application/constants/transaction';
import { PROD_ENV, RAPPI_CLIENT_ID, RAPPI_RETURN_URL } from '../../../../../shared/application/constants/env';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import {
	BUYER,
	PROPERTY,
	GENERATE,
	GENERATING_LINK,
	COPY,
	COPIED,
	NO_COPIED,
	SEND_PAY_LINK,
	TRANSACTION_ERRORS,
	FIDUCIARY_REFERENCE,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { PAYMENT_MODAL } from '../../../infrastructure/locales/translation_keys';

const MODAL_CONTENT_STYLE = {
	maxHeight: '90vh',
	height: '90vh',
	padding: '8.3rem 9rem',
	width: '520px',
};

const ModalPaymentLink = ({ item, showModal, setShowModal }) => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	const currentProject = useSelector(getCurrentProject);
	const { currency } = currentProject.internationalization_config;
	const projectReservation = useSelector(selectProjectReservation);
	const opportunity = useSelector(selectRequestHistory);
	const [maxTimeToExpirationPayment, setMaxTimeToExpirationPayment] = useState(null);
	const [paymentLink, setPaymentLink] = useState(null);
	const [amount, setAmount] = useState(null);
	const [reserveCurrency, setReserveCurrency] = useState(currency);
	const [expirationPaymentLink, setExpirationPaymentLink] = useState(null);
	const [timeRemaining, setTimeRemaining] = useState(null);
	const [isRequestedLink, setIsRequestedLink] = useState(false);
	const [generateLinkError, setGenerateLinkError] = useState(null);
	const [activateButton, setActivateButton] = useState(true);
	const [activateInput, setActivateInput] = useState(true);
	const { t } = useTranslation();
	const [copyButtonText, setCopyButtonText] = useState(t(`${GENERATE}`, { ns: DOMAIN_NAME_SHARED }));
	const paymentMethodName = item?.action_instance?.payment_method_name;
	const isRappiPayment = useMemo(() => {
		return paymentMethodName === PAYMENT_METHODS_OPTIONS.rappi_pay.text;
	}, [paymentMethodName]);

	const dataGenerateLink = useMemo(
		() => generateLinkBodyRequest(currentProject, item, opportunity?.user_snapshot_detail),
		[item, currentProject, opportunity?.user_snapshot_detail],
	);

	const getBuyerName = useMemo(() => {
		if (item?.action_instance?.process_url) {
			const firstName = item?.action_instance?.buyer?.name || item?.performing_user?.first_name || '';
			const lastName = item?.action_instance?.buyer?.surname || item?.performing_user?.last_name || '';
			return `${firstName} ${lastName}`;
		}
		return getFullName(item?.action_instance?.user_snapshot_detail);
	}, [item]);

	const getPropertyName = useMemo(() => {
		const towerName =
			item?.action_instance?.property?.tower?.name ||
			item?.action_instance?.property?.tower_name ||
			item?.action_instance?.tower?.name ||
			'';
		if (item?.action_instance?.process_url) {
			return `${currentProject?.title} - ${towerName} - ${item?.action_instance?.property?.name}`;
		}
		return `${currentProject?.title} - ${towerName} - ${item?.action_instance?.property?.name}`;
	}, [item, currentProject]);

	const isSeparation =
		item?.payment_method_type === SEPARATION_TYPE || item?.action_instance?.payment_method?.type === SEPARATION_TYPE;
	const isFiduciary =
		item?.payment_method_name === SEPARATION_FIDUCIARY_METHOD || paymentMethodName === SEPARATION_FIDUCIARY_METHOD;
	const separationValue = item?.action_instance?.property?.separation_value || 0;

	const parsePaymentLink = useCallback(
		(link) => {
			if (isSeparation) {
				setPaymentLink(link);
			} else {
				const newLink = `${
					moduleLinks?.[moduleKeys.RESERVATION_KEY]?.[PROD_ENV]
				}proyecto/${slug}/aceptar-terminos-y-condiciones-reserva?paymentUrl=${link}`;
				setPaymentLink(newLink);
			}
		},
		[isSeparation, slug],
	);

	const translateErrorMsg = (msg) => {
		const translation = t(`${TRANSACTION_ERRORS[msg]}`, { ns: DOMAIN_NAME_SHARED });
		return translation || msg;
	};

	const generatePaymentLink = useCallback(async () => {
		if (isRequestedLink) return;

		setIsRequestedLink(true);
		setActivateButton(true);
		setGenerateLinkError(null);
		setCopyButtonText(t(`${GENERATING_LINK}`, { ns: DOMAIN_NAME_SHARED }));
		postGeneratePaymentLink(slug, item?.action_instance?.property.id, dataGenerateLink)
			.then((res) => {
				setAmount(res.reservation_amount);
				setReserveCurrency(res?.transaction?.payment?.amount?.currency || currency);
				setCopyButtonText(t(`${COPY}`, { ns: DOMAIN_NAME_SHARED }));
				setActivateButton(false);
				setActivateInput(false);
				parsePaymentLink(res.link);

				const expirationDate = new Date(res?.transaction?.expiration);
				setExpirationPaymentLink(expirationDate);
				setTimeRemaining(calculateTimeLeft(expirationDate));
				dispatch(appendTransaction(res.transaction));

				if (res?.opportunity_history) {
					dispatch(appendOpportunityHistories(res?.opportunity_history));
				}
			})
			.catch((err) => {
				setGenerateLinkError(err);
				setCopyButtonText(t(`${GENERATE}`, { ns: DOMAIN_NAME_SHARED }));
				setActivateButton(false);
				setActivateInput(true);
			});
	}, [
		slug,
		item?.action_instance?.property.id,
		dataGenerateLink,
		parsePaymentLink,
		dispatch,
		currency,
		t,
		isRequestedLink,
	]);

	useEffect(() => {
		if (item?.action_instance?.process_url) {
			setCopyButtonText(t(`${COPY}`, { ns: DOMAIN_NAME_SHARED }));
			setActivateButton(false);
			setActivateInput(false);
			setAmount(projectReservation?.reservation_amount);
			parsePaymentLink(item?.action_instance?.process_url);

			const expirationDate = new Date(item?.action_instance?.expiration);
			setExpirationPaymentLink(expirationDate);
			setTimeRemaining(calculateTimeLeft(expirationDate));

			setMaxTimeToExpirationPayment(item?.action_instance?.payment_method_expiration);
		} else if (!paymentLink) {
			generatePaymentLink();
		}
	}, [paymentLink, item, generatePaymentLink, projectReservation, maxTimeToExpirationPayment, parsePaymentLink, t]);

	useEffect(() => {
		const expirationCounter = setInterval(() => {
			setTimeRemaining(calculateTimeLeft(expirationPaymentLink));
		}, 60000);
		return () => clearInterval(expirationCounter);
	}, [expirationPaymentLink]);

	const handleOnCopyLink = () => {
		if (!paymentLink) {
			generatePaymentLink();
		} else {
			navigator.clipboard
				.writeText(paymentLink)
				.then(() => {
					setCopyButtonText(t(`${COPIED}`, { ns: DOMAIN_NAME_SHARED }));
					setTimeout(() => {
						setCopyButtonText(t(`${COPY}`, { ns: DOMAIN_NAME_SHARED }));
					}, 2000);
				})
				.catch(() => setCopyButtonText(t(`${NO_COPIED}`, { ns: DOMAIN_NAME_SHARED })));
		}
	};

	const handleClickSendLink = () => {
		const reference = item?.action_instance?.reference;
		const redirect_url = `${RAPPI_RETURN_URL}${reference}`;
		const rappipay = new window.PagaRappi({ client_id: RAPPI_CLIENT_ID });
		const checkout = rappipay.checkout({
			checkout_id: item?.action_instance?.checkout_id,
			auto_open: false,
			type: 'popup',
			success_url: redirect_url,
			cancel_url: redirect_url,
		});
		checkout.open();
	};

	return (
		<Modal isOpen={showModal} showClose={true} contentStyle={MODAL_CONTENT_STYLE} onClose={() => setShowModal(false)}>
			<div className="payment-link-modal">
				<img src={PAYMENT_MODAL_ILLUSTRATION} className="illustration" alt="¿Deseas bloquear el inmueble?" />
				<TextTag tag="p" className="description">
					{t(`${PAYMENT_MODAL.TITLE}`, { ns: DOMAIN_NAME })}
				</TextTag>
				<div className="information">
					<TextTag tag="p" className="subtitle">
						<Icon icon="ri-user-smile-line" /> {t(`${BUYER}`, { ns: DOMAIN_NAME_SHARED })}
					</TextTag>
					<TextTag tag="p" className="text">
						{getBuyerName}
					</TextTag>
					{item?.action_instance?.buyer?.document && item?.action_instance?.buyer?.documentType && (
						<TextTag tag="p" className="text">
							{item?.action_instance?.buyer?.documentType}: {item?.action_instance?.buyer?.document}
						</TextTag>
					)}

					<TextTag tag="p" className="subtitle --apartament">
						<Icon icon="ri-home-smile-line" /> {t(`${PROPERTY}`, { ns: DOMAIN_NAME_SHARED })}
					</TextTag>
					<TextTag tag="p" className="text">
						{getPropertyName}
					</TextTag>
					{item?.action_instance?.property?.fiduciary_reference && (
						<TextTag tag="p" className="text">
							{t(`${FIDUCIARY_REFERENCE}`, { ns: DOMAIN_NAME_SHARED })}: {item?.action_instance?.property?.fiduciary_reference}
						</TextTag>
					)}

					<div className="divider"></div>

					{isSeparation ? (
						<TextTag tag="p" className="purchase-value bg-neutral-200t color-primary">
							<Icon icon="ri-price-tag-line" />
							<span className="text">{t(`${PAYMENT_MODAL.SEPARATED_VALUE}`, { ns: DOMAIN_NAME })}</span>
							<span className="price">{formatCurrency(separationValue)}</span>
						</TextTag>
					) : (
						<TextTag tag="p" className="purchase-value bg-secondary color-white">
							<Icon icon="ri-price-tag-line" />
							<span className="text">{t(`${PAYMENT_MODAL.VALUE_BUY}`, { ns: DOMAIN_NAME })}</span>
							<span className="price">
								{formatCurrency(amount || 0)} {reserveCurrency}
							</span>
						</TextTag>
					)}
				</div>

				{isFiduciary ? (
					<div className="time-remaining bg-primary-100t">
						<TextTag tag="p" className="subtitle">
							<Icon icon="ri-time-line" /> {t(`${PAYMENT_MODAL.PROPERTY_BLOKED}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="span" className="color-primary" variant="-body-sm">
							{t(`${PAYMENT_MODAL.PROPERTY_BLOKED_TEXT}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
				) : (
					<div className="time-remaining">
						<TextTag tag="p" className="subtitle">
							<Icon icon="ri-time-line" /> {t(`${PAYMENT_MODAL.TIME_LEFT}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" className="text">
							{t(`${PAYMENT_MODAL.TIME_LEFT_TEXT}`, {
								ns: DOMAIN_NAME,
								minutes: formattedTimeInMinutes(maxTimeToExpirationPayment),
							})}
						</TextTag>

						<div className="timer">
							<TextTag tag="p" className="text">
								<Icon icon="ri-time-line" /> {t(`${PAYMENT_MODAL.TIME_LEFT}`, { ns: DOMAIN_NAME })}
							</TextTag>
							<TextTag tag="p" className="counter">
								{timeRemaining}
							</TextTag>
						</div>
					</div>
				)}

				<div className="link-created">
					<TextTag tag="p" className="subtitle">
						{t(`${PAYMENT_MODAL.BUY_LINK}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<div className="form-group">
						<Input
							type="text"
							readOnly
							placeholder={t(`${PAYMENT_MODAL.BUY_LINK}`, { ns: DOMAIN_NAME })}
							value={paymentLink}
							disabled={activateInput}
						/>
						<Button onClick={handleOnCopyLink} text={copyButtonText} disabled={activateButton} />
					</div>
					{!!generateLinkError && (
						<TextTag tag="p" variant="-body-sm" fw="normal" className="generate-link-error">
							{translateErrorMsg(generateLinkError)}
						</TextTag>
					)}
				</div>

				{isRappiPayment && (
					<Button
						onClick={handleClickSendLink}
						className="rappi-button"
						text={t(`${SEND_PAY_LINK}`, { ns: DOMAIN_NAME_SHARED })}
					/>
				)}
			</div>
		</Modal>
	);
};

ModalPaymentLink.propTypes = {
	item: PropTypes.object,
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
};

export default ModalPaymentLink;
