import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ModalSeparationInformation from '../ModalSeparationInformation';
import { MODAL } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';

const SeeDetailsButton = ({ item }) => {
	const [showModal, setShowModal] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<ModalSeparationInformation item={item} showModal={showModal} setShowModal={setShowModal} />
			<button className="action-link" onClick={() => setShowModal(true)}>
				{t(`${MODAL.DETAILS}`, { ns: DOMAIN_NAME })}
			</button>
		</>
	);
};

SeeDetailsButton.propTypes = {
	item: PropTypes.object,
};

export default SeeDetailsButton;
