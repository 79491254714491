import React from 'react';
import { useSelector } from 'react-redux';
import ExtendValidity from '../../components/ExtendValidity';
import { selectRequestHistory } from '../../../application/selector/history';

const ExtendValidityPage = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <ExtendValidity opportunity={opportunity} />;
};

export default ExtendValidityPage;
