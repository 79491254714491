import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from '@vecindario/vecindario-suite-components';
import './AccountsFBPage.scss';
import AccountsSection from '../../components/AccountsSection';
import CampaignSection from '../../components/CampaignSection';
import { selectAccount, selectAdsCampaigns } from '../../../application/selector/facebook';
import {
	getAccountConnected,
	getCampaigns,
	getListCampaignsAds,
	setInitialState,
} from '../../../application/slices/facebook';
import { EMAIL_REDIRECT_FROM, FB_BACK_PROVIDER } from '../../../application/constants/facebook';
import {
	removeValueFromLocalStorage,
	setValueToLocalStorage,
	useGetQueryParam,
} from '../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';

const AccountsFBPage = () => {
	const { slug } = useParams();
	const history = useHistory();
	const prevSlug = history.location.state?.slug;
	const account = useSelector(selectAccount);
	const campaigns = useSelector(selectAdsCampaigns);
	const dispatch = useDispatch();
	const fromRoute = useGetQueryParam(EMAIL_REDIRECT_FROM);
	const [btnShowCampaign, setBtnShowCampaign] = useState(true);
	const [showCampaign, setShowCampaign] = useState(false);

	useEffect(() => {
		if (prevSlug !== slug && !fromRoute) {
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(setInitialState());
			history.push(opportunityRoute(slug));
		}
	}, [prevSlug, slug, dispatch, history, fromRoute]);

	useEffect(() => {
		if (!account?.id) {
			const provider = FB_BACK_PROVIDER;
			dispatch(getAccountConnected({ slug, provider }));
			removeValueFromLocalStorage(ADS_ACCOUNT);
		} else {
			dispatch(getCampaigns({ slug, provider_account_id: account?.id }));
		}
		if (prevSlug === slug) {
			setValueToLocalStorage(ADS_ACCOUNT, JSON.stringify(account));
		}
	}, [dispatch, prevSlug, slug, account]);

	useEffect(() => {
		if (campaigns?.length > 0) {
			setBtnShowCampaign(false);
			setShowCampaign(true);
		} else {
			setBtnShowCampaign(true);
			setShowCampaign(false);
		}
	}, [campaigns]);

	const handleShowCampaign = () => {
		setBtnShowCampaign(false);
		setShowCampaign(true);
		dispatch(getListCampaignsAds({ slug, account }));
	};

	return (
		<>
			<Helmet title={'Vecindario - Facebook Leads - Cuenta conectada'} />

			<AccountsSection
				btnShowCampaign={btnShowCampaign}
				setShowCampaign={setShowCampaign}
				handleShowCampaign={handleShowCampaign}
			/>

			{showCampaign && <CampaignSection setBtnShowCampaign={setBtnShowCampaign} setShowCampaign={setShowCampaign} />}
		</>
	);
};

export default AccountsFBPage;
