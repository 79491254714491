import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { MODAL_EDIT_TOKEN_HUBSPOT } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import './ModalEditToken.scss';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		borderRadius: '4px',
		padding: '0',
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const ModalEditToken = ({ isOpen, onClose, handleEditToken }) => {
	const { t } = useTranslation();

	return (
		<Modal isOpen={isOpen} onClose={onClose} contentStyle={customStyles.content} showClose={false}>
			<div className="container-modal-edit-token">
				<TextTag fw="bold" className="title-text">
					{t(MODAL_EDIT_TOKEN_HUBSPOT.TITLE, { ns: DOMAIN_NAME })}
				</TextTag>
				<TextTag variant="-body-sm" className="body-text">
					{t(MODAL_EDIT_TOKEN_HUBSPOT.BODY, { ns: DOMAIN_NAME })}
				</TextTag>
				<div className="container-buttons">
					<Button variant="bordered" className="action-button" onClick={() => onClose()}>
						{t(MODAL_EDIT_TOKEN_HUBSPOT.GO_BACK_BUTTON, { ns: DOMAIN_NAME })}
					</Button>
					<Button className="action-button" onClick={() => handleEditToken()}>
						{t(MODAL_EDIT_TOKEN_HUBSPOT.SUBMIT_BUTTON, { ns: DOMAIN_NAME })}
					</Button>
				</div>
			</div>
		</Modal>
	);
};

ModalEditToken.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
	handleEditToken: PropTypes.func,
};

export default ModalEditToken;
