import {
	MANUAL_ASSIGNMENT_DESCRIPTION,
	MANUAL_ASSIGNMENT_TITLE,
	RANDOM_ASSIGNMENT_DESCRIPTION,
	RANDOM_ASSIGNMENT_TITLE,
} from '../../../application/constants/projectConfiguration';
import { ERROR_ASESORS, SETTINGS_PAGE } from '../translation_keys';

export default {
	[SETTINGS_PAGE.TITLE]: 'Business Opportunity Assignment Configuration',
	[SETTINGS_PAGE.TEXT_INFO]:
		'How do you want to assign the business opportunities that are created through the simulator and contact form?<br/>Appointments have default assignment.',
	[SETTINGS_PAGE[RANDOM_ASSIGNMENT_TITLE]]: 'Random Assignment',
	[SETTINGS_PAGE[RANDOM_ASSIGNMENT_DESCRIPTION]]:
		'Business opportunities will be randomly assigned among all project agents.',
	[SETTINGS_PAGE[MANUAL_ASSIGNMENT_TITLE]]: 'Manual Assignment',
	[SETTINGS_PAGE[MANUAL_ASSIGNMENT_DESCRIPTION]]:
		'Agents can manually assign opportunities that are in the interested column in the pipeline.',
	[ERROR_ASESORS]: 'The option cannot be activated because there are no project agents available',
};
