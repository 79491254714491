import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TEXTS } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import './OptionsButtons.scss';

const OptionsButtons = ({ showScoringButton, simulatorUrl, schedulerUrl, scoringUrl }) => {
	const { t } = useTranslation();

	const handleViewService = (route, target = '_blank') => {
		window.open(route, target);
	};

	return (
		<div className="options-actions-container">
			<a className="action-button" onClick={() => handleViewService(simulatorUrl)}>
				{t(`${TEXTS.QUOTER}`, { ns: DOMAIN_NAME })}
			</a>
			<a className="action-button" onClick={() => handleViewService(schedulerUrl)}>
				{t(`${TEXTS.SCHEDULER}`, { ns: DOMAIN_NAME })}
			</a>
			{!showScoringButton && (
				<a className="action-button" onClick={() => handleViewService(scoringUrl, '_self')}>
					{t(`${TEXTS.SCORING}`, { ns: DOMAIN_NAME })}
				</a>
			)}
		</div>
	);
};

OptionsButtons.propTypes = {
	showScoringButton: PropTypes.bool,
	simulatorUrl: PropTypes.string,
	schedulerUrl: PropTypes.string,
	scoringUrl: PropTypes.string,
};

export default OptionsButtons;
