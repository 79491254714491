import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { SERVER_SIDE_ERROR } from '../../../../shared/application/constants/messages/error-messages';

import {
	createHubspotIntegration,
	getHubspotIntegration,
	getHubspotVariables,
	getVecindarioVariables,
	validationTokenHubspotIntegration,
} from '../../infrastructure/Api/integrations';
import { JSON_DATA_INTEGRATION } from '../constants/hubspotIntegration';
import i18nInstance from '../../../../shared/infrastructure/i18n/config';
import { INTEGRATION_HUBSPOT } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';
import { SERVER_ERROR } from '../../../../shared/infrastructure/i18n/locales/translation_keys';

export const initialState = {
	loading: false,
	loadingValidateToken: false,
	hubspotTokenPipelines: {},
	vecindarioVariables: [],
	husbpotVariables: [],
	error: null,
	errorToken: null,
	jsonDataIntegration: JSON_DATA_INTEGRATION,
};

export const postValidationTokenHubspotIntegration = createAsyncThunk(
	'hubspotIntegration/validationTokenHubspotIntegration',
	async ({ slug, hubspot_token }, { rejectWithValue, dispatch }) => {
		try {
			return await validationTokenHubspotIntegration(slug, hubspot_token);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const getVecindarioVariablesHubspotIntegration = createAsyncThunk(
	'hubspotIntegration/vecindarioVariablesHubspotIntegration',
	async (data, { rejectWithValue, dispatch }) => {
		try {
			return await getVecindarioVariables(data);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const getHubspotVariablesHubspotIntegration = createAsyncThunk(
	'hubspotIntegration/hubspotVariablesHubspotIntegration',
	async ({ slug, selectToken }, { rejectWithValue, dispatch }) => {
		try {
			return await getHubspotVariables(slug, selectToken);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const postCreateHubspotIntegration = createAsyncThunk(
	'hubspotIntegration/createHubspotIntegration',
	async ({ slug, data }, { rejectWithValue, dispatch }) => {
		try {
			const response = await createHubspotIntegration(slug, data);
			dispatch(
				setMessageToast({
					type: 'success',
					message: i18nInstance.t(INTEGRATION_HUBSPOT.SUCCESS_INTEGRATION, { ns: DOMAIN_NAME }),
				}),
			);
			return response;
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const getDataHubspotIntegration = createAsyncThunk(
	'hubspotIntegration/getDataHubspotIntegration',
	async (slug, { rejectWithValue, dispatch }) => {
		try {
			return await getHubspotIntegration(slug);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const setObjVariables = createAsyncThunk(
	'hubspotIntegration/setObjVariables',
	async (payload, { rejectWithValue, dispatch, getState }) => {
		try {
			const { isContactVariables, inputValues } = payload;
			dispatch(
				setMessageToast({
					type: 'success',
					message: i18nInstance.t(INTEGRATION_HUBSPOT.SUCCESS_SAVE_DATA, { ns: DOMAIN_NAME }),
				}),
			);
			if (isContactVariables) {
				return { key: 'contactVariables', inputValues };
			}
			return { key: 'businessVariables', inputValues };
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: i18nInstance.t(SERVER_ERROR, { ns: DOMAIN_NAME }),
				}),
			);
			return rejectWithValue(error);
		}
	},
);

const hubspotIntegration = createSlice({
	name: 'hubspotIntegration',
	initialState,
	reducers: {
		resetState() {
			return initialState;
		},
		setFormData(state, { payload }) {
			const { name, value } = payload;
			state[name] = value;
		},
		setValidToken(state, { payload }) {
			state.jsonDataIntegration.data.api_token = payload;
		},
		setObjPipelinesStages(state, { payload }) {
			payload.forEach(({ pipeline_id, stage, opportunity_type }) => {
				const existingPipelineIndex = state.jsonDataIntegration.data.pipelines.findIndex(
					(pipeline) => pipeline.pipeline_id === pipeline_id && pipeline.stage === stage,
				);

				if (existingPipelineIndex !== -1) {
					const existingPipeline = state.jsonDataIntegration.data.pipelines[existingPipelineIndex];
					if (!existingPipeline.group.includes(opportunity_type)) {
						state.jsonDataIntegration.data.pipelines[existingPipelineIndex] = {
							...existingPipeline,
							group: [...existingPipeline.group, opportunity_type],
						};
					}
				} else {
					const newPipeline = {
						pipeline_id,
						stage,
						group: [opportunity_type],
					};

					state.jsonDataIntegration.data.pipelines.push(newPipeline);
				}
			});
		},
		resetObjPipelinesStages(state) {
			state.jsonDataIntegration.data.pipelines = [];
		},
	},
	extraReducers: {
		[postValidationTokenHubspotIntegration.pending]: (state) => {
			state.loadingValidateToken = true;
			state.errorToken = '';
		},
		[postValidationTokenHubspotIntegration.fulfilled]: (state, { payload }) => {
			state.loadingValidateToken = false;
			state.hubspotTokenPipelines = payload || {};
		},
		[postValidationTokenHubspotIntegration.rejected]: (state, { payload }) => {
			state.loadingValidateToken = false;
			state.errorToken = payload;
		},
		[getVecindarioVariablesHubspotIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[getVecindarioVariablesHubspotIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.vecindarioVariables = payload || {};
		},
		[getVecindarioVariablesHubspotIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
		[getHubspotVariablesHubspotIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[getHubspotVariablesHubspotIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.husbpotVariables = payload || {};
		},
		[getHubspotVariablesHubspotIntegration.rejected]: (state) => {
			state.loading = false;
		},
		[postCreateHubspotIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postCreateHubspotIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.jsonDataIntegration = { data: payload } || {};
		},
		[postCreateHubspotIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
		[setObjVariables.fulfilled]: (state, { payload }) => {
			state.loading = false;
			const { key, inputValues } = payload;
			state.jsonDataIntegration.data[key] = inputValues;
		},
		[getDataHubspotIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[getDataHubspotIntegration.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.jsonDataIntegration = { data: payload } || {};
		},
		[getDataHubspotIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
	},
});

export const { resetState, setValidToken, setObjPipelinesStages, resetObjPipelinesStages } = hubspotIntegration.actions;

export default hubspotIntegration.reducer;
