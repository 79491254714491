import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { CANCEL_BUTTON } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import './AlertCard.scss';

const AlertCard = ({ cardTitle, children, buttonActionText, handleCancel, handleAction }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className="alert-campaign-container">
				<div className="header">
					<p>{cardTitle}</p>
					<Icon icon={ICON_CLOSE_LINE} aditionalClassName="icon-close" onClick={handleCancel} />
				</div>
				<div className="accounts-container">{children}</div>
				<div className="buttons-container">
					<Button variant="default" size="-small" className="large-btn" onClick={handleAction}>
						{buttonActionText}
					</Button>
					<Button variant="bordered" size="-small" className="large-btn" onClick={handleCancel}>
						{t(CANCEL_BUTTON, { ns: DOMAIN_NAME_SHARED })}
					</Button>
				</div>
			</div>
		</>
	);
};

AlertCard.propTypes = {
	cardTitle: PropTypes.string,
	children: PropTypes.array,
	buttonActionText: PropTypes.string,
	handleCancel: PropTypes.func,
	handleAction: PropTypes.func,
};

export default AlertCard;
