import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@vecindario/vecindario-suite-components';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import OptionsButtons from './OptionsButtons';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { TEXTS } from '../../../infrastructure/locales/translation_keys';
import './MoreOptionsButton.scss';

function MoreOptionsButton({ showScoringButton, simulatorUrl, schedulerUrl, scoringUrl }) {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<div className="container-button">
			<Popover
				isOpen={isPopoverOpen}
				content={
					<OptionsButtons
						showScoringButton={showScoringButton}
						simulatorUrl={simulatorUrl}
						schedulerUrl={schedulerUrl}
						scoringUrl={scoringUrl}
					/>
				}
				positions={['bottom', 'left']}
				padding={20}
				reposition={true}
				align="end"
				containerClassName="popover-search-container"
				onClickOutside={() => setIsPopoverOpen(false)}
			>
				<div className="popover-content">
					<Button variant="bordered" className="more-button action-button" onClick={() => setIsPopoverOpen(!isPopoverOpen)}>
						{t(`${TEXTS.MORE}`, { ns: DOMAIN_NAME })}
					</Button>
				</div>
			</Popover>
		</div>
	);
}

MoreOptionsButton.propTypes = {
	showScoringButton: PropTypes.bool,
	simulatorUrl: PropTypes.string,
	schedulerUrl: PropTypes.string,
	scoringUrl: PropTypes.string,
};

export default MoreOptionsButton;
