import React from 'react';
import { Route } from 'react-router-dom';
import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import SimulatioEmbeddedPage from '../presentation/pages/EmbeddedPages/Simulator';
import OpportunityHistory from '../presentation/pages/OpportunityHistory';
import { contactSlug, opportunityHistoryRoute, schedulePropertySlug, simulatePropertySlug } from './routes';
import SchedulerEmbeddedPage from '../presentation/pages/EmbeddedPages/Scheduler';
import ContactEmbeddedPage from '../presentation/pages/EmbeddedPages/Contact';

const historyRouter = {
	layout: AdminLayout,
	router: [
		{
			path: opportunityHistoryRoute(),
			page: OpportunityHistory,
			routeComponent: PrivateRoute,
			exact: false,
		},
		{
			path: simulatePropertySlug(),
			page: SimulatioEmbeddedPage,
			routeComponent: Route,
			layout: ({ children }) => <>{children}</>,
		},
		{
			path: schedulePropertySlug(),
			page: SchedulerEmbeddedPage,
			routeComponent: Route,
			layout: ({ children }) => <>{children}</>,
		},
		{
			path: contactSlug(),
			page: ContactEmbeddedPage,
			routeComponent: Route,
			layout: ({ children }) => <>{children}</>,
		},
	],
};

export default historyRouter;
