import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import SettingsPage from '../presentation/pages/Settings';
import { settingsRoute } from './routes';

const settingsRouter = {
	layout: AdminLayout,
	router: [
		{
			path: settingsRoute(),
			page: SettingsPage,
			routeComponent: PrivateRoute,
		},
	],
};

export default settingsRouter;
