import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, TextTag, ToggleSwitch } from '@vecindario/vecindario-suite-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import './IntegrationCard.scss';
import {
	HUBSPOT,
	SICO,
	SINCO,
	SUITE,
	ZOHO,
	GENERIC_WEBHOOK,
	ZAPIER_WEBHOOK,
	META,
} from '../../../application/constants/inventory';
import SincoIntegration from './SincoIntegration';
import Divider from '../../../../../shared/presentation/components/Divider';
import ModalWizard from '../../../../../shared/presentation/components/ModalWizard';
import { activeIntegration as activeIntegrationSelector } from '../../../application/selector/sincoIntegration';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { INTEGRATIONS, INTEGRATION_HUBSPOT, SYNC } from '../../../infrastructure/locales/translation_keys';
import { COPIED, GENERATE_AGAIN, NO_COPIED } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { history } from '../../../../../shared/application/helpers/history';
import { accountFBRoute, connectFBRoute } from '../../../../facebook/infrastructure/routes';
import { selectAccount } from '../../../../facebook/application/selector/facebook';
import { integrationsHubspotShowIntegration } from '../../../infrastructure/routes';
import { SHOW_INTEGRATION_PATH } from '../../../application/constants/hubspotIntegration';

const IntegrationCards = ({ data, onActiveIntegration, activeData, onDesactivateIntegration, token, show }) => {
	const { slug } = useParams();
	const account = useSelector(selectAccount);
	const [activeIntegration, setActiveIntegration] = useState(false);
	const [isModalActiveOpen, setIsModalActiveOpen] = useState(false);
	const isActivatedSincoIntegration = useSelector(activeIntegrationSelector);
	const [activeToggle, setActiveToggle] = useState(false);
	const [url, setUrl] = useState('');
	const { t } = useTranslation();
	const [copyTextSlug, setCopyTextSlug] = useState(
		t(`${INTEGRATIONS.COPY_TO_CLIPBOARD}`, { ns: DOMAIN_NAME, text: 'slug' }),
	);
	const [copyTextToken, setCopyTextToken] = useState(
		t(`${INTEGRATIONS.COPY_TO_CLIPBOARD}`, { ns: DOMAIN_NAME, text: 'token' }),
	);

	const { image, title, description } = data;

	useEffect(() => {
		if (activeData) {
			setActiveToggle(true);
		}
	}, [activeData]);

	const activeIntegrationButton = () => {
		if (data.type === META) {
			const historyState = { slug };
			if (account?.id) {
				history.push(accountFBRoute(slug), historyState);
			} else {
				history.push(connectFBRoute(slug), historyState);
			}
		} else if (activeIntegration || [HUBSPOT, SUITE, ZOHO].includes(data.type)) {
			onActiveIntegration(url, data.type);
		} else {
			setActiveIntegration(!activeIntegration);
		}
	};

	const desactivateIntegration = () => {
		setActiveToggle(!activeToggle);
		onDesactivateIntegration(data.type);
		setIsModalActiveOpen(false);
		setActiveIntegration(false);
	};

	const copyToClipboard = (text, textToCopy, setText) => {
		navigator.clipboard
			.writeText(textToCopy)
			.then(() => {
				setText(t(COPIED, { ns: DOMAIN_NAME_SHARED }));
				setTimeout(() => {
					setText(t(INTEGRATIONS.COPY_TO_CLIPBOARD, { ns: DOMAIN_NAME, text }));
				}, 2000);
			})
			.catch(() => setText(t(NO_COPIED, { ns: DOMAIN_NAME_SHARED })));
	};

	const showToggle = () => ![HUBSPOT, SUITE, ZOHO, GENERIC_WEBHOOK, ZAPIER_WEBHOOK].includes(data.type);

	const sincoModifier = data.type === SINCO && isActivatedSincoIntegration;

	const validateButtonText = () => {
		if (activeIntegration) {
			return t(`${INTEGRATIONS.ACTIVATE_TEXT_BUTTON}`, { ns: DOMAIN_NAME });
		}
		if (data.type === HUBSPOT) {
			return t(`${INTEGRATIONS.ACTIVATE_TEXT_HUBSPOT_BUTTON}`, { ns: DOMAIN_NAME });
		}
		if (data.type === SUITE) {
			return t(`${INTEGRATIONS.GENERATE_TOKEN_TEXT_BUTTON}`, { ns: DOMAIN_NAME });
		}
		if (data.type === META) {
			if (account?.id) {
				return t(`${INTEGRATIONS.SEE_INTEGRATION_TEXT_BUTTON}`, { ns: DOMAIN_NAME });
			}
			return t(`${INTEGRATIONS.SET_UP_TEXT_BUTTON}`, { ns: DOMAIN_NAME });
		}
		return t(`${INTEGRATIONS.INTEGRATE_TEXT_BUTTON}`, { ns: DOMAIN_NAME });
	};

	const handleGoIntegration = () => {
		history.push(integrationsHubspotShowIntegration(slug, SHOW_INTEGRATION_PATH));
	};

	return (
		<>
			{show && (
				<div className={`integratios-container-card ${activeIntegration ? 'active' : 'inactive'}`}>
					<div className={`integratios-card-body ${sincoModifier ? '-sinco-active' : ''}`}>
						<img src={image} alt="" className="integration-image" />
						<TextTag tag="h5" className="card-title">
							{t(`${INTEGRATIONS[title]}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="label" className="card-description">
							<span dangerouslySetInnerHTML={{ __html: t(`${INTEGRATIONS[description]}`, { ns: DOMAIN_NAME }) }} />
						</TextTag>
					</div>

					{data.type !== SINCO && !activeData && !token && (
						<>
							<div className={`integratios-container-input ${activeIntegration ? 'active' : 'inactive'}`}>
								{data.type !== SINCO && activeIntegration && (
									<>
										<TextTag tag="label" className="card-description">
											{t(`${INTEGRATIONS[data?.inputLabel]}`, { ns: DOMAIN_NAME })}
										</TextTag>
										<div className="active-url-action">
											<Input onChange={(e) => setUrl(e.target.value)} />
										</div>
									</>
								)}
							</div>

							<div className="integratios-button-container">
								<Button variant="bordered back" text={validateButtonText()} onClick={activeIntegrationButton} />
							</div>
						</>
					)}

					{data.type === SINCO && (
						<SincoIntegration
							slug={slug}
							data={data}
							activeIntegration={activeIntegration}
							onActiveIntegration={setActiveIntegration}
							onDeactivate={setIsModalActiveOpen}
							isActivatedIntegration={isActivatedSincoIntegration}
						/>
					)}

					{data.type === SICO && token && (
						<div className="integratios-switch-container">
							<ToggleSwitch.Label id={data.type} value={activeToggle} onChange={() => setIsModalActiveOpen(true)} />
						</div>
					)}

					{data.type === HUBSPOT && activeData && (
						<div className="integratios-button-container">
							<Button variant="bordered" onClick={handleGoIntegration}>
								{t(INTEGRATION_HUBSPOT.SHOW_INTEGRATION_BUTTON_CARD, { ns: DOMAIN_NAME })}
							</Button>
						</div>
					)}

					{![HUBSPOT, SICO].includes(data.type) && activeData && (
						<>
							<Divider />
							{token && ![GENERIC_WEBHOOK, ZAPIER_WEBHOOK].includes(data.type) && (
								<>
									<Input value={slug} disabled={true} size="small" />{' '}
									<span className="copy" onClick={() => copyToClipboard('slug', slug, setCopyTextSlug)}>
										{copyTextSlug}
									</span>
									<Input value={token} disabled={true} size="small" />{' '}
									<span className="copy" onClick={() => copyToClipboard('token', token, setCopyTextToken)}>
										{copyTextToken}
									</span>
									<Button
										variant="default back"
										text={t(GENERATE_AGAIN, { ns: DOMAIN_NAME_SHARED })}
										onClick={activeIntegrationButton}
									/>
								</>
							)}

							{[GENERIC_WEBHOOK, ZAPIER_WEBHOOK].includes(data.type) && (
								<>
									<Input value={token} disabled={true} />{' '}
									<div className="integratios-switch-container">
										<ToggleSwitch.Label id={data.type} value={activeToggle} onChange={() => setIsModalActiveOpen(true)} />
									</div>
								</>
							)}

							{!token && showToggle() && (
								<div className="integratios-switch-container">
									<ToggleSwitch.Label id={data.type} value={activeToggle} onChange={() => setIsModalActiveOpen(true)} />
								</div>
							)}

							{!token && data.type === ZOHO && (
								<div className="integratios-switch-container">
									<TextTag className="pending-integration" fw="normal" variant="-body-sm" tag="p">
										{t(`${INTEGRATIONS.REQUEST_INTEGRATION}`, { ns: DOMAIN_NAME })}
									</TextTag>
									<ToggleSwitch.Label
										disabled={true}
										id={data.type}
										value={activeToggle}
										onChange={() => setIsModalActiveOpen(true)}
									/>
								</div>
							)}
						</>
					)}
					<ModalWizard isOpen={isModalActiveOpen} onClose={() => setIsModalActiveOpen(false)}>
						<div className="modal-form-validate-extras">
							<div className="modal-form-body">
								<TextTag tag="h4">{t(`${SYNC.DESACTIVATE}`, { ns: DOMAIN_NAME })}</TextTag>
								<br></br>
								<TextTag tag="small" className="description" variant="-body-sm">
									{t(`${SYNC.SURE}`, { ns: DOMAIN_NAME })}
								</TextTag>
							</div>
							<div className="validate-extras-button-container">
								<Button
									variant="bordered back"
									text={t(`${SYNC.NO}`, { ns: DOMAIN_NAME })}
									onClick={() => setIsModalActiveOpen(false)}
								/>{' '}
								<Button
									variant="default next"
									text={t(`${SYNC.YES}`, { ns: DOMAIN_NAME })}
									onClick={() => desactivateIntegration()}
								/>
							</div>
						</div>
					</ModalWizard>
				</div>
			)}
		</>
	);
};

IntegrationCards.propTypes = {
	data: PropTypes.any,
	onActiveIntegration: PropTypes.func,
	activeData: PropTypes.object,
	onDesactivateIntegration: PropTypes.func,
	token: PropTypes.func,
	show: PropTypes.bool,
};

export default IntegrationCards;
