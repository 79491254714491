import { createSelector } from '@reduxjs/toolkit';

const hubspotIntegrationState = (state) => state?.hubspotIntegration;

export const selectHubspotTokenPipelines = createSelector(hubspotIntegrationState, (state) => {
	return state?.hubspotTokenPipelines;
});

export const selectLoading = createSelector(hubspotIntegrationState, (state) => {
	return state?.loading;
});

export const selectLoadingValidateToken = createSelector(hubspotIntegrationState, (state) => {
	return state?.loadingValidateToken;
});

export const selectErrorResponse = createSelector(hubspotIntegrationState, (state) => {
	return state?.error;
});

export const selectErrorTokenResponse = createSelector(hubspotIntegrationState, (state) => {
	return state?.errorToken;
});

export const selectVecindarioVaraibles = createSelector(hubspotIntegrationState, (state) => {
	return state?.vecindarioVariables;
});

export const selectJsonDataIntegrationPipelines = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration?.data?.pipelines;
});

export const selectJsonDataIntegrationContactVariables = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration?.data?.contactVariables;
});

export const selectJsonDataIntegrationBusinessVariables = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration?.data?.businessVariables;
});

export const selectJsonDataToken = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration?.data?.api_token;
});

export const selectHubspotContactVaraibles = createSelector(hubspotIntegrationState, (state) => {
	return state?.husbpotVariables?.contacts;
});

export const selectHubspotDealsVaraibles = createSelector(hubspotIntegrationState, (state) => {
	return state?.husbpotVariables?.deals;
});

export const selectJsonDataIntegration = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration;
});

export const selectJsonDataErrorsIntegration = createSelector(hubspotIntegrationState, (state) => {
	return state?.jsonDataIntegration?.data?.errors;
});

export default {};
