import {
	ECONOMIC_ACTIVITY_KEY,
	EMAIL_KEY,
	EMPLOYEE_KEY,
	INDEPENDENT_KEY,
	LAST_NAME_KEY,
	MOBILE_KEY,
	MONTHLY_PAYMENT_CAPACITY_KEY,
	NAME_KEY,
	OTHER_ACTIVITY_KEY,
	PENSIONER_KEY,
	RESIDENCE_CITY_KEY,
} from '../../../application/constants/contactInformation';
import {
	DATE_ROW,
	DESISTED_STATUS,
	DETAIL_ROW,
	DISCARTED_STATUS,
	INTERESTED_STATUS,
	PROPERTY_ROW,
	ACTIONS_ROW,
	RESERVED_STATUS,
	SEPARATED_STATUS,
	STAGE_CONTACT,
	STAGE_DESISTED_BUSINESS,
	STAGE_DESISTED_WITHOUT_REFUND,
	STAGE_DESISTED_WITH_REFUND,
	STAGE_DISCARDED,
	STAGE_DONE,
	STAGE_MOVE_TO_DISCARDED,
	STAGE_MOVE_TO_INTERESTED,
	STAGE_MOVE_TO_RESERVED,
	STAGE_MOVE_TO_VISITED,
	STAGE_OPTIONED_PROPERTY,
	STAGE_QUOTED,
	STAGE_RESERVED,
	STAGE_ROW,
	STAGE_SCHEDULE,
	STAGE_SEPARATED,
	STAGE_SEPARATED_BY_OPTION,
	STAGE_SIMULATE,
	STAGE_VERIFYING_PAYMENT,
	STAGE_VISITED,
	TRANSACTION_STAGE_DISPERSED,
	TRANSACTION_STAGE_READY_TO_DISPERSE,
	VISITED_STATUS,
	CONTACT_QUESTION_SLUGS,
	STAGE_EXTERNAL_CONTACT_REQUEST_LABEL,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_EXTERNAL_CONTACT_REQUEST_TEXT,
	STAGE_SOCIAL_LEADS,
	STAGE_LEADS_FACEBOOK,
	STAGE_LEADS_GOOGLE,
} from '../../../application/constants/opportunityHistory';
import {
	OPTION_STATE_ACTIVE,
	OPTION_STATE_CANCELED,
	OPTION_STATE_CONFIRMED,
	OPTION_STATE_EXECUTED,
	OPTION_STATE_EXPIRED,
	OPTION_STATE_PENDING,
} from '../../../application/constants/optionedInformation';
import {
	DOCUMENT_TYPE_CC,
	DOCUMENT_TYPE_CE,
	DOCUMENT_TYPE_NIT,
	DOCUMENT_TYPE_P,
	INVALID_DOCUMENT_CE_FORMAT,
	INVALID_DOCUMENT_P_FORMAT,
} from '../../../application/constants/userInformation';
import {
	OPPORTUNITY_CARD,
	TEXTS,
	MODAL,
	PAYMENT_MODAL,
	STAGES,
	TABLE_HEADER,
	TABLE_ROWS,
	BASE_FIELDS_KEYS,
	ADDITIONAL_FIELDS_KEYS,
	ECONOMIC_ACTIVITIES_KEYS,
	MODAL_UUNAVAILABLE_PROPERTY,
	MOVED_BY,
	OPORTUNITY_STATUS,
	TOOLTIP_MSG_DATE_PROGRAMED,
	MODAL_USER_IDENTIFICATION,
	DISPERSED_MODAL,
	CONTACT_MODAL_TITLE,
	DESIST,
	NOTES,
	INFORMATION_TITLE,
	DESISTED,
	OPTIONED_USER_IDENTIFICATION_FORM,
	CONFIRMATION_OPTIONS,
	OPTION_DETAILS_PAGE,
	SEPARATED_OPTION_DETAIL,
	CONFIRM_PURCHASE,
	MESSAGE_OPTION,
	EXTEND_VALIDITY_PAGE,
	ERROR_EMPTY_FILE,
	ERROR_GREATER_THAN_ZERO,
	USER_ROLES,
	CANCEL_OPTION_PAGE,
	OPTIONS_STATE,
	DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT,
	WEB_ANALISIS,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_1,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_2,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_3,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_4,
	TOOLTIP_MSG_APPOINTMENT_TYPE,
	APPOINTMENT_PHYSICAL_TYPE_TEXT,
	APPOINTMENT_DIGITAL_TYPE_TEXT,
	QUOTATION_IDENTIFICATION_TEXT,
	OPTION_SNAPSHOT_TEXTS,
	MOVED_IN,
	OPTION_STATE_TEXTS,
	CONTACT_ADDITIONAL_FIELDS,
	CAMPAIGN_NAME_TITLE_ROW,
	EXTERNAL_CONTACT,
	FORM_CONTACT,
} from '../translation_keys';

const SIMULATED = 'Simulated';
const DISCARDED = 'Discarded';
const VISITED = 'Visited';

export default {
	[OPPORTUNITY_CARD.STAGE]: 'Stage',
	[OPPORTUNITY_CARD.ASSESOR]: 'Agent',
	[OPPORTUNITY_CARD.NO_ASSESOR]: 'Unassigned',
	[OPPORTUNITY_CARD.NOTES]: 'Notes',
	[OPPORTUNITY_CARD.NO_NOTES]: 'No notes yet, be the first to leave one',
	[OPPORTUNITY_CARD.SAVE]: 'Save',
	[OPPORTUNITY_CARD.NOTE]: 'note',
	[OPPORTUNITY_CARD.CHANGES]: 'changes',
	[OPPORTUNITY_CARD.DISCARD]: 'Cancel',
	[OPPORTUNITY_CARD.START_ATTENTION]: 'Start attention',
	[TEXTS.TITLE]: 'Client History',
	[TEXTS.MORE]: `More ...`,
	[TEXTS.QUOTER]: 'Simulate',
	[TEXTS.SCHEDULER]: 'Schedule',
	[TEXTS.ATTENDED_BY]: 'Attended by',
	[TEXTS.OPTION]: `Option property`,
	[TEXTS.SCORING]: `Get Scoring`,
	[MODAL.DETAILS]: 'View Details',
	[MODAL.VERIFY]: 'Under Verification',
	[MODAL.VERIFY_PAYMENT]: 'Payment under verification',
	[MODAL.VERIFY_PAYMENT_PROCESS]: 'This opportunity has a payment process under verification',
	[MODAL.BLOCK_DATE]: 'Block Date',
	[MODAL.PAYMENT_DATE]: 'Payment Date',
	[MODAL.PAYMENT_METHOD]: 'Payment Method',
	[MODAL.PAID_WITH]: 'Paid with',
	[MODAL.REFERENCE_CODE]: 'Reference Code',
	[MODAL.VALUE_PAID]: 'Payment Value',
	[PAYMENT_MODAL.TITLE]: 'Share the link with the buyer',
	[PAYMENT_MODAL.TITLE_2]: 'Choose the payment method the buyer will use to make the reservation',
	[PAYMENT_MODAL.PAYMENT_BLOCK_PROP_TEXT]:
		'By selecting this method, the property will be blocked for <b>{{minutes}}</b>, and it is also the time the user has to make the reservation.',
	[PAYMENT_MODAL.VALUE_BUY]: 'Purchase Value',
	[PAYMENT_MODAL.TIME_LEFT]: 'Time Left',
	[PAYMENT_MODAL.TIME_LEFT_TEXT]:
		'The client has a maximum of {{minutes}}, to complete the purchase, otherwise, you must regenerate the payment link.',
	[PAYMENT_MODAL.BUY_LINK]: 'Purchase Link',
	[PAYMENT_MODAL.SEPARATED_VALUE]: 'Separated Value',
	[PAYMENT_MODAL.PROPERTY_BLOKED]: 'Property Blocked',
	[PAYMENT_MODAL.PROPERTY_BLOKED_TEXT]: 'According to the selected payment method, the property will be blocked.',
	[PAYMENT_MODAL.tarjeta_de_credito]: 'Credit Card',
	[PAYMENT_MODAL.efectivo]: 'Cash',
	[PAYMENT_MODAL.pse]: 'PSE',
	[PAYMENT_MODAL.BLOCK_PROP_QUESTION]:
		'Do you want to block the property for <br/>{{minutes}}, and generate a payment link?',
	[STAGES[STAGE_RESERVED]]: 'Reserved',
	[STAGES[STAGE_SIMULATE]]: SIMULATED,
	[STAGES[STAGE_QUOTED]]: 'Quoted',
	[STAGES[STAGE_SCHEDULE]]: 'Scheduled',
	[STAGES[STAGE_DISCARDED]]: DISCARDED,
	[STAGES[STAGE_VISITED]]: VISITED,
	[STAGES[STAGE_SEPARATED]]: 'Separated',
	[STAGES[STAGE_SEPARATED_BY_OPTION]]: 'Separated',
	[STAGES[STAGE_DONE]]: 'Attended',
	[STAGES[STAGE_MOVE_TO_INTERESTED]]: SIMULATED,
	[STAGES[STAGE_MOVE_TO_VISITED]]: VISITED,
	[STAGES[STAGE_MOVE_TO_RESERVED]]: 'Compró',
	[STAGES[STAGE_MOVE_TO_DISCARDED]]: DISCARDED,
	[STAGES[STAGE_DESISTED_WITH_REFUND]]: 'Withdrawn with refund',
	[STAGES[STAGE_DESISTED_WITHOUT_REFUND]]: 'Withdrawn without refund',
	[STAGES[STAGE_DESISTED_BUSINESS]]: 'Business withdrawn',
	[STAGES[TRANSACTION_STAGE_READY_TO_DISPERSE]]: 'Ready for disbursement',
	[STAGES[TRANSACTION_STAGE_DISPERSED]]: 'Disbursed',
	[STAGES[STAGE_CONTACT]]: 'Contact',
	[STAGES[STAGE_EXTERNAL_CONTACT_REQUEST]]: 'Contact',
	[STAGES[STAGE_VERIFYING_PAYMENT]]: 'Verifying payment',
	[STAGES[STAGE_OPTIONED_PROPERTY]]: 'Optioned',
	[STAGES[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]]: 'Contact FB',
	[STAGES[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]]: 'Contact G',
	[TABLE_HEADER[DATE_ROW]]: 'Date',
	[TABLE_HEADER[STAGE_ROW]]: 'Status',
	[TABLE_HEADER[PROPERTY_ROW]]: 'Property',
	[TABLE_HEADER[DETAIL_ROW]]: 'Details',
	[TABLE_HEADER[ACTIONS_ROW]]: 'Actions',
	[TABLE_ROWS.NO_PROPERTY]: 'Not applicable',
	[CONTACT_MODAL_TITLE]: 'Buyer detail',
	[BASE_FIELDS_KEYS[NAME_KEY]]: 'Names',
	[BASE_FIELDS_KEYS[LAST_NAME_KEY]]: 'Family name',
	[BASE_FIELDS_KEYS[MOBILE_KEY]]: 'Cellphone',
	[BASE_FIELDS_KEYS[EMAIL_KEY]]: 'Email',
	[BASE_FIELDS_KEYS.IDENTIFICATION]: 'Identification',
	[BASE_FIELDS_KEYS.INCOME]: 'Income',
	[ADDITIONAL_FIELDS_KEYS[RESIDENCE_CITY_KEY]]: 'Residence city',
	[ADDITIONAL_FIELDS_KEYS[MONTHLY_PAYMENT_CAPACITY_KEY]]: 'Monthly payment capacity',
	[ADDITIONAL_FIELDS_KEYS[ECONOMIC_ACTIVITY_KEY]]: 'Job',
	[ECONOMIC_ACTIVITIES_KEYS[EMPLOYEE_KEY]]: 'Employee',
	[ECONOMIC_ACTIVITIES_KEYS[INDEPENDENT_KEY]]: 'Independent',
	[ECONOMIC_ACTIVITIES_KEYS[PENSIONER_KEY]]: 'Pensioner',
	[ECONOMIC_ACTIVITIES_KEYS[OTHER_ACTIVITY_KEY]]: 'Other',
	[MODAL_UUNAVAILABLE_PROPERTY.DESCRIPTION_MODAL]: `We're sorry, this property is not available.`,
	[MODAL_UUNAVAILABLE_PROPERTY.BUTTON_MODAL]: 'Select another property.',
	[MODAL_UUNAVAILABLE_PROPERTY.USER_WITHOUT_SIMULATION]: 'Please select a property',
	[MODAL_UUNAVAILABLE_PROPERTY.SEARCH_BUTTON]: 'Search',
	[MODAL_UUNAVAILABLE_PROPERTY.TEXT_1]: '1 person has optioned this property',
	[MODAL_UUNAVAILABLE_PROPERTY.TEXT_2]: '{{numPeople}} people have optioned this property',
	[MOVED_BY]: 'Moved by',
	[MOVED_IN]: 'Modification date',
	[OPORTUNITY_STATUS[INTERESTED_STATUS]]: 'Interested',
	[OPORTUNITY_STATUS[VISITED_STATUS]]: VISITED,
	[OPORTUNITY_STATUS[RESERVED_STATUS]]: 'Reserved',
	[OPORTUNITY_STATUS[SEPARATED_STATUS]]: 'Separated',
	[OPORTUNITY_STATUS[DISCARTED_STATUS]]: DISCARDED,
	[OPORTUNITY_STATUS[DESISTED_STATUS]]: 'Withdrawn',
	[TOOLTIP_MSG_DATE_PROGRAMED]: 'Scheduled appointment date',
	[TOOLTIP_MSG_APPOINTMENT_TYPE]: 'Appointment type',
	[DESIST]: 'Desist',
	[DESISTED]: 'Desisted',
	[MODAL_USER_IDENTIFICATION.TITLE]: "To proceed with the reservation, we need to ensure the customer's information.",
	[MODAL_USER_IDENTIFICATION.SELECT_DOC]: 'Select the type of document.',
	[MODAL_USER_IDENTIFICATION.TYPE_DOC_PLACEHOLDER]: 'Enter the identification number.',
	[MODAL_USER_IDENTIFICATION.TYPE_DOC_LABEL]: 'Enter the identification number.',
	[MODAL_USER_IDENTIFICATION.NO_COMFIRM_ID]: "We couldn't confirm your identity.",
	[MODAL_USER_IDENTIFICATION.CONTINUE_TEXT]: 'Continue reservation',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CC]]: 'Citizen identification card',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_NIT]]: 'NIT',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CE]]: 'Foreigner identification card',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_P]]: 'Pasport',
	[MODAL_USER_IDENTIFICATION[INVALID_DOCUMENT_CE_FORMAT]]:
		'*El documento puede comenzar con hasta 5 caracteres de texto, luego debe tener entre 4 y 8 caracteres numericos con el primero diferente a 0',
	[MODAL_USER_IDENTIFICATION[INVALID_DOCUMENT_P_FORMAT]]: '*El campo debe contenter entre 4 y 16 caracteres',
	[MODAL_USER_IDENTIFICATION.INVALID_VALUE_LENGTH_RANGE]:
		'The field must start with a number other than 0 and be between {{minValue}} and {{maxValue}}',
	[MODAL_USER_IDENTIFICATION.EMPTY_FIELD]: '*You must fill out this field to proceed.',
	[DISPERSED_MODAL.CLIENT_REFOUND_VALUE]: 'Value returned to the client',
	[DISPERSED_MODAL.VECINDARIO_SALES_COMMISSION]: 'Value charged by Vecindario as commission',
	[DISPERSED_MODAL.COMPANY_VALUE_SENT]: 'Value sent to the construction company',
	[DISPERSED_MODAL.REFOUND_DATE]: 'Return date',
	[DISPERSED_MODAL.DISPERSED_DATE]: 'Dispersion date',
	[DISPERSED_MODAL.DETAIL]: 'Detail',
	[NOTES.SURE]: 'Are you sure you want to delete this note?',
	[NOTES.ACCEPT]: "If you accept, all records of the note will be deleted, and you won't be able to undo this action.",
	[NOTES.BACK]: 'Go back',
	[NOTES.YES]: "Yes, I'm sure",
	[OPPORTUNITY_CARD.STAGES.Interesado]: 'Interested',
	[OPPORTUNITY_CARD.STAGES.Visitado]: 'Visited',
	[OPPORTUNITY_CARD.STAGES.Reservado]: 'Reserved',
	[OPPORTUNITY_CARD.STAGES.Descartado]: 'Discarded',
	[OPPORTUNITY_CARD.STAGES.Separado]: 'Separated',
	[OPPORTUNITY_CARD.STAGES.Desistido]: 'Desisted',
	[INFORMATION_TITLE]: 'Additional information',
	[CONFIRMATION_OPTIONS.TEXT_1]:
		'The latest property {{action}} is {{towerName}}-{{propertyName}} and is currently optioned by 1 person',
	[CONFIRMATION_OPTIONS.TEXT_1_1]:
		'The latest property {{action}} is {{towerName}}-{{propertyName}} and is currently optioned by {{numPeople}} people',
	[CONFIRMATION_OPTIONS.TEXT_1_2]:
		'The latest property {{action}} is {{towerName}}-{{propertyName}} and has no options at the moment',
	[CONFIRMATION_OPTIONS.TEXT_2]: 'Are you sure you want to option this property?',
	[CONFIRMATION_OPTIONS.TEXT_3]: `Yes, I'm sure`,
	[CONFIRMATION_OPTIONS.TEXT_4]: 'No, select another property',
	[CONFIRMATION_OPTIONS.TEXT_5]: 'simulated',
	[CONFIRMATION_OPTIONS.TEXT_6]: 'quoted',
	[OPTIONED_USER_IDENTIFICATION_FORM.TITLE]: 'Before opting complete the customer data',
	[OPTIONED_USER_IDENTIFICATION_FORM.CONTINUE]: 'Continue',
	[OPTION_DETAILS_PAGE.OPTIONED_WORD]: 'Optioned',
	[OPTION_DETAILS_PAGE.VIEW_OPTION]: 'See property options',
	[OPTION_DETAILS_PAGE.BODY_TEXT_1]: 'Option status',
	[OPTION_DETAILS_PAGE.BODY_TEXT_2]: 'Last status update date',
	[OPTION_DETAILS_PAGE.BODY_TEXT_3]: 'Name of the person who optioned',
	[OPTION_DETAILS_PAGE.BODY_TEXT_4]: 'Position in line',
	[OPTION_DETAILS_PAGE.BODY_TEXT_5]: 'Validity',
	[OPTION_DETAILS_PAGE.BODY_TEXT_5_1]: 'Validity (Extended)',
	[OPTION_DETAILS_PAGE.BODY_TEXT_6]: 'Extended by',
	[OPTION_DETAILS_PAGE.BODY_TEXT_7]: 'Confirmed by',
	[OPTION_DETAILS_PAGE.BODY_TEXT_8]: 'Canceled by',
	[OPTION_DETAILS_PAGE.BUTTON_CANCEL_OPTION]: 'Cancel option',
	[OPTION_DETAILS_PAGE.BUTTON_EXTEND_VALIDITY]: 'Extend validity',
	[OPTION_DETAILS_PAGE.BUTTON_CONFIRM_PURCHASE]: 'Confirm purchase',
	[SEPARATED_OPTION_DETAIL.TITLE]: 'Confirmed option',
	[SEPARATED_OPTION_DETAIL.COLUMN_PROPERTY]: 'Property',
	[SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMATION_DATE]: 'Confirmation date',
	[SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMED_BY]: 'Confirmed by',
	[CONFIRM_PURCHASE.TITLE]: 'Are you sure to confirm the purchase?',
	[CONFIRM_PURCHASE.DESCRIPTION]:
		'Doing so will mark the property as separate in the inventory and the business will be created', // verificar
	[CONFIRM_PURCHASE.YES_SURE]: 'Yes, sure',
	[CONFIRM_PURCHASE.CANCEL]: 'Cancel',
	[CONFIRM_PURCHASE.SUCCESS_MESSAGE]: 'Purchase confirmed successfully',
	[MESSAGE_OPTION.TEXT_1]:
		'The payment link cannot be generated because there are people in the line options for this property.',
	[MESSAGE_OPTION.TEXT_2]:
		'To generate the link, you must cancel the options of other clients, or if the user wants to pay for another property, cancel this option or generate the payment link for an available property. If the options are from opportunities of other agents, they or the directors must cancel the options.',
	[MESSAGE_OPTION.TEXT_3]: 'View details',
	[EXTEND_VALIDITY_PAGE.TITLE]: 'Extend validity for',
	[EXTEND_VALIDITY_PAGE.WORD_DAYS]: 'Days',
	[EXTEND_VALIDITY_PAGE.WORD_HOURS]: 'Hours',
	[EXTEND_VALIDITY_PAGE.INFO]: ' Calendar days will be considered',
	[EXTEND_VALIDITY_PAGE.BUTTON_CONFIRM]: 'Confirm',
	[EXTEND_VALIDITY_PAGE.INFO_EXCEPTION_EXTEND]: 'The validity time cannot be extended for more than a week',
	[EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_7]: 'Must be a number between 1 and 7',
	[EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_168]: 'Must be a number between 1 and 168',
	[ERROR_EMPTY_FILE]: 'This field cannot be empty',
	[ERROR_GREATER_THAN_ZERO]: 'The number must be equal to or greater than one',
	[USER_ROLES.DIRECTOR_ROLE]: 'director',
	[USER_ROLES.ASESOR_ROLE]: 'assessor',
	[CANCEL_OPTION_PAGE.TITLE]: 'Are you sure you want to cancel the option?',
	[CANCEL_OPTION_PAGE.BODY]: 'By doing so, your client will lose priority in the purchase intention.',
	[CANCEL_OPTION_PAGE.BUTTON_ACTION_1]: 'Yes, confirm',
	[CANCEL_OPTION_PAGE.BUTTON_ACTION_2]: 'No, go back',

	[OPTIONS_STATE.ACTIVE]: 'Active',
	[OPTIONS_STATE.PENDING]: 'Pending',
	[OPTIONS_STATE.CONFIRMED]: 'Confirmed',
	[OPTIONS_STATE.EXECUTED]: 'Executed',
	[OPTIONS_STATE.EXPIRED]: 'Expired',
	[OPTIONS_STATE.CANCELED]: 'Canceled',
	[DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT]:
		'You cannot option a property for this opportunity because it already has an active option.',
	[WEB_ANALISIS.TITLE]: 'Web Analysis',
	[WEB_ANALISIS.UTM_ID]: 'Campaign ID',
	[WEB_ANALISIS.UTM_SOURCE]: 'Campaign Source',
	[WEB_ANALISIS.UTM_MEDIUM]: 'Campaign Medium',
	[WEB_ANALISIS.UTM_CAMPAIGN]: 'Campaign Name',
	[WEB_ANALISIS.UTM_CONTENT]: 'Campaign Content',
	[WEB_ANALISIS.UTM_TERM]: 'Campaign Keywords',
	[WEB_ANALISIS.DOCUMENT_REFERER]: 'Referring Website',
	[WEB_ANALISIS.SOURCE]: 'Origin Source',
	[WEB_ANALISIS.SOURCE_URL]: "Action's Source URL",
	[WEB_ANALISIS.ACTION_PERFORMER]: 'Business Opportunity Created By',
	[WEB_ANALISIS.USER_ID_EMAIL]: 'Email of Opportunity Creator',
	[WEB_ANALISIS.UTM_ID_TOOLTIP]: 'Indicates which specific ad campaign the Business Opportunity creation refers to.',
	[WEB_ANALISIS.UTM_SOURCE_TOOLTIP]:
		'Corresponds to the source site where the ad or piece is displayed from which a visit to the destination URL originates and generates a Business Opportunity. Examples: facebook, google, twitter, project website, email, billboard, etc.',
	[WEB_ANALISIS.UTM_MEDIUM_TOOLTIP]:
		'Classify the campaign format that generated the Business Opportunity. Examples: search-cpc, story-organic, display-cpc, email-organic, qr, etc.',
	[WEB_ANALISIS.UTM_CAMPAIGN_TOOLTIP]:
		'It is the name of the campaign that generated the Business Opportunity. Examples: housing-fair-june-2023, living-in-medellin, etc.',
	[WEB_ANALISIS.UTM_CONTENT_TOOLTIP]:
		'For example, if you have two call-to-action links in the same email message, you can use utm_content and assign different values to each one to find out which version is more effective.',
	[WEB_ANALISIS.UTM_TERM_TOOLTIP]: 'Used to identify keywords in paid search ads.',
	[WEB_ANALISIS.DOCUMENT_REFERER_TOOLTIP]:
		'Captures the URL that referred or led the user to the website where the Vecindario embed that created the Business Opportunity is located.',
	[WEB_ANALISIS.SOURCE_TOOLTIP]: 'Captures where the Business Opportunity was generated from.',
	[WEB_ANALISIS.SOURCE_URL_TOOLTIP]: 'Indicates the URL from which the Business Opportunity was generated.',
	[WEB_ANALISIS.ACTION_PERFORMER_TOOLTIP]:
		'Captures who or what performs the action that generates the Business Opportunity. For example: a buyer, director, advisor, etc.',
	[WEB_ANALISIS.USER_ID_EMAIL_TOOLTIP]:
		'Stores the email of the person who performs the action that creates the Business Opportunity.',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_DIRECTOR]: 'director',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_COMPRADOR]: 'buyer',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_ASESOR]: 'adviser',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS]: 'Meta integration',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS_SOURCE]: 'Facebook leads',
	[WEB_ANALISIS.TITLE_FACEBOOK_BUSINESS]: 'Meta Business manager integration',

	// Contact action instance
	[CONTACT_QUESTION_SLUGS.ECONOMIC_ACTIVITY]: 'Occupation',
	[CONTACT_QUESTION_SLUGS.CITY_OF_RESIDENCE]: 'City of residence',
	[CONTACT_QUESTION_SLUGS.MONTHLY_PAYMENT_CAPACITY]: 'Monthly payment capacity',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_1]: 'Employee',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_2]: 'Self-employed',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_3]: 'Retired',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_4]: 'Other',

	// Schedule action instance
	[APPOINTMENT_PHYSICAL_TYPE_TEXT]: 'In-person',
	[APPOINTMENT_DIGITAL_TYPE_TEXT]: 'Online',

	// Quoted action instance
	[QUOTATION_IDENTIFICATION_TEXT]: 'Quotation ID',

	// Option snapshot action instance
	[OPTION_SNAPSHOT_TEXTS.ORDER]: 'Order',
	[OPTION_SNAPSHOT_TEXTS.EXPIRATION_DATE]: 'Expiration date',

	// Option states
	[OPTION_STATE_TEXTS[OPTION_STATE_PENDING]]: 'Pending',
	[OPTION_STATE_TEXTS[OPTION_STATE_ACTIVE]]: 'Active',
	[OPTION_STATE_TEXTS[OPTION_STATE_EXPIRED]]: 'Expired',
	[OPTION_STATE_TEXTS[OPTION_STATE_CANCELED]]: 'Canceled',
	[OPTION_STATE_TEXTS[OPTION_STATE_CONFIRMED]]: 'Confirmed',
	[OPTION_STATE_TEXTS[OPTION_STATE_EXECUTED]]: 'Executed',
	[ADDITIONAL_FIELDS_KEYS[STAGE_EXTERNAL_CONTACT_REQUEST_LABEL]]: 'Origin Source',
	[CONTACT_ADDITIONAL_FIELDS[STAGE_EXTERNAL_CONTACT_REQUEST_TEXT]]: 'External Contact Request API',
	[CAMPAIGN_NAME_TITLE_ROW]: 'Campaign name',

	[EXTERNAL_CONTACT.TITLE]: 'Contact FB',
	[EXTERNAL_CONTACT.DETAIL]: 'Detail',
	[EXTERNAL_CONTACT.REQUEST_TYPE]: 'Request Type',
	[EXTERNAL_CONTACT.CAMPAIGN_ID]: '{{provider}} Campaign ID',
	[EXTERNAL_CONTACT.CAMPAIGN_NAME]: '{{provider}} Campaign Name',
	[EXTERNAL_CONTACT.LEAD_REGISTRATION_DATE]: '{{provider}} Registration Date',
	[EXTERNAL_CONTACT.LEAD_ID]: 'Lead ID',
	[EXTERNAL_CONTACT.ADVERTISEMENT_NAME]: 'Ad Name',
	[EXTERNAL_CONTACT.AD_GROUP_NAME]: 'Ad Group Name',
	[EXTERNAL_CONTACT.FORM_ID]: 'Form ID',
	[EXTERNAL_CONTACT[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]]: 'Facebook Leads',
	[EXTERNAL_CONTACT[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]]: 'Google Leads',
	[EXTERNAL_CONTACT.CAMPAIGN_NAME_TOOLTIP]:
		'The campaign name you have set in the Meta Business Manager. This campaign name will help you identify the campaign integrated with Vecindario.',
	[EXTERNAL_CONTACT.CAMPAIGN_ID_TOOLTIP]:
		'The ID of the Facebook leads campaign you have created in the Meta Business Manager corresponding to this project integrated with Vecindario, which generated and/or updated the Business Opportunity.',
	[EXTERNAL_CONTACT.LEAD_REGISTRATION_DATE_TOOLTIP]:
		'The date on which the user registered in the Facebook leads campaign form.',
	[EXTERNAL_CONTACT.LEAD_ID_TOOLTIP]: 'The ID of the lead registered in the Facebook leads campaign.',
	[EXTERNAL_CONTACT.ADVERTISEMENT_NAME_TOOLTIP]:
		'The name of the ad created in the Meta Business Manager in which the user registered.',
	[EXTERNAL_CONTACT.FORM_ID_TOOLTIP]:
		'The ID of the form created in the Meta Business Manager in which the user registered.',

	[FORM_CONTACT.REQUEST_TYPE]: 'Request Type',
	[FORM_CONTACT.CONTACT_FORM]: 'Contact form',
};
