import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { getPropertyParsed } from '../../../../../application/helpers/opportunityHistory';
import { QUOTATION_IDENTIFICATION_TEXT, TABLE_HEADER } from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { PROPERTY_ROW } from '../../../../../application/constants/opportunityHistory';

const ColumnDetailQuotation = ({ item }) => {
	const { t } = useTranslation();

	return (
		<TextTag variant="-body-sm" className="detail-movedto-text">
			<table className="detail-table">
				<tbody className="body">
					<tr className="row">
						<th className="label">{t(TABLE_HEADER[PROPERTY_ROW], { ns: DOMAIN_NAME })}</th>
						<td className="value">{getPropertyParsed(item?.action_instance, t)}</td>
					</tr>

					<tr className="row">
						<th className="label">{t(QUOTATION_IDENTIFICATION_TEXT, { ns: DOMAIN_NAME })}</th>
						<td className="value">{item?.action_instance?.quotation_identification}</td>
					</tr>
				</tbody>
			</table>
		</TextTag>
	);
};

ColumnDetailQuotation.propTypes = {
	item: PropTypes.object,
};

export default ColumnDetailQuotation;
