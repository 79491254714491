export const CURRENT_PROJECT_SLUG = 'lastSelectedSlug';
export const urlRegex =
	/(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
export const ZAPIER_WEBHOOK = 'ZAPIER_WEBHOOK';
export const GENERIC_WEBHOOK = 'GENERIC_WEBHOOK';
export const HUBSPOT = 'HUBSPOT';
export const HUBSPOTV3 = 'HUBSPOTV3';
export const SUITE = 'SUITE';
export const SINCO = 'SINCO';
export const ZOHO = 'ZOHO';
export const INTEGRATION_HUBSPOT = 'integrationHubspotReq';
export const INTEGRATION_META = 'integrationMeta';
export const INTEGRATION_ZOHO = 'integrationZohoReq';
export const INTEGRATION_API = 'integrationApiReq';
export const SICO = 'SICO';
export const META = 'META';

export const DIRECTOR_ROLE = 'director';
export const ASESOR_ROLE = 'asesor';
export const BUYER_ROLE = 'comprador';
export const MARKETING_ROLE = 'marketing';

export const SUITE_DESCRIPTION = 'suiteDescription';
export const HUBSPOT_DESCRIPTION = 'hubspotDescription';
export const ZAPIER_DESCRIPTION = 'zapierDescription';
export const GENERIC_DESCRIPTION = 'genericDescription';
export const SINCO_DESCRIPTION = 'sincoDescription';
export const ZOHO_DESCRIPTION = 'zohoDescription';
export const SICO_DESCRIPTION = 'sicoDescription';
export const META_DESCRIPTION = 'metaDescription';

export const SUITE_INPUT_LABEL = 'suiteInputLabel';
export const HUBSPOT_INPUT_LABEL = 'hubspotInputLabel';
export const ZAPIER_INPUT_LABEL = 'zapierInputLabel';
export const GENERIC_INPUT_LABEL = 'genericInputLabel';
export const SINCO_INPUT_LABEL = 'sincoInputLabel';
export const ZOHO_INPUT_LABEL = 'zohoInputLabel';
export const SICO_INPUT_LABEL = 'sicoInputLabel';
export const META_INPUT_LABEL = 'metaInputLabel';
