import urlBase from '../../../../shared/infrastructure/api/backend-urls';

// INTEGRATIONS
export const urlActivateVecindarioIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion-vecindario`;
export const urlDeactivateVecindarioIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/desactivar-integracion-vecindario`;
export const urlGenerateApiToken = (slug = ':slug') => `${urlBase}proyectos/${slug}/crear-token-api-publica`;
export const urlGeteApiToken = (slug = ':slug') => `${urlBase}proyectos/${slug}/token-api-publica`;
export const urlSendMailIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/enviar-email-integracion`;
export const urlActivateWebhookIntegration = (slug = ':slug', webhookName = ':webhookName') =>
	`${urlBase}proyectos/${slug}/integracion-webhook/${webhookName}`;
export const urlGetWebhookIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-webhook`;
export const urlDeactivateWebhookIntegration = (slug = ':slug', webhookName = ':webhookName') =>
	`${urlBase}proyectos/${slug}/integracion-webhook/${webhookName}/desactivar`;
export const urlActivateSincoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sinco`;
export const urlActivateSincoIntegrationWithCompany = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion-sinco-empresa`;
export const urlActivateSincoIntegrationWithProject = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion-sinco-proyecto`;
export const urlDeactivateSincoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sinco`;
export const urlGetActiveSincoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sinco`;

export const urlActivateSicoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sico`;
export const urlDeactivateSicoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sico`;
export const urlGetActiveSicoIntegration = (slug = ':slug') => `${urlBase}proyectos/${slug}/integracion-sico`;

// HUBSPOT INTEGRATION
export const urlPostValidationTokenHubspotIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion/hubspot/pipelines`;
export const urlGetVecindarioVariables = (lang = ':lang') =>
	`${urlBase}integracion/hubspot/vecindario-variables/${lang}`;
export const urlGetHubspotVariables = (slug = ':slug', api_key = ':api_key') =>
	`${urlBase}proyectos/${slug}/integracion/hubspot-variables/${api_key}`;
export const urlCreateHubspotIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion/gestionar-integracion-hubspot`;
export const urlGetHubspotIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion/listado-integracion-hubspot`;
export const urlChangeTokenHubspotIntegration = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/integracion/eliminar-integracion-hubspot`;
