import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Modal, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatDate, formatCurrency } from '../../../../../shared/application/helpers/common-functions';
import { selectRequestHistory } from '../../../application/selector/history';
import {
	STAGE_DESISTED_WITH_REFUND,
	TRANSACTION_STAGE_DISPERSED,
} from '../../../application/constants/opportunityHistory';
import './DispersionDetailModal.scss';
import { DOMAIN_NAME } from '../../../../integrations/infrastructure/locales';
import { DISPERSED_MODAL, STAGES } from '../../../infrastructure/locales/translation_keys';

const modalContentStyle = {
	maxWidth: '340px',
	padding: '30px 30px 20px 30px',
	borderRadius: '8px',
	backgroundColor: '#FFF',
	inset: 'unset',
};

const modalOverlayStyle = {
	backgroundColor: 'rgba(0, 0, 0, 0.25)',
};

const closeModalStyle = {
	fontSize: '24px',
	top: '30px',
	right: '30px',
	fontWeight: 'bold',
};

const DispersionDetailModal = ({ open = false, onClose = () => {}, historyItem = {} }) => {
	const userHistory = useSelector(selectRequestHistory);
	const showRefundFields = useMemo(
		() => userHistory?.opportunity_histories.map((_history) => _history.action_type).includes(STAGE_DESISTED_WITH_REFUND),
		[userHistory],
	);
	const { t } = useTranslation();

	const dispersion = historyItem?.action_instance || {};

	return (
		<Modal
			isOpen={open}
			onClose={onClose}
			contentStyle={modalContentStyle}
			overlayStyle={modalOverlayStyle}
			closeStyle={closeModalStyle}
		>
			<span className="dispersed-detail-badge">{t(`${STAGES[TRANSACTION_STAGE_DISPERSED]}`, { ns: DOMAIN_NAME })}</span>
			<div className="dispersed-detail-content">
				{showRefundFields && (
					<div className="detail-section">
						<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
							{t(`${DISPERSED_MODAL.CLIENT_REFOUND_VALUE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
							{formatCurrency(dispersion?.client_refunded_value || 0)} COP
						</TextTag>
					</div>
				)}
				<div className="detail-section">
					<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
						{t(`${DISPERSED_MODAL.VECINDARIO_SALES_COMMISSION}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
						{formatCurrency(dispersion?.vecindario_commission_value || 0)} COP
					</TextTag>
				</div>
				<div className="detail-section">
					<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
						{t(`${DISPERSED_MODAL.COMPANY_VALUE_SENT}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
						{formatCurrency(dispersion?.sent_value_to_company || 0)} COP
					</TextTag>
				</div>
				{dispersion?.client_refund_date && showRefundFields && (
					<div className="detail-section">
						<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
							{t(`${DISPERSED_MODAL.REFOUND_DATE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
							{formatDate(dispersion?.client_refund_date, 'LL')}
						</TextTag>
					</div>
				)}
				{dispersion?.disperse_date && (
					<div className="detail-section">
						<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
							{t(`${DISPERSED_MODAL.DISPERSED_DATE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
							{formatDate(dispersion?.disperse_date, 'LL')}
						</TextTag>
					</div>
				)}
				<div className="detail-section">
					<TextTag tag="b" fw="bold" variant="-body-xs" classNamw="title">
						{t(`${DISPERSED_MODAL.DETAIL}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="p" variant="-body-sm" className="color-secondary-600t" classNamw="description">
						{historyItem?.detail || '---'}
					</TextTag>
				</div>
			</div>
		</Modal>
	);
};

DispersionDetailModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	historyItem: PropTypes.object,
};

export default DispersionDetailModal;
