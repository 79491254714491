import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import * as i18nKeys from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';

const ColumnDetailExternalContact = ({ item }) => {
	const { t } = useTranslation();
	const campaign_name = item?.action_instance?.external_source_information?.campaign_name;

	return (
		item?.action_instance !== null && (
			<TextTag variant="-body-sm" className="detail-movedto-text">
				<table className="detail-table">
					<tbody className="body">
						<tr className="row">
							<th className="label">{t(`${i18nKeys.CAMPAIGN_NAME_TITLE_ROW}`, { ns: DOMAIN_NAME })}</th>
							<td className="value">{campaign_name}</td>
						</tr>
					</tbody>
				</table>
			</TextTag>
		)
	);
};

ColumnDetailExternalContact.propTypes = {
	item: PropTypes.object,
};

export default ColumnDetailExternalContact;
