import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Icon, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import { getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import './ModalSeparationInformation.scss';
import { formatCurrency } from '../../../../../shared/application/helpers/common-functions';
import {
	STAGE_RESERVED,
	STAGE_SEPARATED,
	STAGE_VERIFYING_PAYMENT,
	urlPropertyOnInventory,
} from '../../../application/constants/opportunityHistory';
import VerifyingPaymentActions from '../VerifyingPaymentActions';
import { MODAL } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import {
	BUYER,
	EMAIL,
	FIDUCIARY_REFERENCE,
	PERSONAL_DOC,
	PROPERTY,
	RESERVED,
	SEE_PROPERTY,
	SEPARATED,
	TELEPHONE,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';

const ModalSeparationInformation = ({ item, showModal, setShowModal }) => {
	const currentProject = useSelector(getCurrentProject);
	const { t } = useTranslation();
	const getBuyerFullName = (user) => `${user?.name || ''} ${user?.surname || ''}`;
	const isVerifyingPayment = item.action_type === STAGE_VERIFYING_PAYMENT;
	const isSeparated = item.action_type === STAGE_SEPARATED;
	const isReserved = item.action_type === STAGE_RESERVED;

	const modalContentStyle = {
		display: 'block',
		maxHeight: '90vh',
		height: 'auto',
		inset: 'initial',
		padding: '3rem 2rem',
		width: '340px',
	};

	const redirectToProperty = () => {
		const towerId = item?.action_instance?.tower?.id;
		const propertyId = item?.action_instance?.property?.id;
		window.open(urlPropertyOnInventory(currentProject?.slug, towerId, propertyId), '_blank');
	};

	const time = item?.action_instance?.approval_date || item?.action_instance?.seed;
	const transactionTime = moment(time).format('YYYY/MM/DD - h:mm a');
	const blockTime = moment(item.created_at).format('YYYY/MM/DD - h:mm a');

	const handleClose = () => setShowModal(false);

	return (
		<Modal
			isOpen={showModal}
			contentStyle={modalContentStyle}
			showClose={true}
			onClose={handleClose}
			closeClassName="separation-information-close"
		>
			<div className="separation-information-modal">
				{isVerifyingPayment && <p className="status warning">{t(`${MODAL.VERIFY}`, { ns: DOMAIN_NAME })}</p>}
				{isSeparated && <p className="status success">{t(`${SEPARATED}`, { ns: DOMAIN_NAME_SHARED })}</p>}
				{isReserved && <p className="status success">{t(`${RESERVED}`, { ns: DOMAIN_NAME_SHARED })}</p>}

				{isVerifyingPayment && (
					<div style={{ textAlign: 'center' }}>
						<TextTag tag="b" variant="-body-sm" fw="bold" className="font-text">
							{t(`${MODAL.VERIFY_PAYMENT}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="color-secondary-600t">
							{t(`${MODAL.VERIFY_PAYMENT_PROCESS}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
				)}
				<TextTag tag="p" className="subtitle bold">
					<Icon icon="ri-user-smile-line" /> {t(`${BUYER}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
				<TextTag tag="p" className="text">
					{getBuyerFullName(item?.action_instance?.buyer)}
				</TextTag>
				<TextTag tag="p" className="text">
					<b>{t(`${TELEPHONE}`, { ns: DOMAIN_NAME_SHARED })}:</b> {item?.action_instance?.buyer?.mobile}
				</TextTag>
				<TextTag tag="p" className="text">
					<b>{t(`${PERSONAL_DOC}`, { ns: DOMAIN_NAME_SHARED })}:</b> {item?.action_instance?.buyer?.document}
				</TextTag>
				<TextTag tag="p" className="text">
					<b>{t(`${EMAIL}`, { ns: DOMAIN_NAME_SHARED })}:</b> {item?.action_instance?.buyer?.email}
				</TextTag>

				<TextTag tag="p" className="subtitle bold">
					<Icon icon="ri-home-smile-line" /> {t(`${PROPERTY}`, { ns: DOMAIN_NAME_SHARED })}
				</TextTag>
				<TextTag tag="p" className="text">
					{currentProject?.title} - {item?.action_instance?.tower?.name} - {item?.action_instance?.property?.name}
				</TextTag>
				{item?.action_instance?.property?.fiduciary_reference && (
					<TextTag tag="p" className="text">
						{t(`${FIDUCIARY_REFERENCE}`, { ns: DOMAIN_NAME_SHARED })}: {item?.action_instance?.property?.fiduciary_reference}
					</TextTag>
				)}

				{isVerifyingPayment ? (
					<>
						<TextTag tag="b" className="subtitle" fw="bold">
							{t(`${MODAL.BLOCK_DATE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" className="text">
							{blockTime}
						</TextTag>
					</>
				) : (
					<>
						<TextTag tag="p" className="subtitle bold">
							{t(`${MODAL.PAYMENT_DATE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" className="text">
							{transactionTime}
						</TextTag>
					</>
				)}

				<TextTag tag="p" className="see-property">
					<span onClick={redirectToProperty}>{t(`${SEE_PROPERTY}`, { ns: DOMAIN_NAME_SHARED })}</span>
				</TextTag>

				<TextTag tag="p" className="subtitle bold">
					<Icon icon="ri-wallet-3-line" /> {t(`${MODAL.PAYMENT_METHOD}`, { ns: DOMAIN_NAME })}
				</TextTag>
				<div className="payment-method">
					<Icon icon="ri-bank-card-2-line" /> {t(`${MODAL.PAID_WITH}`, { ns: DOMAIN_NAME })}{' '}
					{item?.action_instance?.payment_method_name}
				</div>

				{!isVerifyingPayment && (
					<>
						<TextTag tag="p" className="subtitle bold">
							<Icon icon="ri-wallet-3-line" /> {t(`${MODAL.REFERENCE_CODE}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" className="text reference-code">
							{item?.action_instance?.reference}
						</TextTag>
					</>
				)}

				<div className="divider"></div>

				<TextTag tag="p" className={`separation-value ${isVerifyingPayment ? 'warning' : 'success'}`}>
					<Icon icon="ri-price-tag-line" />
					<span className="text">{t(`${MODAL.VALUE_PAID}`, { ns: DOMAIN_NAME })}</span>
					<span className="price">{formatCurrency(item?.action_instance?.payment?.amount?.total || 0)}</span>
				</TextTag>
				<VerifyingPaymentActions historyItem={item} onSuccess={handleClose} />
			</div>
		</Modal>
	);
};

ModalSeparationInformation.propTypes = {
	item: PropTypes.object,
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
};

export default ModalSeparationInformation;
