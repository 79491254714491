import { moduleKeys } from '@vecindario/suite-library';

export default { moduleKeys };

export const {
	INVENTORY_KEY,
	SCHEDULER_KEY,
	LANDING_BUILDER_KEY,
	COMMERCIAL_OPPORTUNITIES_KEY,
	SSO_KEY,
	MAIN_DASHBOARD_KEY,
	RESERVATION_KEY,
	CONTACT_REQUEST_KEY,
	COTIZADOR_KEY,
	MORTGAGE_CREDITS_KEY,
} = moduleKeys;
