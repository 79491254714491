import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { TextTag, Button, Spinner } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';

import './AdviserInformationCard.scss';
import { ASESOR_ROLE } from '../../../../integrations/application/constants/inventory';
import { advisorAssignment } from '../../../application/slices/history';
import { STAGE } from '../../../../opportunities/application/constants/opportunities';
import { isLoading } from '../../../../opportunities/application/selectors/opportunities';
import { OPPORTUNITY_CARD } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';

const AdviserInformationCard = ({ opportunity }) => {
	const currentUser = useSelector(getCurrentUser);
	const currentProject = useSelector(getCurrentProject);
	const loading = useSelector(isLoading);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleStartAttention = () => {
		dispatch(
			advisorAssignment({
				slug: opportunity?.project_slug,
				commercial_opportunity_id: opportunity?.id,
				data: {
					asesor_email: currentUser?.email,
				},
			}),
		);
	};

	return (
		<div className="adviser-content">
			<div className="adviser-information">
				<TextTag variant="-body-xs" className="title" fw="bold" font="DM-sans">
					<b>{t(`${OPPORTUNITY_CARD.STAGE}`, { ns: DOMAIN_NAME })}</b>{' '}
					<span className="stage">{t(`${OPPORTUNITY_CARD.STAGES[opportunity?.estado]}`, { ns: DOMAIN_NAME })}</span>
				</TextTag>
				<TextTag variant="-body-xs" className="title" fw="bold" font="DM-sans">
					<b>{t(`${OPPORTUNITY_CARD.ASSESOR}`, { ns: DOMAIN_NAME })} </b>{' '}
					<span className="asesor"> {opportunity?.asesor || t(`${OPPORTUNITY_CARD.NO_ASSESOR}`, { ns: DOMAIN_NAME })}</span>
				</TextTag>
			</div>
			<div className="action-container">
				{currentProject?.role === ASESOR_ROLE &&
					opportunity?.asesor === null &&
					[STAGE.INTERESTED, STAGE.VISITED].includes(opportunity?.stage) && (
						<Button size="-medium" onClick={handleStartAttention} disabled={loading}>
							{loading ? <Spinner size="small" /> : t(`${OPPORTUNITY_CARD.START_ATTENTION}`, { ns: DOMAIN_NAME })}
						</Button>
					)}
			</div>
		</div>
	);
};

AdviserInformationCard.propTypes = {
	opportunity: PropTypes.object,
};

export default AdviserInformationCard;
