import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import { propertyOptionSnapshotsQueue } from './routes';
import PropetyOptionsQueuePage from '../presentation/pages/PropetyOptionsQueuePage';

const propertyRouter = {
	layout: AdminLayout,
	router: [
		{
			path: propertyOptionSnapshotsQueue(),
			page: PropetyOptionsQueuePage,
			routeComponent: PrivateRoute,
		},
	],
};

export default propertyRouter;
