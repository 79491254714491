import {
	GENERIC_DESCRIPTION,
	GENERIC_INPUT_LABEL,
	GENERIC_WEBHOOK,
	HUBSPOT,
	HUBSPOT_DESCRIPTION,
	HUBSPOT_INPUT_LABEL,
	META,
	META_DESCRIPTION,
	SICO,
	SICO_DESCRIPTION,
	SICO_INPUT_LABEL,
	SINCO,
	SINCO_DESCRIPTION,
	SINCO_INPUT_LABEL,
	SUITE,
	SUITE_DESCRIPTION,
	SUITE_INPUT_LABEL,
	ZAPIER_DESCRIPTION,
	ZAPIER_INPUT_LABEL,
	ZAPIER_WEBHOOK,
	ZOHO,
	ZOHO_DESCRIPTION,
	ZOHO_INPUT_LABEL,
} from '../../../application/constants/inventory';
import { INTEGRATIONS, INTEGRATION_HUBSPOT, MODAL_EDIT_TOKEN_HUBSPOT, SYNC } from '../translation_keys';

export default {
	[INTEGRATIONS.TITLE]: 'Integrations',
	[INTEGRATIONS.TEXT_INFO]: 'Here you can find the options we have for you to integrate with the Suite',
	[INTEGRATIONS[SUITE]]: 'Business opportunity API',
	[INTEGRATIONS[META]]: 'Meta Business Manager',
	[INTEGRATIONS[HUBSPOT]]: 'Hubspot',
	[INTEGRATIONS[ZAPIER_WEBHOOK]]: 'Zapier',
	[INTEGRATIONS[GENERIC_WEBHOOK]]: 'Webhook',
	[INTEGRATIONS[SINCO]]: 'SINCO ERP',
	[INTEGRATIONS[ZOHO]]: 'Zoho',
	[INTEGRATIONS[SICO]]: 'Sico',
	[INTEGRATIONS[SUITE_DESCRIPTION]]:
		'With the Business Opportunities API of the Suite you will be able to consult in real time the clients that have shown interest in your project. Consult the <a href="https://documenter.getpostman.com/view/8663633/UzBgup9x" target="_blank" rel="noreferrer">version 1</a> or <a href="https://documenter.getpostman.com/view/21508682/2s9YBxYbYb" target="_blank" rel="noreferrer">version 2</a> documentation to learn how to integrate your systems.',
	[INTEGRATIONS[META_DESCRIPTION]]:
		'With this integration, you will be able to create your Facebook leads from advertising campaigns as business opportunities in the project using the IDs of your campaigns.',
	[INTEGRATIONS[HUBSPOT_DESCRIPTION]]:
		'This integration allows you to connect all the commercial opportunities generated in Suite using webhooks.',
	[INTEGRATIONS[ZAPIER_DESCRIPTION]]:
		'This integration allows you to easily and quickly connect commercial opportunities with over 2,000 applications worldwide. It requires a Zapier account.',
	[INTEGRATIONS[GENERIC_DESCRIPTION]]:
		'This integration allows you to connect all the commercial opportunities generated in Suite using webhooks.',
	[INTEGRATIONS[SINCO_DESCRIPTION]]:
		'With this integration you can synchronize in real time the inventory you have in SINCO and make it available to your customers through Vecindario Suite solutions such as the Quotation and Simulator.',
	[INTEGRATIONS[ZOHO_DESCRIPTION]]: 'This integration allows you to connect all business opportunities to Zoho.',
	[INTEGRATIONS[SICO_DESCRIPTION]]:
		'This integration allows you to connect commercial opportunities generated based on buyer interests.',
	[INTEGRATIONS[SUITE_INPUT_LABEL]]: 'Paste or enter the Suite API URL',
	[INTEGRATIONS[HUBSPOT_INPUT_LABEL]]: 'Copy the Hubspot URL here',
	[INTEGRATIONS[ZAPIER_INPUT_LABEL]]: 'Copy the Zapier webhook URL here',
	[INTEGRATIONS[GENERIC_INPUT_LABEL]]: 'Copy the webhook URL you want to integrate here',
	[INTEGRATIONS[SINCO_INPUT_LABEL]]: 'Copy the Sinco CBR environment URL here',
	[INTEGRATIONS[ZOHO_INPUT_LABEL]]: '',
	[INTEGRATIONS[SICO_INPUT_LABEL]]: 'Paste the Sico token here',
	[INTEGRATIONS.SINCO_USERNAME_LABEL]: 'Copy the username of the Sinco CBR environment here',
	[INTEGRATIONS.SINCO_PASSWORD_LABEL]: 'Copy the password of the Sinco CBR environment here',
	[INTEGRATIONS.INTEGRATE_TEXT_BUTTON]: 'Integrate',
	[INTEGRATIONS.ACTIVATE_TEXT_BUTTON]: 'Activate',
	[INTEGRATIONS.ACTIVATE_TEXT_HUBSPOT_BUTTON]: `Activate integration`,
	[INTEGRATIONS.GENERATE_TOKEN_TEXT_BUTTON]: 'Generate token',
	[INTEGRATIONS.COPY_TO_CLIPBOARD]: 'Copy {{text}}',
	[INTEGRATIONS.SET_UP_TEXT_BUTTON]: 'Set up',
	[INTEGRATIONS.SEE_INTEGRATION_TEXT_BUTTON]: 'See integration',
	[INTEGRATIONS.COPY_TO_CLIPBOARD]: 'Copy to clipboard',
	[INTEGRATIONS.REQUEST_INTEGRATION]: 'Integration requested',
	[INTEGRATIONS.INTEGRATED_TEXT]: 'Integrado',
	[SYNC.DESACTIVATE]: 'Desactivar sincronización',
	[SYNC.SURE]: 'Are you sure you want to deactivate synchronization?',
	[SYNC.YES]: 'Accept',
	[SYNC.NO]: 'Cancel',

	[INTEGRATION_HUBSPOT.ENTER_TOKEN]: 'Enter the token',
	[INTEGRATION_HUBSPOT.PLACEHOLDER_INPUT_TOKEN]: 'enter the token here',
	[INTEGRATION_HUBSPOT.VERIFY_BUTTON]: 'Verify',
	[INTEGRATION_HUBSPOT.VERIFIED_STATE_BUTTON]: 'Verified',
	[INTEGRATION_HUBSPOT.VERIFICATION_ERROR]: 'Invalid token, please verify the entered token',
	[INTEGRATION_HUBSPOT.SELECT_PIPELINES_TITLE]:
		'Select the pipelines and stages where you want to receive opportunities',
	[INTEGRATION_HUBSPOT.OPPORTUNITY_TYPE]: 'Opportunity Type',
	[INTEGRATION_HUBSPOT.PIPELINE]: 'Pipeline',
	[INTEGRATION_HUBSPOT.STAGE]: 'Stage',
	[INTEGRATION_HUBSPOT.SAVE_BUTTON]: 'Save',
	[INTEGRATION_HUBSPOT.SCHEDULE_TYPE]: 'Schedules',
	[INTEGRATION_HUBSPOT.QUOTER_TYPE]: 'Quotations',
	[INTEGRATION_HUBSPOT.SIMULATION_TYPE]: 'Simulations',
	[INTEGRATION_HUBSPOT.CONTACT_REQUESTS_TYPE]: 'Contact Requests',
	[INTEGRATION_HUBSPOT.CANCEL_BUTTON]: 'Cancel',
	[INTEGRATION_HUBSPOT.EDIT_TOKEN]: 'Edit token',
	[INTEGRATION_HUBSPOT.TITLE_CARD_CONTACT_VARIABLES]:
		'Select the names of the contact variables you have in HubSpot to receive the information',
	[INTEGRATION_HUBSPOT.TTILE_CARD_BUSINESS_VARIABLES]:
		'Select the names of the business variables you have in HubSpot to receive the information',
	[INTEGRATION_HUBSPOT.SUBTITLE_CARD_CONTACT_VARIABLES]: 'Contact Variables',
	[INTEGRATION_HUBSPOT.SUBTTILE_CARD_BUSINESS_VARIABLES]: 'Business Variables',
	[INTEGRATION_HUBSPOT.ADD_VARIABLE_BUTTON]: 'Add Additional Variable',
	[INTEGRATION_HUBSPOT.LABEL_INPUT_VECINDARIO_VARIABLE]: 'Variable Name in Vecindario',
	[INTEGRATION_HUBSPOT.LABEL_INPUT_HUBSPOT_VARIABLE]: 'Variable Name in HubSpot',
	[INTEGRATION_HUBSPOT.FINIS_SETUP_BUTTON]: 'Finish Setup',
	[INTEGRATION_HUBSPOT.CARD_ERROR_TITLE]: 'The integration has the following errors',
	[INTEGRATION_HUBSPOT.CARD_ERROR_BODY]: 'The property {{error}} does not exist',
	[INTEGRATION_HUBSPOT.CARD_ERROR_FOOTER]: 'You must correct them for the integration to be successfully configured',
	[INTEGRATION_HUBSPOT.SHOW_INTEGRATION_BUTTON_CARD]: 'Show integration',
	[INTEGRATION_HUBSPOT.SUCCESS_INTEGRATION]: 'Successful integration',
	[INTEGRATION_HUBSPOT.UPDATE_INTEGRATION_BUTTON]: 'Update integration',
	[INTEGRATION_HUBSPOT.SUCCESS_SAVE_DATA]: 'Saved data, remember to finish setup',
	[INTEGRATION_HUBSPOT.SUBTITLE_EDIT_INTEGRATION]:
		'Remember to save and finish the configuration if you make any changes to any of the sections (pipelines, contact variables or business variables).',

	[MODAL_EDIT_TOKEN_HUBSPOT.TITLE]: 'Are you sure you want to edit the token?',
	[MODAL_EDIT_TOKEN_HUBSPOT.BODY]: 'Changing it will delete all the integration settings you have made.',
	[MODAL_EDIT_TOKEN_HUBSPOT.GO_BACK_BUTTON]: 'Go Back',
	[MODAL_EDIT_TOKEN_HUBSPOT.SUBMIT_BUTTON]: 'Continue',
	[INTEGRATION_HUBSPOT.CARD_PROPERTY_DOESNT_EXIST_ERROR]: 'The {{error}} property does not exist',
	[INTEGRATION_HUBSPOT.CARD_READ_ONLY_VALUE_ERROR]: 'The {{error}} property is read-only',
	[INTEGRATION_HUBSPOT.CARD_INVALID_LONG_ERROR]: 'The {{error}} property has an invalid format',
	[INTEGRATION_HUBSPOT.DATE_VARIABLE_DISCLAIMER]:
		'This variable is of type date and must match the same data type in HubSpot',
};
