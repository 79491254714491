import React, { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '@vecindario/vecindario-suite-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { ICON_SEARCH_LINE } from '../../../../../shared/application/constants/icons';
import { searchPipeline } from '../../../../../shared/application/helpers/search';
import { getValueFromSessionStorage } from '../../../../../shared/application/helpers/common-functions';
import { LAST_SEARCH_VALUE } from '../../../../../shared/application/constants/application';
import { COTIZADOR_KEY } from '../../../../../shared/application/constants/modules_keys';
import '@vecindario/vecindario-suite-components/dist/index.css';
import './BoardHeader.scss';
import {
	getRequestAvailabilityAsesor,
	setSearchField,
	setSearchResult,
} from '../../../application/slices/opportunities';
import {
	listOpportunities,
	showOpportunitiesSearchError,
	modulesByProjectActions,
} from '../../../application/selectors/opportunities';
import { SEARCH_KEYS, LIST_ACTIONS } from '../../../application/constants/opportunities';
import NewOpportunityButton from './NewOpportunityButton';
import NewOpportunityShared from './NewOpportunityShared';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { ERROR_INPUT_MESSAGE, SEARCH_PLACEHOLDER } from '../../../infrastructure/locales/translation_keys';
import { OPPORTUNITIES_TITLE } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales/index';
import { getRequestScoringConfiguration } from '../../../../Settings/application/slices/settings';

function BoardHeader() {
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState('');
	const [lastSearch, setLastSearch] = useState('');
	const buttonsNewOpportunities = useSelector(modulesByProjectActions(Object.keys(LIST_ACTIONS)));
	const opportunities = useSelector(listOpportunities);
	const showSearchError = useSelector(showOpportunitiesSearchError);
	const { slug } = useParams();
	const currentUser = useSelector(getCurrentUser);
	const { t } = useTranslation();

	const addVariables = opportunities.map((opportunity) => {
		const firstName =
			opportunity?.user_snapshot_detail?.first_name?.trim() ||
			opportunity?.user_snapshot_detail?.table?.first_name?.trim();

		const lastName =
			opportunity?.user_snapshot_detail?.last_name?.trim() || opportunity?.user_snapshot_detail?.table?.last_name?.trim();

		if (firstName && lastName) {
			const fullName = firstName.concat(' ', lastName);
			return { ...opportunity, new_user_snapshot_detail: { fullname: fullName } };
		}
		return opportunity;
	});

	const searchOpportunitiesPipeline = useMemo(
		() =>
			searchPipeline(addVariables, SEARCH_KEYS)
				.search(searchValue)
				.map(({ item }) => item),
		[addVariables, searchValue],
	);

	useEffect(() => {
		dispatch(setSearchField(searchValue));
		dispatch(setSearchResult(searchValue.length >= 2 ? searchOpportunitiesPipeline : opportunities));
	}, [searchValue, searchOpportunitiesPipeline, dispatch, opportunities]);

	useEffect(() => {
		dispatch(getRequestScoringConfiguration(slug));
	}, [dispatch, slug]);

	useEffect(() => {
		setSearchValue(getValueFromSessionStorage(LAST_SEARCH_VALUE) || '');
		setLastSearch(getValueFromSessionStorage(LAST_SEARCH_VALUE) || '');

		dispatch(
			getRequestAvailabilityAsesor({
				slug,
				email: currentUser?.email,
			}),
		);
	}, [currentUser, dispatch, slug]);

	return (
		<div className="dashboard-header-container">
			<h2 className="title">{t(`${OPPORTUNITIES_TITLE}`, { ns: DOMAIN_NAME_SHARED })}</h2>
			<div className="search-dashboard">
				<div className="search-field">
					<Input
						className="search"
						placeholder={t(`${SEARCH_PLACEHOLDER}`, { ns: DOMAIN_NAME })}
						icon={`${ICON_SEARCH_LINE} search-icon`}
						iconPosition="right"
						onChange={(v) => setSearchValue(v.target.value)}
						showIconError={true}
						error={showSearchError ? t(`${ERROR_INPUT_MESSAGE}`, { ns: DOMAIN_NAME }) : ''}
						errorClassName={'input-error-message'}
						value={lastSearch}
					/>
				</div>
				{buttonsNewOpportunities.length > 0 && <NewOpportunityButton />}

				{buttonsNewOpportunities.length > 0 && buttonsNewOpportunities.some((elem) => elem?.name !== COTIZADOR_KEY) && (
					<NewOpportunityShared />
				)}
			</div>
		</div>
	);
}

export default BoardHeader;
