import React, { useEffect, useState } from 'react';
import { TextTag, Spinner } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ARROW_GO_BACK_LINE } from '../../../../../shared/application/constants/icons';
import { selectOptionedData, selectRequestHistory } from '../../../application/selector/history';
import { importResource, setValueToLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import {
	setOptionsConfiguration,
	requestOpportunityHistory,
	setBuyerData,
	setProjectReservation,
	updateOptionedData,
} from '../../../application/slices/history';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';
import { getOptionsConfiguration, getProjectReservationBySlug } from '../../../infrastructure/api/history';
import { GENERAL_MODAL_PROVIDER } from '../../../application/constants/opportunityHistory';
import UserNotificationCard from '../../components/UserNotificationCard';
import UserInformationCard from '../../components/UserInformationCard';
import './OpportunityHistory.scss';
import AdviserInformationCard from '../../components/AdviserInformationCard';
import { BUYER_DATA } from '../../../../../shared/application/constants/application';
import { GO_BACK, OPPORTUNITIES_TITLE } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales/index';
import RouterHistory from '../../../infrastructure/routerHistory';
import ModalSelectProperty from '../../components/ModalSelectProperty';
import { history } from '../../../../../shared/application/helpers/history';
import {
	opportunityOptionedPeopleInQueueRoute,
	opportunityOptionedUserInformationRoute,
} from '../../../infrastructure/routes';
import { extractUserIdentificationFromOpportunity } from '../../../application/helpers/optionsSystem';

const OpportunityHistory = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [loadingUserHistory, setLoadingUserHistory] = useState(true);
	const { slug, commercial_opportunity_id } = useParams();
	const opportunity = useSelector(selectRequestHistory);
	const [showSelectPropertyModal, setShowSelectPropertyModal] = useState(false);
	const optionedData = useSelector(selectOptionedData);

	dispatch(setBuyerData(opportunity?.user_snapshot_detail));
	setValueToLocalStorage(BUYER_DATA, JSON.stringify(opportunity?.user_snapshot_detail));

	useEffect(() => {
		if (slug && commercial_opportunity_id) {
			setLoadingUserHistory(true);

			// Import Rappi SDK to payment link generator
			importResource({
				script: 'https://microservices.dev.rappi.com/rms/merchant-services/sdk/v2/rms.js',
				id: 'rappi-pay-sdk',
				async: true,
			});

			// Get current opportunity history
			dispatch(requestOpportunityHistory({ slug, commercial_opportunity_id }))
				.then(() => {
					setLoadingUserHistory(false);
				})
				.catch(() => {
					setLoadingUserHistory(false);
				});

			// Get project reservation
			getProjectReservationBySlug(slug)
				.then((res) => dispatch(setProjectReservation(res)))
				.catch((err) => dispatch(setProjectReservation(null)));

			// Get options configuration
			getOptionsConfiguration(slug)
				.then((resp) => dispatch(setOptionsConfiguration(resp)))
				.catch((err) => dispatch(setOptionsConfiguration(null)));
		}
	}, [dispatch, slug, commercial_opportunity_id]);

	const handleOnSelectProperty = (property) => {
		dispatch(updateOptionedData({ property }));
		setShowSelectPropertyModal(false);

		const userIdentification = extractUserIdentificationFromOpportunity({ opportunity });
		const userIdentificationFromStore = optionedData?.identification && optionedData?.document_type;
		if (!userIdentificationFromStore && userIdentification) {
			dispatch(updateOptionedData(userIdentification));
		}

		if (userIdentificationFromStore || userIdentification) {
			history.push(opportunityOptionedPeopleInQueueRoute(slug, commercial_opportunity_id));
			return;
		}

		history.push(opportunityOptionedUserInformationRoute(slug, commercial_opportunity_id));
	};

	return (
		<>
			{loadingUserHistory ? (
				<div className="container-spinner">
					<Spinner size="x-large" />
				</div>
			) : (
				<div className="config-history-container">
					<Link to={opportunityRoute(slug)} className="go-back-button suite-button bordered">
						<i className={ARROW_GO_BACK_LINE}></i> {t(`${GO_BACK}`, { ns: DOMAIN_NAME_SHARED })}
					</Link>
					<div className="history-container-title">
						<TextTag tag="h5">{t(`${OPPORTUNITIES_TITLE}`, { ns: DOMAIN_NAME_SHARED })}</TextTag>
					</div>
					<div className="history-container-body">
						<div className="history-container-card">
							<div className="history-cards-container">
								<div className="left-section">
									<AdviserInformationCard opportunity={opportunity} />
									<UserInformationCard userSnapshotDetail={opportunity?.user_snapshot_detail} />
								</div>
								<UserNotificationCard />
							</div>

							<RouterHistory opportunity={opportunity} setShowSelectPropertyModal={setShowSelectPropertyModal} />

							<ModalSelectProperty
								provider={GENERAL_MODAL_PROVIDER}
								showModal={showSelectPropertyModal}
								setShowModal={setShowSelectPropertyModal}
								setPropertySelected={handleOnSelectProperty}
								propertySelected={optionedData?.property}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default OpportunityHistory;
