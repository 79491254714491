import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Helmet, Input, TextTag, Spinner } from '@vecindario/vecindario-suite-components';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { INTEGRATIONS, INTEGRATION_HUBSPOT } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import GoBackButton from '../../../../../shared/presentation/components/GoBackButton';
import './HubspotIntegration.scss';
import ModalEditToken from '../../components/HubspotIntegration/ModalEditToken';
import VariableSelectorBox from '../../components/HubspotIntegration/VariableSelectorBox';
import PipelinesStagesSelector from '../../components/HubspotIntegration/PipelinesStagesSelector';
import {
	getDataHubspotIntegration,
	postValidationTokenHubspotIntegration,
	resetState,
	setValidToken,
} from '../../../application/slices/hubspotIntegration';
import {
	selectErrorTokenResponse,
	selectHubspotTokenPipelines,
	selectJsonDataErrorsIntegration,
	selectJsonDataIntegrationBusinessVariables,
	selectJsonDataIntegrationContactVariables,
	selectJsonDataIntegrationPipelines,
	selectJsonDataToken,
	selectLoadingValidateToken,
} from '../../../application/selector/hubspotIntegration';
import { INITIAL_INPUST_BUSINESS, INITIAL_INPUST_CONTACT } from '../../../application/constants/hubspotIntegration';
import { changeTokenHubspotIntegration } from '../../../infrastructure/Api/integrations';
import { history } from '../../../../../shared/application/helpers/history';
import { integrationsHubspot, integrationsRoute } from '../../../infrastructure/routes';
import ErrorCard from '../../components/HubspotIntegration/ErrorCard';
import { checkCookieExistence } from '../../../../../shared/application/helpers/common-functions';
import { LAST_SELECTED_SLUG } from '../../../../../shared/application/constants/application';

const HubspotIntegration = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { slug, showIntegration } = useParams();
	const [stateVerifyButton, setStateVerifyButton] = useState(false);
	const [inputValue, setInputValue] = useState('');
	const [isModalTokenOpen, setIsModalTokenOpen] = useState(false);
	const handleOpenModal = () => setIsModalTokenOpen(!isModalTokenOpen);
	const validToken = useSelector(selectHubspotTokenPipelines);
	const isLoadingTokenVerification = useSelector(selectLoadingValidateToken);
	const errorValidateToken = useSelector(selectErrorTokenResponse);
	const hasPipelines = useSelector(selectJsonDataIntegrationPipelines)?.length > 0;
	const hasContactVariables = useSelector(selectJsonDataIntegrationContactVariables);
	const hasBusinessVariables = useSelector(selectJsonDataIntegrationBusinessVariables);
	const integratedToken = useSelector(selectJsonDataToken);
	const hasDataErros = useSelector(selectJsonDataErrorsIntegration);
	const localValueSlug = checkCookieExistence(LAST_SELECTED_SLUG);

	useEffect(() => {
		if (slug !== localValueSlug) {
			window.location.href = integrationsRoute(slug);
		}
	}, [slug, localValueSlug]);

	const defaultInitContactVariables = () => {
		if (showIntegration) {
			return hasContactVariables.filter((objeto) => objeto.vecindarioName !== 'vecindario_user_email');
		}
		return INITIAL_INPUST_CONTACT;
	};

	const defaultInitBusinessVariables = showIntegration ? hasBusinessVariables : INITIAL_INPUST_BUSINESS;

	const handleChange = (e) => {
		if (e?.target?.value?.length >= 10) {
			setStateVerifyButton(true);
			setInputValue(e?.target?.value);
		} else {
			setStateVerifyButton(false);
			setInputValue('');
		}
	};

	const handleValidateToken = () => {
		const data = { slug, hubspot_token: { hubspot_token: inputValue } };
		dispatch(postValidationTokenHubspotIntegration(data));
	};

	const handleShowModalToken = () => {
		handleOpenModal();
	};

	const handleEditToken = () => {
		setInputValue('');
		dispatch(resetState());
		if (showIntegration) {
			changeTokenHubspotIntegration(slug);
			history.push(integrationsHubspot(slug));
		}
		handleOpenModal();
	};

	useEffect(() => {
		if (Object.keys(validToken).length !== 0) {
			dispatch(setValidToken(inputValue));
		}
	}, [dispatch, validToken, inputValue]);

	useEffect(() => {
		if (showIntegration) {
			dispatch(getDataHubspotIntegration(slug))
				.then((result) => {
					if (!result.payload.api_token) {
						window.location.href = integrationsRoute(slug);
					}
				})
				.catch((error) => {
					dispatch(
						setMessageToast({
							type: 'error',
							message: error,
						}),
					);
				});
		}
		if (showIntegration && integratedToken) {
			const data = { slug, hubspot_token: { hubspot_token: integratedToken } };
			setInputValue(integratedToken);
			dispatch(postValidationTokenHubspotIntegration(data));
		}
	}, [dispatch, integratedToken, showIntegration, slug]);

	return (
		<div className="integration-hubspot-container">
			<Helmet title={'Vecindario Suite - Oportunidades de negocio - Integracion Hubspot'} />
			<div className="integrations-container-title">
				<GoBackButton routeBack={integrationsRoute(slug)} />
				<TextTag tag="h5" className="integratios-container-text">
					{t(`${INTEGRATIONS.TITLE}`, { ns: DOMAIN_NAME })}
				</TextTag>
				{showIntegration && (
					<TextTag variant="-body-sm" className="subtitle">
						{t(INTEGRATION_HUBSPOT.SUBTITLE_EDIT_INTEGRATION, { ns: DOMAIN_NAME })}
					</TextTag>
				)}
				{showIntegration && hasDataErros && <ErrorCard errors={hasDataErros} />}
			</div>
			<div className="token-container-box">
				<TextTag className="title-card" fw="bold">
					{t(INTEGRATION_HUBSPOT.ENTER_TOKEN, { ns: DOMAIN_NAME })}
				</TextTag>
				<div className="input-verify">
					<div className={`input-and-action ${errorValidateToken && 'error'}`}>
						<Input
							placeholder={t(INTEGRATION_HUBSPOT.PLACEHOLDER_INPUT_TOKEN, { ns: DOMAIN_NAME })}
							onChange={(e) => handleChange(e)}
							value={inputValue}
							disabled={Object.keys(validToken).length !== 0}
						/>
						{Object.keys(validToken).length !== 0 && (
							<button className="edit-token-button" onClick={handleShowModalToken}>
								{t(INTEGRATION_HUBSPOT.EDIT_TOKEN, { ns: DOMAIN_NAME })}
							</button>
						)}
					</div>

					<Button
						className={`button-verify ${Object.keys(validToken).length !== 0 && 'virified-state'}`}
						disabled={!stateVerifyButton}
						onClick={handleValidateToken}
					>
						{isLoadingTokenVerification ? (
							<Spinner />
						) : (
							t(
								Object.keys(validToken).length !== 0
									? INTEGRATION_HUBSPOT.VERIFIED_STATE_BUTTON
									: INTEGRATION_HUBSPOT.VERIFY_BUTTON,
								{
									ns: DOMAIN_NAME,
								},
							)
						)}
					</Button>
				</div>
				{errorValidateToken && (
					<div className={`container-error-token`}>
						<TextTag variant="-body-xs" className="color-error">
							{t(`${INTEGRATION_HUBSPOT.VERIFICATION_ERROR}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
				)}
			</div>
			{Object.keys(validToken).length !== 0 && <PipelinesStagesSelector />}
			{Object.keys(validToken).length !== 0 && hasPipelines && (
				<VariableSelectorBox
					title={t(`${INTEGRATION_HUBSPOT.TITLE_CARD_CONTACT_VARIABLES}`, { ns: DOMAIN_NAME })}
					subtitle={t(`${INTEGRATION_HUBSPOT.SUBTITLE_CARD_CONTACT_VARIABLES}`, { ns: DOMAIN_NAME })}
					isContactVariables={true}
					initialInpust={defaultInitContactVariables()}
				/>
			)}
			{Object.keys(validToken).length !== 0 && hasContactVariables.length > 0 && (
				<VariableSelectorBox
					title={t(`${INTEGRATION_HUBSPOT.TITLE_CARD_CONTACT_VARIABLES}`, { ns: DOMAIN_NAME })}
					subtitle={t(`${INTEGRATION_HUBSPOT.SUBTTILE_CARD_BUSINESS_VARIABLES}`, { ns: DOMAIN_NAME })}
					isContactVariables={false}
					ifFinishConfig={true}
					initialInpust={defaultInitBusinessVariables}
				/>
			)}
			<ModalEditToken isOpen={isModalTokenOpen} onClose={handleOpenModal} handleEditToken={handleEditToken} />
		</div>
	);
};

export default HubspotIntegration;
