import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import {
	urlCreateAndGetAccounts,
	urlDeleteCampaign,
	urlGetAccountConnected,
	urlGetListCampaigns,
	urlGetCampaigns,
	urlPostConnectAccount,
	urlPostConnectCampaign,
	urlPatchActiveCampaign,
	urlPatchDisconnectAccount,
	urlDeleteAccount,
	urlPatchReconnectAccount,
} from './backend-url';

export const connectAccount = (slug, accountId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostConnectAccount(slug, accountId), requestOptions).then(handleResponse);
};

export const showAccounts = (slug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlCreateAndGetAccounts(slug), requestOptions).then(handleResponse);
};

export const showAccountConnected = (slug, provider) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetAccountConnected(slug, provider), requestOptions).then(handleResponse);
};

export const disconnectAccount = (slug, accountId) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
	};
	return fetch(urlPatchDisconnectAccount(slug, accountId), requestOptions).then(handleResponse);
};

export const reconnectAccount = (slug, accountId, data) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPatchReconnectAccount(slug, accountId), requestOptions).then(handleResponse);
};

export const deleteAccount = (slug, accountId) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlDeleteAccount(slug, accountId), requestOptions).then(handleResponse);
};

export const listCampaigns = (slug, provider, account_id, access_token) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetListCampaigns(slug, provider, account_id, access_token), requestOptions).then(handleResponse);
};

export const connectCampaign = (slug, provider_account_id, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostConnectCampaign(slug, provider_account_id), requestOptions).then(handleResponse);
};

export const showCampaigns = (slug, provider_account_id) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetCampaigns(slug, provider_account_id), requestOptions).then(handleResponse);
};

export const activeCampaign = (slug, provider_account_id, campaignId) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
	};
	return fetch(urlPatchActiveCampaign(slug, provider_account_id, campaignId), requestOptions).then(handleResponse);
};

export const deleteCampaign = (slug, campaignId) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlDeleteCampaign(slug, campaignId), requestOptions).then(handleResponse);
};
