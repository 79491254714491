import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { MESSAGE_OPTION } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';

import './MessageOption.scss';
import { propertyOptionSnapshotsQueue } from '../../../../property/infrastructure/routes';
import { selectCurrentOptionSelected } from '../../../application/selector/history';

const MessageOption = ({ opportunity }) => {
	const { t } = useTranslation();
	const optionSnapshot = useSelector(selectCurrentOptionSelected);

	const handleRedirect = () => {
		const urlRedirect = `${window.location.origin}${opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id)}`;
		history.push(propertyOptionSnapshotsQueue(opportunity?.project_slug, optionSnapshot?.property?.id, urlRedirect));
	};

	return (
		<div className="container-message-option">
			<TextTag variant="-body-sm">{t(MESSAGE_OPTION.TEXT_1, { ns: DOMAIN_NAME })}</TextTag>

			<TextTag className="text" variant="-body-xs">
				{t(MESSAGE_OPTION.TEXT_2, { ns: DOMAIN_NAME })}
			</TextTag>

			<section className="actions">
				<Button onClick={handleRedirect}>
					<TextTag variant="-body-xs" fw="bold">
						{t(MESSAGE_OPTION.TEXT_3, { ns: DOMAIN_NAME })}
					</TextTag>
				</Button>
			</section>

			<span
				className="container-close"
				onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
			>
				<Icon icon={ICON_CLOSE_LINE} aditionalClassName="icon-close" />
			</span>
		</div>
	);
};

MessageOption.propTypes = {
	opportunity: PropTypes.object,
};

export default MessageOption;
