import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { i18nInstance } from '@vecindario/vecindario-suite-components';
import {
	getConfigurationByProject,
	getScoringConfigurationByProject,
	putConfigurationByProject,
} from '../../infrastructure/api';
import { ASESORS_ERROR } from '../constants/projectConfiguration';
import { ERROR_ASESORS } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';

export const initialState = {
	projectConfiguration: {},
	projectScoringConfiguration: {},
	isLoading: true,
};

export const getRequestConfiguration = createAsyncThunk(
	'request/getConfiguration',
	async (slug, { rejectWithValue }) => {
		try {
			return await getConfigurationByProject(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getRequestScoringConfiguration = createAsyncThunk(
	'request/getScoringConfiguration',
	async (slug, { rejectWithValue }) => {
		try {
			return await getScoringConfigurationByProject(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const putRequestConfiguration = createAsyncThunk(
	'request/putConfiguration',
	async (payload, { rejectWithValue, dispatch }) => {
		try {
			const { slug, id, data } = payload;
			return await putConfigurationByProject(slug, id, data);
		} catch (error) {
			let errorMsg = error;
			if (error === ASESORS_ERROR) {
				errorMsg = i18nInstance.t(`${ERROR_ASESORS}`, { ns: DOMAIN_NAME });
			}
			dispatch(
				setMessageToast({
					type: 'error',
					message: errorMsg,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

const Settings = createSlice({
	name: 'settings',
	initialState,
	extraReducers: {
		[getRequestConfiguration.pending](state) {
			state.isLoading = true;
			state.projectConfiguration = {};
		},
		[getRequestConfiguration.rejected](state) {
			state.isLoading = false;
		},
		[getRequestConfiguration.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.projectConfiguration = payload;
		},
		[getRequestScoringConfiguration.pending](state) {
			state.isLoading = true;
			state.projectScoringConfiguration = {};
		},
		[getRequestScoringConfiguration.rejected](state) {
			state.isLoading = false;
		},
		[getRequestScoringConfiguration.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.projectScoringConfiguration = payload;
		},
		[putRequestConfiguration.pending](state) {
			state.isLoading = true;
		},
		[putRequestConfiguration.rejected](state) {
			state.isLoading = false;
		},
		[putRequestConfiguration.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.projectConfiguration = payload;
		},
	},
});

export default Settings.reducer;
