import React from 'react';
import PropTypes from 'prop-types';
import { TextTag } from '@vecindario/vecindario-suite-components';

const ColumnActionTransactionDetail = ({ item }) => {
	return (
		<TextTag className="detail-movedto-text color-secondary-900t" fw="bold" variant="-body-xs">
			{item?.detail}
		</TextTag>
	);
};

ColumnActionTransactionDetail.propTypes = {
	item: PropTypes.object,
};

export default ColumnActionTransactionDetail;
