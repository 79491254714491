import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import {
	AdminLayout,
	Error404,
	HomeRedirect,
	PrivateRoute,
	getCurrentProject,
	getCurrentUser,
} from '@vecindario/suite-dashboard-layout-lib';
import { Spinner } from '@vecindario/vecindario-suite-components';
import historyRouter from '../../domains/opportunityHistory/infrastructure/router';
import opportunitiesRouter from '../../domains/opportunities/infrastructure/router';
import integrationsRouter from '../../domains/integrations/infrastructure/router';
import facebookRouter from '../../domains/facebook/infrastructure/router';
import { homeRoute } from '../infrastructure/routing/routes';
import { opportunityRoute } from '../../domains/opportunities/infrastructure/routes';
import settingsRouter from '../../domains/Settings/infrastructure/router';
import notesRouter from '../../domains/opportunityNotes/infrastructure/router';
import propertyRouter from '../../domains/property/infrastructure/router';
import toolsRouter from '../../domains/tools/infrastructure/routing/router';
import { saveUtmIntoLocalStorage, setValueToLocalStorage } from '../application/helpers/common-functions';
import { history } from '../application/helpers/history';
import { ACTION_PERFORMER, USER_ID_EMAIL } from '../application/constants/utms';
import { MARKETING_ROLE } from '../../domains/integrations/application/constants/inventory';
import { integrationsRoute } from '../../domains/integrations/infrastructure/routes';

const Home = () => {
	const currentProject = useSelector(getCurrentProject);
	const projectRequested = useSelector((state) => state.project?.requested);
	return projectRequested ? (
		<HomeRedirect pathRoute={currentProject?.role === MARKETING_ROLE ? integrationsRoute : opportunityRoute} />
	) : (
		<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'inherit' }}>
			<Spinner size="x-large" className="loading" />
		</div>
	);
};

const Router = () => {
	const currentUser = useSelector(getCurrentUser);
	const currentProject = useSelector(getCurrentProject);
	const defaultLayout = ({ children }) => <>{children}</>;
	const routes = [
		opportunitiesRouter,
		historyRouter,
		integrationsRouter,
		facebookRouter,
		settingsRouter,
		notesRouter,
		propertyRouter,
		toolsRouter,
	];

	const setUtmInLocalStorage = () => {
		saveUtmIntoLocalStorage(history);
		setValueToLocalStorage(USER_ID_EMAIL, currentUser?.email);
		setValueToLocalStorage(ACTION_PERFORMER, currentProject?.role);
	};

	return (
		<>
			{setUtmInLocalStorage()}
			<Switch>
				<PrivateRoute exact={true} path={homeRoute} layout={AdminLayout} component={Home} />
				{routes.map((route) => {
					return route?.router?.map(({ path, page: Component, routeComponent: Route, exact = true, layout, ...rest }) => (
						<Route
							key={path}
							exact={exact}
							path={path}
							component={Component}
							layout={layout || route.layout || defaultLayout}
							{...rest}
						/>
					));
				})}
				<Route path="*">
					<AdminLayout>
						<Error404 backRoute={homeRoute} />
					</AdminLayout>
				</Route>
			</Switch>
		</>
	);
};

export default Router;
