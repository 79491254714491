import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { useDispatch, useSelector } from 'react-redux';
import { ICON_MORE_LINE } from '../../../../../shared/application/constants/icons';
import { OPTIONS_QUEUE_PAGE } from '../../../../property/infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../property/infrastructure/locales';
import ListActions from './ListActions';
import { optionsStockList } from '../../../application/helpers/optionsSystem';
import './ActionOption.scss';
import { setCurrentOptionSelected } from '../../../application/slices/history';

const ActionOption = ({ option }) => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const currentProject = useSelector(getCurrentProject);
	const currentUser = useSelector(getCurrentUser);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const arrayOptionsStock = useMemo(
		() =>
			optionsStockList({
				currentOption: option,
				currentProject,
				currentUser,
				opportunity: option?.commercial_opportunity,
				dispatch,
				t,
			}),
		[dispatch, option, currentProject, currentUser, t],
	);

	const hanldeOpenPopover = () => {
		setIsPopoverOpen(!isPopoverOpen);
		dispatch(setCurrentOptionSelected(option));
	};

	return (
		<>
			{arrayOptionsStock.length > 0 && (
				<div className="container-actions">
					<Popover
						isOpen={isPopoverOpen}
						content={<ListActions actions={arrayOptionsStock} />}
						positions={['bottom', 'left']}
						padding={20}
						reposition={true}
						align="end"
						onClickOutside={() => setIsPopoverOpen(false)}
					>
						<div className="popover-content">
							<Button variant="transparent" className="button" onClick={hanldeOpenPopover}>
								<TextTag className="text" variant="-body-sm" fw="bold">
									{t(`${OPTIONS_QUEUE_PAGE.VIEW_ACTIONS}`, { ns: DOMAIN_NAME })}
								</TextTag>
								<Icon icon={ICON_MORE_LINE} />
							</Button>
						</div>
					</Popover>
				</div>
			)}
		</>
	);
};

ActionOption.propTypes = {
	option: PropTypes.object,
};

export default ActionOption;
