import React from 'react';
import PropTypes from 'prop-types';
import { TextTag } from '@vecindario/vecindario-suite-components';

const ItemDetail = ({ textTitle, description }) => {
	return (
		<div className="item">
			<TextTag tag="p" variant="-body-xs" className="header color-secondary-600t">
				{textTitle}
			</TextTag>
			<TextTag tag="p" variant="-body-sm" className="text">
				{description}
			</TextTag>
		</div>
	);
};

ItemDetail.propTypes = {
	textTitle: PropTypes.string,
	description: PropTypes.string,
};

export default ItemDetail;
