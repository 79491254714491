export const OPTIONED_USER_INFORMATION_SECTION_HASH = '/opcionar-documento-identidad';
export const OPTIONED_PEOPLE_IN_THE_QUEUE_SECTION_HASH = '/personas-en-cola';
export const OPTIONED_DETAILS_PAGE = '/inmueble-opcionado';
export const SEPARATED_BY_OPTION_DETAIL_SECTION_HASH = '/separacion-por-opcion';
export const OPTIONED_CONFIRM_PURCHASE_HASH = '/confirmar-compra';
export const OPTIONED_QUEUED_HASH = '/opciones-en-cola';
export const EXTEND_VALIDITY_TIME_SECTION_HASH = '/extender-vigencia-de-tiempo';
export const CANCEL_OPTION_SECTION_HASH = '/cancelar-opcionado';
export const TRACKING_INFO_SECTION_HASH = '/analisis-web';
export const CONTACT_REQUEST_DETAILS_HASH = '/detalle-de-solicitud-de-contacto';
export const EXTERNAL_CONTACT_REQUEST_DETAILS_HASH = '/detalle-de-solicitud-de-contacto-externo';

export const OPTIONED_USER_INFORMATION_FIELDS = {
	DOCUMENT_TYPE: 'document_type',
	IDENTIFICATION: 'identification',
};

export const LOCAL_STORAGE_KEY_OPTIONED_USER = 'opportunityOptionUser';
export const LOCAL_STORAGE_KEY_OPTIONED_PROPERTY = 'opportunityOptionProperty';

export const OPTION_STATE_PENDING = 'pending';
export const OPTION_STATE_ACTIVE = 'active';
export const OPTION_STATE_EXPIRED = 'expired';
export const OPTION_STATE_CANCELED = 'canceled';
export const OPTION_STATE_CONFIRMED = 'confirmed';
export const OPTION_STATE_EXECUTED = 'executed';

export const OPTION_ACTION_CREATE_OPTION = 'create_option';
export const OPTION_ACTION_EXTEND_EXPIRATION = 'extend_expiration';
export const OPTION_ACTION_EXPIRE_OPTION = 'expire_option';
export const OPTION_ACTION_CONFIRM_PURCHASE = 'confirm_purchase';
export const OPTION_ACTION_CANCEL_OPTION = 'cancel_option';
export const OPTION_ACTION_EXECUTE_PURCHASE = 'execute_purchase';

export const OPTION_ACTIONS_ID = {
	SHOW_CANCEL_OPTION_BUTTON: 'SHOW_CANCEL_OPTION_BUTTON',
	SHOW_EXTEND_VALIDITY_BUTTON: 'SHOW_EXTEND_VALIDITY_BUTTON',
	CAN_CONFIRM_PURCHASE: 'CAN_CONFIRM_PURCHASE',
};

export const OPEN_OPTION_STATES = [OPTION_STATE_PENDING, OPTION_STATE_ACTIVE];
