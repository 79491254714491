import { isRejectedWithValue } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';

const showToast = (dispatch, type, message) => {
	dispatch(
		setMessageToast({
			type,
			message,
		}),
	);
};

const rtkQueryErrorLogger =
	({ dispatch }) =>
	(next) =>
	(action) => {
		if (isRejectedWithValue(action)) {
			let { payload } = action;
			if (typeof payload !== 'string') {
				payload = payload?.message;
			}
			showToast(dispatch, 'error', payload);
		}

		return next(action);
	};

export default rtkQueryErrorLogger;
