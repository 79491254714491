import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { CONTACT_FORM_EMBEDDED } from '../../../../../../shared/application/constants/env';
import {
	importResource,
	removeMultipleResources,
	removeResource,
} from '../../../../../../shared/application/helpers/common-functions';

import './ContactEmbeddedPage.scss';

const ContactEmbeddedPage = () => {
	const { slug } = useParams();

	useEffect(() => {
		setTimeout(() => {
			importResource({
				id: 'contact-embedded-by-suite',
				script: CONTACT_FORM_EMBEDDED,
			});
		}, 10);
		return () => {
			removeResource('#contact-embedded-by-suite');
			removeMultipleResources('.script-contact-request');
			removeMultipleResources('.style-contact-request');
		};
	}, []);

	return (
		<>
			<Helmet title={`${slug} - Formulario de contacto.`} />
			<div className="content-preview-embedded">
				<div id="contactosByVecindario" data-project-slug={slug}></div>
			</div>
		</>
	);
};

export default ContactEmbeddedPage;
