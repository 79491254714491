import React from 'react';
import PropTypes from 'prop-types';
import { TextTag } from '@vecindario/vecindario-suite-components';
import './ListActions.scss';

const ListActions = ({ actions }) => {
	return (
		<div className="actions-options-container">
			{actions.map((item) => (
				<span className="text" key={item.id} onClick={() => item.onClick()}>
					<TextTag variant="-body-sm" fw="bold">
						{item.text}
					</TextTag>
				</span>
			))}
		</div>
	);
};

ListActions.propTypes = {
	actions: PropTypes.array,
};

export default ListActions;
