export const filterByActivePipeline = (arr) => {
	return arr
		.filter((item) => item.archived === false)
		.map((item) => ({
			value: item.id,
			label: item.label,
		}));
};

const sortArray = (arr) => {
	arr.sort((a, b) =>
		a.display_order === b.display_order ? a.label.localeCompare(b.label) : a.display_order - b.display_order,
	);

	return arr;
};
export const filterStageBySelectedPipeline = (arr, pipeline) => {
	const arrPipelineSelected = arr?.filter((item) => item.id === pipeline);
	const noArchivedStages = arrPipelineSelected[0].stages.filter((item) => item?.archived === false);
	return sortArray(noArchivedStages).map((item) => ({
		value: item.id,
		label: item.label,
	}));
};

export const renameKeysListSelectors = (array) => {
	return array.map((item) => ({
		value: item.suite_variable,
		label: item.translated_text,
	}));
};
