import React, { useState } from 'react';
import { Button, Icon } from '@vecindario/vecindario-suite-components';
import { Popover } from 'react-tiny-popover';
import { useTranslation } from 'react-i18next';
import { ICON_ARROW_DOWN_LINE } from '../../../../../../shared/application/constants/icons';
import ListNewOpportunity from '../ListNewOpportunity';

import './NewOpportunityButton.scss';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { DROPDONW_BOARD } from '../../../../infrastructure/locales/translation_keys';

function NewOpportunityButton() {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	const { t } = useTranslation();

	return (
		<div className="container-button">
			<Popover
				isOpen={isPopoverOpen}
				content={<ListNewOpportunity />}
				positions={['bottom', 'left']}
				padding={20}
				reposition={true}
				align="end"
				containerClassName="popover-search-container"
				onClickOutside={() => setIsPopoverOpen(false)}
			>
				<div className="popover-content">
					<Button
						variant="bordered"
						className="new-oc-button"
						size="-medium"
						onClick={() => setIsPopoverOpen(!isPopoverOpen)}
					>
						{t(`${DROPDONW_BOARD.SELECT_TEXT}`, { ns: DOMAIN_NAME })}
						<Icon icon={ICON_ARROW_DOWN_LINE} />
					</Button>
				</div>
			</Popover>
		</div>
	);
}

export default NewOpportunityButton;
