import urlBase from '../../../../shared/infrastructure/api/backend-urls';

export const urlGetOpportunityHistory = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') =>
	`${urlBase}proyectos/${slug}/oportunidades-comerciales/${commercial_opportunity_id}`;

export const urlGetUserHistory = (slug = ':slug') => `${urlBase}proyectos/${slug}/oportunidades-comerciales`;
export const urlGetProjectReservation = (slug, paymentType = 'reserves_and_separation') =>
	`${urlBase}proyecto/${slug}/configuracion-de-reserva?payment_type=${paymentType}`;
export const urlGetProjectAvailableProperties = (slug) => `${urlBase}proyecto/${slug}/propiedades-disponibles`;
export const urlUpdateAppointmentUserSnapshot = (id) => `${urlBase}agendamiento/detalles-usuario/${id}`;
export const urlUpdateContactAnswerSnapshot = (id) => `${urlBase}contacto/detalles-usuario/${id}`;
export const urlUpdateSimulationUserSnapshot = (id) => `${urlBase}simulacion/detalles-usuario/${id}`;
export const UrlUpdateExternalContactSnapshot = (id) => `${urlBase}contacto-externo/detalles-usuario/${id}`;
export const urlGeneratePaymentLink = (slug, propertyId) =>
	`${urlBase}proyecto/${slug}/inmueble/${propertyId}/generar-link-de-pago`;

export const urlPostApproveFiduciaryPayment = (slug = ':slug', propertyId = ':property_id') =>
	`${urlBase}/proyecto/${slug}/inmueble/${propertyId}/aprobar-pago-fiduciaria`;
export const urlPostReleaseProperty = (slug = ':slug', propertyId = ':property_id') =>
	`${urlBase}proyecto/${slug}/inmueble/${propertyId}/liberar-inmueble`;
export const urlPostAdvisorAssignment = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') =>
	`${urlGetOpportunityHistory(slug, commercial_opportunity_id)}/asignar-asesor`;

export const urlUpdateQuotationUserSnapshot = (slug, quotationSnapshotId) => {
	return `${urlBase}proyecto/${slug}/cotizacion/detalles-usuario/${quotationSnapshotId}`;
};

export const urlGetQuotationPdfUrl = (slug, quotationId, lang) =>
	`${urlBase}proyecto/${slug}/cotizacion/${quotationId}/pdf?lang=${lang}`;

export const urlPostCreateOption = (slug, propertyId) =>
	`${urlBase}proyectos/${slug}/inmueble/${propertyId}/crear-opcion`;

export const urlPostConfirmPurchase = (slug, optionSnapshotId) =>
	`${urlBase}proyecto/${slug}/opcion/${optionSnapshotId}/confirmar-compra`;

export const urlPostExtendValidityTime = (slug, optionSnapshotId) => {
	return `${urlBase}proyecto/${slug}/opcion/${optionSnapshotId}/extender-opcion`;
};

export const urlPostCancelOption = (slug, optionSnapshotId) => {
	return `${urlBase}proyecto/${slug}/opcion/${optionSnapshotId}/anular-opcion`;
};

export const urlGetOptionsConfiguration = (slug) => {
	return `${urlBase}proyecto/${slug}/configuracion-opciones`;
};

export const urlGetOpportunityTrackingOns = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => `${urlBase}proyectos/${slug}/obtener-tracking-on/${commercial_opportunity_id}`;
