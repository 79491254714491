import React from 'react';
import PropTypes from 'prop-types';
import './InformativeNotice.scss';

const InformativeNotice = ({ children }) => {
	return <div className="informative-notice-container">{children}</div>;
};

InformativeNotice.propTypes = {
	children: PropTypes.object,
};

export default InformativeNotice;
