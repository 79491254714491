import { URL_CLIENT_PROD } from '../../../../shared/application/constants/env';
import urlBase from '../../../../shared/infrastructure/api/backend-urls';

export const urlGetConfigurationByProject = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/configuracion-de-asignacion`;

export const urlPutConfiguration = (slug = ':slug', id = ':id') =>
	`${urlBase}proyectos/${slug}/actualizar-configuracion/${id}`;

export const urlScoringProject = (slug = ':slug') => `${URL_CLIENT_PROD}proyectos/${slug}/configuracion-financiera`;
