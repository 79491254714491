import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import {
	STAGE_EXTERNAL_CONTACT_REQUEST,
	MOVE_ACTIONS,
	STAGE_CONTACT,
	STAGE_EXTERNAL_CONTACT_ADS,
	STAGE_OPTIONED_PROPERTY,
	STAGE_QUOTED,
	STAGE_SCHEDULE,
	STAGE_SEPARATED,
	STAGE_SIMULATE,
} from '../../../../application/constants/opportunityHistory';
import ColumnDetailMoved from './ColumnDetailMoved';
import ColumnDetailContact from './ColumnDetailContact';
import ColumnDetailSimulation from './ColumnDetailSimulation';
import ColumnDetailSchedule from './ColumnDetailSchedule';
import ColumnDetailQuotation from './ColumnDetailQuotation';
import ColumnDetailOptionedProperty from './ColumnDetailOptionedProperty';
import ColumnDetailOnlyProperty from './ColumnDetailOnlyProperty';
import ColumnDetailExternalContact from './ColumnDetailExternalContact';

const ColumnDetail = ({ item, opportunity }) => {
	const currentProject = useSelector(getCurrentProject);

	// Show move description if action_type is some of move action types
	if (MOVE_ACTIONS.includes(item?.action_type)) {
		return <ColumnDetailMoved opportunity={opportunity} item={item} />;
	}

	// Show contact optional answers when action type is "contact"
	if ([STAGE_EXTERNAL_CONTACT_REQUEST, STAGE_CONTACT].includes(item?.action_type)) {
		return <ColumnDetailContact item={item} project={currentProject} />;
	}

	// Show property parsed name when action type is "simulate"
	if (item?.action_type === STAGE_SIMULATE) {
		return <ColumnDetailSimulation item={item} />;
	}

	// Show appointment date & type when action type is "schedule"
	if (item?.action_type === STAGE_SCHEDULE) {
		return <ColumnDetailSchedule item={item} />;
	}

	// Show property parsed name and quotation identification when action type is "quoted"
	if (item?.action_type === STAGE_QUOTED) {
		return <ColumnDetailQuotation item={item} />;
	}

	// Show property parsed name, order, and expired date when action type is "optioned_property"
	if (item?.action_type === STAGE_OPTIONED_PROPERTY) {
		return <ColumnDetailOptionedProperty item={item} />;
	}

	// Show user performer name and modification date when action type is "separated"
	if (item.action_type === STAGE_SEPARATED && item?.performing_user?.user_id !== opportunity?.user_id) {
		return <ColumnDetailMoved opportunity={opportunity} item={item} />;
	}

	// Show facebook campaign name when action type is "external_contact_snapshot"
	if (item.action_type === STAGE_EXTERNAL_CONTACT_ADS) {
		return <ColumnDetailExternalContact opportunity={opportunity} item={item} />;
	}

	return <ColumnDetailOnlyProperty item={item} />;
};

ColumnDetail.propTypes = {
	item: PropTypes.object,
	opportunity: PropTypes.object,
};

export default ColumnDetail;
