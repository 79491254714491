import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { getPropertyParsed } from '../../../../../application/helpers/opportunityHistory';
import {
	OPTION_SNAPSHOT_TEXTS,
	OPTION_STATE_TEXTS,
	TABLE_HEADER,
} from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { PROPERTY_ROW } from '../../../../../application/constants/opportunityHistory';
import { formatDate } from '../../../../../../../shared/application/helpers/common-functions';
import { COMMON_DATE_WITH_12_HOUR } from '../../../../../../../shared/application/constants/dates';
import { OPEN_OPTION_STATES } from '../../../../../application/constants/optionedInformation';

const ColumnDetailOptionedProperty = ({ item }) => {
	const { t } = useTranslation();

	const isOpenOption = OPEN_OPTION_STATES.includes(item?.action_instance?.option_state);

	return (
		<TextTag variant="-body-sm" className="detail-movedto-text">
			<table className="detail-table">
				<tbody className="body">
					{/* Show optioned property parsed name */}
					<tr className="row">
						<th className="label">{t(TABLE_HEADER[PROPERTY_ROW], { ns: DOMAIN_NAME })}</th>
						<td className="value">{getPropertyParsed(item?.action_instance, t)}</td>
					</tr>

					{/* Show option state */}
					<tr className="row">
						<th className="label">{t(TABLE_HEADER[PROPERTY_ROW], { ns: DOMAIN_NAME })}</th>
						<td className="value">{t(OPTION_STATE_TEXTS[item?.action_instance?.option_state], { ns: DOMAIN_NAME })}</td>
					</tr>

					{/* Show option order if option is open */}
					{isOpenOption && (
						<tr className="row">
							<th className="label">{t(OPTION_SNAPSHOT_TEXTS.ORDER, { ns: DOMAIN_NAME })}</th>
							<td className="value">{item?.action_instance?.order}</td>
						</tr>
					)}

					{/* Show expiration_date if exists and is open option */}
					{isOpenOption && item?.action_instance?.expiration_date && (
						<tr className="row">
							<th className="label">{t(OPTION_SNAPSHOT_TEXTS.EXPIRATION_DATE, { ns: DOMAIN_NAME })}</th>
							<td className="value">{formatDate(item?.action_instance?.expiration_date, COMMON_DATE_WITH_12_HOUR)}</td>
						</tr>
					)}
				</tbody>
			</table>
		</TextTag>
	);
};

ColumnDetailOptionedProperty.propTypes = {
	item: PropTypes.object,
};

export default ColumnDetailOptionedProperty;
