import { getCurrentUser, getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import { Helmet, Spinner } from '@vecindario/vecindario-suite-components';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRequestOpportunities } from '../../application/slices/opportunities';
import BoardBody from '../components/BoardBody';
import BoardHeader from '../components/BoardHeader';
import './Boar.scss';
import PremiumModal from '../components/PremiumModal';
import { LICENCE_FREE_PLAN } from '../../../../shared/application/constants/application';
import { isLoading } from '../../application/selectors/opportunities';
import { setCurrentHistory } from '../../../opportunityHistory/application/slices/history';

const BoardPage = () => {
	const dispatch = useDispatch();
	const currentUser = useSelector(getCurrentUser);
	const loading = useSelector(isLoading);
	const { slug } = useParams();
	const currentProject = useSelector(getCurrentProject);
	const [modalOpen, setModalOpen] = useState(false);
	const handleOpen = () => setModalOpen(!modalOpen);

	useEffect(() => {
		if (slug && currentUser?.token) {
			dispatch(setCurrentHistory({}));
			dispatch(getRequestOpportunities(slug));
		}
	}, [currentUser?.token, dispatch, slug]);

	useEffect(() => {
		if (currentProject?.licence_type === LICENCE_FREE_PLAN && currentProject?.remaining_free_opportunities <= 0) {
			setModalOpen(true);
		}
	}, [dispatch, currentUser, slug, currentProject]);

	return (
		<>
			<Helmet title={'Vecindario Suite - Oportunidades de negocio - Tablero'} />
			<div className="board-container">
				<BoardHeader />
				{loading ? (
					<div className="container-spinner">
						<Spinner size="x-large" />
					</div>
				) : (
					<BoardBody />
				)}
			</div>
			<PremiumModal isModalOpen={modalOpen} setIsModalOpen={handleOpen} />
		</>
	);
};

export default BoardPage;
