import { createSelector } from '@reduxjs/toolkit';

const sincoIntegrationState = (state) => state?.sincoContactIntegration;

export const urlSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.url;
});

export const passwordSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.password;
});

export const usernameSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.username;
});

export const loadingSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.loading;
});

export const showCompaniesModalSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.showCompaniesModal;
});

export const stepSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.step;
});

export const companiesSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.companies;
});

export const activeCompanySelector = createSelector(sincoIntegrationState, (state) => {
	return state?.activeCompany;
});

export const activeIntegration = createSelector(sincoIntegrationState, (state) => {
	return state?.isIntegrationActive;
});

export const activeProjectSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.activeProject;
});

export const projectsSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.projects;
});

export const errorSelector = createSelector(sincoIntegrationState, (state) => {
	return state?.error;
});
