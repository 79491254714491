import { useTranslation } from 'react-i18next';
import { PROPERTY_QUEUE_TABLE_HEADERS } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';

export const OPTIONS_QUEUE_DATE_FORMAT = 'DD/MM/YYYY - hh:mm:ss';

export const TYPE_OF_VIEW_RESUME = 'resume';
export const TYPE_OF_VIEW_FULL = 'full';

export const OPTIONS_QUEUE_FIELDS = () => {
	const { t } = useTranslation();

	return [
		{
			name: 'order',
			typeOfView: [TYPE_OF_VIEW_RESUME, TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.ORDER, { ns: DOMAIN_NAME }),
		},
		{
			name: 'opportunity_name',
			typeOfView: [TYPE_OF_VIEW_RESUME, TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.OPPORTUNITY_NAME, { ns: DOMAIN_NAME }),
		},
		{
			name: 'option_state',
			typeOfView: [TYPE_OF_VIEW_RESUME, TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.OPTION_STATE, { ns: DOMAIN_NAME }),
		},
		{
			name: 'expiration_date',
			typeOfView: [TYPE_OF_VIEW_RESUME, TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_DATE, { ns: DOMAIN_NAME }),
		},
		{
			name: 'option_creator_name',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.OPTION_CREATOR_NAME, { ns: DOMAIN_NAME }),
		},
		{
			name: 'opportunity_advisor_name',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.ADVISOR_NAME, { ns: DOMAIN_NAME }),
		},
		{
			name: 'option_date',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.OPTION_DATE, { ns: DOMAIN_NAME }),
		},
		{
			name: 'last_option_action_date',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_DATE, { ns: DOMAIN_NAME }),
		},
		{
			name: 'last_option_action_name',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_NAME, { ns: DOMAIN_NAME }),
		},
		{
			name: 'extend_expiration_name',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_NAME, { ns: DOMAIN_NAME }),
		},
		{
			name: 'extend_expiration_date_action',
			typeOfView: [TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_DATE_ACTION, { ns: DOMAIN_NAME }),
		},
		{
			name: 'actions',
			typeOfView: [TYPE_OF_VIEW_RESUME, TYPE_OF_VIEW_FULL],
			text: t(PROPERTY_QUEUE_TABLE_HEADERS.ACTIONS, { ns: DOMAIN_NAME }),
		},
	];
};

export const optionsQueueTableHeaders = ({ typeOfView }) => {
	let fields = OPTIONS_QUEUE_FIELDS();
	if (typeOfView) {
		fields = OPTIONS_QUEUE_FIELDS().filter((elem) => elem?.typeOfView?.includes(typeOfView));
	}

	const tableHeaders = {};
	fields?.forEach((elem) => {
		tableHeaders[`${elem?.name}`] = elem?.text;
	});

	return tableHeaders;
};
