import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { TextTag } from '@vecindario/vecindario-suite-components';
import DynamicInputVariables from './DynamicInputVariables';
import './VariableSelectorBox.scss';
import GeneralSelectorsCard from '../GeneralSelectorsCard';
import {
	getHubspotVariablesHubspotIntegration,
	getVecindarioVariablesHubspotIntegration,
	postCreateHubspotIntegration,
	setObjVariables,
} from '../../../../application/slices/hubspotIntegration';
import { selectJsonDataIntegration, selectJsonDataToken } from '../../../../application/selector/hubspotIntegration';
import { history } from '../../../../../../shared/application/helpers/history';
import { integrationsHubspotShowIntegration } from '../../../../infrastructure/routes';
import { SHOW_INTEGRATION_PATH } from '../../../../application/constants/hubspotIntegration';

const VariableSelectorBox = ({ title, subtitle, isContactVariables, ifFinishConfig, initialInpust }) => {
	const { i18n } = useTranslation();
	const dispatch = useDispatch();
	const { slug, showIntegration } = useParams();
	const selectToken = useSelector(selectJsonDataToken);
	const [inputValues, setInputValues] = useState(initialInpust);
	const isDisabled = !inputValues.every((item) => item.vecindarioName !== '' && item.hubspotName !== '');
	const allData = useSelector(selectJsonDataIntegration);

	const actionSaveButton = async () => {
		let payload = {};
		if (isContactVariables) {
			payload = {
				isContactVariables,
				inputValues: [{ vecindarioName: 'vecindario_user_email', hubspotName: 'email' }, ...inputValues],
			};
			await dispatch(setObjVariables(payload));
		} else {
			payload = { isContactVariables, inputValues };
			const businessvariables = await dispatch(setObjVariables(payload));
			const { key, inputValues: values } = businessvariables.payload;
			const data = { slug, data: { data: { ...allData.data, [key]: values } } };
			dispatch(postCreateHubspotIntegration(data));
			if (!showIntegration) {
				history.push(integrationsHubspotShowIntegration(slug, SHOW_INTEGRATION_PATH));
			}
		}
	};

	useEffect(() => {
		dispatch(getVecindarioVariablesHubspotIntegration(i18n?.language));
		if (selectToken) {
			dispatch(getHubspotVariablesHubspotIntegration({ slug, selectToken }));
		}
	}, [dispatch, i18n.language, slug, selectToken]);

	return (
		<GeneralSelectorsCard
			title={title}
			ifFinishConfig={ifFinishConfig}
			actionSaveButton={actionSaveButton}
			isDisabled={isDisabled}
		>
			<TextTag variant="-body-sm" fw="bold">
				{subtitle}
			</TextTag>
			<DynamicInputVariables
				isContactVariables={isContactVariables}
				setInputValues={setInputValues}
				inputValues={inputValues}
			/>
		</GeneralSelectorsCard>
	);
};

VariableSelectorBox.propTypes = {
	title: PropTypes.string,
	subtitle: PropTypes.string,
	isContactVariables: PropTypes.bool,
	ifFinishConfig: PropTypes.bool,
	initialInpust: PropTypes.array,
};

export default VariableSelectorBox;
