import {
	ASESOR_ROLE,
	DIRECTOR_ROLE,
	MARKETING_ROLE,
} from '../../../domains/integrations/application/constants/inventory';
import { integrationsRoute } from '../../../domains/integrations/infrastructure/routes';
import { opportunityRoute } from '../../../domains/opportunities/infrastructure/routes';
import { settingsRoute } from '../../../domains/Settings/infrastructure/routes';
import { ICON_DASHBOARD_LINE, TOGGLE_LINE_ICON, ICON_SETTINGS, ICON_RULER_LINE } from './icons';
import { toolsRoute } from '../../../domains/tools/infrastructure/routing/routes';

export const DASHBOARD_SIDEBAR_KEY = 'dashboardSidebar';
export const SETTINGS_SIDEBAR_KEY = 'settingsSidebar';
export const INTEGRATIONS_SIDEBAR_KEY = 'integrationsSideBar';
export const TOOLS_SIDEBAR_KEY = 'toolsSidebar';

const sidebarOptions = (role, SIDEBAR_OPTIONS) => {
	const optiosArray = [
		{
			text: SIDEBAR_OPTIONS[DASHBOARD_SIDEBAR_KEY],
			// text: 'Tablero',
			className: ICON_DASHBOARD_LINE,
			path: opportunityRoute(),
			role: [DIRECTOR_ROLE, ASESOR_ROLE],
		},
		{
			text: SIDEBAR_OPTIONS[SETTINGS_SIDEBAR_KEY],
			// text: 'Configuración',
			className: ICON_SETTINGS,
			path: settingsRoute(),
			role: [DIRECTOR_ROLE],
		},
		{
			text: SIDEBAR_OPTIONS[INTEGRATIONS_SIDEBAR_KEY],
			// text: 'Integraciones',
			className: TOGGLE_LINE_ICON,
			path: integrationsRoute(),
			role: [DIRECTOR_ROLE, MARKETING_ROLE],
		},
		{
			text: SIDEBAR_OPTIONS[TOOLS_SIDEBAR_KEY],
			className: ICON_RULER_LINE,
			path: toolsRoute(),
			role: [DIRECTOR_ROLE, ASESOR_ROLE],
		},
	];

	return optiosArray.filter((option) => option.role.includes(role) || option.role === '');
};

export default sidebarOptions;
