import { ICON_SHARE_LINE, ICON_TEAM_LINE } from '../../../../shared/application/constants/icons';

export const RANDOM_ASSIGNMENT = 'random_assignment';
export const RANDOM_ASSIGNMENT_TITLE = 'random_assignment_title';
export const RANDOM_ASSIGNMENT_DESCRIPTION = 'random_assignment_description';
export const MANUAL_ASSIGNMENT = 'manual_assignment';
export const MANUAL_ASSIGNMENT_TITLE = 'manual_assignment_title';
export const MANUAL_ASSIGNMENT_DESCRIPTION = 'manual_assignment_description';

export const ObjectConfigurationCard = [
	{
		id: RANDOM_ASSIGNMENT,
		title: RANDOM_ASSIGNMENT_TITLE,
		description: RANDOM_ASSIGNMENT_DESCRIPTION,
		icon: ICON_SHARE_LINE,
		value: 'automatic',
	},
	{
		id: MANUAL_ASSIGNMENT,
		title: MANUAL_ASSIGNMENT_TITLE,
		description: MANUAL_ASSIGNMENT_DESCRIPTION,
		icon: ICON_TEAM_LINE,
		value: 'manual',
	},
];

export const ASESORS_ERROR = 'No se puede activar la opción porque no hay asesores del proyecto';
