import { META_LOGO } from '../../../../facebook/application/constants/logos';
import {
	GENERIC_DESCRIPTION,
	GENERIC_INPUT_LABEL,
	GENERIC_WEBHOOK,
	HUBSPOT,
	HUBSPOT_DESCRIPTION,
	HUBSPOT_INPUT_LABEL,
	META,
	META_DESCRIPTION,
	META_INPUT_LABEL,
	SICO,
	SICO_DESCRIPTION,
	SICO_INPUT_LABEL,
	SINCO,
	SINCO_DESCRIPTION,
	SINCO_INPUT_LABEL,
	SUITE,
	SUITE_DESCRIPTION,
	SUITE_INPUT_LABEL,
	ZAPIER_DESCRIPTION,
	ZAPIER_INPUT_LABEL,
	ZAPIER_WEBHOOK,
	ZOHO,
	ZOHO_DESCRIPTION,
} from '../../../application/constants/inventory';

const integrationsArray = [
	{
		type: SUITE,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/suite-2.png',
		title: SUITE,
		description: SUITE_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: SUITE_INPUT_LABEL,
	},
	{
		type: META,
		image: META_LOGO,
		title: META,
		description: META_DESCRIPTION,
		buttonText: 'Configurar',
		inputLabel: META_INPUT_LABEL,
	},
	{
		type: HUBSPOT,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/hubspot.png',
		title: HUBSPOT,
		description: HUBSPOT_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: HUBSPOT_INPUT_LABEL,
	},
	{
		type: ZAPIER_WEBHOOK,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/zapier.png',
		title: ZAPIER_WEBHOOK,
		description: ZAPIER_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: ZAPIER_INPUT_LABEL,
	},
	{
		type: GENERIC_WEBHOOK,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/webhook.png',
		title: GENERIC_WEBHOOK,
		description: GENERIC_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: GENERIC_INPUT_LABEL,
	},
	{
		type: SINCO,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/sinco.png',
		title: SINCO,
		description: SINCO_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: SINCO_INPUT_LABEL,
	},
	{
		type: ZOHO,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/zoho.png',
		title: ZOHO,
		description: ZOHO_DESCRIPTION,
		buttonText: 'Integrar',
	},
	{
		type: SICO,
		image: 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/integrations/sico.svg',
		title: SICO,
		description: SICO_DESCRIPTION,
		buttonText: 'Integrar',
		inputLabel: SICO_INPUT_LABEL,
	},
];

export default integrationsArray;
