import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { TEXTS } from '../../../../../infrastructure/locales/translation_keys';
import { getFullName } from '../../../../../../../shared/application/helpers/common-functions';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';

const ColumnActionVisited = ({ item }) => {
	const { t } = useTranslation();

	return (
		<TextTag variant="-body-sm" className="detail-movedto-text">
			{t(TEXTS.ATTENDED_BY, { ns: DOMAIN_NAME })} {getFullName(item?.performing_user)}
		</TextTag>
	);
};

ColumnActionVisited.propTypes = {
	item: PropTypes.object,
};

export default ColumnActionVisited;
