import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { getCurrentProject, setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal, Select, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import { PAYMENT_MODAL_ILLUSTRATION } from '../../../../../shared/application/constants/images';
import {
	DOCUMENT_TYPE_CC_VALUE,
	DOCUMENT_TYPE_CE,
	DOCUMENT_TYPE_CE_VALUE,
	DOCUMENT_TYPE_NIT,
	DOCUMENT_TYPE_NIT_VALUE,
	DOCUMENT_TYPE_CC,
	DOCUMENT_TYPE_P,
	DOCUMENT_TYPE_P_VALUE,
	userInformationFields,
} from '../../../application/constants/userInformation';
import { userInformationSchema } from '../../../application/helpers/validations/userIdentification';
import {
	updateAppointmentUserSnapshot,
	updateContactAnswerSnapshot,
	updateExternalContactSnapshot,
	updateQuotationUserSnapshot,
	updateSimulationUserSnapshot,
} from '../../../infrastructure/api/history';
import {
	STAGE_CONTACT,
	STAGE_EXTERNAL_CONTACT_ADS,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_QUOTED,
	STAGE_SCHEDULE,
	STAGE_SIMULATE,
} from '../../../application/constants/opportunityHistory';
import { setOpportunityHistoryUserSnapshotDetail } from '../../../application/slices/history';
import './ModalUserIdentification.scss';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { MODAL_USER_IDENTIFICATION } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';

const ModalUserIdentification = ({ item, showModal, setShowModal, onUserSnapshotUpdate }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [defaultValues, setDefaultValues] = useState({});
	const currentProject = useSelector(getCurrentProject);
	const userInformationFieldNames = userInformationFields(item?.action_type);
	const {
		control,
		register,
		handleSubmit,
		formState: { errors, isValid },
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(userInformationSchema(item?.action_type)),
	});

	const modalContentStyle = {
		alignItems: 'center',
		display: 'flex',
		flexFlow: 'column nowrap',
		gap: '18px',
		height: '90vh',
		justifyContent: 'center',
		padding: '8.3rem 9rem',
		width: '530px',
	};

	const DOCUMENT_TYPES_OPTIONS = [
		{ value: DOCUMENT_TYPE_CC_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CC], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_NIT_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_NIT], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_CE_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CE], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_P_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_P], { ns: DOMAIN_NAME }) },
	];

	const handleOnSubmitForm = async (data) => {
		setIsLoading(true);
		try {
			let service;
			if (item?.action_type === STAGE_SCHEDULE) {
				service = await updateAppointmentUserSnapshot(item?.action_instance?.user_snapshot_detail?.id, data);
			} else if ([STAGE_CONTACT, STAGE_EXTERNAL_CONTACT_REQUEST].includes(item?.action_type)) {
				service = await updateContactAnswerSnapshot(item?.action_instance?.id, data);
			} else if (item?.action_type === STAGE_SIMULATE) {
				service = await updateSimulationUserSnapshot(item?.action_instance?.user_snapshot_detail?.id, data);
			} else if (item?.action_type === STAGE_QUOTED) {
				const quotation_snapshot = await updateQuotationUserSnapshot(
					currentProject?.slug,
					item?.action_instance?.id,
					data,
					{ commercial_opportunity_id: item?.commercial_opportunity_id },
				);
				service = quotation_snapshot?.user_snapshot;
			} else if (item?.action_type === STAGE_EXTERNAL_CONTACT_ADS) {
				const quotation_snapshot = await updateExternalContactSnapshot(item?.action_instance?.id, {
					commercial_opportunity_id: item?.commercial_opportunity_id,
					...data,
				});
				service = quotation_snapshot?.user_snapshot_detail;
			}
			setIsLoading(false);
			dispatch(setOpportunityHistoryUserSnapshotDetail({ history_id: item?.id, snapshot: service }));
			onUserSnapshotUpdate();
		} catch (err) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: t(MODAL_USER_IDENTIFICATION.NO_COMFIRM_ID, { ns: DOMAIN_NAME }),
				}),
			);
		}
	};

	useEffect(() => {
		setDefaultValues({});
	}, []);

	return (
		<Modal isOpen={showModal} showClose={true} contentStyle={modalContentStyle} onClose={() => setShowModal(false)}>
			<div className="user-identification-modal">
				<img src={PAYMENT_MODAL_ILLUSTRATION} className="illustration" alt="¿Deseas bloquear el inmueble?" />
				<form onSubmit={handleSubmit(handleOnSubmitForm)} className="form">
					<TextTag tag="p" className="description text-center">
						{t(MODAL_USER_IDENTIFICATION.TITLE, { ns: DOMAIN_NAME })}
					</TextTag>
					<Controller
						control={control}
						name={userInformationFieldNames.DOCUMENT_TYPE}
						render={({ field: { onChange, name, value } }) => (
							<Select
								defaultOptionValue={value}
								options={DOCUMENT_TYPES_OPTIONS}
								id={name}
								showIconError={false}
								onSelectItem={(option) => onChange(option.value)}
								label={t(MODAL_USER_IDENTIFICATION.SELECT_DOC, { ns: DOMAIN_NAME })}
								error={
									errors[userInformationFieldNames.DOCUMENT_TYPE]?.message
										? t(errors[userInformationFieldNames.DOCUMENT_TYPE]?.message, { ns: DOMAIN_NAME_SHARED })
										: ''
								}
								size="fullwidth"
							/>
						)}
					/>

					<Input
						label={t(MODAL_USER_IDENTIFICATION.TYPE_DOC_LABEL, { ns: DOMAIN_NAME })}
						placeholder={t(MODAL_USER_IDENTIFICATION.TYPE_DOC_PLACEHOLDER, { ns: DOMAIN_NAME })}
						error={errors[userInformationFieldNames.DOCUMENT]?.message}
						{...register(userInformationFieldNames.DOCUMENT)}
					/>
					<Button className={'form-button'} disabled={!isValid} type="submit" variant="default">
						{isLoading ? (
							<Spinner className="spinner-locate" size="medium" />
						) : (
							t(MODAL_USER_IDENTIFICATION.CONTINUE_TEXT, { ns: DOMAIN_NAME })
						)}
					</Button>
				</form>
			</div>
		</Modal>
	);
};

ModalUserIdentification.propTypes = {
	item: PropTypes.object,
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	onUserSnapshotUpdate: PropTypes.func,
};

export default ModalUserIdentification;
