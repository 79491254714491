import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextTag } from '@vecindario/vecindario-suite-components';
import { finishIntegration as finishContactIntegration } from '../../../../application/slices/sincoContactIntegration';
import { activeCompanySelector, activeProjectSelector } from '../../../../application/selector/sincoIntegration';
import { SIMULATOR_EXIT_LOAD } from '../../../../../../shared/application/constants/images';

const Step3 = ({ setExpandCmpt }) => {
	const dispatch = useDispatch();

	const activeProject = useSelector(activeProjectSelector);
	const activeCompany = useSelector(activeCompanySelector);

	const handleClick = () => {
		setExpandCmpt && setExpandCmpt(false);
		dispatch(finishContactIntegration());
	};

	return (
		<>
			<img src={SIMULATOR_EXIT_LOAD} alt="Subir inmuebles" className="success-integration" />
			<TextTag tag="h3" className="text-center">
				Detalles de la integración
			</TextTag>
			<ul className="integration-details">
				<li>
					<TextTag tag="b">Nombre del proyecto:</TextTag> {activeProject?.nombre}
				</li>
				<li>
					<TextTag tag="b">Nombre de la empresa:</TextTag> {activeCompany?.Nombre}
				</li>
			</ul>
			<div className="action-buttons">
				<Button variant="bordered" onClick={handleClick}>
					Finalizar
				</Button>
			</div>
		</>
	);
};

Step3.propTypes = {
	setExpandCmpt: PropTypes.func,
};

export default Step3;
