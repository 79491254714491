import moment from 'moment';
import { TRANSACTION_STATUS_PENDING } from '../constants/transaction';
import { getFullName } from '../../../../shared/application/helpers/common-functions';
import { extractUserSnapshotDetail } from './opportunityHistory';

export const opportunityHasPendingTransactions = (transactions) => {
	let hasPendingTransaction = false;

	transactions.forEach((transaction) => {
		if (transaction.status === TRANSACTION_STATUS_PENDING && new Date(transaction?.expiration) > new Date())
			hasPendingTransaction = true;
	});

	return hasPendingTransaction;
};

export const getOpportunityPendingTransaction = (transactions) => {
	return transactions.find((transaction) => transaction.status === TRANSACTION_STATUS_PENDING);
};

export const calculateTimeLeft = (date) => {
	const minutesDifference = moment(date).diff(moment(), 'minutes');
	const minutesText = 'Min';
	const hoursDifference = moment(date).diff(moment(), 'hours');
	const hoursText = 'Hr';

	if (minutesDifference < 60) {
		return `${minutesDifference} ${minutesText}`;
	}

	return `${hoursDifference} ${hoursText}`;
};

export const getPerformerUserFullName = (opportunity, item) => {
	const userSnapshotDetail = extractUserSnapshotDetail(opportunity);
	const performingUserId = item?.performing_user?.user_id;
	const opportunityUserId = opportunity?.user_id;
	return getFullName(opportunityUserId !== performingUserId ? item?.performing_user : userSnapshotDetail);
};
