import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Input, Select, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import {
	MODAL_USER_IDENTIFICATION,
	OPTIONED_USER_IDENTIFICATION_FORM,
} from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { updateOptionedData } from '../../../application/slices/history';
import {
	DOCUMENT_TYPE_CC,
	DOCUMENT_TYPE_CC_VALUE,
	DOCUMENT_TYPE_CE,
	DOCUMENT_TYPE_CE_VALUE,
	DOCUMENT_TYPE_NIT,
	DOCUMENT_TYPE_NIT_VALUE,
	DOCUMENT_TYPE_P,
	DOCUMENT_TYPE_P_VALUE,
} from '../../../application/constants/userInformation';
import {
	LOCAL_STORAGE_KEY_OPTIONED_PROPERTY,
	LOCAL_STORAGE_KEY_OPTIONED_USER,
	OPTIONED_USER_INFORMATION_FIELDS,
} from '../../../application/constants/optionedInformation';
import { optionedUserInformationSchema } from '../../../application/helpers/validations/optionedIdentification';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityHistoryRoute, opportunityOptionedPeopleInQueueRoute } from '../../../infrastructure/routes';
import { extractPropertyToOptionFromOpportunity } from '../../../application/helpers/optionsSystem';
import { setValueToLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { selectOptionedData } from '../../../application/selector/history';

import './UserIdentificationForm.scss';

const UserIdentificationForm = ({ opportunity, setShowSelectPropertyModal }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const optionedData = useSelector(selectOptionedData);
	const [defaultValues, setDefaultValues] = useState({});
	const [isLoading, setIsLoading] = useState(false);

	const {
		control,
		register,
		handleSubmit,
		formState: { errors, isValid },
		reset,
	} = useForm({
		mode: 'onChange',
		defaultValues,
		resolver: yupResolver(optionedUserInformationSchema),
	});

	const DOCUMENT_TYPES_OPTIONS = [
		{ value: DOCUMENT_TYPE_CC_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CC], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_NIT_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_NIT], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_CE_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CE], { ns: DOMAIN_NAME }) },
		{ value: DOCUMENT_TYPE_P_VALUE, label: t(MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_P], { ns: DOMAIN_NAME }) },
	];

	const handleOnSubmitForm = (data) => {
		setIsLoading(true);

		reset({
			[OPTIONED_USER_INFORMATION_FIELDS.DOCUMENT_TYPE]: null,
			[OPTIONED_USER_INFORMATION_FIELDS.IDENTIFICATION]: null,
		});

		const propertyToOption = extractPropertyToOptionFromOpportunity({ opportunity });
		const propertyFromStore = optionedData?.property;

		setValueToLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_USER, JSON.stringify(data));
		dispatch(updateOptionedData(data));

		if (!propertyFromStore && propertyToOption?.property) {
			const propertyToSave = { ...propertyToOption?.property, lastAction: propertyToOption?.lastAction };
			dispatch(updateOptionedData({ ...data, property: propertyToSave }));
			setValueToLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_PROPERTY, JSON.stringify(propertyToSave));
		}

		if (propertyFromStore?.id || propertyToOption?.property?.id) {
			history.push(opportunityOptionedPeopleInQueueRoute(opportunity?.project_slug, opportunity?.id));
			return;
		}

		setShowSelectPropertyModal(true);
	};

	useEffect(() => {
		setDefaultValues({});
		dispatch(updateOptionedData({}));
	}, [dispatch, reset]);

	return (
		<div className="user-identification-form">
			<form onSubmit={handleSubmit(handleOnSubmitForm)} className="form">
				<TextTag tag="p" fw="bold" variant="-body-sm" className="header color-secondary">
					{t(OPTIONED_USER_IDENTIFICATION_FORM.TITLE, { ns: DOMAIN_NAME })}

					<Icon
						icon={ICON_CLOSE_LINE}
						onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
					/>
				</TextTag>

				<div className="content">
					<Controller
						control={control}
						name={OPTIONED_USER_INFORMATION_FIELDS.DOCUMENT_TYPE}
						render={({ field: { onChange, name, value } }) => (
							<Select
								defaultOptionValue={value}
								options={DOCUMENT_TYPES_OPTIONS}
								id={name}
								showIconError={false}
								onSelectItem={(option) => onChange(option.value)}
								label={t(MODAL_USER_IDENTIFICATION.SELECT_DOC, { ns: DOMAIN_NAME })}
								error={
									errors[OPTIONED_USER_INFORMATION_FIELDS.DOCUMENT_TYPE]?.message
										? t(errors[OPTIONED_USER_INFORMATION_FIELDS.DOCUMENT_TYPE]?.message, { ns: DOMAIN_NAME_SHARED })
										: ''
								}
								size="fullwidth"
							/>
						)}
					/>

					<Input
						{...register(OPTIONED_USER_INFORMATION_FIELDS.IDENTIFICATION)}
						label={t(MODAL_USER_IDENTIFICATION.TYPE_DOC_LABEL, { ns: DOMAIN_NAME })}
						placeholder={t(MODAL_USER_IDENTIFICATION.TYPE_DOC_PLACEHOLDER, { ns: DOMAIN_NAME })}
						error={errors[OPTIONED_USER_INFORMATION_FIELDS.IDENTIFICATION]?.message}
						defaultValue={null}
					/>
				</div>

				<div className="footer">
					<Button className={'form-button'} disabled={!isValid} type="submit" variant="default">
						{isLoading ? (
							<Spinner className="spinner-locate" size="medium" />
						) : (
							t(OPTIONED_USER_IDENTIFICATION_FORM.CONTINUE, { ns: DOMAIN_NAME })
						)}
					</Button>
				</div>
			</form>
		</div>
	);
};

UserIdentificationForm.propTypes = {
	opportunity: PropTypes.object,
	setShowSelectPropertyModal: PropTypes.func,
};

export default UserIdentificationForm;
