import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import './WebAnalisis.scss';
import { Icon, TextTag, Tooltip } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { ICON_CLOSE_LINE, ICON_FOLDER, ICON_INFORMATION_LINE } from '../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { WEB_ANALISIS } from '../../../infrastructure/locales/translation_keys';
import { requestOpportunityTracking } from '../../../application/slices/history';
import { selectOpportunityTracking } from '../../../application/selector/history';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';
import { history } from '../../../../../shared/application/helpers/history';
import { STAGE_LEADS_FACEBOOK, UTM_KEY_LIST } from '../../../application/constants/opportunityHistory';
import { ASESOR_ROLE, BUYER_ROLE, DIRECTOR_ROLE } from '../../../../integrations/application/constants/inventory';
import AdsIntegration from './AdsIntegration';

const WebAnalisisInfo = ({ opportunity }) => {
	const traking = useSelector(selectOpportunityTracking);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(requestOpportunityTracking({ slug: opportunity?.project_slug, commercial_opportunity_id: opportunity?.id }));
	}, [dispatch, opportunity]);

	const getTextValue = (item) => {
		if (item === UTM_KEY_LIST[9]) {
			const value = traking?.tracking_info?.[item];
			if ([DIRECTOR_ROLE, ASESOR_ROLE, BUYER_ROLE, STAGE_LEADS_FACEBOOK].includes(value)) {
				return t(`${WEB_ANALISIS?.[`ACTION_PERFORMER_VALUE_${value?.toUpperCase()}`]}`, { ns: DOMAIN_NAME });
			}
			return value;
		}

		if (item === UTM_KEY_LIST[7]) {
			const value = traking?.tracking_info?.[item];
			if (STAGE_LEADS_FACEBOOK.includes(value)) {
				return t(`${WEB_ANALISIS?.[`ACTION_PERFORMER_VALUE_${value?.toUpperCase()}_SOURCE`]}`, { ns: DOMAIN_NAME });
			}
		}

		return traking?.tracking_info?.[item];
	};

	return (
		<div className="web-analisis-general-container">
			<div className="web-analisis-container">
				<div className="web-analisis-header">
					<div className="wa-header-title">
						<Icon icon={ICON_FOLDER} size="small" />
						<TextTag variant="-body-sm" tag="label">
							{t(`${WEB_ANALISIS.TITLE}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
					<div className="wa-header-close">
						<Icon
							icon={ICON_CLOSE_LINE}
							size="small"
							onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
						/>
					</div>
				</div>
				<div className="web-analisis-body">
					{UTM_KEY_LIST.map((item) => {
						return (
							<div className="row" key={item}>
								<div className="row-item">
									<div className="utm-item">
										<TextTag variant="-body-sm" tag="label">
											{t(`${WEB_ANALISIS[item.toLocaleUpperCase()]}`, { ns: DOMAIN_NAME })}{' '}
											<TextTag variant="-body-xs" tag="label">
												({item})
											</TextTag>
										</TextTag>
									</div>
									<Tooltip
										position="right"
										text={
											<TextTag variant="-body-sm" tag="label">
												{t(`${WEB_ANALISIS[`${item.toLocaleUpperCase()}_TOOLTIP`]}`, { ns: DOMAIN_NAME })}
											</TextTag>
										}
									>
										<Icon icon={ICON_INFORMATION_LINE} />
									</Tooltip>
								</div>
								<div className="row-item">
									<TextTag variant="-body-sm" tag="label" className="traking-value">
										{getTextValue(item)}
									</TextTag>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{traking?.tracking_info?.source === STAGE_LEADS_FACEBOOK && <AdsIntegration />}
		</div>
	);
};

WebAnalisisInfo.propTypes = {
	opportunity: PropTypes.object.isRequired,
};

export default WebAnalisisInfo;
