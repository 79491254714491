import React from 'react';
import PropTypes from 'prop-types';
import {
	ALLOWED_TRANSACTION_STAGES,
	STAGE_CONTACT,
	STAGE_SEPARATED_BY_OPTION,
	STAGE_QUOTED,
	STAGE_RESERVED,
	STAGE_SEPARATED,
	STAGE_SIMULATE,
	STAGE_VERIFYING_PAYMENT,
	STAGE_VISITED,
	TRANSACTION_STAGE_DISPERSED,
	STAGE_OPTIONED_PROPERTY,
	CLOSED_OPPORTUNITY_STAGES,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_EXTERNAL_CONTACT_ADS,
} from '../../../../application/constants/opportunityHistory';
import SeeSimulationOrQuotationButton from '../../SeeSimulationOrQuotationButton';
import DispersionDetailButton from '../../DispersionDetailButton';
import { showPaymentButton } from '../../../../application/helpers/opportunityHistory';
import PaymentLinkButton from '../../PaymentLinkButton';
import PaymentButton from '../../PaymentButton';
import ColumnActionVisited from './ColumnActionVisited';
import ColumnActionReserved from './ColumnActionReserved';
import ColumnActionSeparated from './ColumnActionSeparated';
import ColumnActionTransactionDetail from './ColumnActionTransactionDetail';
import ColumnActionSeparatedByOption from './ColumnActionSeparatedByOption';
import ColumnActionOptionedProperty from './ColumnActionOptionedProperty';
import ColumnActionContactRequest from './ColumnActionContactRequest';
import ColumnActionExtContactRequest from './ColumnActionExtContactRequest';

const ColumnAction = ({ item, opportunity, transactionHistories, projectReservation }) => {
	let actionComponent = null;
	const validProjectReservation = projectReservation?.payment_methods_by_projects?.length > 0;
	const isOpenOpportunity = !CLOSED_OPPORTUNITY_STAGES.includes(opportunity?.stage);

	if (item?.action_type === STAGE_SIMULATE || item?.action_type === STAGE_QUOTED) {
		actionComponent = <SeeSimulationOrQuotationButton type={item?.action_type} item={item} />;
	}

	if (item?.action_type === STAGE_RESERVED) {
		actionComponent = (
			<ColumnActionReserved opportunity={opportunity} item={item} transactionHistories={transactionHistories} />
		);
	}

	if ([STAGE_VERIFYING_PAYMENT, STAGE_SEPARATED].includes(item?.action_type)) {
		actionComponent = <ColumnActionSeparated item={item} />;
	}

	if (item?.action_type === STAGE_VISITED) {
		actionComponent = <ColumnActionVisited item={item} />;
	}

	if (item?.action_type === TRANSACTION_STAGE_DISPERSED) {
		actionComponent = <DispersionDetailButton historyItem={item} />;
	}

	if (ALLOWED_TRANSACTION_STAGES.includes(item?.action_type)) {
		actionComponent = <ColumnActionTransactionDetail item={item} />;
	}

	if ([STAGE_CONTACT, STAGE_EXTERNAL_CONTACT_REQUEST].includes(item?.action_type)) {
		actionComponent = <ColumnActionContactRequest historyItem={item} opportunity={opportunity} />;
	}

	if (item?.action_type === STAGE_SEPARATED_BY_OPTION) {
		actionComponent = <ColumnActionSeparatedByOption opportunity={opportunity} item={item} />;
	}

	if (item?.action_type === STAGE_OPTIONED_PROPERTY) {
		actionComponent = <ColumnActionOptionedProperty opportunity={opportunity} item={item} />;
	}

	if (validProjectReservation && isOpenOpportunity) {
		actionComponent = (
			<>
				{actionComponent}
				{item?.action_type === STAGE_VERIFYING_PAYMENT ? <PaymentLinkButton historyItem={item} /> : null}
				{showPaymentButton(item) && item?.action_type !== STAGE_VERIFYING_PAYMENT ? <PaymentButton item={item} /> : null}
			</>
		);
	}

	if (item?.action_type === STAGE_EXTERNAL_CONTACT_ADS) {
		actionComponent = (
			<>
				{actionComponent}
				<ColumnActionExtContactRequest historyItem={item} opportunity={opportunity} />
			</>
		);
	}

	return <div className="actions-container">{actionComponent}</div>;
};

ColumnAction.propTypes = {
	item: PropTypes.object,
	opportunity: PropTypes.object,
	transactionHistories: PropTypes.array,
	projectReservation: PropTypes.object,
};

export default ColumnAction;
