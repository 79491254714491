import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { currencies } from '@vecindario/suite-library';
import { CONTACT_QUESTION_SLUGS } from '../../../../../application/constants/opportunityHistory';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { formatCurrency } from '../../../../../../../shared/application/helpers/common-functions';

const ColumnDetailContact = ({ item, project }) => {
	const { t } = useTranslation();
	const prefix = project?.currency_format?.prefix;
	const suffix = project?.currency_format?.suffix;
	const currency = item?.action_instance?.answers?.currency;

	const parseRowValue = (slug, value) => {
		if (slug === CONTACT_QUESTION_SLUGS.MONTHLY_PAYMENT_CAPACITY) {
			if (currency) {
				const foundCurrency = currencies.find((curr) => curr.suffix === currency);
				if (foundCurrency) {
					return formatCurrency(value, foundCurrency.prefix, foundCurrency.suffix);
				}
			}
			return formatCurrency(value, prefix, suffix);
		}

		if (slug === CONTACT_QUESTION_SLUGS.ECONOMIC_ACTIVITY) {
			return t(value, { ns: DOMAIN_NAME });
		}

		return value;
	};

	return (
		<table className="detail-table">
			<tbody className="body">
				{Object.values(CONTACT_QUESTION_SLUGS)?.map((slug) => {
					const value = item?.action_instance?.answers[slug];

					return !value ? null : (
						<tr className="row" key={`contact-question-${slug}`}>
							<th className="label">{t(slug, { ns: DOMAIN_NAME })}</th>
							<td className="value">{parseRowValue(slug, value)}</td>
						</tr>
					);
				})}
			</tbody>
		</table>
	);
};

ColumnDetailContact.propTypes = {
	item: PropTypes.object,
	project: PropTypes.object,
};

export default ColumnDetailContact;
