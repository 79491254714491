import urlBase from '../../../../shared/infrastructure/api/backend-urls';

export const urlGetOpportunitiesByProject = (slug = ':slug') => `${urlBase}proyectos/${slug}/oportunidades-comerciales`;

export const urlPutStageOpportunities = (slug = ':slug', id = ':id') =>
	`${urlBase}proyectos/${slug}/oportunidades-comerciales/${id}/cambiar-estado`;

export const urlDownloadReport = (slug = ':slug') =>
	`${urlBase}proyectos/${slug}/generar-reporte-oportunidades-comerciales`;

export const urlAvailabilityAsesor = (slug = ':slug', email = ':email') =>
	`${urlBase}proyectos/${slug}/disponibilidad-asesor?email=${email}`;
