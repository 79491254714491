import React, { useEffect, useMemo, useState } from 'react';
import { Input, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getProjectAvailablePropertiesBySlug } from '../../../infrastructure/api/history';
import './ModalSelectProperty.scss';
import {
	PAYMENT_LINK_MODAL_PROVIDER,
	PROPERTIES_NOT_FOUND,
	SEARCHER_PROPERTIES_NOT_FOUND,
	SEARCH_KEYS,
} from '../../../application/constants/opportunityHistory';
import { searchPipeline } from '../../../../../shared/application/helpers/search';
import { MODAL_UUNAVAILABLE_PROPERTY } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { LOADING } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { selectOpportunityUserDetails } from '../../../application/selector/history';

const ModalSelectProperty = ({ provider, showModal, setShowModal, setPropertySelected, propertySelected }) => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const [searchValue, setSearchValue] = useState('');
	const [properties, setProperties] = useState([]);
	const [searchResult, setSearchResult] = useState([]);
	const [loading, setLoading] = useState(true);
	const userSnapshotDetails = useSelector(selectOpportunityUserDetails);

	const modalContentStyle = {
		height: 'fit-content',
		maxHeight: '385px',
		padding: '0px',
		width: '600px',
		border: '1px solid #E7E8EA',
		overflowY: 'hidden',
	};

	const searchOpportunitiesPipeline = useMemo(
		() =>
			searchPipeline(properties, SEARCH_KEYS)
				.search(searchValue)
				.map(({ item }) => item),
		[properties, searchValue],
	);

	useEffect(() => {
		let filtered = searchValue.length >= 3 ? searchOpportunitiesPipeline : properties;
		if (provider === PAYMENT_LINK_MODAL_PROVIDER) {
			filtered = filtered?.filter((elem) => {
				const isOwner = elem?.active_option_user_email === userSnapshotDetails?.email;
				return elem?.option_snapshots_count > 0 && !isOwner ? null : elem;
			});
		}
		setSearchResult(filtered);
	}, [searchValue, searchOpportunitiesPipeline, properties, userSnapshotDetails, provider]);

	useEffect(() => {
		if (showModal) {
			getProjectAvailablePropertiesBySlug(slug)
				.then((resp) => setProperties(resp))
				.finally(() => setLoading(false));
		}
	}, [showModal, slug]);

	const renderStates = () => {
		if (loading) {
			return (
				<TextTag className="item-not-found" tag="p" variant="-body-sm">
					<i className="fal fa-spinner fa-spin"></i> {t(LOADING, { ns: DOMAIN_NAME_SHARED })} ...
				</TextTag>
			);
		}
		if (searchResult.length < 1) {
			let msg = SEARCHER_PROPERTIES_NOT_FOUND;
			if (properties.length === 0) {
				msg = PROPERTIES_NOT_FOUND;
			}

			return (
				<TextTag className="item-not-found" tag="p" variant="-body-sm">
					{msg}
				</TextTag>
			);
		}
		return null;
	};

	return (
		<Modal isOpen={showModal} showClose={true} contentStyle={modalContentStyle} onClose={() => setShowModal(false)}>
			<div className="select-property-modal">
				<TextTag tag="p" className="description">
					<span
						dangerouslySetInnerHTML={{ __html: t(MODAL_UUNAVAILABLE_PROPERTY.USER_WITHOUT_SIMULATION, { ns: DOMAIN_NAME }) }}
					/>
				</TextTag>
				<section className="container-list">
					<Input
						placeholder={t(MODAL_UUNAVAILABLE_PROPERTY.SEARCH_BUTTON, { ns: DOMAIN_NAME })}
						icon="ri-search-2-line"
						iconPosition="right"
						defaultValue={searchValue}
						onChange={(v) => setSearchValue(v.target.value)}
					/>

					<div className="properties-list">
						{searchResult.map((property) => (
							<div
								key={`property-${property?.id}`}
								className={`item ${propertySelected?.id === property?.id && 'selected'}`}
								onClick={() => setPropertySelected(property)}
							>
								<TextTag variant="-body-sm" fw="bold">
									{property?.tower_name} - {property?.name} - {property?.type_of_property}
									{property?.option_snapshots_count >= 1 && (
										<>
											-{' '}
											<TextTag variant="-body-sm" fw="normal" className="">
												{property?.option_snapshots_count === 1
													? t(MODAL_UUNAVAILABLE_PROPERTY.TEXT_1, { ns: DOMAIN_NAME })
													: t(MODAL_UUNAVAILABLE_PROPERTY.TEXT_2, { ns: DOMAIN_NAME, numPeople: property?.option_snapshots_count })}
											</TextTag>
										</>
									)}
								</TextTag>
							</div>
						))}

						{renderStates()}
					</div>
				</section>
			</div>
		</Modal>
	);
};

ModalSelectProperty.propTypes = {
	provider: PropTypes.string,
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	setPropertySelected: PropTypes.func,
	propertySelected: PropTypes.object,
};

export default ModalSelectProperty;
