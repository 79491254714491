export const ARROW_GO_BACK_LINE = 'ri-arrow-go-back-line';
export const ACCOUNT_CIRCLE_LINE = 'ri-account-circle-line';
export const ICON_DASHBOARD_LINE = 'ri-dashboard-line';
export const ICON_PHONE_LINE = 'ri-phone-line';
export const ICON_CONTACTS_BOOK_LINE = 'ri-contacts-book-2-line';
export const ICON_SEARCH_LINE = 'ri-search-2-line';
export const ICON_DOWNLOAD_LINE = 'ri-download-line';
export const TOGGLE_LINE_ICON = 'ri-toggle-line';
export const ICON_SHIELD = 'ri-shield-keyhole-line';
export const ICON_SETTINGS = 'ri-settings-3-line';
export const ICON_SHARE_LINE = 'ri-share-line';
export const ICON_TEAM_LINE = 'ri-team-line';
export const ICON_ARROW_DOWN_LINE = 'ri-arrow-drop-down-line';
export const ICON_NOTES_LINES = 'ri-sticky-note-line';
export const ICON_EYES_LINE = 'ri-eye-line';
export const ICON_ADD_CIRCLE_LINE = 'ri-add-circle-line';
export const ICON_ALARM_WARNING_LINE = 'ri-alarm-warning-line';
export const ICON_CLOSE_LINE = 'ri-close-line';
export const ICON_PENCIL_LINE = 'ri-pencil-line';
export const ICON_DELETE_LINE = 'ri-delete-bin-7-line';
export const ICON_INFORMATION_LINE = 'ri-information-line';
export const ICON_FOLDER = 'ri-folder-chart-line';
export const ICON_MORE_LINE = 'ri-more-2-line';
export const ICON_RULER_LINE = 'ri-ruler-line';
export const CHECK_LINE = 'ri-check-line';
export const DELETE_ICON = 'ri-delete-bin-line';
export const ICON_FACEBOOK = 'ri-facebook-box-line';
