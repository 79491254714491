import React from 'react';
import './HeaderMinimalNote.scss';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
	ICON_ADD_CIRCLE_LINE,
	ICON_EYES_LINE,
	ICON_NOTES_LINES,
} from '../../../../../shared/application/constants/icons';
import { setAction } from '../../../application/slices/notes';
import { actionSelector, listNotesSelector } from '../../../application/selector/notes';
import { opportunityNotesRoute } from '../../../infrastructure/routes';
import { ACTIONS } from '../../../application/constants/notes';
import { OPPORTUNITY_CARD } from '../../../../opportunityHistory/infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../opportunityHistory/infrastructure/locales';

const HeaderMinimalNote = () => {
	const action = useSelector(actionSelector);
	const listNotes = useSelector(listNotesSelector);
	const history = useHistory();
	const dispatch = useDispatch();
	const { slug, commercial_opportunity_id } = useParams();
	const { t } = useTranslation();

	const handleAddView = () => dispatch(setAction(ACTIONS.NEW));
	const handleDetailView = () => history.push(opportunityNotesRoute(slug, commercial_opportunity_id));

	return (
		<div className="container-header-note">
			<div className="title">
				<Icon icon={ICON_NOTES_LINES} size="medium" />
				<TextTag variant="-body-sm" fw="bold" font="DM-sans">
					{t(`${OPPORTUNITY_CARD.NOTES}`, { ns: DOMAIN_NAME })} {listNotes?.length > 0 ? `(${listNotes.length})` : ''}
				</TextTag>
			</div>
			{action !== ACTIONS.NEW && (
				<div className="actions">
					{listNotes.length > 0 && (
						<Icon icon={ICON_EYES_LINE} size="medium" aditionalClassName="view-detail" onClick={handleDetailView} />
					)}
					<Icon icon={ICON_ADD_CIRCLE_LINE} size="medium" onClick={handleAddView} aditionalClassName="add-note" />
				</div>
			)}
		</div>
	);
};

export default HeaderMinimalNote;
