import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import { opportunityNotesRoute } from './routes';
import DetailNotesPages from '../presentation/pages/DetailNotesPages';

const notesRouter = {
	layout: AdminLayout,
	router: [
		{
			path: opportunityNotesRoute(),
			page: DetailNotesPages,
			routeComponent: PrivateRoute,
		},
	],
};

export default notesRouter;
