import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestHistory } from '../../../application/selector/history';
import MessageOption from '../../components/MessageOption';

const MessageOptionPage = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <MessageOption opportunity={opportunity} />;
};

export default MessageOptionPage;
