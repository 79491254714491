import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
	PAYMENT_LINK_MODAL_PROVIDER,
	STAGE_CONTACT,
	STAGE_EXTERNAL_CONTACT_ADS,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_SCHEDULE,
} from '../../../application/constants/opportunityHistory';
import ModalSelectProperty from '../ModalSelectProperty';
import ModalPaymentLink from '../ModalPaymentLink';
import ModalUserIdentification from '../ModalUserIdentification';
import {
	isPropertyAvailable,
	opportunityHistoryIncludeProperty,
	snapshotHasUserIdentification,
} from '../../../application/helpers/opportunityHistory';
import ModalUnavailableProperty from '../ModalUnavailableProperty';
import { selectRequestHistoryTransactions } from '../../../application/selector/history';
import {
	getOpportunityPendingTransaction,
	opportunityHasPendingTransactions,
} from '../../../application/helpers/commercialOpportunity';
import ModalSelectPaymentMethod from '../ModalSelectPaymentMethod';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { PAY_LINK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { OPTION_STATE_PENDING } from '../../../application/constants/optionedInformation';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityOptionedQueuedRoute } from '../../../infrastructure/routes';
import { setCurrentOptionSelected } from '../../../application/slices/history';

const PaymentButton = ({ item }) => {
	const opportunityTransactions = useSelector(selectRequestHistoryTransactions);
	const [showUnavailablePropertyModal, setShowUnavailablePropertyModal] = useState(false);
	const [showSelectPropertyModal, setShowSelectPropertyModal] = useState(false);
	const [showSelectPaymentMethodModal, setShowSelectPaymentMethodModal] = useState(false);
	const [showPaymentLinkModal, setShowPaymentLinkModal] = useState(false);
	const [showUserIdentificationModal, setShowUserIdentificationModal] = useState(false);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState(null);
	const [propertySelected, setPropertySelected] = useState(null);
	const { t } = useTranslation();
	const { slug, commercial_opportunity_id } = useParams();
	const dispatch = useDispatch();

	const handleValidateAction = () => {
		if (item?.action_instance?.option_state === OPTION_STATE_PENDING) {
			dispatch(setCurrentOptionSelected({ ...item?.action_instance, history: item }));
			history.push(opportunityOptionedQueuedRoute(slug, commercial_opportunity_id));
		} else if (opportunityHasPendingTransactions(opportunityTransactions)) {
			setShowPaymentLinkModal(true);
		} else if (
			!isPropertyAvailable(item) &&
			![STAGE_SCHEDULE, STAGE_CONTACT, STAGE_EXTERNAL_CONTACT_REQUEST, STAGE_EXTERNAL_CONTACT_ADS].includes(
				item.action_type,
			)
		) {
			setShowUnavailablePropertyModal(true);
		} else if (!snapshotHasUserIdentification(item)) {
			setShowUserIdentificationModal(true);
		} else if (!opportunityHistoryIncludeProperty(item)) {
			setShowSelectPropertyModal(true);
		} else {
			setShowSelectPaymentMethodModal(true);
		}
	};

	const handleOnCloseUnavailablePropertyModal = () => {
		setShowUnavailablePropertyModal(false);

		if (!snapshotHasUserIdentification(item)) {
			setShowUserIdentificationModal(true);
		} else {
			setShowSelectPropertyModal(true);
		}
	};

	const handleOnSelectProperty = (property) => {
		setPropertySelected(property);
		setShowSelectPropertyModal(false);
		setShowSelectPaymentMethodModal(true);
	};

	const handleUserSnapshotUpdated = () => {
		setShowUserIdentificationModal(false);

		if (!opportunityHistoryIncludeProperty(item) || !isPropertyAvailable(item)) {
			setShowSelectPropertyModal(true);
		} else {
			setShowSelectPaymentMethodModal(true);
		}
	};

	const buildItem = useMemo(() => {
		if (opportunityHasPendingTransactions(opportunityTransactions))
			return { ...item, action_instance: getOpportunityPendingTransaction(opportunityTransactions) };

		const property = propertySelected || item?.action_instance?.property;
		const itemBuilded = { ...item, action_instance: { ...item.action_instance, property } };

		if (paymentMethodSelected?.id) {
			itemBuilded.payment_method_name = paymentMethodSelected?.name;
			itemBuilded.payment_method_type = paymentMethodSelected?.type;
		}
		return itemBuilded;
	}, [item, propertySelected, opportunityTransactions, paymentMethodSelected]);

	const handleOnSelectPaymentMethod = (payment) => {
		setPaymentMethodSelected(payment);
		setShowSelectPaymentMethodModal(false);
		setShowPaymentLinkModal(true);
	};

	return (
		<>
			<button className="payment-link action-link" onClick={handleValidateAction}>
				{t(`${PAY_LINK}`, { ns: DOMAIN_NAME_SHARED })}
			</button>
			<ModalUnavailableProperty
				showModal={showUnavailablePropertyModal}
				setShowModal={setShowUnavailablePropertyModal}
				onCloseModal={handleOnCloseUnavailablePropertyModal}
			/>
			<ModalSelectProperty
				provider={PAYMENT_LINK_MODAL_PROVIDER}
				showModal={showSelectPropertyModal}
				setShowModal={setShowSelectPropertyModal}
				setPropertySelected={handleOnSelectProperty}
				propertySelected={propertySelected}
			/>
			<ModalUserIdentification
				item={item}
				showModal={showUserIdentificationModal}
				setShowModal={setShowUserIdentificationModal}
				onUserSnapshotUpdate={handleUserSnapshotUpdated}
			/>
			<ModalSelectPaymentMethod
				showModal={showSelectPaymentMethodModal}
				setShowModal={setShowSelectPaymentMethodModal}
				onPaymentMethodSelect={handleOnSelectPaymentMethod}
			/>
			{showPaymentLinkModal && (
				<ModalPaymentLink showModal={showPaymentLinkModal} setShowModal={setShowPaymentLinkModal} item={buildItem} />
			)}
		</>
	);
};

PaymentButton.propTypes = {
	item: PropTypes.object.isRequired,
};

export default PaymentButton;
