import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Checkbox, Select, Input } from '@vecindario/vecindario-suite-components';
import './PipelineStageRow.scss';
import { filterStageBySelectedPipeline } from '../../../../application/helpers/hubspotIntegration';

const PipelineStageRow = ({
	checkboxText,
	opportunityTypeVale,
	pipelineList,
	pipelinesData,
	setArrayPipelines,
	integratedDataRow,
}) => {
	const dispatch = useDispatch();
	const [disableSelectPipeline, setdisableSelectPipeline] = useState(true);
	const [disableSelectStage, setdisableSelectStage] = useState(true);
	const [pipelineSelected, setPipelineSelected] = useState('');
	const [opportunityTypeSelected, setOpportunityTypeSelected] = useState('');
	const styleDisable = { cursor: 'not-allowed', borderColor: '#b6bbc0' };

	const stagesListSelector = useMemo(
		() => pipelinesData && pipelineSelected && filterStageBySelectedPipeline(pipelinesData, pipelineSelected),
		[pipelineSelected, pipelinesData],
	);
	const [stageSelected, setStageSelected] = useState(stagesListSelector[0]?.value);

	const handleCheckbox = (e) => {
		if (e?.target?.checked) {
			setdisableSelectPipeline(false);
			setOpportunityTypeSelected(opportunityTypeVale);
		} else {
			setdisableSelectPipeline(true);
			setOpportunityTypeSelected('');
			setPipelineSelected('');
			setStageSelected('');
			setArrayPipelines((preArray) => {
				const newArray = preArray.filter((item) => item.opportunity_type !== opportunityTypeVale);
				return newArray;
			});
		}
	};

	const handleOnSelectItem = (value, isStage) => {
		if (isStage) {
			setStageSelected(value);
		} else {
			setdisableSelectStage(false);
			setPipelineSelected(value);
		}
	};

	useEffect(() => {
		if (!integratedDataRow) {
			setStageSelected(stagesListSelector[0]?.value);
		}
	}, [stagesListSelector, integratedDataRow]);

	useEffect(() => {
		if (integratedDataRow) {
			setdisableSelectPipeline(false);
			setOpportunityTypeSelected(opportunityTypeVale);
			setPipelineSelected(integratedDataRow?.pipeline_id);
			setStageSelected(integratedDataRow?.stage);
		}
	}, [dispatch, integratedDataRow, opportunityTypeVale]);

	useEffect(() => {
		if (opportunityTypeSelected && pipelineSelected && stageSelected) {
			const data = { pipeline_id: pipelineSelected, stage: stageSelected, opportunity_type: opportunityTypeSelected };
			setArrayPipelines((preArray) => {
				const index = preArray.findIndex((item) => item.opportunity_type === data.opportunity_type);

				if (index !== -1) {
					const newArray = [...preArray];
					newArray[index] = { ...newArray[index], pipeline_id: pipelineSelected, stage: stageSelected };
					return newArray;
				}
				return [...preArray, data];
			});
		}
	}, [opportunityTypeSelected, pipelineSelected, stageSelected, setArrayPipelines]);

	useEffect(() => {
		if (integratedDataRow) {
			setPipelineSelected(integratedDataRow?.pipeline_id);
			setStageSelected(integratedDataRow?.stage);
		}
	}, [integratedDataRow]);

	console.log('DATA', integratedDataRow);
	console.log('PIPE', pipelineSelected);
	console.log('STAGE', stageSelected);

	return (
		<div className="container-pipeline-stage-row">
			<Checkbox
				id={`${opportunityTypeVale}-checkbox`}
				label={checkboxText}
				labelPosition="right"
				onChange={(e) => handleCheckbox(e)}
				defaultChecked={integratedDataRow && true}
			/>
			<Select
				label=" "
				id={opportunityTypeVale}
				onSelectItem={({ value }) => handleOnSelectItem(value)}
				options={pipelineList}
				disabled={disableSelectPipeline}
				containerStyle={disableSelectPipeline ? styleDisable : {}}
				showFinder={true}
				defaultOptionValue={!disableSelectPipeline && pipelineSelected}
			/>
			{pipelineSelected ? (
				<Select
					label=" "
					id={`${opportunityTypeVale}-stageList`}
					onSelectItem={({ value }) => handleOnSelectItem(value, true)}
					options={stagesListSelector}
					disabled={disableSelectStage}
					containerStyle={disableSelectStage ? styleDisable : {}}
					defaultOptionValue={!disableSelectStage && stageSelected}
					showFinder={true}
				/>
			) : (
				<Input disabled={true} style={styleDisable}></Input>
			)}
		</div>
	);
};

PipelineStageRow.propTypes = {
	checkboxText: PropTypes.string,
	opportunityTypeVale: PropTypes.string,
	stageName: PropTypes.string,
	pipelineList: PropTypes.array,
	pipelinesData: PropTypes.array,
	setArrayPipelines: PropTypes.func,
	integratedDataRow: PropTypes.object,
};

export default PipelineStageRow;
