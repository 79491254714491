import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, TextTag, Tooltip } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { ICON_FACEBOOK, ICON_INFORMATION_LINE } from '../../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { EXTERNAL_CONTACT, WEB_ANALISIS } from '../../../../infrastructure/locales/translation_keys';
import { requestOpportunityTracking } from '../../../../application/slices/history';
import { EXTERNAL_CONTACT_KEY_LIST } from '../../../../application/constants/opportunityHistory';
import { selectRequestHistory } from '../../../../application/selector/history';

const AdsIntegration = ({ opportunity }) => {
	const { t } = useTranslation();
	const reqHistory = useSelector(selectRequestHistory);
	const dispatch = useDispatch();
	const externalSourceInformation = reqHistory?.opportunity_histories[0]?.action_instance?.external_source_information;

	const getTextProvider = () => {
		return t(`${EXTERNAL_CONTACT[externalSourceInformation?.source]}`, { ns: DOMAIN_NAME });
	};

	const externalSourceInfo = {
		campaign_id: externalSourceInformation?.campaign_id,
		campaign_name: externalSourceInformation?.campaign_name,
		lead_registration_date: externalSourceInformation?.created_time,
		lead_id: externalSourceInformation?.lead_id,
		advertisement_name: externalSourceInformation?.ad_name,
		form_id: externalSourceInformation?.form_id,
	};

	useEffect(() => {
		dispatch(requestOpportunityTracking({ slug: opportunity?.project_slug, commercial_opportunity_id: opportunity?.id }));
	}, [dispatch, opportunity]);

	const filteredKeys = EXTERNAL_CONTACT_KEY_LIST.filter((key) => {
		return Object.keys(externalSourceInfo).includes(key);
	});

	const getTextValue = (item) => {
		return externalSourceInfo?.[item];
	};

	return (
		<div className="web-analisis-container">
			<div className="web-analisis-header">
				<div className="wa-header-title">
					<Icon icon={ICON_FACEBOOK} size="small" />
					<TextTag variant="-body-sm" tag="label">
						{t(`${WEB_ANALISIS.TITLE_FACEBOOK_BUSINESS}`, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>
			</div>
			<div className="web-analisis-body">
				{filteredKeys.map((item) => {
					return (
						<div className="row" key={item}>
							<div className="row-item">
								<div className="utm-item">
									<TextTag variant="-body-sm" tag="label">
										{t(`${EXTERNAL_CONTACT[item.toLocaleUpperCase()]}`, { ns: DOMAIN_NAME, provider: getTextProvider() })}{' '}
									</TextTag>
								</div>
								<Tooltip
									position="right"
									text={
										<TextTag variant="-body-sm" tag="label">
											{t(`${EXTERNAL_CONTACT[`${item.toLocaleUpperCase()}_TOOLTIP`]}`, { ns: DOMAIN_NAME })}
										</TextTag>
									}
								>
									<Icon icon={ICON_INFORMATION_LINE} />
								</Tooltip>
							</div>
							<div className="row-item">
								<TextTag variant="-body-sm" tag="label" className="traking-value">
									{getTextValue(item)}
								</TextTag>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

AdsIntegration.propTypes = {
	opportunity: PropTypes.object.isRequired,
};

export default AdsIntegration;
