import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import { PAYMENT_MODAL_ILLUSTRATION } from '../../../../../shared/application/constants/images';
import { selectPaymentMethods } from '../../../application/selector/history';
import { formattedTimeInMinutes } from '../../../../../shared/application/helpers/common-functions';
import { PAYMENT_METHODS_OPTIONS } from '../../../application/constants/transaction';
import { SEPARATION_FIDUCIARY_METHOD, SEPARATION_TYPE } from '../../../application/constants/opportunityHistory';
import './ModalSelectPaymentMethod.scss';
import { PAYMENT_MODAL } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { GENERATE_LINK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';

const MODAL_CONTENT_STYLES = {
	maxHeight: '90vh',
	height: '770px',
	padding: '8.3rem 9rem',
	width: '530px',
};

const GetDescriptionText = ({ expirationMinutes }) => {
	const { t } = useTranslation();
	return (
		<span
			dangerouslySetInnerHTML={{
				__html: t(`${PAYMENT_MODAL.PAYMENT_BLOCK_PROP_TEXT}`, {
					ns: DOMAIN_NAME,
					minutes: formattedTimeInMinutes(expirationMinutes),
				}),
			}}
		/>
	);
};

GetDescriptionText.propTypes = {
	expirationMinutes: PropTypes.number,
};

const ModalSelectPaymentMethod = ({ showModal, setShowModal, onPaymentMethodSelect }) => {
	const paymentMethods = useSelector(selectPaymentMethods);
	const [paymentMethodSelected, setPaymentMethodSelected] = useState({});
	const { t } = useTranslation();

	const getBadgeExpirationTime = (expirationMinutes) => {
		const timeNumber = expirationMinutes < 60 ? expirationMinutes : Math.floor(expirationMinutes / 60);
		const timeText = expirationMinutes < 60 ? 'Min' : 'Hrs';

		return `${timeNumber} ${timeText}`;
	};

	const normalizeString = (str) =>
		str
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replaceAll(' ', '_')
			.toLowerCase();

	const paymentRappiMethod = useMemo(() => {
		if (!paymentMethods) return null;
		const method = paymentMethods?.find((elem) => elem?.payment_method?.name === PAYMENT_METHODS_OPTIONS.rappi_pay.text);
		return method?.payment_method || null;
	}, [paymentMethods]);

	useEffect(() => {
		if (showModal) setPaymentMethodSelected({});
	}, [showModal]);

	return (
		<Modal
			isOpen={showModal}
			contentStyle={MODAL_CONTENT_STYLES}
			onClose={() => setShowModal(false)}
			closeClassName="select-payment-method-close"
		>
			<div className="select-payment-method-modal">
				<img src={PAYMENT_MODAL_ILLUSTRATION} className="illustration" alt="Elige el método de pago" />
				<TextTag tag="p" className="description text-center">
					{t(`${PAYMENT_MODAL.TITLE_2}`, { ns: DOMAIN_NAME })}
				</TextTag>
				<div className="payment-methods">
					{paymentMethods
						?.filter((elem) => elem?.payment_method?.name !== PAYMENT_METHODS_OPTIONS.rappi_pay.text)
						?.map((method) => {
							const { id, name, expiration_minutes, type } = method?.payment_method || {};
							const nameNormalized = normalizeString(name);
							const isSeparation = type === SEPARATION_TYPE;
							const isFiduciary = name === SEPARATION_FIDUCIARY_METHOD;

							return (
								<div key={id} className="item">
									<div
										onClick={() => setPaymentMethodSelected(method?.payment_method)}
										className={`title ${paymentMethodSelected?.id === id ? 'active' : ''}`}
									>
										{isFiduciary ? method?.payment_info?.name : t(`${PAYMENT_MODAL[nameNormalized]}`, { ns: DOMAIN_NAME })}
										{!isSeparation && <span className="badge">{getBadgeExpirationTime(expiration_minutes)}</span>}
									</div>
									{paymentMethodSelected?.id === id && !isFiduciary && (
										<TextTag tag="p" className="description">
											<GetDescriptionText expirationMinutes={expiration_minutes} />
										</TextTag>
									)}
								</div>
							);
						})}
					{paymentRappiMethod && (
						<div className="item">
							<div
								onClick={() => setPaymentMethodSelected(paymentRappiMethod)}
								className={`title rappi_pay ${paymentMethodSelected?.id === paymentRappiMethod.id ? 'active' : ''}`}
							>
								<img
									className="logo"
									src="https://suite-static-files.s3.us-east-2.amazonaws.com/inventory/button-rappipay-text.svg"
								/>
							</div>
							{paymentMethodSelected?.id === paymentRappiMethod.id && (
								<TextTag tag="p" className="description">
									<GetDescriptionText expirationMinutes={paymentRappiMethod?.expiration_minutes} />
								</TextTag>
							)}
						</div>
					)}
				</div>

				<Button
					disabled={!paymentMethodSelected?.id}
					onClick={() => onPaymentMethodSelect(paymentMethodSelected)}
					text={t(`${GENERATE_LINK}`, { ns: DOMAIN_NAME_SHARED })}
				/>
			</div>
		</Modal>
	);
};

ModalSelectPaymentMethod.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	onPaymentMethodSelect: PropTypes.func,
};

export default ModalSelectPaymentMethod;
