import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { useSelector } from 'react-redux';
import { selectRequestHistory } from '../../../application/selector/history';
import { externalBusinessDetailRoute } from '../../../infrastructure/routes';
import './DesistButton.scss';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { DESIST } from '../../../infrastructure/locales/translation_keys';

const DesistButton = () => {
	const { slug } = useParams();
	const userHistory = useSelector(selectRequestHistory) || {};
	const { t } = useTranslation();

	if (!userHistory.business) return null;

	return (
		<>
			<Button variant="secondary" className="desist-button">
				<a
					href={externalBusinessDetailRoute(slug, userHistory.business.id)}
					target="_blank"
					rel="noreferrer"
					className="link"
				>
					<Icon icon="ri-close-circle-line" size="small" aditionalClassName="color-error-400t" />
					<TextTag variant="-body-xs" fw="bold" font="DM-sans" tag="span" className="text color-secondary-400t">
						{t(`${DESIST}`, { ns: DOMAIN_NAME })}
					</TextTag>
				</a>
			</Button>
		</>
	);
};

export default DesistButton;
