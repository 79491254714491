import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, TextTag, ToggleSwitch } from '@vecindario/vecindario-suite-components';
import {
	CONNECTED_BY_ROW,
	CONNECTION_ROW,
	DELETE_ROW,
	CAMPAIGN_NAME_ROW,
	CAMPAIGN_ID_ROW,
	CREATION_DATE_ROW,
} from '../../../../application/constants/facebookAccounts';
import {
	ACCOUNTS_TABLE_HEADER,
	CAMPAIGN_TABLE_HEADER,
	FACEBOOK,
} from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import TableIntegration from '../../TableIntegration';
import { selectAccount, selectAdsCampaigns } from '../../../../application/selector/facebook';
import InformativeNotice from '../../InformativeNotice';
import {
	getListCampaignsAds,
	setCampaignSelected,
	updateConnectionCampaign,
} from '../../../../application/slices/facebook';
import { deleteCampaignRoute, disconnectCampaignRoute } from '../../../../infrastructure/routes';

const TableCampaigns = ({ setShowCampaigns }) => {
	const { slug } = useParams();
	const history = useHistory();
	const { t } = useTranslation();
	const account = useSelector(selectAccount);
	const campaigns = useSelector(selectAdsCampaigns);
	const dispatch = useDispatch();
	const [showBtnNewCampaign, setShowBtnNewCampaign] = useState(true);
	const historyState = { slug };

	const headersTable = {
		[CAMPAIGN_NAME_ROW]: t(CAMPAIGN_TABLE_HEADER[CAMPAIGN_NAME_ROW], { ns: DOMAIN_NAME }),
		[CAMPAIGN_ID_ROW]: t(CAMPAIGN_TABLE_HEADER[CAMPAIGN_ID_ROW], { ns: DOMAIN_NAME }),
		[CREATION_DATE_ROW]: t(CAMPAIGN_TABLE_HEADER[CREATION_DATE_ROW], { ns: DOMAIN_NAME }),
		[CONNECTED_BY_ROW]: t(ACCOUNTS_TABLE_HEADER[CONNECTED_BY_ROW], { ns: DOMAIN_NAME }),
		[CONNECTION_ROW]: t(ACCOUNTS_TABLE_HEADER[CONNECTION_ROW], { ns: DOMAIN_NAME }),
		[DELETE_ROW]: t(ACCOUNTS_TABLE_HEADER[DELETE_ROW], { ns: DOMAIN_NAME }),
	};

	useEffect(() => {
		if (campaigns.length >= 5) {
			setShowBtnNewCampaign(false);
		} else {
			setShowBtnNewCampaign(true);
		}
	}, [campaigns]);

	const handleNewCampaign = () => {
		setShowCampaigns(false);
		dispatch(getListCampaignsAds({ slug, account }));
	};

	const handleConnection = (campaign) => {
		if (campaign.is_active) {
			dispatch(setCampaignSelected(campaign));
			history.push(disconnectCampaignRoute(slug), historyState);
		} else {
			dispatch(
				updateConnectionCampaign({
					slug,
					provider_account_id: account?.account_id,
					campaignId: campaign?.id,
				}),
			);
		}
	};

	const handleDelete = (campaign) => {
		dispatch(setCampaignSelected(campaign));
		history.push(deleteCampaignRoute(slug), historyState);
	};

	const sortedCampaigns = campaigns.slice().sort((a, b) => {
		// Sort descending by campaign_start_time
		return new Date(b.campaign_start_time) - new Date(a.campaign_start_time);
	});

	const rowsDataTable = () => {
		if (campaigns.length > 0) {
			return sortedCampaigns?.map((item) => {
				const row = {
					[CAMPAIGN_NAME_ROW]: (
						<div className="campaign-name">
							<TextTag tag="span" variant="-body-sm" fw="bold">
								{item?.campaign_name}
							</TextTag>
						</div>
					),
					[CAMPAIGN_ID_ROW]: (
						<div className="campaign-id">
							<TextTag tag="span" variant="-body-sm">
								{item?.campaign_id}
							</TextTag>
						</div>
					),
					[CREATION_DATE_ROW]: (
						<div className="creation-date">
							<TextTag tag="span" variant="-body-sm">
								{item?.connection_date}
							</TextTag>
						</div>
					),
					[CONNECTED_BY_ROW]: (
						<div className="connected_by">
							<TextTag tag="span" variant="-body-sm">
								{item?.user_creator_name}
							</TextTag>
							<a href={`mailto: ${item?.user_creator_email}`}>
								<TextTag tag="p" variant="-body-xs">
									{item?.user_creator_email}
								</TextTag>
							</a>
						</div>
					),
					[CONNECTION_ROW]: (
						<ToggleSwitch
							id={`${item?.campaign_id}`}
							value={item?.is_active}
							onChange={() => handleConnection(item)}
							disabled={!account.is_active}
						/>
					),
					[DELETE_ROW]: (
						<Button className="delete-cta" onClick={() => handleDelete(item)}>
							<Icon icon="ri-delete-bin-2-line" size="small" />
						</Button>
					),
				};

				return row;
			});
		}

		return [];
	};

	return (
		<div className="table-campaign-container">
			<div className="header">
				<TextTag tag="h5">{t(FACEBOOK.CAMPAIGNS_TITLE_V2, { ns: DOMAIN_NAME })}</TextTag>
				{showBtnNewCampaign && (
					<Button size="-small" onClick={handleNewCampaign} disabled={!account.is_active}>
						{t(FACEBOOK.CAMPAIGNS_NEW_CAMPAIGN, { ns: DOMAIN_NAME })}
					</Button>
				)}
			</div>

			<div className="campaigns-fb-card">
				<div className="campaigns-table">
					{campaigns.length >= 5 && (
						<InformativeNotice>
							<Icon icon="ri-alert-line" size="small" />
							<TextTag tag="span" variant="-body-sm">
								{t(FACEBOOK.CAMPAIGNS_MAXIMUM_ACTIVE, { ns: DOMAIN_NAME })}
							</TextTag>
						</InformativeNotice>
					)}
					<TableIntegration headersTable={headersTable} rowsDataTable={rowsDataTable} />
				</div>
			</div>
		</div>
	);
};

TableCampaigns.propTypes = {
	setShowCampaigns: PropTypes.func,
};

export default TableCampaigns;
