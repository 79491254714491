import { createSelector } from '@reduxjs/toolkit';
import { STAGE } from '../constants/opportunities';
import { SCHEDULER_KEY } from '../../../../shared/application/constants/modules_keys';

export const opportunitiesState = (state) => state.opportunities;

export const listOpportunities = createSelector(opportunitiesState, (state = {}) => state?.list);

export const opportunitiesSearchResult = createSelector(opportunitiesState, (state = {}) => state?.search.result);

export const opportunitiesSearchField = createSelector(opportunitiesState, (state = {}) => state?.search.field);

export const canMakeRequests = createSelector(opportunitiesState, (state = {}) => !state.isLoading);

export const isLoading = createSelector(opportunitiesState, (state = {}) => state.isLoading);

export const showOpportunitiesSearchError =
	(opportunitiesState,
	({ opportunities }) => {
		if (opportunities?.search?.field.length < 3) return false;
		if (opportunities?.search?.result.length === 0) return true;
		return false;
	});

export const opportunitiesInterested = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.INTERESTED);

	return {
		id: STAGE.INTERESTED,
		badge: 'info',
		itemDraggable: true,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const opportunitiesVisited = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.VISITED);
	return {
		id: STAGE.VISITED,
		badge: 'warning',
		itemDraggable: true,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const opportunitiesReserved = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.RESERVED);
	return {
		id: STAGE.RESERVED,
		badge: 'success',
		itemDraggable: false,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const opportunitiesDiscarded = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.DISCARDED);
	return {
		id: STAGE.DISCARDED,
		badge: 'error',
		itemDraggable: false,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const opportunitiesDesisted = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.DESISTED);
	return {
		id: STAGE.DESISTED,
		badge: 'purple',
		itemDraggable: false,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const separatedOpportunities = createSelector(opportunitiesSearchResult, (opportunities) => {
	if (!opportunities) return null;

	const items = opportunities.filter((op) => !op?.stage || op?.stage === STAGE.SEPARATED);
	return {
		id: STAGE.SEPARATED,
		badge: 'success',
		itemDraggable: false,
		items: items.sort((a, b) => new Date(b.last_modification) - new Date(a.last_modification)),
	};
});

export const modulesByProjectActions = (arrayModules = []) =>
	createSelector(
		({ project }) => project.modulesByProject,
		({ opportunities }) => opportunities.availabilityAsesorScheduler,
		(modulesByProject, availabilityAsesorScheduler) => {
			arrayModules = arrayModules.filter((item) =>
				!availabilityAsesorScheduler && arrayModules.includes(SCHEDULER_KEY) ? item !== SCHEDULER_KEY : item,
			);
			return modulesByProject.filter((module) => arrayModules?.some((item) => module.name === item));
		},
	);
