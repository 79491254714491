import React, { useState } from 'react';
import { Icon } from '@vecindario/vecindario-suite-components';
import { Popover } from 'react-tiny-popover';
import { ICON_SHARE_LINE } from '../../../../../../shared/application/constants/icons';

import './NewOpportunityShared.scss';
import ListNewOpportunity from '../ListNewOpportunity';

function NewOpportunityShared() {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false);
	return (
		<Popover
			isOpen={isPopoverOpen}
			content={<ListNewOpportunity action="copy" actionType="share" />}
			positions={['bottom', 'left']}
			padding={20}
			reposition={true}
			align="end"
			containerClassName="popover-search-container"
			onClickOutside={() => setIsPopoverOpen(false)}
		>
			<div className="popover-content">
				<Icon
					aditionalClassName="icon-container"
					icon={ICON_SHARE_LINE}
					size="medium"
					onClick={() => setIsPopoverOpen(!isPopoverOpen)}
				/>
			</div>
		</Popover>
	);
}

export default NewOpportunityShared;
