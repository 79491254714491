import {
	ACCOUNT_ID_ROW,
	ADVERTISIG_ACCOUNT_ROW,
	CAMPAIGN_ID_ROW,
	CAMPAIGN_NAME_ROW,
	CONNECTED_BY_ROW,
	CONNECTION_DATE_ROW,
	CONNECTION_ROW,
	CREATION_DATE_ROW,
	DELETE_ROW,
} from '../../application/constants/facebookAccounts';

export const FACEBOOK = Object.freeze({
	BUTTON_TEXT: 'buttonText',
	TEXT_INFO: 'textInfo',
	INTEGRATION_NAME: 'integrationName',
	ACCOUNTS_CONNECT_CAMPAIGN_TEXT: 'accountsConnectCampaignText',
	ACCOUNTS_CONNECT_CAMPAIGN_BUTTON: 'accountsConnectCampaignButton',
	ACCOUNTS_CONNECT_ERROR: 'accountsConnectError',
	ACCOUNTS_CONNECT_ERROR_PERMISSIONS: 'accountsConnectErrorPermissions',
	ACCOUNTS_CONNECT_TOOLTIP: 'accountsConnectTooltip',
	ACCOUNTS_CONNECT_INFORMATIVE_NOTICE: 'accountsConnectInformativeNotice',
	ACCOUNT_HEADER: 'accountHeader',
	CANCEL_BUTTON: 'cancelButton',
	CONNECT_BUTTON: 'connectButton',
	GOT_TO_ACCOUNT_INFO: 'goToAccountInfo',
	COULUNM_ONE: 'coulunmOne',
	COULUNM_TWO: 'coulunmTwo',
	NO_ACCOUNTS: 'noAccounts',
	CAMPAIGNS_TITLE_V1: 'campaignsTitleV1',
	CAMPAIGNS_TITLE_V2: 'campaignsTitleV2',
	CAMPAIGNS_LIST_TITLE: 'campaignsListTitle',
	CAMPAIGNS_LIST_CANCEL_BUTTON: 'CampaignsListCancelButton',
	CAMPAIGNS_LIST_CONNECT_BUTTON: 'CampaignsListConnectButton',
	CAMPAIGNS_LIST_INFORMATIVE_1: 'campaignsListInformative1',
	CAMPAIGNS_LIST_INFORMATIVE_2: 'campaignsListInformative2',
	CAMPAIGNS_LIST_INFORMATIVE_3: 'campaignsListInformative3',
	CAMPAIGNS_LIST_INFORMATIVE_4: 'campaignsListInformative4',
	CAMPAIGNS_LIST_INFORMATIVE_5: 'campaignsListInformative5',
	CAMPAIGNS_LIST_INFORMATIVE_6: 'campaignsListInformative6',
	CAMPAIGNS_LIST_INFORMATIVE_7: 'campaignsListInformative7',
	CAMPAIGNS_LIST_INFORMATIVE_8: 'campaignsListInformative8',
	CAMPAIGNS_NEW_CAMPAIGN: 'campaignsNewCampaign',
	CAMPAIGNS_MAXIMUM_ACTIVE: 'campaignsMaximumActive',
	CAMPAIGNS_FB_NO_FOUND: 'campaignsFbNoFound',
	DISCONNECT_CAMPAIGN_TITLE: 'disconnectCampaignTitle',
	DISCONNECT_CAMPAIGN_TEXT_DESC_0: 'disconnectCampaignTextDesc0',
	DISCONNECT_CAMPAIGN_TEXT_DESC_1: 'disconnectCampaignTextDesc1',
	DISCONNECT_CAMPAIGN_ITEM_0: 'disconnectCampaignItem0',
	DISCONNECT_CAMPAIGN_ITEM_1: 'disconnectCampaignItem1',
	DISCONNECT_CAMPAIGN_ITEM_2: 'disconnectCampaignItem2',
	DISCONNECT_CAMPAIGN_ITEM_3: 'disconnectCampaignItem3',
	DISCONNECT_CAMPAIGN_BUTTON: 'disconnectCampaignButton',
	DELETE_CAMPAIGN_TITLE: 'deleteCampaignTitle',
	DELETE_CAMPAIGN_TEXT_DESC: 'deleteCampaignTextDesc',
	DELETE_CAMPAIGN_ITEM_0: 'deleteCampaignItem0',
	DELETE_CAMPAIGN_ITEM_1: 'deleteCampaignItem1',
	DELETE_CAMPAIGN_ITEM_2: 'deleteCampaignItem2',
	DELETE_CAMPAIGN_ITEM_3: 'deleteCampaignItem3',
	ACCOUNT_CONNECTED_INFO: 'accountConnectedInfo',
	DELETE_CAMPAIGN_BUTTON: 'deleteCampaignButton',

	DISCONNECT_ACCOUNT_TITLE: 'disconnectAccountTitle',
	DISCONNECT_ACCOUNT_TEXT_DESC_0: 'disconnectAccountTextDesc0',
	DISCONNECT_ACCOUNT_TEXT_DESC_1: 'disconnectAccountTextDesc1',
	DISCONNECT_ACCOUNT_ITEM_0: 'disconnectAccountItem0',
	DISCONNECT_ACCOUNT_ITEM_1: 'disconnectAccountItem1',
	DISCONNECT_ACCOUNT_ITEM_2: 'disconnectAccountItem2',

	DELETE_ACCOUNT_TITLE: 'deleteAccountTitle',
	DELETE_ACCOUNT_TEXT_DESC: 'deleteAccountTextDesc',
	DELETE_ACCOUNT_ITEM_0: 'deleteAccountItem0',
	DELETE_ACCOUNT_ITEM_1: 'deleteAccountItem1',
	DELETE_ACCOUNT_ITEM_2: 'deleteAccountItem2',

	SESSION_EXPIRATION: 'sessionExpiration',
});

export const ACCOUNTS_TABLE_HEADER = Object.freeze({
	[ADVERTISIG_ACCOUNT_ROW]: ADVERTISIG_ACCOUNT_ROW,
	[ACCOUNT_ID_ROW]: ACCOUNT_ID_ROW,
	[CONNECTED_BY_ROW]: CONNECTED_BY_ROW,
	[CONNECTION_DATE_ROW]: CONNECTION_DATE_ROW,
	[CONNECTION_ROW]: CONNECTION_ROW,
	[DELETE_ROW]: DELETE_ROW,
});

export const CAMPAIGN_TABLE_HEADER = Object.freeze({
	[CAMPAIGN_NAME_ROW]: CAMPAIGN_NAME_ROW,
	[CAMPAIGN_ID_ROW]: CAMPAIGN_ID_ROW,
	[CREATION_DATE_ROW]: CREATION_DATE_ROW,
});

export default {};
