import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { MODAL } from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { history } from '../../../../../../../shared/application/helpers/history';
import { externalContactRequestDetailsRoute } from '../../../../../infrastructure/routes';
import { setValueToLocalStorage } from '../../../../../../../shared/application/helpers/common-functions';

const ColumnActionExtContactRequest = ({ historyItem, opportunity }) => {
	const { t } = useTranslation();

	const handleContactRequest = () => {
		setValueToLocalStorage('history_item', JSON.stringify(historyItem));
		history.push(externalContactRequestDetailsRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<>
			<button className="action-link" onClick={handleContactRequest}>
				{t(`${MODAL.DETAILS}`, { ns: DOMAIN_NAME })}
			</button>
		</>
	);
};

ColumnActionExtContactRequest.propTypes = {
	historyItem: PropTypes.object,
	opportunity: PropTypes.object,
};

export default ColumnActionExtContactRequest;
