import {
	ECONOMIC_ACTIVITY_KEY,
	EMAIL_KEY,
	EMPLOYEE_KEY,
	INDEPENDENT_KEY,
	LAST_NAME_KEY,
	MOBILE_KEY,
	MONTHLY_PAYMENT_CAPACITY_KEY,
	NAME_KEY,
	OTHER_ACTIVITY_KEY,
	PENSIONER_KEY,
	RESIDENCE_CITY_KEY,
} from '../../application/constants/contactInformation';
import {
	DATE_ROW,
	DESISTED_STATUS,
	DETAIL_ROW,
	DISCARTED_STATUS,
	INTERESTED_STATUS,
	PROPERTY_ROW,
	ACTIONS_ROW,
	RESERVED_STATUS,
	SEPARATED_STATUS,
	STAGE_CONTACT,
	STAGE_DESISTED_BUSINESS,
	STAGE_DESISTED_WITHOUT_REFUND,
	STAGE_DESISTED_WITH_REFUND,
	STAGE_DISCARDED,
	STAGE_DONE,
	STAGE_MOVE_TO_DISCARDED,
	STAGE_MOVE_TO_INTERESTED,
	STAGE_MOVE_TO_RESERVED,
	STAGE_MOVE_TO_VISITED,
	STAGE_SEPARATED_BY_OPTION,
	STAGE_QUOTED,
	STAGE_RESERVED,
	STAGE_ROW,
	STAGE_SCHEDULE,
	STAGE_SEPARATED,
	STAGE_SIMULATE,
	STAGE_VERIFYING_PAYMENT,
	STAGE_VISITED,
	TRANSACTION_STAGE_DISPERSED,
	TRANSACTION_STAGE_READY_TO_DISPERSE,
	VISITED_STATUS,
	STAGE_OPTIONED_PROPERTY,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_EXTERNAL_CONTACT_REQUEST_LABEL,
	STAGE_EXTERNAL_CONTACT_REQUEST_TEXT,
	STAGE_SOCIAL_LEADS,
	STAGE_LEADS_FACEBOOK,
	STAGE_LEADS_GOOGLE,
} from '../../application/constants/opportunityHistory';
import {
	OPTION_STATE_ACTIVE,
	OPTION_STATE_CANCELED,
	OPTION_STATE_CONFIRMED,
	OPTION_STATE_EXECUTED,
	OPTION_STATE_EXPIRED,
	OPTION_STATE_PENDING,
} from '../../application/constants/optionedInformation';
import {
	DOCUMENT_TYPE_CC,
	DOCUMENT_TYPE_CE,
	DOCUMENT_TYPE_NIT,
	DOCUMENT_TYPE_P,
	INVALID_DOCUMENT_CE_FORMAT,
	INVALID_DOCUMENT_P_FORMAT,
} from '../../application/constants/userInformation';

export const OPPORTUNITY_CARD = Object.freeze({
	STAGE: 'stage_title',
	ASSESOR: 'assessor',
	NO_ASSESOR: 'noAssessor',
	NOTES: 'notes',
	NO_NOTES: 'noNotes',
	SAVE: 'save',
	NOTE: 'note',
	CHANGES: 'changes',
	DISCARD: 'discard',
	START_ATTENTION: 'startAttention',
	STAGES: {
		Interesado: 'interested',
		Visitado: 'visited',
		Reservado: 'reserved',
		Descartado: 'discarded',
		Separado: 'separated',
		Desistido: 'desisted',
	},
});

export const MODAL = Object.freeze({
	DETAILS: 'details',
	VERIFY: 'verify',
	VERIFY_PAYMENT: 'verifyPayment',
	VERIFY_PAYMENT_PROCESS: 'verifyPaymentProcess',
	BLOCK_DATE: 'blockDate',
	PAYMENT_DATE: 'paymentDate',
	PAYMENT_METHOD: 'paymentMethod',
	PAID_WITH: 'paidWith',
	REFERENCE_CODE: 'referenceCode',
	VALUE_PAID: 'valuePaid',
});

export const PAYMENT_MODAL = Object.freeze({
	TITLE: 'paymentModalTitle',
	TITLE_2: 'paymentModalTitle2',
	PAYMENT_BLOCK_PROP_TEXT: 'paymentBlockPropText',
	VALUE_BUY: 'valueBuy',
	TIME_LEFT: 'timeLeft',
	TIME_LEFT_TEXT: 'timeLeftText',
	BUY_LINK: 'buyLink',
	SEPARATED_VALUE: 'separatedValue',
	PROPERTY_BLOKED: 'propertyBlocked',
	PROPERTY_BLOKED_TEXT: 'propertyBlockedText',
	tarjeta_de_credito: 'tarjeta_de_credito',
	efectivo: 'efectivo',
	pse: 'pse',
	BLOCK_PROP_QUESTION: 'blockPropQuestion',
});

export const STAGES = Object.freeze({
	[STAGE_RESERVED]: STAGE_RESERVED,
	[STAGE_QUOTED]: STAGE_QUOTED,
	[STAGE_SIMULATE]: STAGE_SIMULATE,
	[STAGE_SCHEDULE]: STAGE_SCHEDULE,
	[STAGE_DISCARDED]: STAGE_DISCARDED,
	[STAGE_VISITED]: STAGE_VISITED,
	[STAGE_SEPARATED]: STAGE_SEPARATED,
	[STAGE_SEPARATED_BY_OPTION]: STAGE_SEPARATED_BY_OPTION,
	[STAGE_DONE]: STAGE_DONE,
	[STAGE_MOVE_TO_INTERESTED]: [STAGE_MOVE_TO_INTERESTED],
	[STAGE_MOVE_TO_VISITED]: STAGE_MOVE_TO_VISITED,
	[STAGE_MOVE_TO_RESERVED]: STAGE_MOVE_TO_RESERVED,
	[STAGE_MOVE_TO_DISCARDED]: STAGE_MOVE_TO_DISCARDED,
	[STAGE_DESISTED_WITH_REFUND]: STAGE_DESISTED_WITH_REFUND,
	[STAGE_DESISTED_WITHOUT_REFUND]: STAGE_DESISTED_WITHOUT_REFUND,
	[STAGE_DESISTED_BUSINESS]: STAGE_DESISTED_BUSINESS,
	[TRANSACTION_STAGE_READY_TO_DISPERSE]: TRANSACTION_STAGE_READY_TO_DISPERSE,
	[TRANSACTION_STAGE_DISPERSED]: TRANSACTION_STAGE_DISPERSED,
	[STAGE_CONTACT]: STAGE_CONTACT,
	[STAGE_EXTERNAL_CONTACT_REQUEST]: STAGE_EXTERNAL_CONTACT_REQUEST,
	[STAGE_VERIFYING_PAYMENT]: STAGE_VERIFYING_PAYMENT,
	[STAGE_OPTIONED_PROPERTY]: STAGE_OPTIONED_PROPERTY,
	[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]: [STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]],
	[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]: [STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]],
});

export const TABLE_HEADER = Object.freeze({
	[DATE_ROW]: DATE_ROW,
	[STAGE_ROW]: STAGE_ROW,
	[PROPERTY_ROW]: PROPERTY_ROW,
	[DETAIL_ROW]: DETAIL_ROW,
	[ACTIONS_ROW]: ACTIONS_ROW,
});

export const TABLE_ROWS = Object.freeze({
	NO_PROPERTY: 'noProperty',
});

export const TEXTS = Object.freeze({
	TITLE: 'textSubTitle',
	MORE: 'moreButton',
	QUOTER: 'quoter',
	SCHEDULER: 'scheduler',
	ATTENDED_BY: 'attendedBy',
	OPTION: 'option',
	SCORING: 'scoring',
});

export const BASE_FIELDS_KEYS = Object.freeze({
	[NAME_KEY]: NAME_KEY,
	[LAST_NAME_KEY]: LAST_NAME_KEY,
	[MOBILE_KEY]: MOBILE_KEY,
	[EMAIL_KEY]: EMAIL_KEY,
	IDENTIFICATION: 'identification_word',
	INCOME: 'income_base_fields_keys',
});

export const CONTACT_MODAL_TITLE = 'contactModalTitle';
export const INFORMATION_TITLE = 'information_title';

export const ADDITIONAL_FIELDS_KEYS = Object.freeze({
	[RESIDENCE_CITY_KEY]: RESIDENCE_CITY_KEY,
	[MONTHLY_PAYMENT_CAPACITY_KEY]: MONTHLY_PAYMENT_CAPACITY_KEY,
	[ECONOMIC_ACTIVITY_KEY]: ECONOMIC_ACTIVITY_KEY,
	[STAGE_EXTERNAL_CONTACT_REQUEST_LABEL]: STAGE_EXTERNAL_CONTACT_REQUEST_LABEL,
});

export const CONTACT_ADDITIONAL_FIELDS = Object.freeze({
	[STAGE_EXTERNAL_CONTACT_REQUEST_TEXT]: STAGE_EXTERNAL_CONTACT_REQUEST_TEXT,
});

export const ECONOMIC_ACTIVITIES_KEYS = Object.freeze({
	[EMPLOYEE_KEY]: EMPLOYEE_KEY,
	[INDEPENDENT_KEY]: INDEPENDENT_KEY,
	[PENSIONER_KEY]: PENSIONER_KEY,
	[OTHER_ACTIVITY_KEY]: OTHER_ACTIVITY_KEY,
});

export const MODAL_UUNAVAILABLE_PROPERTY = Object.freeze({
	DESCRIPTION_MODAL: 'descriptionModal',
	BUTTON_MODAL: 'buttonModal',
	USER_WITHOUT_SIMULATION: 'userWithOutSimulation',
	SEARCH_BUTTON: 'serachButton',
	TEXT_1: 'modal_uunavailable_property_text_1',
	TEXT_2: 'modal_uunavailable_property_text_2',
});

export const OPORTUNITY_STATUS = Object.freeze({
	[INTERESTED_STATUS]: INTERESTED_STATUS,
	[VISITED_STATUS]: VISITED_STATUS,
	[RESERVED_STATUS]: RESERVED_STATUS,
	[SEPARATED_STATUS]: SEPARATED_STATUS,
	[DISCARTED_STATUS]: DISCARTED_STATUS,
	[DESISTED_STATUS]: DESISTED_STATUS,
});

export const MOVED_BY = 'movedBy';
export const MOVED_IN = 'movedIn';
export const TOOLTIP_MSG_DATE_PROGRAMED = 'tooltipMsgDateProgramed';
export const TOOLTIP_MSG_APPOINTMENT_TYPE = 'tooltipMsgMeetType';
export const DESIST = 'desistBtn';
export const DESISTED = 'desisted';

export const MODAL_USER_IDENTIFICATION = Object.freeze({
	TITLE: 'UserIdTitle',
	SELECT_DOC: 'UserIdSelectDoc',
	TYPE_DOC_PLACEHOLDER: 'UserIdTypeDocPlaceHolder',
	TYPE_DOC_LABEL: 'UserIdTypeDocLabel',
	NO_COMFIRM_ID: 'UserIdNoComfirmation',
	CONTINUE_TEXT: 'continueText',
	[DOCUMENT_TYPE_CC]: DOCUMENT_TYPE_CC,
	[DOCUMENT_TYPE_NIT]: DOCUMENT_TYPE_NIT,
	[DOCUMENT_TYPE_CE]: DOCUMENT_TYPE_CE,
	[DOCUMENT_TYPE_P]: DOCUMENT_TYPE_P,
	[INVALID_DOCUMENT_CE_FORMAT]: INVALID_DOCUMENT_CE_FORMAT,
	[INVALID_DOCUMENT_P_FORMAT]: INVALID_DOCUMENT_P_FORMAT,
	INVALID_VALUE_LENGTH_RANGE: 'invalidLengthValueRange',
	EMPTY_FIELD: 'emptyField',
});

export const DISPERSED_MODAL = Object.freeze({
	CLIENT_REFOUND_VALUE: 'refoundValue',
	VECINDARIO_SALES_COMMISSION: 'vecindarioSalesCommission',
	COMPANY_VALUE_SENT: 'clienteValueSent',
	REFOUND_DATE: 'refoundDate',
	DISPERSED_DATE: 'dispersedDate',
	DETAIL: 'detail',
});

export const NOTES = Object.freeze({
	SURE: 'sure',
	ACCEPT: 'accept',
	BACK: 'back',
	YES: 'yes',
});

export const CONFIRMATION_OPTIONS = Object.freeze({
	TEXT_1: 'CONFIRMATION_OPTIONS_TEXT_1',
	TEXT_1_1: 'CONFIRMATION_OPTIONS_TEXT_1_1',
	TEXT_1_2: 'CONFIRMATION_OPTIONS_TEXT_1_2',
	TEXT_2: 'CONFIRMATION_OPTIONS_TEXT_2',
	TEXT_3: 'CONFIRMATION_OPTIONS_TEXT_3',
	TEXT_4: 'CONFIRMATION_OPTIONS_TEXT_4',
	TEXT_5: 'CONFIRMATION_OPTIONS_TEXT_5',
	TEXT_6: 'CONFIRMATION_OPTIONS_TEXT_6',
});

export const OPTIONED_USER_IDENTIFICATION_FORM = {
	TITLE: 'optioned_user_identification_form_title',
	CONTINUE: 'optioned_user_identification_form_continue',
};

export const TRANSLATE_ACTION = {
	[STAGE_SIMULATE]: CONFIRMATION_OPTIONS.TEXT_5,
	[STAGE_QUOTED]: CONFIRMATION_OPTIONS.TEXT_6,
};

export const OPTION_DETAILS_PAGE = Object.freeze({
	OPTIONED_WORD: 'OPTIONED_WORD',
	BODY_TEXT_1: 'BODY_TEXT_1',
	BODY_TEXT_2: 'BODY_TEXT_2',
	BODY_TEXT_3: 'BODY_TEXT_3',
	BODY_TEXT_4: 'BODY_TEXT_4',
	BODY_TEXT_5: 'BODY_TEXT_5',
	BODY_TEXT_5_1: 'BODY_TEXT_5_1',
	BODY_TEXT_6: 'BODY_TEXT_6',
	BODY_TEXT_7: 'BODY_TEXT_7',
	BODY_TEXT_8: 'BODY_TEXT_8',
	BUTTON_CONFIRM_PURCHASE: 'BUTTON_CONFIRM_PURCHASE',
	BUTTON_CANCEL_OPTION: 'BUTTON_CANCEL_OPTION',
	BUTTON_EXTEND_VALIDITY: 'BUTTON_EXTEND_VALIDITY',
	VIEW_OPTION: 'OPTION_DETAILS_PAGE_VIEW_OPTION',
});

export const SEPARATED_OPTION_DETAIL = Object.freeze({
	TITLE: 'separated_option_detail_title',
	COLUMN_PROPERTY: 'separated_option_detail_column_property',
	COLUMN_CONFIRMATION_DATE: 'separated_option_detail_column_confirmation_date',
	COLUMN_CONFIRMED_BY: 'separated_option_detail_column_confirmed_by',
});

export const CONFIRM_PURCHASE = Object.freeze({
	TITLE: 'confirm_purchase_title',
	DESCRIPTION: 'confirm_purchase_description',
	YES_SURE: 'confirm_purchase_yes_sure',
	CANCEL: 'confirm_purchase_cancel',
	SUCCESS_MESSAGE: 'confirm_purchase_success_message',
});

export const MESSAGE_OPTION = Object.freeze({
	TEXT_1: 'MESSAGE_OPTION_TEXT_1',
	TEXT_2: 'MESSAGE_OPTION_TEXT_2',
	TEXT_3: 'MESSAGE_OPTION_TEXT_3',
});

export const EXTEND_VALIDITY_PAGE = {
	TITLE: 'extend_validity_page_title',
	WORD_DAYS: 'extend_validity_page_days',
	WORD_HOURS: 'extend_validity_page_hours',
	INFO: 'extend_validity_page_info',
	BUTTON_CONFIRM: 'extend_validity_page_button_confirm',
	INFO_EXCEPTION_EXTEND: 'extend_validity_page_info_exception_extend',
	ERROR_NUMBER_BETWEEN_1_7: 'error_number_between_1_7',
	ERROR_NUMBER_BETWEEN_1_168: 'error_number_between_1_168',
};

export const ERROR_EMPTY_FILE = 'error_empty_file';
export const ERROR_GREATER_THAN_ZERO = 'error_greater_than_zero';

export const USER_ROLES = Object.freeze({
	DIRECTOR_ROLE: 'director',
	ASESOR_ROLE: 'asesor',
});

export const CANCEL_OPTION_PAGE = Object.freeze({
	TITLE: 'cancel_option_page_title',
	BODY: 'cancel_option_page_body',
	BUTTON_ACTION_1: 'cancel_option_page_button_action_1',
	BUTTON_ACTION_2: 'cancel_option_page_button_action_2',
});

export const OPTIONS_STATE = Object.freeze({
	PENDING: 'pending',
	ACTIVE: 'active',
	EXPIRED: 'expired',
	CANCELED: 'canceled',
	CONFIRMED: 'confirmed',
	EXECUTED: 'executed',
});

export const WEB_ANALISIS = Object.freeze({
	TITLE: 'title',
	UTM_ID: 'utm_id',
	UTM_SOURCE: 'utm_source',
	UTM_MEDIUM: 'utm_medium',
	UTM_CAMPAIGN: 'utm_campaign',
	UTM_CONTENT: 'utm_content',
	UTM_TERM: 'utm_term',
	DOCUMENT_REFERER: 'document_referer',
	SOURCE: 'source',
	SOURCE_URL: 'source_url',
	ACTION_PERFORMER: 'action_performer',
	USER_ID_EMAIL: 'user_id_email',
	UTM_ID_TOOLTIP: 'utm_id_tooltip',
	UTM_SOURCE_TOOLTIP: 'utm_source_tooltip',
	UTM_MEDIUM_TOOLTIP: 'utm_medium_tooltip',
	UTM_CAMPAIGN_TOOLTIP: 'utm_campaign_tooltip',
	UTM_CONTENT_TOOLTIP: 'utm_content_tooltip',
	UTM_TERM_TOOLTIP: 'utm_term_tooltip',
	DOCUMENT_REFERER_TOOLTIP: 'document_referer_tooltip',
	SOURCE_TOOLTIP: 'source_tooltip',
	SOURCE_URL_TOOLTIP: 'source_url_tooltip',
	ACTION_PERFORMER_TOOLTIP: 'action_performer_tooltip',
	USER_ID_EMAIL_TOOLTIP: 'user_id_email_tooltip',
	ACTION_PERFORMER_VALUE_DIRECTOR: 'action_performer_value_director',
	ACTION_PERFORMER_VALUE_COMPRADOR: 'action_performer_value_comprador',
	ACTION_PERFORMER_VALUE_ASESOR: 'action_performer_value_asesor',
	ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS: 'action_performer_facebook_business',
	ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS_SOURCE: 'action_performer_facebook_business_source',
	TITLE_FACEBOOK_BUSINESS: 'title_facebook_business',
});

export const DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT = 'disabled_options_button_tooltip_text';

export const CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_1 = 'employee';
export const CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_2 = 'independent';
export const CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_3 = 'pensioner';
export const CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_4 = 'other_activity';

export const APPOINTMENT_PHYSICAL_TYPE_TEXT = 'appointment_type_visit';
export const APPOINTMENT_DIGITAL_TYPE_TEXT = 'appointment_type_video_call';

export const QUOTATION_IDENTIFICATION_TEXT = 'quotation_identification_text';

export const OPTION_SNAPSHOT_TEXTS = {
	ORDER: 'option_snapshot_texts_order',
	EXPIRATION_DATE: 'option_snapshot_texts_expiration_date',
};

export const OPTION_STATE_TEXTS = {
	[OPTION_STATE_PENDING]: 'option_state_texts_pending',
	[OPTION_STATE_ACTIVE]: 'option_state_texts_active',
	[OPTION_STATE_EXPIRED]: 'option_state_texts_expired',
	[OPTION_STATE_CANCELED]: 'option_state_texts_canceled',
	[OPTION_STATE_CONFIRMED]: 'option_state_texts_confirmed',
	[OPTION_STATE_EXECUTED]: 'option_state_texts_executed',
};

export const CAMPAIGN_NAME_TITLE_ROW = 'campaign_name_title_row';

export const EXTERNAL_CONTACT = Object.freeze({
	TITLE: 'external_title',
	DETAIL: 'detail',
	REQUEST_TYPE: 'request_type',
	CAMPAIGN_ID: 'campaign_id',
	CAMPAIGN_NAME: 'campaign_name',
	LEAD_REGISTRATION_DATE: 'lead_registration_date',
	LEAD_ID: 'lead_id',
	ADVERTISEMENT_NAME: 'advertisement_name',
	AD_GROUP_NAME: 'ad_group_name',
	FORM_ID: 'form_id',
	[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]: 'fb_request_type',
	[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]: 'google_request_type',
	CAMPAIGN_NAME_TOOLTIP: 'campaign_name_tooltip',
	CAMPAIGN_ID_TOOLTIP: 'campaign_id_tooltip',
	LEAD_REGISTRATION_DATE_TOOLTIP: 'lead_registration_date_tooltip',
	LEAD_ID_TOOLTIP: 'lead_id_tooltip',
	ADVERTISEMENT_NAME_TOOLTIP: 'advertisement_name_tooltip',
	FORM_ID_TOOLTIP: 'form_id_tooltip',
});

export const FORM_CONTACT = Object.freeze({
	REQUEST_TYPE: 'request_type',
	CONTACT_FORM: 'contact_form',
});
