import React, { useCallback, useEffect, useState } from 'react';
import { Helmet, TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject, setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IntegrationCards from '../../components/integrationCards';
import './Integrations.scss';
import integrationsArray from './integrationOptions';
import { isUrl } from '../../../../../shared/application/helpers/common-functions';
import {
	activateWebhookIntegration,
	deactivateWebhookIntegration,
	generateApiToken,
	getApiPublicToken,
	getWebhookIntegrations,
	sendMailIntegration,
} from '../../../infrastructure/Api/integrations';
import {
	GENERIC_WEBHOOK,
	HUBSPOT,
	SINCO,
	SUITE,
	ZAPIER_WEBHOOK,
	INTEGRATION_API,
	INTEGRATION_HUBSPOT,
	INTEGRATION_ZOHO,
	ZOHO,
	HUBSPOTV3,
	DIRECTOR_ROLE,
	MARKETING_ROLE,
} from '../../../application/constants/inventory';
import {
	deleteDeactivateIntegration,
	postActivateIntegration,
} from '../../../application/slices/sincoContactIntegration';
import { sicoTokenSelector } from '../../../application/selector/sicoIntegration';
import {
	deleteDeactivateSicoIntegration,
	getActiveSicoIntegration,
	postActivateSicoIntegration,
} from '../../../application/slices/sicoContactIntegration';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { INTEGRATIONS } from '../../../infrastructure/locales/translation_keys';
import { history } from '../../../../../shared/application/helpers/history';
import { integrationsHubspot } from '../../../infrastructure/routes';
import { resetState } from '../../../application/slices/hubspotIntegration';
import { getAccountConnected } from '../../../../facebook/application/slices/facebook';
import { FB_BACK_PROVIDER } from '../../../../facebook/application/constants/facebook';
import { selectAccount } from '../../../../facebook/application/selector/facebook';

const IntegrationsPage = () => {
	const dispatch = useDispatch();
	const account = useSelector(selectAccount);
	const [zapierWebhook, setZapierWebhook] = useState(null);
	const [genericWebhook, setGenericWebhook] = useState(null);
	const [apiPublicToken, setApiPublicToken] = useState();
	const [integrationHubspotReq, setIntegrationHubspotReq] = useState(null);
	const [integrationZohoReq, setIntegrationZohoReq] = useState(localStorage.getItem(INTEGRATION_ZOHO));
	const sicoToken = useSelector(sicoTokenSelector);
	const currentProject = useSelector(getCurrentProject);

	const { t } = useTranslation();

	const { slug } = useParams();

	const fetchWebHookIntegration = useCallback(() => {
		getWebhookIntegrations(slug).then((webhooks) => {
			const webhook = webhooks && webhooks.find((_webhook) => _webhook.name === ZAPIER_WEBHOOK);
			const newGenericWebhook = webhooks && webhooks.find((_webhook) => _webhook.name === GENERIC_WEBHOOK);
			const newHubspotWebhook = webhooks && webhooks.find((_webhook) => _webhook.name === HUBSPOTV3);
			setIntegrationHubspotReq(newHubspotWebhook);
			setZapierWebhook(webhook);
			setGenericWebhook(newGenericWebhook);
		});
	}, [slug]);

	useEffect(() => {
		getApiPublicToken(slug).then(({ token }) => setApiPublicToken(token));
		fetchWebHookIntegration();
		dispatch(getActiveSicoIntegration({ slug }));
	}, [dispatch, slug, fetchWebHookIntegration]);

	const setMessageAlert = (type, message) => {
		dispatch(
			setMessageToast({
				type,
				message,
			}),
		);
	};

	const activateIntegration = (url, type) => {
		if (!isUrl(url)) {
			setMessageAlert('error', 'Url inválida');
			return null;
		}

		if (type === SINCO) {
			dispatch(postActivateIntegration({ slug, url }));
		} else {
			activateWebhookIntegration(slug, url, type)
				.then((response) => {
					fetchWebHookIntegration();
					// setMessageAlert('success', response.msg);
				})
				.catch((error) => {
					const errorMsg = error?.msg || 'Error';
					setMessageAlert('error', errorMsg);
				});
		}
		return null;
	};

	const sendMail = (service) => {
		sendMailIntegration(slug, service)
			.then((response) => {
				if (service === HUBSPOT) {
					localStorage.setItem(INTEGRATION_HUBSPOT, true);
					setIntegrationHubspotReq(true);
				} else if (service === 'api simula') {
					localStorage.setItem(INTEGRATION_API, true);
				} else if (service === ZOHO) {
					localStorage.setItem(INTEGRATION_ZOHO, true);
					setIntegrationZohoReq(true);
				} else {
					localStorage.setItem(`integration${service}Req`, true);
				}
				setMessageAlert('success', response.msg);
			})
			.catch((error) => {
				const errorMsg = error?.msg || 'Error';
				setMessageAlert('error', errorMsg);
			});
	};

	const generateTokenSuite = () => {
		generateApiToken(slug).then((response) => {
			setApiPublicToken(response.token);
			setMessageAlert('success', response.msg);
		});
	};

	const activeIntegration = (url, type) => {
		if (type === ZOHO) {
			sendMail(type);
		} else if (type === HUBSPOT) {
			history.push(integrationsHubspot(slug));
		} else if (type === SUITE) {
			generateTokenSuite();
		} else {
			activateIntegration(url, type);
		}
	};

	const handleDeactivateZapierIntegration = (webhookName) => {
		deactivateWebhookIntegration(slug, webhookName).then(() => {
			fetchWebHookIntegration();
		});
	};

	const sicoIntegration = (token) => {
		dispatch(postActivateSicoIntegration({ slug, token }));
	};

	useEffect(() => {
		dispatch(resetState());
	}, [dispatch]);

	useEffect(() => {
		if ([DIRECTOR_ROLE, MARKETING_ROLE].includes(currentProject?.role) && !account?.id) {
			const provider = FB_BACK_PROVIDER;
			dispatch(getAccountConnected({ slug, provider }));
		}
	}, [dispatch, slug, fetchWebHookIntegration, currentProject?.role, account]);

	const urlData = (info) => {
		if (info?.url?.base_url) {
			return `${info?.url?.base_url}${info?.url?.final_url}`;
		}

		return null;
	};

	const showByRoler = (role = [DIRECTOR_ROLE]) => role.includes(currentProject?.role);

	return (
		<>
			<Helmet title={'Vecindario Suite - Oportunidades de negocio - Integraciones'} />
			<div className="integratios-container">
				<div className="integratios-container-title">
					<TextTag tag="h2" fw="bold" className="integratios-container-text">
						{t(`${INTEGRATIONS.TITLE}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag fw="normal" variant="-body" className="integratios-subtitle-text">
						{t(`${INTEGRATIONS.TEXT_INFO}`, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>

				<div className="integratios-container-body">
					<IntegrationCards
						data={integrationsArray[0]}
						onActiveIntegration={activeIntegration}
						activeData={apiPublicToken !== null}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						token={apiPublicToken}
						show={showByRoler()}
					/>
					<IntegrationCards
						data={integrationsArray[1]}
						onActiveIntegration={activeIntegration}
						activeData={false}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						show={showByRoler([DIRECTOR_ROLE, MARKETING_ROLE])}
					/>
					<IntegrationCards
						data={integrationsArray[2]}
						onActiveIntegration={activeIntegration}
						activeData={integrationHubspotReq}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						show={showByRoler()}
					/>
					<IntegrationCards
						data={integrationsArray[3]}
						onActiveIntegration={activeIntegration}
						activeData={![undefined, null].includes(zapierWebhook)}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						token={urlData(zapierWebhook)}
						show={showByRoler()}
					/>
					<IntegrationCards
						data={integrationsArray[4]}
						onActiveIntegration={activeIntegration}
						activeData={![undefined, null].includes(genericWebhook)}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						token={urlData(genericWebhook)}
						show={showByRoler()}
					/>
					<IntegrationCards
						data={integrationsArray[5]}
						onActiveIntegration={activeIntegration}
						activeData={false}
						onDesactivateIntegration={() => dispatch(deleteDeactivateIntegration({ slug }))}
						show={showByRoler()}
					/>

					<IntegrationCards
						data={integrationsArray[6]}
						onActiveIntegration={activeIntegration}
						activeData={integrationZohoReq}
						onDesactivateIntegration={handleDeactivateZapierIntegration}
						show={showByRoler()}
					/>

					<IntegrationCards
						data={integrationsArray[7]}
						onActiveIntegration={sicoIntegration}
						onDesactivateIntegration={() => dispatch(deleteDeactivateSicoIntegration({ slug }))}
						activeData={sicoToken !== null}
						token={sicoToken}
						show={showByRoler()}
					/>
				</div>
			</div>
		</>
	);
};

export default IntegrationsPage;
