import { TOOLS_PAGE } from '../translation_keys';

export default {
	[TOOLS_PAGE.TITLE]: 'Herramientas',
	[TOOLS_PAGE.DESCRIPTION]:
		'Usa este enlace para que los clientes ingresen a la Suite y realicen el pago de la reserva o la separación',
	[TOOLS_PAGE.TITLE_CARD]: 'Link de acceso general a la Suite comprador',
	[TOOLS_PAGE.BUTTON_CARD]: 'Generar link',
	[TOOLS_PAGE.ERROR_PROJECT_AGREEMENT]: 'Proyecto sin convenio',
	[TOOLS_PAGE.SUCCESS_COPY_LINK]: 'Link copiado en portapapeles',
	[TOOLS_PAGE.ERROR_COPY_LINK]: 'Error al copiar link',
};
