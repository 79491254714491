import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getAvailabilityAsesor, getOpportunitiesByProject, patchStageOpportunities } from '../../infrastructure/api';

export const initialState = {
	list: [],
	isLoading: false,
	opportunitiesRequested: false,
	availabilityAsesorScheduler: false,
	search: {
		field: '',
		result: [],
	},
};

export const getRequestOpportunities = createAsyncThunk('request/opportunities', async (slug, { rejectWithValue }) => {
	try {
		return await getOpportunitiesByProject(slug);
	} catch (error) {
		return rejectWithValue(error);
	}
});

export const putRequestStageOpportunities = createAsyncThunk(
	'request/opportunities/change_state',
	async ({ slug, id, stage }, { rejectWithValue }) => {
		try {
			await patchStageOpportunities(slug, id, stage);
			return await getOpportunitiesByProject(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getRequestAvailabilityAsesor = createAsyncThunk(
	'request/opportunities/availability_asesor',
	async ({ slug, email }, { rejectWithValue }) => {
		try {
			return await getAvailabilityAsesor(slug, email);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const Opportunities = createSlice({
	name: 'opportunities',
	initialState,
	reducers: {
		setSearchField(state, { payload }) {
			state.search.field = payload;
		},
		setSearchResult(state, { payload }) {
			state.search.result = payload;
		},
	},
	extraReducers: {
		[getRequestOpportunities.pending](state) {
			state.isLoading = true;
		},
		[getRequestOpportunities.rejected](state) {
			state.isLoading = false;
		},
		[getRequestOpportunities.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.list = payload;
			state.opportunitiesRequested = true;
		},
		[putRequestStageOpportunities.pending](state) {
			state.isLoading = true;
		},
		[putRequestStageOpportunities.rejected](state) {
			state.isLoading = false;
		},
		[putRequestStageOpportunities.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.list = payload;
		},
		[getRequestAvailabilityAsesor.fulfilled](state, { payload }) {
			state.availabilityAsesorScheduler = payload?.availability_asesor;
		},
	},
});

export const { setSearchField, setSearchResult } = Opportunities.actions;

export default Opportunities.reducer;
