import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetListNotes, urlPostCreateNote, urlPutEditNote } from './backend-url';

export const getListNotes = (slug, commercial_opportunity_id) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetListNotes(slug, commercial_opportunity_id), requestOptions).then(handleResponse);
};

export const postCreateNote = (slug, payload) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(payload),
	};
	return fetch(urlPostCreateNote(slug), requestOptions).then(handleResponse);
};

export const putEditNote = (slug, opportunity_note_id, payload) => {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(payload),
	};
	return fetch(urlPutEditNote(slug, opportunity_note_id), requestOptions).then(handleResponse);
};

export const deleteNote = (slug, opportunity_note_id) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlPutEditNote(slug, opportunity_note_id), requestOptions).then(handleResponse);
};
