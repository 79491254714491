import {
	CONTACT_REQUEST_KEY,
	COTIZADOR_KEY,
	INVENTORY_KEY,
	SCHEDULER_KEY,
} from '../../../../../shared/application/constants/modules_keys';
import {
	BOARD_COLUMNS,
	DROPDONW_BOARD,
	SEARCH_PLACEHOLDER,
	PREMIUM_MODAL,
	ERROR_INPUT_MESSAGE,
	TODAY,
	INVALID_ACTION,
} from '../translation_keys';

export default {
	[BOARD_COLUMNS.INTERESTED]: 'Interested',
	[BOARD_COLUMNS.VISITED]: 'Visited',
	[BOARD_COLUMNS.RESERVED]: 'Reserved',
	[BOARD_COLUMNS.SEPARATED]: 'Separated',
	[BOARD_COLUMNS.DISCARDED]: 'Discarded',
	[BOARD_COLUMNS.DESISTED]: 'Withdrawn',
	[DROPDONW_BOARD.SELECT_TEXT]: 'Create opportunity',
	[DROPDONW_BOARD.SELECT_TITLE]: 'Select one',
	[DROPDONW_BOARD[INVENTORY_KEY]]: 'Simulator',
	[DROPDONW_BOARD[SCHEDULER_KEY]]: 'Scheduler',
	[DROPDONW_BOARD[CONTACT_REQUEST_KEY]]: 'Form',
	[DROPDONW_BOARD[COTIZADOR_KEY]]: 'Quotation',
	[DROPDONW_BOARD.COPY_LINK]: 'Copy link',
	[DROPDONW_BOARD.FORM]: 'Form',
	[SEARCH_PLACEHOLDER]: 'Search',
	[PREMIUM_MODAL.BODY_TEXT]:
		'Conversion mechanisms (simulator, scheduler, and contact form) will no longer be displayed in the places where your landing pages, direct URLs, or QR codes are embedded.',
	[PREMIUM_MODAL.MAX_MONTHLY_OPPORTUNITIES]: '20/20 <br/>You have reached the maximum monthly number of opportunities',
	[ERROR_INPUT_MESSAGE]: 'No matches found for the search.',
	[INVALID_ACTION]: 'Action not allowed',
	[TODAY]: 'Today',
};
