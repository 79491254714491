import { history as redirect } from '../../../../shared/application/helpers/history';
import { ASESOR_ROLE, DIRECTOR_ROLE } from '../../../integrations/application/constants/inventory';
import { OPTION_DETAILS_PAGE } from '../../infrastructure/locales/translation_keys';
import {
	cancelOptionRoute,
	extendValidityTimeRoute,
	opportunityConfirmPurchaseRoute,
} from '../../infrastructure/routes';
import {
	PROPERTY_AVAILABLE,
	PROPERTY_OPTIONED,
	STAGE_OPTIONED_PROPERTY,
	STAGE_QUOTED,
	STAGE_SCHEDULE,
	STAGE_SIMULATE,
} from '../constants/opportunityHistory';
import {
	OPTIONED_USER_INFORMATION_FIELDS,
	OPTION_ACTIONS_ID,
	OPTION_STATE_ACTIVE,
	OPTION_STATE_CANCELED,
	OPTION_STATE_EXPIRED,
	OPTION_STATE_PENDING,
} from '../constants/optionedInformation';
import { advisorAssignment } from '../slices/history';
import { DOMAIN_NAME } from '../../infrastructure/locales';
import { TRANSACTION_STATUS_APPROVED, TRANSACTION_STATUS_PENDING } from '../constants/transaction';

export const extractUserIdentificationFromOpportunity = ({ opportunity }) => {
	let documentType = null;
	let identification = null;
	let orderedHistories = opportunity?.opportunity_histories;
	orderedHistories = orderedHistories?.slice()?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

	const withQuotationSnapshot = orderedHistories?.find((history) => history?.action_type === STAGE_QUOTED);
	const withSimulation = orderedHistories?.find((history) => history?.action_type === STAGE_SIMULATE);
	const withAppointment = orderedHistories?.find((history) => history?.action_type === STAGE_SCHEDULE);
	const withOption = orderedHistories?.find((history) => history?.action_type === STAGE_OPTIONED_PROPERTY);

	if (withQuotationSnapshot) {
		documentType = withQuotationSnapshot?.action_instance?.user_snapshot_detail?.document_type;
		identification = withQuotationSnapshot?.action_instance?.user_snapshot_detail?.identification;
	}

	if (withSimulation && (!identification || !documentType)) {
		documentType = withSimulation?.action_instance?.user_snapshot_detail?.identification_type;
		identification = withSimulation?.action_instance?.user_snapshot_detail?.identification;
	}

	if (withAppointment && (!identification || !documentType)) {
		const userSnapshotDetail = withAppointment?.action_instance?.user_snapshot_detail;
		documentType = userSnapshotDetail?.identification_type;
		identification = userSnapshotDetail?.identification;
	}

	if (withOption && (!identification || !documentType)) {
		documentType = withOption?.action_instance?.document_type;
		identification = withOption?.action_instance?.identification;
	}

	return !identification || !documentType
		? null
		: {
				[OPTIONED_USER_INFORMATION_FIELDS.DOCUMENT_TYPE]: documentType,
				[OPTIONED_USER_INFORMATION_FIELDS.IDENTIFICATION]: identification,
		  };
};

export const extractPropertyToOptionFromOpportunity = ({ opportunity }) => {
	let property = null;
	let lastAction = null;
	let orderedHistories = opportunity?.opportunity_histories;
	orderedHistories = orderedHistories?.slice()?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1));

	const propertyAvailable = (history) => {
		return [PROPERTY_AVAILABLE, PROPERTY_OPTIONED].includes(history?.action_instance?.property?.availability);
	};

	const quotedHistory = orderedHistories?.find((elem) => elem?.action_type === STAGE_QUOTED && propertyAvailable(elem));
	if (quotedHistory) {
		property = quotedHistory?.action_instance?.property;
		lastAction = quotedHistory?.action_type;
	}

	const simulatedHistory = orderedHistories?.find(
		(elem) => elem?.action_type === STAGE_SIMULATE && propertyAvailable(elem),
	);
	if (simulatedHistory && !property) {
		property = simulatedHistory?.action_instance?.property;
		lastAction = simulatedHistory?.action_type;
	}

	const availableProperty = orderedHistories?.find((elem) => propertyAvailable(elem));
	if (availableProperty && !property) {
		property = availableProperty?.action_instance?.property;
		lastAction = availableProperty?.action_type;
	}

	return { property, lastAction };
};

export const optionsStockList = ({ currentOption, currentProject, currentUser, opportunity, dispatch, t }) => {
	const actionsArray = [];

	const showCancelOptionButton = () => {
		const isOptionPendingOrActive =
			currentOption?.option_state === OPTION_STATE_PENDING || currentOption?.option_state === OPTION_STATE_ACTIVE;

		return (
			isOptionPendingOrActive &&
			((currentProject?.role === ASESOR_ROLE &&
				(opportunity?.asesor_email === null || opportunity?.asesor_email === currentUser?.email)) ||
				currentProject?.role === DIRECTOR_ROLE)
		);
	};

	const showExtendValidityButton = () => {
		return (
			currentOption?.option_state === OPTION_STATE_ACTIVE &&
			((currentProject?.role === ASESOR_ROLE &&
				(opportunity?.asesor_email === null || opportunity?.asesor_email === currentUser?.email)) ||
				currentProject?.role === DIRECTOR_ROLE)
		);
	};

	const canConfirmPurchase = () => {
		// Check if user role is Asesor and opportunity has asesor assigned and his is equal to current user email
		const asesorValidation =
			currentProject?.role === ASESOR_ROLE &&
			opportunity?.asesor_email &&
			opportunity?.asesor_email === currentUser?.email;

		// Check if director current user is a director project
		const directorValidation = currentProject?.role === DIRECTOR_ROLE;

		// Check if opportunity not has active options
		const optionValidation = currentOption?.option_state === OPTION_STATE_ACTIVE;

		// Check if opportunity has transactions in pending or approved status
		const hasTransactionPendingOrActive = opportunity?.transactions?.some((elem) => {
			return [TRANSACTION_STATUS_PENDING, TRANSACTION_STATUS_APPROVED].includes(elem?.status);
		});

		return (
			optionValidation &&
			!hasTransactionPendingOrActive &&
			(directorValidation || asesorValidation || !opportunity?.asesor_email)
		);
	};

	const handleOnConfirmPurchase = async () => {
		if (!opportunity?.asesor_email && currentProject?.role === ASESOR_ROLE) {
			await dispatch(
				advisorAssignment({
					slug: opportunity?.project_slug,
					commercial_opportunity_id: opportunity?.id,
					data: {
						asesor_email: currentUser?.email,
					},
				}),
			);
		}

		redirect.push(opportunityConfirmPurchaseRoute(opportunity?.project_slug, opportunity?.id));
	};

	if (!currentOption?.option_state?.includes(OPTION_STATE_CANCELED, OPTION_STATE_EXPIRED)) {
		if (showCancelOptionButton()) {
			actionsArray.push({
				id: OPTION_ACTIONS_ID.SHOW_CANCEL_OPTION_BUTTON,
				onClick: () => redirect.push(cancelOptionRoute(opportunity?.project_slug, opportunity?.id)),
				text: t(OPTION_DETAILS_PAGE.BUTTON_CANCEL_OPTION, { ns: DOMAIN_NAME }),
			});
		}

		if (showExtendValidityButton()) {
			actionsArray.push({
				id: OPTION_ACTIONS_ID.SHOW_EXTEND_VALIDITY_BUTTON,
				onClick: () => redirect.push(extendValidityTimeRoute(opportunity?.project_slug, opportunity?.id)),
				text: t(OPTION_DETAILS_PAGE.BUTTON_EXTEND_VALIDITY, { ns: DOMAIN_NAME }),
			});
		}

		if (canConfirmPurchase()) {
			actionsArray.push({
				id: OPTION_ACTIONS_ID.CAN_CONFIRM_PURCHASE,
				onClick: () => handleOnConfirmPurchase(),
				text: t(OPTION_DETAILS_PAGE.BUTTON_CONFIRM_PURCHASE, { ns: DOMAIN_NAME }),
			});
		}
	}

	return actionsArray;
};
