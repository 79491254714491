import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { MODAL } from '../../../../../infrastructure/locales/translation_keys';
import { setCurrentOptionSelected } from '../../../../../application/slices/history';
import { opportunityOptioneddetailsRoute } from '../../../../../infrastructure/routes';
import { history } from '../../../../../../../shared/application/helpers/history';

const ColumnActionOptionedProperty = ({ opportunity, item }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const seeOptionDetail = () => {
		dispatch(setCurrentOptionSelected({ ...item?.action_instance, history: item }));
		history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<button className="button-show-details action-link" onClick={seeOptionDetail}>
			{t(`${MODAL.DETAILS}`, { ns: DOMAIN_NAME })}
		</button>
	);
};

ColumnActionOptionedProperty.propTypes = {
	opportunity: PropTypes.object,
	item: PropTypes.object,
};

export default ColumnActionOptionedProperty;
