import {
	NO_USER_PERFORMER_CRONTASK,
	NO_USER_PERFORMER_LINK_GENERATION,
	PROPERTY_AVAILABILITY_AVAILABLE,
	PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION,
	PROPERTY_AVAILABILITY_DISABLED,
	PROPERTY_AVAILABILITY_OPTIONED,
	PROPERTY_AVAILABILITY_RESERVED,
	PROPERTY_AVAILABILITY_SEPARATED,
} from '../../../application/constants/property';
import {
	OPTIONS_QUEUE_PAGE,
	OPTION_ACTION_ACTION,
	OPTION_NO_PERFORMER_USER,
	PROPERTY_AVAILABILITIES,
	PROPERTY_QUEUE_TABLE_HEADERS,
} from '../translation_keys';

export default {
	[OPTIONS_QUEUE_PAGE.PAGE_TITLE]:
		'Histórico de la cola de opciones para el inmueble {{towerName}} - {{propertyReference}}',
	[OPTIONS_QUEUE_PAGE.SECTION_TITLE]: 'Estado del inmueble',
	[OPTIONS_QUEUE_PAGE.SHOW_ALL_COLUMNS_CTA]: 'Mostrar todas las columnas',
	[OPTIONS_QUEUE_PAGE.HIDE_ADVANCED_COLUMNS_CTA]: 'Mostrar menos columnas',
	[OPTIONS_QUEUE_PAGE.VIEW_ACTIONS]: 'Ver opciones',
	[PROPERTY_QUEUE_TABLE_HEADERS.ORDER]: 'Posición en la cola',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPPORTUNITY_NAME]: 'Nombre de la oportunidad',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_STATE]: 'Estado de la opción',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_DATE]: 'Opción vigente hasta',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_CREATOR_NAME]: 'Nombre quíen opcionó',
	[PROPERTY_QUEUE_TABLE_HEADERS.ADVISOR_NAME]: 'Asesor asignado',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_DATE]: 'Fecha en la que opcionó',
	[PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_DATE]: 'Fecha actualización del ultimo estado',
	[PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_NAME]: 'Estado actualizado por',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_NAME]: 'Vigencia extendida por',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_DATE_ACTION]: 'Fecha de extensión de vigencia',
	[PROPERTY_QUEUE_TABLE_HEADERS.ACTIONS]: 'Acciones',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_OPTIONED]]: 'Opcionó',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_AVAILABLE]]: 'Disponible',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_SEPARATED]]: 'Separado',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_RESERVED]]: 'Reservado',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_DISABLED]]: 'Desactivado',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION]]: 'Bloqueado por transacción',
	[OPTION_NO_PERFORMER_USER[NO_USER_PERFORMER_CRONTASK]]: 'Sistema',
	[OPTION_NO_PERFORMER_USER[NO_USER_PERFORMER_LINK_GENERATION]]: 'Link de pago',
	[OPTION_ACTION_ACTION.EXTENDED]: 'extendida',
};
