import { createSelector } from '@reduxjs/toolkit';
import { STAGE_CONTACT, STAGE_QUOTED, STAGE_SCHEDULE, STAGE_SIMULATE } from '../constants/opportunityHistory';

export const historyState = (state) => state.opportunityHistory;

export const selectRequestHistory = createSelector(historyState, (request) => {
	const { history } = request;
	return history || [];
});

export const selectRequestUserHistory = createSelector(historyState, (request) => {
	const { userHistory } = request;
	return userHistory;
});

export const selectProjectReservation = createSelector(historyState, (request) => {
	const { projectReservation } = request;
	return projectReservation;
});

export const selectPaymentMethods = createSelector(historyState, (request) => {
	const {
		projectReservation: { payment_methods_by_projects },
	} = request;
	return payment_methods_by_projects;
});

export const selectMaxPaymentMethodExpirationTime = createSelector(historyState, (request) => {
	const {
		projectReservation: { payment_methods_by_projects },
	} = request;

	let maxExpirationTime = 0;

	payment_methods_by_projects?.forEach((method) => {
		const method_expiration_minutes = method?.payment_method?.expiration_minutes;
		if (method_expiration_minutes && method_expiration_minutes > maxExpirationTime) {
			maxExpirationTime = method_expiration_minutes;
		}
	});

	return maxExpirationTime;
});

export const selectRequestHistoryTransactions = createSelector(historyState, (request) => {
	const { history } = request;
	return history?.transactions || [];
});

export const selectLoadingPayment = createSelector(historyState, (request) => {
	const { loadingPayment } = request;
	return loadingPayment;
});

export const selectLoadingReleaseProperty = createSelector(historyState, (request) => {
	const { loadingReleaseProperty } = request;
	return loadingReleaseProperty;
});

export const selectBuyerData = createSelector(historyState, (state) => {
	return state?.buyerData;
});

export const selectOptionedData = createSelector(historyState, (state) => {
	return state?.optionedData;
});

export const selectCurrentOptionSelected = createSelector(historyState, (state) => {
	return state?.currentOptionSelected;
});

export const selectIsLoading = createSelector(historyState, (state) => {
	return state?.isLoading;
});

export const selectOptionsConfiguration = createSelector(historyState, (state) => {
	return state?.optionsConfiguration;
});

export const selectOpportunityUserDetails = createSelector(selectRequestHistory, (opportunity) => {
	if (Object.keys(opportunity).length > 0) {
		const filerData = opportunity?.opportunity_histories?.filter((item) => {
			return [STAGE_SIMULATE, STAGE_SCHEDULE, STAGE_CONTACT, STAGE_QUOTED].includes(item.action_type);
		});
		const userData = filerData ? filerData.at(-1) : {};
		return userData?.action_instance?.user_snapshot_detail;
	}

	return {};
});

export const selectOpportunityTracking = createSelector(historyState, (state) => {
	return state?.opportunityTracking;
});
