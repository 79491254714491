import { createSelector } from '@reduxjs/toolkit';

export const facebookState = (state) => state.facebook;

export const selectLongToken = createSelector(facebookState, (state) => {
	return state?.loginConfig;
});

export const selectAccount = createSelector(facebookState, (state) => {
	return state?.account;
});

export const selectAdsCampaigns = createSelector(facebookState, (state) => {
	return state?.campaigns;
});

export const selectListCampaignsAds = createSelector(facebookState, (state) => {
	return {
		listCampaignsAds: state?.listCampaignsAds,
		isLoading: state?.isLoading,
		error: state?.error,
	};
});

export const selectCampaign = createSelector(facebookState, (state) => {
	return state?.campaignSelected;
});
