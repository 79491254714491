import { BUSINESS_FRONT_URL, COTIZADOR_FRONT_URL, SCORING_FRONT_URL } from '../../../shared/application/constants/env';
import {
	OPTIONED_CONFIRM_PURCHASE_HASH,
	EXTEND_VALIDITY_TIME_SECTION_HASH,
	OPTIONED_DETAILS_PAGE,
	OPTIONED_PEOPLE_IN_THE_QUEUE_SECTION_HASH,
	OPTIONED_USER_INFORMATION_SECTION_HASH,
	OPTIONED_QUEUED_HASH,
	SEPARATED_BY_OPTION_DETAIL_SECTION_HASH,
	CANCEL_OPTION_SECTION_HASH,
	TRACKING_INFO_SECTION_HASH,
	CONTACT_REQUEST_DETAILS_HASH,
	EXTERNAL_CONTACT_REQUEST_DETAILS_HASH,
} from '../application/constants/optionedInformation';

export const opportunityHistoryRoute = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') =>
	`/proyectos/${slug}/negocios/${commercial_opportunity_id}`;

export const externalBusinessDetailRoute = (slug = ':slug', businessId = ':business_id') =>
	`${BUSINESS_FRONT_URL}proyectos/${slug}/negocios/${businessId}`;

export const simulatePropertySlug = (slug = ':slug') => `/proyecto/${slug}/simular`;

export const schedulePropertySlug = (slug = ':slug') => `/proyecto/${slug}/agendar`;

export const contactSlug = (slug = ':slug') => `/proyecto/${slug}/contactar`;

export const externalCotizadorDetailRoute = (slug = ':slug') =>
	`${COTIZADOR_FRONT_URL}proyecto/${slug}/cotizador/herramientas`;

export const opportunityOptionedUserInformationRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${OPTIONED_USER_INFORMATION_SECTION_HASH}`;
};

export const opportunityOptionedPeopleInQueueRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${OPTIONED_PEOPLE_IN_THE_QUEUE_SECTION_HASH}`;
};

export const opportunityOptioneddetailsRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${OPTIONED_DETAILS_PAGE}`;
};

export const opportunityOptionDetailRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${SEPARATED_BY_OPTION_DETAIL_SECTION_HASH}`;
};

export const opportunityConfirmPurchaseRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${OPTIONED_CONFIRM_PURCHASE_HASH}`;
};

export const opportunityOptionedQueuedRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${OPTIONED_QUEUED_HASH}`;
};

export const extendValidityTimeRoute = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${EXTEND_VALIDITY_TIME_SECTION_HASH}`;
};

export const cancelOptionRoute = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${CANCEL_OPTION_SECTION_HASH}`;
};

export const externalScoringQueries = (slug) => `${SCORING_FRONT_URL}proyecto/${slug}/scoring/consultas/usuarios`;

export const webAnalisisRoute = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${TRACKING_INFO_SECTION_HASH}`;
};

export const contactRequestDetailsRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${CONTACT_REQUEST_DETAILS_HASH}`;
};

export const externalContactRequestDetailsRoute = (
	slug = ':slug',
	commercial_opportunity_id = ':commercial_opportunity_id',
) => {
	return `${opportunityHistoryRoute(slug, commercial_opportunity_id)}${EXTERNAL_CONTACT_REQUEST_DETAILS_HASH}`;
};
