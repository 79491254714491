export const OPPORTUNITIES_TYPE = {
	SCHEDULES_TYPE: 'schedule',
	QUOTATIONS_TYPE: 'quoted',
	SIMULATIONS_TYPE: 'simulate',
	CONTACT_REQUESTS_TYPE: 'contact',
};

export const JSON_DATA_INTEGRATION = {
	data: {
		api_token: '',
		pipelines: [],
		contactVariables: [],
		businessVariables: [],
	},
};

export const INITIAL_INPUST_CONTACT = [
	{
		vecindarioName: 'vecindario_user_name',
		hubspotName: 'firstname',
	},
	{
		vecindarioName: 'vecindario_user_lastname',
		hubspotName: 'lastname',
	},
	{
		vecindarioName: 'vecindario_user_mobile',
		hubspotName: 'phone',
	},
];

export const INITIAL_INPUST_BUSINESS = [
	{
		vecindarioName: '',
		hubspotName: 'dealname',
	},
];

export const SHOW_INTEGRATION_PATH = 'ver-integracion';

export default {};
