import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
	Button,
	Icon,
	NumberFormat,
	Select,
	Spinner,
	TextTag,
	useTranslate,
} from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import './ExtendValidity.scss';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { ICON_CLOSE_LINE, ICON_INFORMATION_LINE } from '../../../../../shared/application/constants/icons';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityOptioneddetailsRoute } from '../../../infrastructure/routes';
import { ERROR_EMPTY_FILE, EXTEND_VALIDITY_PAGE } from '../../../infrastructure/locales/translation_keys';
import {
	DAYS,
	NUMBER_HOURS_DAY,
	VALIDITY_TIME_OPTIONS,
	extendValidityFields,
} from '../../../application/constants/extendValidity';
import { extendValiditySchema } from '../../../application/schemas/extendValidity';
import { requestExtendValidityTime } from '../../../application/slices/history';
import { selectCurrentOptionSelected, selectIsLoading } from '../../../application/selector/history';
import { ASESOR_ROLE } from '../../../../integrations/application/constants/inventory';

const ExtendValidity = ({ opportunity }) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const VALIDITY_TIME_OPTIONS_LIST = useMemo(() => VALIDITY_TIME_OPTIONS({ t }), [t]);
	const optionSnapshot = useSelector(selectCurrentOptionSelected);
	const currentUser = useSelector(getCurrentUser);
	const currentProject = useSelector(getCurrentProject);
	const isLoading = useSelector(selectIsLoading);

	const {
		handleSubmit,
		setValue,
		control,
		watch,
		formState: { isValid, isDirty, errors },
	} = useForm({
		mode: 'onChange',
		resolver: yupResolver(extendValiditySchema),
	});

	const fieldValidityTime = watch(extendValidityFields.VALIDITY_TIME);
	const fieldTime = watch(extendValidityFields.TIME);

	const errorMessageTime = useTranslate(errors[extendValidityFields.TIME]?.message, DOMAIN_NAME);

	const onSubmit = async (data) => {
		const newData = { ...data };
		if (newData.validity_time === DAYS) {
			newData.options_validity_hours *= NUMBER_HOURS_DAY;
		}
		if (
			currentProject?.role === ASESOR_ROLE &&
			(opportunity?.asesor_email === null || opportunity?.asesor_email === currentUser?.email)
		) {
			newData.asesor_email = currentUser?.email;
		} else {
			newData.asesor_email = '';
		}
		newData.commercial_opportunity_id = opportunity?.id;
		const optionSnapshotId = optionSnapshot?.id;
		const slug = opportunity?.project_slug;
		const response = await dispatch(requestExtendValidityTime({ slug, optionSnapshotId, newData }));
		if (!response.error) history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id));
	};

	const showInformation = () => {
		const watchField = watch(extendValidityFields.VALIDITY_TIME);
		if (watchField === DAYS) {
			return true;
		}
		return false;
	};

	const showInfoException = () => {
		return (
			errors?.options_validity_hours && errors?.options_validity_hours?.message !== ERROR_EMPTY_FILE && fieldTime !== '0'
		);
	};

	useEffect(() => {
		fieldValidityTime && setValue(extendValidityFields.TIME, 1);
	}, [fieldValidityTime, setValue]);

	return (
		<div className="container-extend-validity">
			<div className="header">
				<TextTag variant="-body-sm" fw="bold" type="label">
					{t(EXTEND_VALIDITY_PAGE.TITLE, { ns: DOMAIN_NAME })}
				</TextTag>
				<Icon
					aditionalClassName="icon-close"
					icon={ICON_CLOSE_LINE}
					onClick={() => history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id))}
				/>
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="form-extend-validity">
				<div className="container-inputs">
					<Select
						label=" "
						defaultOptionValue={i18n.language}
						id="DayOrHours"
						name={extendValidityFields.VALIDITY_TIME}
						onSelectItem={(item) => setValue(extendValidityFields.VALIDITY_TIME, item?.value)}
						options={VALIDITY_TIME_OPTIONS_LIST}
						error={errors[extendValidityFields.VALIDITY_TIME]}
					/>
					<Controller
						control={control}
						name={extendValidityFields.TIME}
						render={({ field: { value, onChange, name } }) => (
							<NumberFormat
								name={name}
								value={value}
								defaultValue={0}
								prefix=""
								className={'input-summary'}
								onChange={onChange}
								error={errorMessageTime}
								showIconError={false}
							/>
						)}
					/>
					{showInformation() && (
						<div className="section-info">
							<Icon aditionalClassName="icon-close" icon={ICON_INFORMATION_LINE} />
							<TextTag variant="-body-xs" fw="bold" type="label">
								{t(EXTEND_VALIDITY_PAGE.INFO, { ns: DOMAIN_NAME })}
							</TextTag>
						</div>
					)}
				</div>
				{showInfoException() && (
					<div className="info-exception">
						<Icon icon={ICON_INFORMATION_LINE} />
						<TextTag variant="-body-sm">{t(EXTEND_VALIDITY_PAGE.INFO_EXCEPTION_EXTEND, { ns: DOMAIN_NAME })}</TextTag>
					</div>
				)}
				<div className="action">
					<Button type="submit" className="button-confirm" disabled={!isDirty || !isValid || isLoading}>
						{isLoading ? <Spinner /> : t(EXTEND_VALIDITY_PAGE.BUTTON_CONFIRM, { ns: DOMAIN_NAME })}
					</Button>
				</div>
			</form>
		</div>
	);
};

ExtendValidity.propTypes = {
	opportunity: PropTypes.object,
};

export default ExtendValidity;
