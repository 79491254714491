import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import './CardButtonTools.scss';
import { CHECK_LINE } from '../../../../../shared/application/constants/icons';

const CardButtonTools = ({ title, subtitle, buttonText, variant, actionCallback }) => {
	return (
		<div className="card-button">
			<div className="container-elements">
				<div className={`container-icon ${variant}`}>
					<Icon icon={CHECK_LINE} size="medium" />
				</div>
				<div className="container-text">
					<TextTag tag="h5">{title}</TextTag>
					<TextTag variant="-body-sm" className="subtitle">
						{subtitle}
					</TextTag>
				</div>
			</div>
			<Button variant="bordered" onClick={actionCallback}>
				{buttonText}
			</Button>
		</div>
	);
};

CardButtonTools.propTypes = {
	title: PropTypes.string.isRequired,
	subtitle: PropTypes.string.isRequired,
	buttonText: PropTypes.string.isRequired,
	variant: PropTypes.string.isRequired,
	actionCallback: PropTypes.func,
};

export default CardButtonTools;
