import React from 'react';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { getFullName, numberWithDots } from '../../../../../shared/application/helpers/common-functions';
import './UserInformationCard.scss';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { BASE_FIELDS_KEYS } from '../../../infrastructure/locales/translation_keys';

const UserInformationCard = ({ userSnapshotDetail }) => {
	const { t } = useTranslation();

	return (
		<div className="user-information">
			<div className="top-section-wrapper">
				<div>
					<TextTag variant="-body-xs" className="color-secondary-600t">
						{t(`${BASE_FIELDS_KEYS.first_name}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="h5" className="title">
						{getFullName(userSnapshotDetail)}
					</TextTag>
				</div>
				{userSnapshotDetail?.monthly_income && userSnapshotDetail?.monthly_income > 0 ? (
					<div>
						<TextTag variant="-body-xs" className="color-secondary-600t">
							{t(`${BASE_FIELDS_KEYS.INCOME}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="h5" className="title">
							{`${userSnapshotDetail?.currency} ${numberWithDots(userSnapshotDetail?.monthly_income)}`}
						</TextTag>
					</div>
				) : null}
			</div>

			<div className="information-data">
				{userSnapshotDetail?.identification && (
					<div className="user-data-content">
						<TextTag variant="-body-xs" className="color-secondary-600t">
							{t(`${BASE_FIELDS_KEYS.IDENTIFICATION}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="small" className="text">
							{userSnapshotDetail?.identification}
						</TextTag>
					</div>
				)}
				<div className="user-data-content">
					<TextTag variant="-body-xs" className="color-secondary-600t">
						{t(`${BASE_FIELDS_KEYS.mobile}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="small" className="text">
						{userSnapshotDetail?.mobile}
					</TextTag>
				</div>

				<div className="user-data-content">
					<TextTag variant="-body-xs" className="color-secondary-600t">
						{t(`${BASE_FIELDS_KEYS.email}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag tag="small" className="text">
						{userSnapshotDetail?.email}
					</TextTag>
				</div>
			</div>
		</div>
	);
};

UserInformationCard.propTypes = {
	userSnapshotDetail: PropTypes.object,
};

export default UserInformationCard;
