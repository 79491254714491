import React from 'react';
import { useSelector } from 'react-redux';
import WebAnalisisInfo from '../../components/WebAnalisis';
import { selectRequestHistory } from '../../../application/selector/history';

const WebAnalisis = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <WebAnalisisInfo opportunity={opportunity} />;
};

export default WebAnalisis;
