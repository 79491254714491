import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { EXTERNAL_CONTACT } from '../../../infrastructure/locales/translation_keys';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';
import { history } from '../../../../../shared/application/helpers/history';
import { EXTERNAL_CONTACT_KEY_LIST } from '../../../application/constants/opportunityHistory';
import {
	getStateClass,
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
} from '../../../../../shared/application/helpers/common-functions';

const ExternalContactRequestDetailsInfo = ({ opportunity }) => {
	const { t } = useTranslation();
	const currentOpportunityHistory = JSON.parse(getValueFromLocalStorage('history_item'));
	const getExternalSourceInfo = currentOpportunityHistory?.action_instance?.external_source_information;

	const getTextProvider = () => {
		return t(`${EXTERNAL_CONTACT[getExternalSourceInfo?.source]}`, { ns: DOMAIN_NAME });
	};

	const externalSourceInfo = {
		request_type: getTextProvider(),
		campaign_name: getExternalSourceInfo?.campaign_name,
		campaign_id: getExternalSourceInfo?.campaign_id,
		lead_registration_date: getExternalSourceInfo?.created_time,
		lead_id: getExternalSourceInfo?.lead_id,
		advertisement_name: getExternalSourceInfo?.ad_name,
		ad_group_name: getExternalSourceInfo?.adset_name,
		form_id: getExternalSourceInfo?.form_id,
	};

	const getTextValue = (item) => {
		return externalSourceInfo?.[item];
	};

	const handleHistoryRequest = () => {
		removeValueFromLocalStorage('history_item');
		history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<div className="contact-request-details-container">
			<div className="contact-request-details-header">
				<div className="crd-header-title">
					<div className={`history-state-badge ${getStateClass(currentOpportunityHistory?.action_type)}`}>
						<TextTag variant="-body-xs" tag="p" fw="bold">
							{t(`${EXTERNAL_CONTACT.TITLE}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
					<TextTag variant="-body-sm" tag="label" fw="bold">
						{t(`${EXTERNAL_CONTACT.DETAIL}`, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>
				<div className="crd-header-close">
					<Icon icon={ICON_CLOSE_LINE} size="small" onClick={handleHistoryRequest} />
				</div>
			</div>
			<div className="contact-request-details-body">
				{EXTERNAL_CONTACT_KEY_LIST.map((item) => {
					return (
						<div className="row" key={item}>
							<div className="row-item">
								<div className="utm-item">
									<TextTag variant="-body-sm" tag="label">
										{t(`${EXTERNAL_CONTACT[item.toLocaleUpperCase()]}`, { ns: DOMAIN_NAME, provider: getTextProvider() })}{' '}
									</TextTag>
								</div>
							</div>
							<div className="row-item">
								<TextTag variant="-body-sm" tag="label" className="crd-value">
									{getTextValue(item)}
								</TextTag>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

ExternalContactRequestDetailsInfo.propTypes = {
	opportunity: PropTypes.object.isRequired,
};

export default ExternalContactRequestDetailsInfo;
