import React from 'react';
import { InformativeCard, Modal } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { ICON_SHIELD } from '../../../../../shared/application/constants/icons';
import { LINK_GO_PREMIUM } from '../../../../../shared/application/constants/application';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { PREMIUM_MODAL } from '../../../infrastructure/locales/translation_keys';

const PremiumModal = ({ isModalOpen, setIsModalOpen }) => {
	const { t } = useTranslation();

	const stylesModal = {
		width: '450px',
		height: '556px',
		borderRadius: '23px',
		border: 'none',
		padding: '0px',
	};
	const handleActionClose = () => {
		setIsModalOpen(false);
	};
	const handleActionPremium = () => {
		window.open(LINK_GO_PREMIUM, '_blank');
	};
	return (
		<Modal
			overlayStyle={{
				overflow: 'hidden',
				zIndex: '2',
				backgroundColor: 'rgba(11, 29, 46, 0.8)',
			}}
			contentStyle={stylesModal}
			onClose={setIsModalOpen}
			isOpen={isModalOpen}
			showClose={false}
		>
			<InformativeCard
				bodyText={t(`${PREMIUM_MODAL.BODY_TEXT}`, { ns: DOMAIN_NAME })}
				showIconClose={true}
				handleActionClose={handleActionClose}
				headerStyle={{
					background: '#F499B3',
				}}
				handleClick={handleActionPremium}
				iconHeader={ICON_SHIELD}
				titleText={
					<span dangerouslySetInnerHTML={{ __html: t(`${PREMIUM_MODAL.MAX_MONTHLY_OPPORTUNITIES}`, { ns: DOMAIN_NAME }) }} />
				}
			/>
		</Modal>
	);
};

PremiumModal.propTypes = {
	isModalOpen: PropTypes.bool,
	setIsModalOpen: PropTypes.func,
};

export default PremiumModal;
