import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestHistory } from '../../../application/selector/history';
import ExternalContactRequestDetailsInfo from '../../components/ExternalContactRequestDetails';

const ExternalContactRequestDetails = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <ExternalContactRequestDetailsInfo opportunity={opportunity} />;
};

export default ExternalContactRequestDetails;
