import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import UserIdentificationForm from '../../components/UserIdentificationForm';
import { selectRequestHistory } from '../../../application/selector/history';

const UserIdentificationPage = ({ setShowSelectPropertyModal }) => {
	const opportunity = useSelector(selectRequestHistory);
	return <UserIdentificationForm opportunity={opportunity} setShowSelectPropertyModal={setShowSelectPropertyModal} />;
};

UserIdentificationPage.propTypes = {
	setShowSelectPropertyModal: PropTypes.func,
};

export default UserIdentificationPage;
