import { ACCOUNTS_TABLE_HEADER, CAMPAIGN_TABLE_HEADER, FACEBOOK } from '../translation_keys';
import {
	ACCOUNT_ID_ROW,
	ADVERTISIG_ACCOUNT_ROW,
	CAMPAIGN_ID_ROW,
	CAMPAIGN_NAME_ROW,
	CONNECTED_BY_ROW,
	CONNECTION_DATE_ROW,
	CONNECTION_ROW,
	CREATION_DATE_ROW,
	DELETE_ROW,
} from '../../../application/constants/facebookAccounts';

export default {
	[FACEBOOK.BUTTON_TEXT]: 'Connect account',
	[FACEBOOK.TEXT_INFO]:
		'Connect your Meta Business Manager account to automatically synchronize your ad campaign leads as Business Opportunities. Ensure that you have the appropriate permissions in the Meta Ads Manager.',
	[FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_TEXT]:
		'Connect your campaigns to start receiving leads in your Opportunity Pipeline.',
	[FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_BUTTON]: 'Connect Campaign',
	[FACEBOOK.ACCOUNTS_CONNECT_ERROR]:
		'Error fetching information from Meta accounts, follow the steps in the following article to retry connecting your account. ',
	[FACEBOOK.ACCOUNTS_CONNECT_ERROR_PERMISSIONS]:
		'You have made multiple unsuccessful connection attempts, so the account has been temporarily blocked. Please try again in a few minutes.',
	[FACEBOOK.ACCOUNTS_CONNECT_TOOLTIP]:
		'If you wish to disconnect your Meta advertising account, it is recommended to do so from Vecindario and not from Meta. Disconnecting your Meta advertising account in Meta will remove all account connections that the registered Facebook user has access to, including others from Meta applications that are not connected to Vecindario.',
	[FACEBOOK.ACCOUNTS_CONNECT_INFORMATIVE_NOTICE]:
		"The Meta Business Manager account is disconnected, so it's not possible to create or edit campaigns. To reconnect the account, click on the connection button. If you want to connect a different account, you must first delete the disconnected one.",
	[ACCOUNTS_TABLE_HEADER[ADVERTISIG_ACCOUNT_ROW]]: 'Advertising Account',
	[ACCOUNTS_TABLE_HEADER[ACCOUNT_ID_ROW]]: 'Account ID',
	[ACCOUNTS_TABLE_HEADER[CONNECTED_BY_ROW]]: 'Connected By',
	[ACCOUNTS_TABLE_HEADER[CONNECTION_DATE_ROW]]: 'Connection Date',
	[ACCOUNTS_TABLE_HEADER[CONNECTION_ROW]]: 'Connection',
	[ACCOUNTS_TABLE_HEADER[DELETE_ROW]]: '',
	[CAMPAIGN_TABLE_HEADER[CAMPAIGN_NAME_ROW]]: 'Campaign Name',
	[CAMPAIGN_TABLE_HEADER[CAMPAIGN_ID_ROW]]: 'Campaign ID',
	[CAMPAIGN_TABLE_HEADER[CREATION_DATE_ROW]]: 'Connection Date',
	[FACEBOOK.ACCOUNT_HEADER]: 'Choose a Meta advertising account.',
	[FACEBOOK.CANCEL_BUTTON]: 'Cancel',
	[FACEBOOK.CONNECT_BUTTON]: 'Connect',
	[FACEBOOK.GOT_TO_ACCOUNT_INFO]: 'Go to account info',
	[FACEBOOK.COULUNM_ONE]: 'Advertising accounts',
	[FACEBOOK.COULUNM_TWO]: 'Advertising account ID',
	[FACEBOOK.NO_ACCOUNTS]: 'You do not have ads accounts.',
	[FACEBOOK.CAMPAIGNS_TITLE_V1]: 'Campaign',
	[FACEBOOK.CAMPAIGNS_TITLE_V2]: 'Campaigns',
	[FACEBOOK.CAMPAIGNS_LIST_TITLE]: 'Campaign Name:*',
	[FACEBOOK.CAMPAIGNS_LIST_CANCEL_BUTTON]: 'Cancel',
	[FACEBOOK.CAMPAIGNS_LIST_CONNECT_BUTTON]: 'Connect',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_1]:
		'Please note that the values to be synchronized from Meta forms to Vecindario for this campaign will be:',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_2]: "firstname or fullname - For the user's first name",
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_3]: "lastname - For the user's last name",
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_4]: "email or work_email - For the user's email",
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_5]: "phone or work_phone - For the user's phone number",
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_6]:
		"ID_CO_CC or ID_AR_DNI or ID_CL_RUT or ID_EC_CI or ID_PE_DNI or ID_MX_RFC - For the user's identification",
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_7]:
		'The internal field value in the forms should be the same as these, otherwise integration issues may occur, and Facebook leads may not be reflected.',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_8]: 'More Information.',
	[FACEBOOK.CAMPAIGNS_NEW_CAMPAIGN]: 'New Campaign',
	[FACEBOOK.CAMPAIGNS_MAXIMUM_ACTIVE]:
		'You have reached the maximum number of active campaigns. Please contact your account manager if you need to link more campaigns.',
	[FACEBOOK.CAMPAIGNS_FB_NO_FOUND]:
		'No campaigns were found in your Meta account. To connect a campaign, it must first be created in Meta and will then be listed in Vecindario for connection.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TITLE]: 'Disconnect Campaign {{campaignName}}?',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_0]:
		'By disconnecting this campaign, you are deactivating lead synchronization to Vecindario.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_1]:
		'Please note that this action will not deactivate your campaign in your Meta account. To do that, you will need to log in to your Meta Business Manager account and pause it from there.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_0]: 'Previously collected data will not be lost.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_1]: 'Leads generated by this campaign will no longer be synchronized.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_2]:
		'If you reconnect your campaigns, potential customers registered in your campaign over the last 90 days will be resynchronized.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_BUTTON]: 'Disconnect',
	[FACEBOOK.DELETE_CAMPAIGN_TITLE]: 'Delete campaign {{campaignName}}?',
	[FACEBOOK.DELETE_CAMPAIGN_TEXT_DESC]:
		'Please note that this action will not deactivate your campaign on your Meta account. To do so, you will need to log in to your Meta Business account and pause it from there.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_0]: 'The campaign will be deactivated and deleted.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_1]:
		'To start receiving leads from this campaign again, you will need to recreate it in Vecindario.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_2]: 'Previously collected data will not be lost.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_3]: 'Facebook leads generated in this campaign will no longer be synchronized.',
	[FACEBOOK.ACCOUNT_CONNECTED_INFO]: 'Currently, you have {{campaignName}} account connected.',
	[FACEBOOK.DELETE_CAMPAIGN_BUTTON]: 'Delete',

	[FACEBOOK.DISCONNECT_ACCOUNT_TITLE]: 'Disconnect {{accountName}} Account?',
	[FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_0]:
		'This affects the synchronization and creation of leads from the campaigns of the project as Business Opportunities.',
	[FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_1]:
		'Note that this action will not deactivate your account in your Meta account.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_0]: 'Previously collected data will not be lost.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_1]:
		'Lead synchronization from all campaigns connected to Vecindario will be disabled.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_2]:
		'If you reconnect your campaigns, leads that were not sent while disconnected will be retroactively synchronized up to 90 days ago.',

	[FACEBOOK.DELETE_ACCOUNT_TITLE]: 'Delete {{accountName}} Account?',
	[FACEBOOK.DELETE_ACCOUNT_TEXT_DESC]:
		'Please note that this action does not delete your account in Meta; you must go to your Meta Business Manager account and delete it from there.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_0]:
		'All campaigns created within this account will be deleted. To receive leads from these campaigns again, you will need to reconnect an account and recreate the campaigns in Vecindario.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_1]: 'Previously collected data will not be lost.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_2]: 'Leads generated in this account will no longer be synchronized.',
	[FACEBOOK.SESSION_EXPIRATION]: 'Invalid token, please connect the meta account again',
};
