import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestHistory } from '../../../application/selector/history';
import ContactRequestDetailsInfo from '../../components/ContactRequestDetails';

const ContactRequestDetails = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <ContactRequestDetailsInfo opportunity={opportunity} />;
};

export default ContactRequestDetails;
