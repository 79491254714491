import * as yup from 'yup';
import {
	DAYS,
	HOURS,
	NUMBER_BETWEEN_1_168_REGEX,
	NUMBER_BETWEEN_1_7_REGEX,
	extendValidityFields,
} from '../constants/extendValidity';
import { ERROR_EMPTY_FILE, EXTEND_VALIDITY_PAGE } from '../../infrastructure/locales/translation_keys';

export const extendValidity = {};
extendValidity[extendValidityFields.VALIDITY_TIME] = yup.string().required(ERROR_EMPTY_FILE);
extendValidity[extendValidityFields.TIME] = yup
	.string()
	.when(extendValidityFields.VALIDITY_TIME, (validityTime, schema) => {
		if (!validityTime) {
			return schema.default('');
		}
		if (validityTime === DAYS) {
			return schema
				.required(ERROR_EMPTY_FILE)
				.matches(NUMBER_BETWEEN_1_7_REGEX, EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_7);
		}
		if (validityTime === HOURS) {
			return schema
				.required(ERROR_EMPTY_FILE)
				.matches(NUMBER_BETWEEN_1_168_REGEX, EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_168);
		}
		return schema;
	});

export const extendValiditySchema = yup.object().shape({ ...extendValidity });
