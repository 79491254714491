export const { NODE_ENV } = process.env;
export const URL_PROD = process.env.REACT_APP_URL_PROD || 'https://simulator-api-2.herokuapp.com/api/v1/';
export const URL_CLIENT_PROD = process.env.REACT_APP_URL_CLIENT_PROD || 'http://localhost:3006/api_client/v1/';
export const PROD_ENV = process.env.REACT_APP_PROD_ENV || 'testing';
export const RAPPI_CLIENT_ID = process.env.RAPPI_CLIENT_ID || null;
export const RAPPI_RETURN_URL =
	process.env.RAPPI_RETURN_URL || 'https://reservas.vecindariosuite.com/estado-transaccion?reference=';
export const BUSINESS_FRONT_URL = process.env.REACT_APP_BUSINESS_FRONT_URL;

export const SIMULATOR_EMBEDDED =
	process.env.REACT_APP_SIMULATOR_EMBEDDED_URL ||
	'https://suite-simulator-embedded-testing.s3.us-east-2.amazonaws.com/script-simula.js';

export const SCHEDULER_EMBEDDED =
	process.env.REACT_APP_SCHEDULER_EMBEDDED_URL ||
	'https://scheduler-client-embedded.s3.us-east-2.amazonaws.com/script-scheduler.js';

export const CONTACT_FORM_EMBEDDED =
	process.env.REACT_APP_CONTACT_FORM_EMBEDDED ||
	'https://suite-contacts-testing.s3.us-east-2.amazonaws.com/script-contact.js';

export const COTIZADOR_FRONT_URL = process.env.REACT_APP_COTIZADOR_FRONT_URL;

export const MY_INTERESTS_DOMAIN = process.env.REACT_APP_INTERESTS_DOMAIN || 'misuitevecindario.pro';

export const SCORING_FRONT_URL = process.env.REACT_APP_SCORING_FRONT_URL;

export const GTM_ID_SUITE = process.env.REACT_APP_GTM_ID_SUITE || 'GTM-N7Q9RDT';
export const GTM_SCRIPT_ID = process.env.REACT_APP_GTM_SCRIPT_ID || 'suiteOpportunityGtmScript';
export const GTM_NO_SCRIPT_ID = process.env.REACT_APP_GTM_NO_SCRIPT_ID || 'suiteOpportunityGtmNoscript';

export const FRONT_URL = process.env.REACT_APP_FRONT_URL;

export const FB_CLIENT_ID = process.env.REACT_APP_FB_CLIENT_ID;
