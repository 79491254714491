import React, { useEffect } from 'react';
import { Helmet } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { accountFBRoute } from '../../../infrastructure/routes';
import { selectCampaign } from '../../../application/selector/facebook';
import { deleteCampaignSelected, setInitialState } from '../../../application/slices/facebook';
import AlertCard from '../../components/AlertCard';
import { removeValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';

const DeleteCampaignPage = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const history = useHistory();
	const prevSlug = history.location.state?.slug;
	const dispatch = useDispatch();
	const campaign = useSelector(selectCampaign);
	const historyState = { slug };

	useEffect(() => {
		if (prevSlug !== slug) {
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(setInitialState());
			history.push(opportunityRoute(slug));
		}
	}, [prevSlug, slug, dispatch, history]);

	const handleCancelDelete = async () => {
		history.push(accountFBRoute(slug), historyState);
	};

	const handleDeleteCampaign = () => {
		dispatch(
			deleteCampaignSelected({
				slug,
				campaignId: campaign.id,
			}),
		);
		history.push(accountFBRoute(slug), historyState);
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Facebook Leads - Eliminar campaña'} />
			<AlertCard
				cardTitle={t(FACEBOOK.DELETE_CAMPAIGN_TITLE, { ns: DOMAIN_NAME, campaignName: campaign?.campaign_name })}
				buttonActionText={t(FACEBOOK.DELETE_CAMPAIGN_BUTTON, { ns: DOMAIN_NAME })}
				handleCancel={handleCancelDelete}
				handleAction={handleDeleteCampaign}
			>
				<p>{t(FACEBOOK.DELETE_CAMPAIGN_TEXT_DESC, { ns: DOMAIN_NAME })}</p>
				<br />
				<ul>
					{campaign.active && <li>{t(FACEBOOK.DELETE_CAMPAIGN_ITEM_0, { ns: DOMAIN_NAME })}</li>}
					<li>{t(FACEBOOK.DELETE_CAMPAIGN_ITEM_1, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DELETE_CAMPAIGN_ITEM_2, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DELETE_CAMPAIGN_ITEM_3, { ns: DOMAIN_NAME })}</li>
				</ul>
			</AlertCard>
		</>
	);
};

export default DeleteCampaignPage;
