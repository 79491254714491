import React, { useState } from 'react';
import { Button, TextTag } from '@vecindario/vecindario-suite-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DispersionDetailModal from '../DispersionDetailModal';
import { MODAL } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';

const DispersionDetailButton = ({ historyItem }) => {
	const { t } = useTranslation();
	const [openModal, setOpenModal] = useState(false);
	const handleOpenModal = () => setOpenModal(!openModal);

	return (
		<>
			<Button variant="bordered" onClick={handleOpenModal}>
				<TextTag variant="-body-xs" fw="bold" font="DM-sans" tag="span" className="text">
					{t(`${MODAL.DETAILS}`, { ns: DOMAIN_NAME })}
				</TextTag>
			</Button>
			<DispersionDetailModal open={openModal} onClose={handleOpenModal} historyItem={historyItem} />
		</>
	);
};

DispersionDetailButton.propTypes = {
	historyItem: PropTypes.object,
};

export default DispersionDetailButton;
