import {
	ECONOMIC_ACTIVITY_KEY,
	EMAIL_KEY,
	EMPLOYEE_KEY,
	INDEPENDENT_KEY,
	LAST_NAME_KEY,
	MOBILE_KEY,
	MONTHLY_PAYMENT_CAPACITY_KEY,
	NAME_KEY,
	OTHER_ACTIVITY_KEY,
	PENSIONER_KEY,
	RESIDENCE_CITY_KEY,
} from '../../../application/constants/contactInformation';
import {
	DATE_ROW,
	DESISTED_STATUS,
	DETAIL_ROW,
	DISCARTED_STATUS,
	INTERESTED_STATUS,
	PROPERTY_ROW,
	ACTIONS_ROW,
	RESERVED_STATUS,
	SEPARATED_STATUS,
	STAGE_CONTACT,
	STAGE_DESISTED_BUSINESS,
	STAGE_DESISTED_WITHOUT_REFUND,
	STAGE_DESISTED_WITH_REFUND,
	STAGE_DISCARDED,
	STAGE_DONE,
	STAGE_MOVE_TO_DISCARDED,
	STAGE_MOVE_TO_INTERESTED,
	STAGE_MOVE_TO_RESERVED,
	STAGE_MOVE_TO_SEPARATED,
	STAGE_MOVE_TO_VISITED,
	STAGE_OPTIONED_PROPERTY,
	STAGE_QUOTED,
	STAGE_RESERVED,
	STAGE_ROW,
	STAGE_SCHEDULE,
	STAGE_SEPARATED,
	STAGE_SEPARATED_BY_OPTION,
	STAGE_SIMULATE,
	STAGE_VERIFYING_PAYMENT,
	STAGE_VISITED,
	TRANSACTION_STAGE_DISPERSED,
	TRANSACTION_STAGE_READY_TO_DISPERSE,
	VISITED_STATUS,
	CONTACT_QUESTION_SLUGS,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_EXTERNAL_CONTACT_REQUEST_LABEL,
	STAGE_EXTERNAL_CONTACT_REQUEST_TEXT,
	STAGE_SOCIAL_LEADS,
	STAGE_LEADS_FACEBOOK,
	STAGE_LEADS_GOOGLE,
} from '../../../application/constants/opportunityHistory';
import {
	OPTION_STATE_ACTIVE,
	OPTION_STATE_CANCELED,
	OPTION_STATE_CONFIRMED,
	OPTION_STATE_EXECUTED,
	OPTION_STATE_EXPIRED,
	OPTION_STATE_PENDING,
} from '../../../application/constants/optionedInformation';
import {
	DOCUMENT_TYPE_CC,
	DOCUMENT_TYPE_CE,
	DOCUMENT_TYPE_NIT,
	DOCUMENT_TYPE_P,
	INVALID_DOCUMENT_CE_FORMAT,
	INVALID_DOCUMENT_P_FORMAT,
} from '../../../application/constants/userInformation';
import {
	OPPORTUNITY_CARD,
	TEXTS,
	MODAL,
	PAYMENT_MODAL,
	STAGES,
	TABLE_HEADER,
	TABLE_ROWS,
	BASE_FIELDS_KEYS,
	ADDITIONAL_FIELDS_KEYS,
	ECONOMIC_ACTIVITIES_KEYS,
	MODAL_UUNAVAILABLE_PROPERTY,
	MOVED_BY,
	OPORTUNITY_STATUS,
	TOOLTIP_MSG_DATE_PROGRAMED,
	MODAL_USER_IDENTIFICATION,
	DISPERSED_MODAL,
	CONTACT_MODAL_TITLE,
	DESIST,
	NOTES,
	INFORMATION_TITLE,
	DESISTED,
	CONFIRMATION_OPTIONS,
	OPTIONED_USER_IDENTIFICATION_FORM,
	OPTION_DETAILS_PAGE,
	SEPARATED_OPTION_DETAIL,
	CONFIRM_PURCHASE,
	MESSAGE_OPTION,
	EXTEND_VALIDITY_PAGE,
	ERROR_EMPTY_FILE,
	ERROR_GREATER_THAN_ZERO,
	USER_ROLES,
	CANCEL_OPTION_PAGE,
	OPTIONS_STATE,
	DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT,
	WEB_ANALISIS,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_1,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_2,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_3,
	CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_4,
	TOOLTIP_MSG_APPOINTMENT_TYPE,
	APPOINTMENT_PHYSICAL_TYPE_TEXT,
	APPOINTMENT_DIGITAL_TYPE_TEXT,
	QUOTATION_IDENTIFICATION_TEXT,
	OPTION_SNAPSHOT_TEXTS,
	MOVED_IN,
	OPTION_STATE_TEXTS,
	CONTACT_ADDITIONAL_FIELDS,
	CAMPAIGN_NAME_TITLE_ROW,
	EXTERNAL_CONTACT,
	FORM_CONTACT,
} from '../translation_keys';

const SIMULATE = 'Simuló';
const DISCARDED = 'Descartado';
const VISITED = 'Visitó';

export default {
	[OPPORTUNITY_CARD.STAGE]: `Etapa`,
	[OPPORTUNITY_CARD.ASSESOR]: `Asesor`,
	[OPPORTUNITY_CARD.NO_ASSESOR]: `Sin asignar`,
	[OPPORTUNITY_CARD.NOTES]: `Notas`,
	[OPPORTUNITY_CARD.NO_NOTES]: `No hay notas, sé el primero en dejar una`,
	[OPPORTUNITY_CARD.SAVE]: `Guardar`,
	[OPPORTUNITY_CARD.NOTE]: `nota`,
	[OPPORTUNITY_CARD.CHANGES]: `cambios`,
	[OPPORTUNITY_CARD.DISCARD]: `Cancelar`,
	[OPPORTUNITY_CARD.START_ATTENTION]: `iniciar atención`,
	[TEXTS.TITLE]: `Historial del cliente`,
	[TEXTS.MORE]: `Más ...`,
	[TEXTS.QUOTER]: `Simular`,
	[TEXTS.SCHEDULER]: `Agendar`,
	[TEXTS.ATTENDED_BY]: `Atendido por`,
	[TEXTS.OPTION]: `Opcionar inmueble`,
	[TEXTS.SCORING]: `Obtener Scoring`,
	[MODAL.DETAILS]: 'Ver detalles',
	[MODAL.VERIFY]: 'En verificación',
	[MODAL.VERIFY_PAYMENT]: 'Pago en verificación',
	[MODAL.VERIFY_PAYMENT_PROCESS]: 'Esta oportunidad tiene un proceso de pago en verificación',
	[MODAL.BLOCK_DATE]: 'Fecha de bloqueo',
	[MODAL.PAYMENT_DATE]: 'Fecha de pago',
	[MODAL.PAYMENT_METHOD]: 'Método de pago',
	[MODAL.PAID_WITH]: 'Pago con',
	[MODAL.REFERENCE_CODE]: 'Código de Referencia',
	[MODAL.VALUE_PAID]: 'Valor pago',
	[PAYMENT_MODAL.TITLE]: 'Comparte el link con el comprador',
	[PAYMENT_MODAL.TITLE_2]: 'Elige el método de pago con el que el comprador hará la reserva',
	[PAYMENT_MODAL.PAYMENT_BLOCK_PROP_TEXT]:
		'Al seleccionar este método, el inmueble quedará bloqueado por <b>{{minutes}}</b>, además, es el tiempo que tiene el usuario para reservar.',
	[PAYMENT_MODAL.VALUE_BUY]: 'Valor Compra',
	[PAYMENT_MODAL.TIME_LEFT]: 'Tiempo restante',
	[PAYMENT_MODAL.TIME_LEFT_TEXT]:
		'El cliente tiene un tiempo máximo de {{minutes}} para realizar la compra, de lo contrario debes regenerar el link de pago',
	[PAYMENT_MODAL.BUY_LINK]: 'Link de la compra',
	[PAYMENT_MODAL.SEPARATED_VALUE]: 'Valor separado',
	[PAYMENT_MODAL.PROPERTY_BLOKED]: 'Inmueble bloqueado',
	[PAYMENT_MODAL.PROPERTY_BLOKED_TEXT]: 'De acuerdo al método de pago seleccionado, el inmueble será bloqueado.',
	[PAYMENT_MODAL.tarjeta_de_credito]: 'Tarjeta de crédito',
	[PAYMENT_MODAL.efectivo]: 'Efectivo',
	[PAYMENT_MODAL.pse]: 'PSE',
	[PAYMENT_MODAL.BLOCK_PROP_QUESTION]: '¿Deseas bloquear el inmueble durante<br/>{{minutes}} y generar un link de pago?',
	[STAGES[STAGE_RESERVED]]: 'Reservó',
	[STAGES[STAGE_QUOTED]]: 'Cotizó',
	[STAGES[STAGE_SIMULATE]]: SIMULATE,
	[STAGES[STAGE_SCHEDULE]]: 'Agendó',
	[STAGES[STAGE_DISCARDED]]: DISCARDED,
	[STAGES[STAGE_VISITED]]: VISITED,
	[STAGES[STAGE_SEPARATED]]: 'Separó',
	[STAGES[STAGE_SEPARATED_BY_OPTION]]: 'Separó',
	[STAGES[STAGE_MOVE_TO_SEPARATED]]: 'Separó',
	[STAGES[STAGE_DONE]]: 'Atendida',
	[STAGES[STAGE_MOVE_TO_INTERESTED]]: SIMULATE,
	[STAGES[STAGE_MOVE_TO_VISITED]]: VISITED,
	[STAGES[STAGE_MOVE_TO_RESERVED]]: 'Compró',
	[STAGES[STAGE_MOVE_TO_DISCARDED]]: DISCARDED,
	[STAGES[STAGE_DESISTED_WITH_REFUND]]: 'Desistido con devolución',
	[STAGES[STAGE_DESISTED_WITHOUT_REFUND]]: 'Desistido sin devolución',
	[STAGES[STAGE_DESISTED_BUSINESS]]: 'Negocio desistido',
	[STAGES[TRANSACTION_STAGE_READY_TO_DISPERSE]]: 'Listo para dispersión',
	[STAGES[TRANSACTION_STAGE_DISPERSED]]: 'Dispersado',
	[STAGES[STAGE_CONTACT]]: 'Contactar',
	[STAGES[STAGE_EXTERNAL_CONTACT_REQUEST]]: 'Contactar',
	[STAGES[STAGE_VERIFYING_PAYMENT]]: 'Verificando pago',
	[STAGES[STAGE_OPTIONED_PROPERTY]]: 'Opcionó',
	[STAGES[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]]: 'Contactar FB',
	[STAGES[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]]: 'Contactar G',
	[TABLE_HEADER[DATE_ROW]]: 'Fecha',
	[TABLE_HEADER[STAGE_ROW]]: 'Estado',
	[TABLE_HEADER[PROPERTY_ROW]]: 'Inmueble',
	[TABLE_HEADER[DETAIL_ROW]]: 'Detalle',
	[TABLE_HEADER[ACTIONS_ROW]]: 'Acciones',
	[TABLE_ROWS.NO_PROPERTY]: 'No aplica',
	[CONTACT_MODAL_TITLE]: 'Detalle del comprador',
	[BASE_FIELDS_KEYS[NAME_KEY]]: 'Nombres',
	[BASE_FIELDS_KEYS[LAST_NAME_KEY]]: 'Apellidos',
	[BASE_FIELDS_KEYS[MOBILE_KEY]]: 'Celular',
	[BASE_FIELDS_KEYS[EMAIL_KEY]]: 'Correo electrónico',
	[BASE_FIELDS_KEYS.IDENTIFICATION]: 'Identificación',
	[BASE_FIELDS_KEYS.INCOME]: 'Ingresos',
	[ADDITIONAL_FIELDS_KEYS[RESIDENCE_CITY_KEY]]: 'Ciudad de residencia',
	[ADDITIONAL_FIELDS_KEYS[MONTHLY_PAYMENT_CAPACITY_KEY]]: 'Capacidad de pago mensual',
	[ADDITIONAL_FIELDS_KEYS[ECONOMIC_ACTIVITY_KEY]]: 'Ocupación',
	[ECONOMIC_ACTIVITIES_KEYS[EMPLOYEE_KEY]]: 'Empleado',
	[ECONOMIC_ACTIVITIES_KEYS[INDEPENDENT_KEY]]: 'Independiente',
	[ECONOMIC_ACTIVITIES_KEYS[PENSIONER_KEY]]: 'Pensionado',
	[ECONOMIC_ACTIVITIES_KEYS[OTHER_ACTIVITY_KEY]]: 'Otro',
	[MODAL_UUNAVAILABLE_PROPERTY.DESCRIPTION_MODAL]: 'Lo sentimos mucho, este inmueble no se encuentra disponible',
	[MODAL_UUNAVAILABLE_PROPERTY.BUTTON_MODAL]: 'Seleccionar otro inmueble',
	[MODAL_UUNAVAILABLE_PROPERTY.USER_WITHOUT_SIMULATION]: 'Por favor selecciona un inmueble',
	[MODAL_UUNAVAILABLE_PROPERTY.SEARCH_BUTTON]: 'Buscar',
	[MODAL_UUNAVAILABLE_PROPERTY.TEXT_1]: '1 persona ha opcionado este inmueble',
	[MODAL_UUNAVAILABLE_PROPERTY.TEXT_2]: '{{numPeople}} personas han opcionado este inmueble',
	[MOVED_BY]: 'Movido por',
	[MOVED_IN]: 'Fecha de modificación',
	[OPORTUNITY_STATUS[INTERESTED_STATUS]]: 'Interesado',
	[OPORTUNITY_STATUS[VISITED_STATUS]]: 'Visitado',
	[OPORTUNITY_STATUS[RESERVED_STATUS]]: 'Reservado',
	[OPORTUNITY_STATUS[SEPARATED_STATUS]]: 'Separado',
	[OPORTUNITY_STATUS[DISCARTED_STATUS]]: DISCARDED,
	[OPORTUNITY_STATUS[DESISTED_STATUS]]: 'Desistido',
	[TOOLTIP_MSG_DATE_PROGRAMED]: 'Fecha de la cita programada',
	[TOOLTIP_MSG_APPOINTMENT_TYPE]: 'Tipo de cita',
	[DESIST]: 'Desistir',
	[DESISTED]: 'Desistido',
	[MODAL_USER_IDENTIFICATION.TITLE]: 'Antes de reservar necesitamos asegurarnos de la información del cliente',
	[MODAL_USER_IDENTIFICATION.SELECT_DOC]: 'Selecciona el tipo de documento',
	[MODAL_USER_IDENTIFICATION.TYPE_DOC_PLACEHOLDER]: 'Escribe el número de identificación',
	[MODAL_USER_IDENTIFICATION.TYPE_DOC_LABEL]: 'Escribe el numero de identificación',
	[MODAL_USER_IDENTIFICATION.NO_COMFIRM_ID]: 'No se pudo confirmar tu identidad',
	[MODAL_USER_IDENTIFICATION.CONTINUE_TEXT]: 'Continuar reserva',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CC]]: 'Cedula de ciudadania',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_NIT]]: 'NIT',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_CE]]: 'Cedula de extranjería',
	[MODAL_USER_IDENTIFICATION[DOCUMENT_TYPE_P]]: 'Pasaporte',
	[MODAL_USER_IDENTIFICATION[INVALID_DOCUMENT_CE_FORMAT]]:
		'*El documento puede comenzar con hasta 5 caracteres de texto, luego debe tener entre 4 y 8 caracteres numericos con el primero diferente a 0',
	[MODAL_USER_IDENTIFICATION[INVALID_DOCUMENT_P_FORMAT]]: '*El campo debe contenter entre 4 y 16 caracteres',
	[MODAL_USER_IDENTIFICATION.INVALID_VALUE_LENGTH_RANGE]:
		'*El campo debe comenzar con un número diferente a 0 y tener entre {{minValue}} y {{maxValue}}',
	[MODAL_USER_IDENTIFICATION.EMPTY_FIELD]: '*Debes diligenciar este campo para poder continuar',
	[DISPERSED_MODAL.CLIENT_REFOUND_VALUE]: 'Valor devuelto al cliente',
	[DISPERSED_MODAL.VECINDARIO_SALES_COMMISSION]: 'Valor cobrado por vecindario como comisión',
	[DISPERSED_MODAL.COMPANY_VALUE_SENT]: 'Valor enviado a la constructora',
	[DISPERSED_MODAL.REFOUND_DATE]: 'Fecha de devolución',
	[DISPERSED_MODAL.DISPERSED_DATE]: 'Fecha de dispersión',
	[DISPERSED_MODAL.DETAIL]: 'Detalle',
	[NOTES.SURE]: '¿Seguro que quieres eliminar esta nota?',
	[NOTES.ACCEPT]: 'Si aceptas se eliminará todo registro de la nota y no podrás deshacer esta acción',
	[NOTES.BACK]: 'Volver',
	[NOTES.YES]: 'Si, seguro',
	[OPPORTUNITY_CARD.STAGES.Interesado]: 'Interesado',
	[OPPORTUNITY_CARD.STAGES.Visitado]: 'Visitado',
	[OPPORTUNITY_CARD.STAGES.Reservado]: 'Reservado',
	[OPPORTUNITY_CARD.STAGES.Descartado]: 'Descartado',
	[OPPORTUNITY_CARD.STAGES.Separado]: 'Separado',
	[OPPORTUNITY_CARD.STAGES.Desistido]: 'Desistido',
	[INFORMATION_TITLE]: 'Información adicional',
	[CONFIRMATION_OPTIONS.TEXT_1]:
		'El úlitmo inmueble {{action}} es {{towerName}}-{{propertyName}} y está opcionado en el momento por 1 persona',
	[CONFIRMATION_OPTIONS.TEXT_1_1]:
		'El úlitmo inmueble {{action}} es {{towerName}}-{{propertyName}} y está opcionado en el momento por {{numPeople}} personas',
	[CONFIRMATION_OPTIONS.TEXT_1_2]:
		'El úlitmo inmueble {{action}} es {{towerName}}-{{propertyName}} y no tiene opciones en el momento',
	[CONFIRMATION_OPTIONS.TEXT_2]: '¿Estás seguro de opcionar este inmueble?',
	[CONFIRMATION_OPTIONS.TEXT_3]: 'Si, seguro',
	[CONFIRMATION_OPTIONS.TEXT_4]: 'No, seleccionar otro inmueble',
	[CONFIRMATION_OPTIONS.TEXT_5]: 'simulado',
	[CONFIRMATION_OPTIONS.TEXT_6]: 'cotizado',
	[OPTIONED_USER_IDENTIFICATION_FORM.TITLE]: 'Antes de opcionar completa los datos del cliente',
	[OPTIONED_USER_IDENTIFICATION_FORM.CONTINUE]: 'Continuar',

	[OPTION_DETAILS_PAGE.OPTIONED_WORD]: 'Opcionó',
	[OPTION_DETAILS_PAGE.VIEW_OPTION]: 'Ver opciones del inmueble',
	[OPTION_DETAILS_PAGE.BODY_TEXT_1]: 'Estado de la opción',
	[OPTION_DETAILS_PAGE.BODY_TEXT_2]: 'Fecha de actualización del último estado',
	[OPTION_DETAILS_PAGE.BODY_TEXT_3]: 'Nombre de quien opcionó',
	[OPTION_DETAILS_PAGE.BODY_TEXT_4]: 'Posición en la cola',
	[OPTION_DETAILS_PAGE.BODY_TEXT_5]: 'Vigencia',
	[OPTION_DETAILS_PAGE.BODY_TEXT_5_1]: 'Vigencia (Extendida)',
	[OPTION_DETAILS_PAGE.BODY_TEXT_6]: 'Extendida por',
	[OPTION_DETAILS_PAGE.BODY_TEXT_7]: 'Confirmada por',
	[OPTION_DETAILS_PAGE.BODY_TEXT_8]: 'Anulada por',
	[OPTION_DETAILS_PAGE.BUTTON_CANCEL_OPTION]: 'Anular opción',
	[OPTION_DETAILS_PAGE.BUTTON_EXTEND_VALIDITY]: 'Extender vigencia',
	[OPTION_DETAILS_PAGE.BUTTON_CONFIRM_PURCHASE]: 'Confirmar compra',
	[SEPARATED_OPTION_DETAIL.TITLE]: 'Opción confirmada',
	[SEPARATED_OPTION_DETAIL.COLUMN_PROPERTY]: 'Inmueble',
	[SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMATION_DATE]: 'Fecha de confirmación',
	[SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMED_BY]: 'Confirmada por',
	[CONFIRM_PURCHASE.TITLE]: '¿Estás seguro de confirmar la compra?',
	[CONFIRM_PURCHASE.DESCRIPTION]:
		'Al hacerlo se marcará el inmueble como separado en el inventario y se creará el negocio',
	[CONFIRM_PURCHASE.YES_SURE]: 'Sí, seguro',
	[CONFIRM_PURCHASE.CANCEL]: 'Cancelar',
	[CONFIRM_PURCHASE.SUCCESS_MESSAGE]: 'Compra confirmada con éxito',
	[MESSAGE_OPTION.TEXT_1]:
		'El enlace de pago no se puede generar debido a que hay personas en la cola de opciones para este inmueble.',
	[MESSAGE_OPTION.TEXT_2]:
		'Para generar  el enlace debes anular las opciones de los demás clientes o si el usuario desea pagar otro inmueble, anula esta opción o genera el enlace de pago sobre un inmueble disponible. Si las opciones son de oportunidades de otros asesores, ellos o los directores deben anular las opciones.',
	[MESSAGE_OPTION.TEXT_3]: 'Ver detalles',
	[EXTEND_VALIDITY_PAGE.TITLE]: 'Extender vigencia por',
	[EXTEND_VALIDITY_PAGE.WORD_DAYS]: 'Días',
	[EXTEND_VALIDITY_PAGE.WORD_HOURS]: 'Horas',
	[EXTEND_VALIDITY_PAGE.INFO]: 'Se tomarán como días calendario',
	[EXTEND_VALIDITY_PAGE.BUTTON_CONFIRM]: 'Confirmar',
	[EXTEND_VALIDITY_PAGE.INFO_EXCEPTION_EXTEND]: 'No se puede extender la vigencia por más de una semana',
	[EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_7]: 'Debe ser un número entre 1 y 7',
	[EXTEND_VALIDITY_PAGE.ERROR_NUMBER_BETWEEN_1_168]: 'Debe ser un número entre 1 y 168',
	[ERROR_EMPTY_FILE]: 'Este campo no puede estar vacio',
	[ERROR_GREATER_THAN_ZERO]: 'El número debe ser igual o mayor a uno',
	[USER_ROLES.DIRECTOR_ROLE]: 'director',
	[USER_ROLES.ASESOR_ROLE]: 'asesor',

	[CANCEL_OPTION_PAGE.TITLE]: '¿ Estás seguro de anular la opción?',
	[CANCEL_OPTION_PAGE.BODY]: 'Al hacerlo, tu cliente perderá la prioridad en la intención de compra',
	[CANCEL_OPTION_PAGE.BUTTON_ACTION_1]: 'Si, seguro',
	[CANCEL_OPTION_PAGE.BUTTON_ACTION_2]: 'No, volver',

	[OPTIONS_STATE.ACTIVE]: 'Activa',
	[OPTIONS_STATE.PENDING]: 'Pendiente',
	[OPTIONS_STATE.CONFIRMED]: 'Confirmada',
	[OPTIONS_STATE.EXECUTED]: 'Ejecutada',
	[OPTIONS_STATE.EXPIRED]: 'Vencida',
	[OPTIONS_STATE.CANCELED]: 'Anulada',
	[DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT]:
		'No puedes opcionar un inmueble para esta oportunidad pues ya tiene una opción vigente',
	[WEB_ANALISIS.TITLE]: 'Análisis web',
	[WEB_ANALISIS.UTM_ID]: 'Id de la campaña',
	[WEB_ANALISIS.UTM_SOURCE]: 'Fuente de la campaña',
	[WEB_ANALISIS.UTM_MEDIUM]: 'Medio de la campaña',
	[WEB_ANALISIS.UTM_CAMPAIGN]: 'Nombre de la campaña',
	[WEB_ANALISIS.UTM_CONTENT]: 'Contenido de la campaña',
	[WEB_ANALISIS.UTM_TERM]: 'Términos claves de la campaña',
	[WEB_ANALISIS.DOCUMENT_REFERER]: 'Sitio web que refirió al usuario',
	[WEB_ANALISIS.SOURCE]: 'Fuente de origen',
	[WEB_ANALISIS.SOURCE_URL]: 'URL donde se realiza la acción',
	[WEB_ANALISIS.ACTION_PERFORMER]: 'Oportunidad de negocio creada por',
	[WEB_ANALISIS.USER_ID_EMAIL]: 'Correo de quien creo la Oportunidad de negocio',

	[WEB_ANALISIS.UTM_ID_TOOLTIP]:
		'Indica a qué campaña específica de anuncios hace referencia la creación de la Oportunidad de negocio',
	[WEB_ANALISIS.UTM_SOURCE_TOOLTIP]:
		'Corresponde al sitio de origen donde está expuesta la pauta o pieza desde la cual se origina una visita a la URL de destino y genera una Oportunidad de negocio. Ejemplos: facebook, google, twitter, el sitio web del proyecto, email, valla, etc.',
	[WEB_ANALISIS.UTM_MEDIUM_TOOLTIP]:
		'Clasificar el formato de la campaña que generó la Oportunidad de negocio. Ejemplos: search-cpc, story-organic, display-cpc, email-organic, qr, etc.',
	[WEB_ANALISIS.UTM_CAMPAIGN_TOOLTIP]:
		'Es el nombre de la campaña que generó la Oportunidad de negocio. Ejemplos: feria-vivienda-junio-2023, vivir-en-medellin, etc.',
	[WEB_ANALISIS.UTM_CONTENT_TOOLTIP]:
		'Por ejemplo, si tiene dos enlaces de llamada a la acción en un mismo mensaje de correo, puede usar utm_content y asignar valores distintos a cada uno para poder averiguar cuál es la versión más eficaz.',
	[WEB_ANALISIS.UTM_TERM_TOOLTIP]: 'Se usa para identificar keywords o palabras claves en anuncios de búsqueda pagados.',
	[WEB_ANALISIS.DOCUMENT_REFERER_TOOLTIP]:
		'Captura la URL que refirió o llevó al usuario al sitio web donde está el embebido de Vecindario que creó la Oportunidad de negocio.',
	[WEB_ANALISIS.SOURCE_TOOLTIP]: 'Captura desde donde se generó la Oportunidad de negocio. ',
	[WEB_ANALISIS.SOURCE_URL_TOOLTIP]: 'Indica la url desde la cual generó la Oportunidad de negocio.',
	[WEB_ANALISIS.ACTION_PERFORMER_TOOLTIP]:
		'Captura quién o qué realiza la acción que generó la Oportunidad de negocio. Por ejemplo: un usuario comprador, director, asesor, etc.',
	[WEB_ANALISIS.USER_ID_EMAIL_TOOLTIP]:
		'Guarda el correo de la persona que realiza la acción que genera la Oportunidad de negocio.',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_DIRECTOR]: 'director',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_COMPRADOR]: 'comprador',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_ASESOR]: 'asesor',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS]: 'Integración Meta',
	[WEB_ANALISIS.ACTION_PERFORMER_VALUE_FACEBOOK_BUSINESS_SOURCE]: 'Facebook leads',
	[WEB_ANALISIS.TITLE_FACEBOOK_BUSINESS]: 'Integración Meta Business manager',

	// Contact action instance
	[CONTACT_QUESTION_SLUGS.ECONOMIC_ACTIVITY]: 'Ocupación',
	[CONTACT_QUESTION_SLUGS.CITY_OF_RESIDENCE]: 'Ciudad de residencia',
	[CONTACT_QUESTION_SLUGS.MONTHLY_PAYMENT_CAPACITY]: 'Capacidad de pago mensual',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_1]: 'Empleado',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_2]: 'Independiente',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_3]: 'Pensionado',
	[CONTACT_QUESTION_PH_ECONOMIC_ACTIVITY_4]: 'Otro',

	// Schedule action instance
	[APPOINTMENT_PHYSICAL_TYPE_TEXT]: 'Física',
	[APPOINTMENT_DIGITAL_TYPE_TEXT]: 'Digital',

	// Quoted action instance
	[QUOTATION_IDENTIFICATION_TEXT]: 'ID de cotización',

	// Option snapshot action instance
	[OPTION_SNAPSHOT_TEXTS.ORDER]: 'Orden',
	[OPTION_SNAPSHOT_TEXTS.EXPIRATION_DATE]: 'Fecha de vencimiento',

	// Option states
	[OPTION_STATE_TEXTS[OPTION_STATE_PENDING]]: 'Pendiente',
	[OPTION_STATE_TEXTS[OPTION_STATE_ACTIVE]]: 'Activa',
	[OPTION_STATE_TEXTS[OPTION_STATE_EXPIRED]]: 'Vencida',
	[OPTION_STATE_TEXTS[OPTION_STATE_CANCELED]]: 'Cancelada',
	[OPTION_STATE_TEXTS[OPTION_STATE_CONFIRMED]]: 'Confirmada',
	[OPTION_STATE_TEXTS[OPTION_STATE_EXECUTED]]: 'Ejecutada',
	[ADDITIONAL_FIELDS_KEYS[STAGE_EXTERNAL_CONTACT_REQUEST_LABEL]]: 'Fuente de origen',
	[CONTACT_ADDITIONAL_FIELDS[STAGE_EXTERNAL_CONTACT_REQUEST_TEXT]]: 'Solicitud de contacto externa API',
	[CAMPAIGN_NAME_TITLE_ROW]: 'Nombre de la campaña',

	[EXTERNAL_CONTACT.TITLE]: 'Contactar FB',
	[EXTERNAL_CONTACT.DETAIL]: 'Detalle',
	[EXTERNAL_CONTACT.REQUEST_TYPE]: 'Tipo de solicitud',
	[EXTERNAL_CONTACT.CAMPAIGN_ID]: 'ID de la campaña de {{provider}}',
	[EXTERNAL_CONTACT.CAMPAIGN_NAME]: 'Nombre de la campaña de {{provider}}',
	[EXTERNAL_CONTACT.LEAD_REGISTRATION_DATE]: 'Fecha de registro del {{provider}}',
	[EXTERNAL_CONTACT.LEAD_ID]: 'ID del lead',
	[EXTERNAL_CONTACT.ADVERTISEMENT_NAME]: 'Nombre del anuncio',
	[EXTERNAL_CONTACT.AD_GROUP_NAME]: 'Nombre del grupo de anuncios',
	[EXTERNAL_CONTACT.FORM_ID]: 'ID del formulario',
	[EXTERNAL_CONTACT[STAGE_SOCIAL_LEADS[STAGE_LEADS_FACEBOOK]]]: 'Facebook leads',
	[EXTERNAL_CONTACT[STAGE_SOCIAL_LEADS[STAGE_LEADS_GOOGLE]]]: 'Google leads',
	[EXTERNAL_CONTACT.CAMPAIGN_NAME_TOOLTIP]:
		'Nombre de campaña que hayas colocado en el Business manager de Meta. Este nombre de campaña te servirá para identificar dicha campaña integrada con Vecindario.',
	[EXTERNAL_CONTACT.CAMPAIGN_ID_TOOLTIP]:
		'ID de la campaña de facebook leads que hayas creado en el Business manager de Meta correspondiente a este proyecto que está integrado con Vecindario y generó y/o actualizó la Oportunidad de negocio.',
	[EXTERNAL_CONTACT.LEAD_REGISTRATION_DATE_TOOLTIP]:
		'Fecha en que se registró el usuario en el formulario de la campaña de Facebook leads.',
	[EXTERNAL_CONTACT.LEAD_ID_TOOLTIP]: 'ID del lead registrado en la campaña de Facebook leads',
	[EXTERNAL_CONTACT.ADVERTISEMENT_NAME_TOOLTIP]:
		'Nombre del anuncio creando en el Business manager de Meta en el que se registró el usuario.',
	[EXTERNAL_CONTACT.FORM_ID_TOOLTIP]:
		'ID del formulario creando en el Business manager de Meta en el que se registró el usuario.',

	[FORM_CONTACT.REQUEST_TYPE]: 'Tipo de solicitud',
	[FORM_CONTACT.CONTACT_FORM]: 'Formulario de contactos',
};
