import { FB_CLIENT_ID, FRONT_URL } from '../../../../shared/application/constants/env';

const FACEBOOK_URL = 'https://www.facebook.com';
export const FACEBOOK_POP_UP = (redirectUri = FRONT_URL, state = {}) =>
	`${FACEBOOK_URL}/v17.0/dialog/oauth?client_id=${FB_CLIENT_ID}&redirect_uri=${redirectUri}&state=${JSON.stringify(
		state,
	)}&response_type=code%20token&scope=public_profile+ads_management+pages_show_list+business_management+leads_retrieval+pages_manage_ads+pages_manage_metadata+pages_read_engagement+pages_read_user_content`;

// params returned in FACEBOOK_POP_UP after login
export const ACCESS_TOKEN = 'access_token';
export const ACCESS_EXPIRATION = 'data_access_expiration_time';
export const EXPIRES_IN = 'expires_in';
export const LONG_TOKEN = 'long_lived_token';
export const CODE = 'code';
export const STATE = 'state';

export const EMAIL_REDIRECT_FROM = 'from';

export const FB_BACK_PROVIDER = 'facebook_business';
