export const ECONOMIC_ACTIVITY_KEY = 'economic_activity';
export const NAME_KEY = 'first_name';
export const LAST_NAME_KEY = 'last_name';
export const MOBILE_KEY = 'mobile';
export const EMAIL_KEY = 'email';
export const RESIDENCE_CITY_KEY = 'city_of_residence';
export const MONTHLY_PAYMENT_CAPACITY_KEY = 'monthly_payment_capacity';
export const EMPLOYEE_KEY = 'employee';
export const INDEPENDENT_KEY = 'independent';
export const PENSIONER_KEY = 'pensioner';
export const OTHER_ACTIVITY_KEY = 'other_activity';
export const REQUEST_TYPE_KEY = 'request_type';

export const BASE_FIELDS = {
	[NAME_KEY]: NAME_KEY,
	[LAST_NAME_KEY]: LAST_NAME_KEY,
	[MOBILE_KEY]: MOBILE_KEY,
	[EMAIL_KEY]: EMAIL_KEY,
};

export const ADDITIONAL_FIELDS = {
	[RESIDENCE_CITY_KEY]: RESIDENCE_CITY_KEY,
	[MONTHLY_PAYMENT_CAPACITY_KEY]: MONTHLY_PAYMENT_CAPACITY_KEY,
	[ECONOMIC_ACTIVITY_KEY]: ECONOMIC_ACTIVITY_KEY,
};

export const ECONOMIC_ACTIVITIES = {
	[EMPLOYEE_KEY]: EMPLOYEE_KEY,
	[INDEPENDENT_KEY]: INDEPENDENT_KEY,
	[PENSIONER_KEY]: PENSIONER_KEY,
	[OTHER_ACTIVITY_KEY]: OTHER_ACTIVITY_KEY,
};
