import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Modal, TextTag, ToggleSwitch } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import {
	getActiveIntegration,
	postActivateIntegration as postActivateContactIntegration,
	setShowCompaniesModal as setShowContactCompaniesModal,
	setFormData,
} from '../../../../application/slices/sincoContactIntegration';

import './sicoIntegration.scss';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import {
	loadingSelector,
	passwordSelector,
	showCompaniesModalSelector,
	stepSelector,
	urlSelector,
	usernameSelector,
} from '../../../../application/selector/sincoIntegration';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { INTEGRATIONS } from '../../../../infrastructure/locales/translation_keys';

const contentStyle = {
	padding: '38px',
	minWidth: '800px',
	minHeight: '450px',
};

const SincoIntegration = ({
	slug,
	setExpandCmpt,
	data,
	activeIntegration,
	onActiveIntegration,
	onDeactivate,
	isActivatedIntegration,
}) => {
	const dispatch = useDispatch();

	const url = useSelector(urlSelector);
	const password = useSelector(passwordSelector);
	const username = useSelector(usernameSelector);
	const loading = useSelector(loadingSelector);
	const showCompaniesModal = useSelector(showCompaniesModalSelector);
	const step = useSelector(stepSelector);
	const { t } = useTranslation();

	useEffect(() => {
		if (slug) {
			dispatch(getActiveIntegration({ slug }));
		}
	}, [dispatch, slug]);

	const activateIntegration = () => {
		dispatch(postActivateContactIntegration({ slug, url, password, username }));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		dispatch(setFormData({ name, value }));
	};

	const handleCloseModal = () => {
		dispatch(setShowContactCompaniesModal(false));
	};

	return (
		<>
			<div className={`integratios-container-input ${activeIntegration ? 'active' : 'inactive'}`}>
				{activeIntegration && (
					<>
						<TextTag tag="label" className="card-description">
							{t(`${INTEGRATIONS[data?.inputLabel]}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<div className="active-url-action">
							<Input
								type="text"
								value={url}
								name="url"
								onChange={handleChange}
								autoComplete="off"
								className="token form-control mr-3"
							/>
						</div>
						<TextTag tag="label" className="card-description">
							{t(`${INTEGRATIONS.SINCO_USERNAME_LABEL}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<div className="active-url-action">
							<Input
								type="text"
								value={username}
								name="username"
								onChange={handleChange}
								autoComplete="off"
								className="token form-control mr-3"
							/>
						</div>
						<TextTag tag="label" className="card-description">
							{t(`${INTEGRATIONS.SINCO_PASSWORD_LABEL}`, { ns: DOMAIN_NAME })}
						</TextTag>
						<div className="active-url-action">
							<Input
								type="password"
								value={password}
								name="password"
								onChange={handleChange}
								autoComplete="off"
								className="token form-control mr-3"
							/>
						</div>
					</>
				)}
			</div>
			<div className="integratios-button-container">
				{isActivatedIntegration ? (
					<>
						<Button
							className="integrated-btn"
							text={t(`${INTEGRATIONS.INTEGRATED_TEXT}`, { ns: DOMAIN_NAME })}
							onClick={onDeactivate}
						/>
						<div className="integratios-switch-container">
							<ToggleSwitch.Label id={data.type} value={true} onChange={onDeactivate} />
						</div>
					</>
				) : (
					<>
						{activeIntegration ? (
							<>
								{loading ? (
									<Button variant="bordered back" className="btn btn-dimgray generate-btn" disabled>
										<i className="fal fa-spinner fa-spin"></i>
									</Button>
								) : (
									<Button
										variant="bordered back"
										text={t(`${INTEGRATIONS.INTEGRATE_TEXT_BUTTON}`, { ns: DOMAIN_NAME })}
										onClick={activateIntegration}
									/>
								)}
							</>
						) : (
							<Button
								variant="bordered back"
								text={t(`${INTEGRATIONS.ACTIVATE_TEXT_BUTTON}`, { ns: DOMAIN_NAME })}
								onClick={() => onActiveIntegration(true)}
							/>
						)}
					</>
				)}
			</div>

			{showCompaniesModal && (
				<Modal isOpen={showCompaniesModal} onClose={handleCloseModal} contentStyle={contentStyle}>
					<div className="sinco-modal">
						{step === 1 && <Step1 slug={slug} />}
						{step === 2 && <Step2 slug={slug} setExpandCmpt={setExpandCmpt} />}
						{step === 3 && <Step3 slug={slug} setExpandCmpt={setExpandCmpt} />}
					</div>
				</Modal>
			)}
		</>
	);
};

SincoIntegration.propTypes = {
	setExpandCmpt: PropTypes.func,
	slug: PropTypes.string.isRequired,
	data: PropTypes.object,
	activeIntegration: PropTypes.bool,
	onActiveIntegration: PropTypes.func,
	onDeactivate: PropTypes.func,
	isActivatedIntegration: PropTypes.bool,
};

export default SincoIntegration;
