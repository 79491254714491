import { ACCOUNTS_TABLE_HEADER, CAMPAIGN_TABLE_HEADER, FACEBOOK } from '../translation_keys';
import {
	ACCOUNT_ID_ROW,
	ADVERTISIG_ACCOUNT_ROW,
	CONNECTED_BY_ROW,
	CONNECTION_DATE_ROW,
	CONNECTION_ROW,
	DELETE_ROW,
	CAMPAIGN_NAME_ROW,
	CAMPAIGN_ID_ROW,
	CREATION_DATE_ROW,
} from '../../../application/constants/facebookAccounts';

export default {
	[FACEBOOK.BUTTON_TEXT]: 'Conectar cuenta',
	[FACEBOOK.TEXT_INFO]:
		'Conecta tu cuenta de Business Manager de Meta para sincronizar automáticamente tus leads de campañas de pauta como Oportunidades de negocio. Verifica que tengas los permisos adecuados en el administrador de anuncios de Meta.',
	[FACEBOOK.INTEGRATION_NAME]: 'Meta',
	[FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_TEXT]:
		'Conecta tus campañas para empezar a recibir los leads en tu pipeline de Oportunidades de negocio',
	[FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_BUTTON]: 'Conectar campaña',
	[FACEBOOK.ACCOUNTS_CONNECT_ERROR]:
		'Error al traer la información de las cuentas de Meta, sigue los pasos de siguiente artículo para volver a intentar conectar la cuenta. ',
	[FACEBOOK.ACCOUNTS_CONNECT_ERROR_PERMISSIONS]:
		'Haz realizado múltiples intentos de conexión sin éxito, por lo que la cuenta ha sido bloqueada. Vuelve a intentarlo en unos minutos.',
	[FACEBOOK.ACCOUNTS_CONNECT_TOOLTIP]:
		'Si deseas desconectar tu cuenta de anuncios de Meta, se recomienda que lo hagas desde Vecindario y no desde Meta. Desconectar tu cuenta de anuncios de Meta en Meta eliminará todas las conexiones de cuenta a la que el usuario de Facebook registrado tenga acceso, incluidas las demás de aplicaciones de Meta que no se conectaron a Vecindario.',
	[FACEBOOK.ACCOUNTS_CONNECT_INFORMATIVE_NOTICE]:
		'La cuenta de Business manager de Meta está desconectada, por lo cual no es posible crear ni editar campañas. Para volver a conectar la cuenta haz clic en conexión. Si deseas conectar otra cuenta diferente primero se debe eliminar la que está desconectada.',
	[ACCOUNTS_TABLE_HEADER[ADVERTISIG_ACCOUNT_ROW]]: 'Cuenta Publicitaria',
	[ACCOUNTS_TABLE_HEADER[ACCOUNT_ID_ROW]]: 'ID de cuenta',
	[ACCOUNTS_TABLE_HEADER[CONNECTED_BY_ROW]]: 'Conectado por',
	[ACCOUNTS_TABLE_HEADER[CONNECTION_DATE_ROW]]: 'Fecha de conexión',
	[ACCOUNTS_TABLE_HEADER[CONNECTION_ROW]]: 'Conexión',
	[ACCOUNTS_TABLE_HEADER[DELETE_ROW]]: '',

	[CAMPAIGN_TABLE_HEADER[CAMPAIGN_NAME_ROW]]: 'Nombre de la campaña',
	[CAMPAIGN_TABLE_HEADER[CAMPAIGN_ID_ROW]]: 'ID de campaña',
	[CAMPAIGN_TABLE_HEADER[CREATION_DATE_ROW]]: 'Fecha de conexión',
	[FACEBOOK.ACCOUNT_HEADER]: 'Selecciona una cuenta publicitaria de Meta',
	[FACEBOOK.CANCEL_BUTTON]: 'Cancelar',
	[FACEBOOK.CONNECT_BUTTON]: 'Conectar',
	[FACEBOOK.GOT_TO_ACCOUNT_INFO]: 'Ir a información de cuenta',
	[FACEBOOK.COULUNM_ONE]: 'Cuentas publicitarias',
	[FACEBOOK.COULUNM_TWO]: 'ID de cuenta publicitaria',
	[FACEBOOK.NO_ACCOUNTS]: 'No tienes cuentas publicitarias.',
	[FACEBOOK.CAMPAIGNS_TITLE_V1]: 'Campaña',
	[FACEBOOK.CAMPAIGNS_TITLE_V2]: 'Campañas',
	[FACEBOOK.CAMPAIGNS_LIST_TITLE]: 'Nombre de la campaña:*',
	[FACEBOOK.CAMPAIGNS_LIST_CANCEL_BUTTON]: 'Cancelar',
	[FACEBOOK.CAMPAIGNS_LIST_CONNECT_BUTTON]: 'Conectar',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_1]:
		'Ten en cuenta que los valores a sincronizar de los formularios de Meta a Vecindario de esta campaña serán:',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_2]: 'firstname o fullname - Para el nombre del usuario',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_3]: 'lastname -Para el apellido del usuario',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_4]: 'email o work_email - Para el correo del usuario',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_5]: 'phone o work_phone - Para el teléfono del usuario',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_6]:
		'ID_CO_CC o ID_AR_DNI o ID_CL_RUT o ID_EC_CI o ID_PE_DNI o ID_MX_RFC - Para la identificación del usuario',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_7]:
		'El valor interno del campo en los formularios deben ser iguales a estos, de lo contrario podrán ocurrir fallas en la integración y no verse reflejados los facebook leads.',
	[FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_8]: 'Mas información.',
	[FACEBOOK.CAMPAIGNS_NEW_CAMPAIGN]: 'Nueva campaña',
	[FACEBOOK.CAMPAIGNS_MAXIMUM_ACTIVE]:
		'Tienes el máximo de campañas activas habilitadas, comunícate con tu asesor comercial para incrementar la cantidad de campañas en caso de necesitar vincular más',
	[FACEBOOK.CAMPAIGNS_FB_NO_FOUND]:
		'No se encontraron campañas creadas en la cuenta de Meta. Para poder conectar una campaña primero deberá ser creada en Meta y luego se listará en Vecindario para su conexión.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TITLE]: '¿Desconectar la campaña {{campaignName}}?',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_0]:
		'Al desconectar esta campaña estás desactivando la sincronización de los leads hacia Vecindario.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_TEXT_DESC_1]:
		'Ten en cuenta que esta acción no desactivará tu campaña en tu cuenta de Meta, para ello deberás ingresar a tu cuenta de Business manager de Meta y pausarla desde allí.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_0]: 'No se perderán datos recopilados anteriormente.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_1]: 'No se sincronizarán más los leads generados en esta campaña',
	[FACEBOOK.DISCONNECT_CAMPAIGN_ITEM_2]:
		'En caso de volver a conectar tus campañas, se resincronizarán los clientes potenciales registrados en tu campaña en los últimos 90 días.',
	[FACEBOOK.DISCONNECT_CAMPAIGN_BUTTON]: 'Desconectar',

	[FACEBOOK.DELETE_CAMPAIGN_TITLE]: '¿Eliminar la campaña {{campaignName}}?',
	[FACEBOOK.DELETE_CAMPAIGN_TEXT_DESC]:
		'Ten en cuenta que esta acción no desactivará tu campaña en tu cuenta de Meta, para ello deberás ingresar a tu cuenta de Meta Business y pausarla desde allí.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_0]: 'Se desactivará y eliminará la campaña.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_1]:
		'Para volver a recibir leads de esta campaña deberás volver a crearla de nuevo en Vecindario.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_2]: 'No se perderán datos recopilados anteriormente.',
	[FACEBOOK.DELETE_CAMPAIGN_ITEM_3]: 'No se sincronizarán más los facebook leads generados en esta campaña.',
	[FACEBOOK.ACCOUNT_CONNECTED_INFO]: 'Actualmente tienes conectada la cuenta de {{campaignName}}.',
	[FACEBOOK.DELETE_CAMPAIGN_BUTTON]: 'Eliminar',

	[FACEBOOK.DISCONNECT_ACCOUNT_TITLE]: '¿Desconectar la cuenta {{accountName}}?',
	[FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_0]:
		'Esto afecta la sincronización y creación de los leads de las campañas del proyecto como Oportunidades de negocio.',
	[FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_1]:
		'Ten en cuenta que esta acción no desactivará tu cuenta en tu cuenta de Meta.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_0]: 'No se perderán datos recopilados anteriormente.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_1]:
		'Se desactivarán la sincronización de leads de todas las campañas conectadas a Vecindario.',
	[FACEBOOK.DISCONNECT_ACCOUNT_ITEM_2]:
		'En caso de volver a conectar tus campañas, los clientes potenciales que no se hayan enviado mientras estuvieron desconectadas se sincronizarán de manera retroactiva hasta 90 días atrás.',

	[FACEBOOK.DELETE_ACCOUNT_TITLE]: '¿Eliminar la cuenta {{accountName}}?',
	[FACEBOOK.DELETE_ACCOUNT_TEXT_DESC]:
		'Ten presente que esta acción no elimina tu cuenta en Meta, para ello deberás ingresar a tu cuenta de Business manager de Meta y eliminarla desde allí.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_0]:
		'Todas las campañas que se habían creado dentro de esta cuenta serán eliminadas, para volver a recibir leads de estas campañas deberás volver a conectar una cuenta y volver a crear las campañas de nuevo en Vecindario.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_1]: 'No se perderán datos recopilados anteriormente.',
	[FACEBOOK.DELETE_ACCOUNT_ITEM_2]: 'No se sincronizarán más los leads generados en esta cuenta',
	[FACEBOOK.SESSION_EXPIRATION]: 'Token inválido, vuelva a conectar la cuenta de Meta por favor',
};
