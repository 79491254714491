import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from '@vecindario/vecindario-suite-components';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		borderRadius: '12px',
		paddingTop: '2rem',
		paddingBottom: '5rem',
	},
	overlay: {
		zIndex: '10000',
		backgroundColor: 'rgba(0, 0, 0, 0.75)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
};

const ModalWizard = ({ children, isOpen, onClose }) => {
	return (
		<Modal isOpen={isOpen} onClose={onClose} contentStyle={customStyles.content}>
			{children}
		</Modal>
	);
};

ModalWizard.propTypes = {
	children: PropTypes.node,
	isOpen: PropTypes.bool,
	onClose: PropTypes.func,
};

export default ModalWizard;
