import { Button, Helmet, Icon, RadioButton, Spinner } from '@vecindario/vecindario-suite-components';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import './AccountPage.scss';
import {
	ACCESS_EXPIRATION,
	ACCESS_TOKEN,
	EXPIRES_IN,
	LONG_TOKEN,
	CODE,
	STATE,
	FB_BACK_PROVIDER,
} from '../../../application/constants/facebook';
import { ARROW_GO_BACK_LINE } from '../../../../../shared/application/constants/icons';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { GO_BACK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { accountFBRoute, connectFBRoute } from '../../../infrastructure/routes';
import { postConnectAccount, setLongToken, updateConnectionAccount } from '../../../application/slices/facebook';
import { showAccounts } from '../../../infrastructure/api/account';
import { selectAccount } from '../../../application/selector/facebook';
import { getValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';

const useQueryFromFBUrl = (badUrl, params) => {
	if (badUrl) {
		const url = new URLSearchParams(badUrl.replace('?#', '&'));
		return url.get(params);
	}
	return '';
};

const AccountsTable = ({ accounts, errorMsgAccount, errorPermissions, setaccountSelected }) => {
	const { t } = useTranslation();

	if (errorMsgAccount) {
		return (
			<p>
				{t(FACEBOOK.ACCOUNTS_CONNECT_ERROR, { ns: DOMAIN_NAME })}
				<a
					href="https://mesadeayuda.vecindario.com/es/base-de-conocimiento/no-conecta-la-cuenta-de-facebook-en-la-integraci%C3%B3n-de-meta"
					target="_blank"
					rel="noreferrer"
				>
					{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_8, { ns: DOMAIN_NAME })}
				</a>
			</p>
		);
	}
	if (errorPermissions) return <p>{t(FACEBOOK.ACCOUNTS_CONNECT_ERROR_PERMISSIONS, { ns: DOMAIN_NAME })}</p>;
	if (!accounts?.length) return <p>{t(FACEBOOK.NO_ACCOUNTS, { ns: DOMAIN_NAME })}</p>;

	return (
		<div className="two-columns">
			<div className="header">
				<p>{t(FACEBOOK.COULUNM_ONE, { ns: DOMAIN_NAME })}</p>
				<p>{t(FACEBOOK.COULUNM_TWO, { ns: DOMAIN_NAME })}</p>
			</div>
			{accounts.map((account) => (
				<div key={account.id} className="radio-check">
					<div className="radio-container">
						<RadioButton
							label={account.name}
							name="relation"
							value={account.id}
							handleClick={() => setaccountSelected(account.id)}
						/>
					</div>
					<div className="id-container">
						<p>{account.id.replace('act_', '')}</p>
					</div>
				</div>
			))}
		</div>
	);
};

AccountsTable.propTypes = {
	accounts: PropTypes.array,
	errorMsgAccount: PropTypes.bool,
	errorPermissions: PropTypes.bool,
	setaccountSelected: PropTypes.func,
};

const AccountInfoConnected = ({ accountConnected, slug }) => {
	const { t } = useTranslation();
	const history = useHistory();
	const historyState = { slug };

	const handleConnectAccount = async () => {
		history.push(accountFBRoute(slug), historyState);
	};

	return (
		<>
			<div className="accounts-container">
				<p>{t(FACEBOOK.ACCOUNT_CONNECTED_INFO, { ns: DOMAIN_NAME, campaignName: accountConnected.name })}</p>
			</div>
			<div className="buttons-container">
				<Button variant="default" size="-medium" onClick={handleConnectAccount}>
					{t(FACEBOOK.GOT_TO_ACCOUNT_INFO, { ns: DOMAIN_NAME })}
				</Button>
			</div>
		</>
	);
};

AccountInfoConnected.propTypes = {
	accountConnected: PropTypes.obj,
	slug: PropTypes.string,
};

const substringFBState = (string) => {
	const charIndex = string.indexOf('}') + 1;
	return string.substring(0, charIndex);
};

const SelectAccountPage = () => {
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const accountConnected = useSelector(selectAccount);
	const [accounts, setAccounts] = useState([]);
	const [accountInstance, setAccountInstance] = useState('');
	const [accountSelected, setaccountSelected] = useState('');
	const [loadingAccounts, setLoadingAccounts] = useState(false);
	const [errorMsgAccount, seterrorMsgAccount] = useState(false);
	const [errorPermissions, setErrorPermissions] = useState(false);
	const fbUrl = window.location.href;
	const access_token = useQueryFromFBUrl(fbUrl, ACCESS_TOKEN);
	const access_expiration = useQueryFromFBUrl(fbUrl, ACCESS_EXPIRATION);
	const expires_in = useQueryFromFBUrl(fbUrl, EXPIRES_IN);
	const long_token = useQueryFromFBUrl(fbUrl, LONG_TOKEN);
	const code = useQueryFromFBUrl(fbUrl, CODE);
	const fbState = substringFBState(useQueryFromFBUrl(fbUrl, STATE));
	const stateFBObj = JSON.parse(fbState || '{}');
	const { id: state, slug } = stateFBObj;
	const historyState = { slug };
	const provider_response = useMemo(
		() => ({ access_token, access_expiration, expires_in, long_token, code, state, provider: FB_BACK_PROVIDER }),
		[access_token, access_expiration, expires_in, long_token, code, state],
	);

	useEffect(() => {
		if (access_token && code && !accountConnected?.name) {
			setLoadingAccounts(true);
			showAccounts(slug, provider_response)
				.then(async (response) => {
					const { fb_accounts } = response;
					const currentAccountConected = JSON.parse(getValueFromLocalStorage(ADS_ACCOUNT));
					if (currentAccountConected?.account_id) {
						const resFb_accounts = await fb_accounts.filter((account) => account.id === currentAccountConected?.account_id);
						const data = {
							access_token,
						};
						dispatch(updateConnectionAccount({ slug, accountId: resFb_accounts[0]?.id, data }));
						setAccounts(resFb_accounts);
					} else {
						setAccounts(fb_accounts);
					}
					setAccountInstance(response.ads_account_id);
					setLoadingAccounts(false);
					seterrorMsgAccount(false);
				})
				.catch((error) => {
					if (error?.error) {
						setAccounts([]);
						setLoadingAccounts(false);
						seterrorMsgAccount(true);
						dispatch(
							setMessageToast({
								type: 'error',
								message: error?.error,
							}),
						);
					}
				});
		}
		if (!access_token && !code) {
			setErrorPermissions(true);
		}
	}, [access_token, long_token, code, slug, provider_response, accountConnected, dispatch]);

	useEffect(() => {
		if (long_token && access_token && !accountConnected?.name) {
			dispatch(setLongToken(provider_response));
		}
	}, [dispatch, provider_response, access_token, long_token, accountConnected]);

	const handleFirstStep = () => history.push(connectFBRoute(slug), historyState);

	const handleConnectAccount = async () => {
		try {
			const account = accounts.find((elm) => elm.id === accountSelected);
			const data = {
				account_name: account.name,
				provider_account_id: account.id,
			};
			await dispatch(postConnectAccount({ slug, accountInstance, data }));
			history.push(accountFBRoute(slug), historyState);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
		}
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Facebook Leads - Cuenta'} />
			<Button variant="neutral" size="-small" onClick={handleFirstStep}>
				<Icon icon={ARROW_GO_BACK_LINE} />
				{t(GO_BACK, { ns: DOMAIN_NAME_SHARED })}
			</Button>
			<div className="title-container">
				<h2>Meta</h2>
			</div>
			<div className="connect-fb-account-container">
				<div className="header">
					<p>{t(FACEBOOK.ACCOUNT_HEADER, { ns: DOMAIN_NAME })}</p>
				</div>
				{accountConnected?.name ? (
					<AccountInfoConnected accountConnected={accountConnected} slug={slug} />
				) : (
					<>
						<div className="accounts-container">
							{loadingAccounts ? (
								<Spinner size="x-large" />
							) : (
								<AccountsTable
									accounts={accounts}
									errorMsgAccount={errorMsgAccount}
									errorPermissions={errorPermissions}
									setaccountSelected={setaccountSelected}
								/>
							)}
						</div>
						<div className="buttons-container">
							<Button variant="bordered" size="-medium" onClick={handleFirstStep}>
								{t(FACEBOOK.CANCEL_BUTTON, { ns: DOMAIN_NAME })}
							</Button>
							<Button variant="default" size="-medium" onClick={handleConnectAccount} disabled={!accountSelected}>
								{t(FACEBOOK.CONNECT_BUTTON, { ns: DOMAIN_NAME })}
							</Button>
						</div>
					</>
				)}
			</div>
		</>
	);
};

export default SelectAccountPage;
