import React, { useMemo } from 'react';
import { BoardBlock, Card, Icon, i18nInstance } from '@vecindario/vecindario-suite-components';
import {
	setMessageToast,
	getCurrentProject,
	getModulesByProject,
	getCurrentUser,
} from '@vecindario/suite-dashboard-layout-lib';
import './BoardBody.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICON_CONTACTS_BOOK_LINE, ICON_PHONE_LINE } from '../../../../../shared/application/constants/icons';
import {
	opportunitiesReserved,
	opportunitiesDiscarded,
	opportunitiesInterested,
	opportunitiesVisited,
	opportunitiesDesisted,
	separatedOpportunities,
	opportunitiesSearchResult,
	opportunitiesSearchField,
} from '../../../application/selectors/opportunities';
import { LAST_SEARCH_VALUE } from '../../../../../shared/application/constants/application';
import {
	getDatesDifference,
	setValueToSessionStorage,
} from '../../../../../shared/application/helpers/common-functions';
import { putRequestStageOpportunities } from '../../../application/slices/opportunities';
import { opportunityHistoryRoute } from '../../../../opportunityHistory/infrastructure/routes';
import { STAGE } from '../../../application/constants/opportunities';
import { RESERVATION_KEY } from '../../../../../shared/application/constants/modules_keys';
import {
	STAGE_CONTACT,
	STAGE_EXTERNAL_CONTACT_REQUEST,
	STAGE_EXTERNAL_CONTACT_ADS,
} from '../../../../opportunityHistory/application/constants/opportunityHistory';
import { DIRECTOR_ROLE } from '../../../../integrations/application/constants/inventory';
import { BOARD_COLUMNS, INVALID_ACTION, TODAY } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';

function BoardBody() {
	const dispatch = useDispatch();
	const history = useHistory();
	const { t } = useTranslation();
	const { slug } = useParams();
	const interested = useSelector(opportunitiesInterested);
	const visited = useSelector(opportunitiesVisited);
	const reserved = useSelector(opportunitiesReserved);
	const separated = useSelector(separatedOpportunities);
	const discarded = useSelector(opportunitiesDiscarded);
	const desisted = useSelector(opportunitiesDesisted);
	const modulesByProject = useSelector(getModulesByProject);
	const opportunities = useSelector(opportunitiesSearchResult);
	const currentUser = useSelector(getCurrentUser);
	const searchValue = useSelector(opportunitiesSearchField);

	const boardBlocks = useMemo(() => {
		const blocks = [
			{
				...interested,
				title: t(`${BOARD_COLUMNS.INTERESTED}`, { ns: DOMAIN_NAME }),
			},
			{
				...visited,
				title: t(`${BOARD_COLUMNS.VISITED}`, { ns: DOMAIN_NAME }),
			},
			{
				...reserved,
				title: t(`${BOARD_COLUMNS.RESERVED}`, { ns: DOMAIN_NAME }),
			},
			{
				...separated,
				title: t(`${BOARD_COLUMNS.SEPARATED}`, { ns: DOMAIN_NAME }),
			},
			{
				...discarded,
				title: t(`${BOARD_COLUMNS.DISCARDED}`, { ns: DOMAIN_NAME }),
			},
		];
		const showDesisted =
			modulesByProject.map((module) => module.name).includes(RESERVATION_KEY) ||
			reserved.items.length > 0 ||
			desisted.items.length > 0;
		showDesisted && blocks.push({ ...desisted, title: t(`${BOARD_COLUMNS.DESISTED}`, { ns: DOMAIN_NAME }) });
		return blocks;
	}, [t, interested, visited, reserved, discarded, desisted, modulesByProject, separated]);
	const currentProject = useSelector(getCurrentProject);

	const errorToast = () => {
		dispatch(
			setMessageToast({
				type: 'error',
				message: t(INVALID_ACTION, { ns: DOMAIN_NAME }),
			}),
		);
	};

	const setSearchValue = (id, stage) => {
		const { items } = visited;
		const itemState = items.find((item) => item.id === id);
		const fullNameUser = `${currentUser?.user_detail?.name} ${currentUser?.user_detail?.lastname}`.trim();
		const userRole = currentProject?.role;
		const notAssignedAdviser = opportunities.find(
			(oc) => oc.id === id && oc.asesor !== null && oc.asesor !== fullNameUser,
		);

		if (notAssignedAdviser && userRole !== DIRECTOR_ROLE) {
			errorToast();
		} else if (itemState && [STAGE.INTERESTED, STAGE.RESERVED].includes(stage)) {
			errorToast();
		} else if ([STAGE.SEPARATED, STAGE.RESERVED, STAGE.DESISTED].includes(stage)) {
			errorToast();
		} else {
			dispatch(putRequestStageOpportunities({ id, slug, stage }));
		}
	};

	const handleRoute = (id) => {
		setValueToSessionStorage(LAST_SEARCH_VALUE, searchValue);
		history.push(opportunityHistoryRoute(slug, id));
	};

	const getFullName = (user) => `${user?.first_name || ''} ${user?.last_name || ''}`;

	return (
		<div className="dashboard-body-container">
			{boardBlocks.map((block, index) => (
				<BoardBlock
					idElement={block.id}
					title={block.title}
					badge={block.badge}
					key={index}
					itemDraggable={block.itemDraggable}
					onDropEvent={setSearchValue}
				>
					{block.items.map((item, key) => (
						<Card
							id={item.id}
							key={key}
							title={getDatesDifference(item.last_modification, i18nInstance?.language || 'es', t(TODAY, { ns: DOMAIN_NAME }))}
							classNameCard="content-card"
							classNameCardHead="head-content-card"
							onClick={() => handleRoute(item.id)}
						>
							<div className="body-content-card">
								{[STAGE_CONTACT, STAGE_EXTERNAL_CONTACT_REQUEST].includes(item.last_action) && (
									<span className="contact-badge"></span>
								)}
								{item.last_action === STAGE_CONTACT && <span className="contact-badge"></span>}
								{item.last_action === STAGE_EXTERNAL_CONTACT_ADS && <span className="contact-badge"></span>}
								<p className="name">{getFullName(item?.user_snapshot_detail)}</p>
								<small className="other">
									<Icon icon={ICON_PHONE_LINE} aditionalClassName="icon" />
									{item?.user_snapshot_detail?.mobile}
								</small>
								<small className="other">
									<Icon icon={ICON_CONTACTS_BOOK_LINE} aditionalClassName="icon" />
									<span className="other-text">{item?.user_snapshot_detail?.email}</span>
								</small>
							</div>
						</Card>
					))}
				</BoardBlock>
			))}
		</div>
	);
}

export default BoardBody;
