import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SERVER_SIDE_ERROR } from '../../../../shared/application/constants/messages/error-messages';

import {
	activateSincoIntegration,
	activateSincoIntegrationWithCompany,
	activateSincoIntegrationWithProject,
	deactivateSincoIntegration,
	getActiveSincoIntegration,
} from '../../infrastructure/Api/integrations';

export const initialState = {
	activeCompany: null,
	activeProject: null,
	companies: null,
	isIntegrationActive: false,
	error: '',
	loading: false,
	projects: null,
	synchronizedTowers: null,
	synchronizedProperties: null,
	showCompaniesModal: false,
	step: 1,
	url: '',
	password: null,
	username: null,
};

export const postActivateIntegration = createAsyncThunk(
	'sincoContactIntegration/activateIntegration',
	async ({ slug, url, password, username }, { rejectWithValue }) => {
		try {
			return await activateSincoIntegration(slug, { url, password, username });
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const postActivateIntegrationWithCompany = createAsyncThunk(
	'sincoContactIntegration/activateIntegrationWithCompany',
	async ({ slug, url, activeCompany }, { rejectWithValue }) => {
		try {
			const data = {
				url,
				origin_id: activeCompany.IdOrigen,
				company_id: activeCompany.IdEmpresa,
			};
			return await activateSincoIntegrationWithCompany(slug, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const postActivateIntegrationWithProject = createAsyncThunk(
	'sincoContactIntegration/activateIntegrationWithProject',
	async ({ slug, activeProject }, { rejectWithValue }) => {
		try {
			const data = {
				id_macroproject: activeProject.id,
			};
			return await activateSincoIntegrationWithProject(slug, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const deleteDeactivateIntegration = createAsyncThunk(
	'sincoContactIntegration/deactivateIntegration',
	async ({ slug }, { rejectWithValue }) => {
		try {
			return await deactivateSincoIntegration(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getActiveIntegration = createAsyncThunk(
	'sincoIntegration/getActiveIntegration',
	async ({ slug }, { rejectWithValue }) => {
		try {
			return await getActiveSincoIntegration(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const sincoContactIntegration = createSlice({
	name: 'sincoContactIntegration',
	initialState,
	reducers: {
		finishIntegration(state) {
			state.projects = initialState.projects;
			state.step = initialState.step;
			state.loading = false;
			state.showCompaniesModal = false;
			state.activeCompany = initialState.activeCompany;
			state.activeProject = initialState.activeProject;
			state.companies = initialState.companies;
			state.error = initialState.error;
			state.isIntegrationActive = true;
		},
		resetState() {
			return initialState;
		},
		setActiveCompany(state, { payload }) {
			state.activeCompany = payload;
		},
		setActiveProject(state, { payload }) {
			state.activeProject = payload;
		},
		setError(state, { payload }) {
			state.error = payload;
		},
		setIsIntegrationActive(state, { payload }) {
			if (payload?.contactIntegration && !payload?.contactIntegration.isDeleted) {
				state.isIntegrationActive = payload;
			}
		},
		setLoading(state, { payload }) {
			state.loading = payload;
		},
		setShowCompaniesModal(state, { payload }) {
			state.showCompaniesModal = payload;
		},
		setUrl(state, { payload }) {
			state.url = payload;
		},
		setFormData(state, { payload }) {
			const { name, value } = payload;
			state[name] = value;
		},
	},
	extraReducers: {
		[postActivateIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postActivateIntegration.fulfilled]: (state, { payload }) => {
			state.companies = payload;
			state.showCompaniesModal = true;
			state.loading = false;
		},
		[postActivateIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},

		[postActivateIntegrationWithCompany.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postActivateIntegrationWithCompany.fulfilled]: (state, { payload }) => {
			state.projects = payload;
			state.step += 1;
			state.loading = false;
		},
		[postActivateIntegrationWithCompany.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},

		[postActivateIntegrationWithProject.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postActivateIntegrationWithProject.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.error = '';
			state.isIntegrationActive = true;
			state.step += 1;
			state.synchronizedTowers = payload?.towersNumber;
			state.synchronizedProperties = payload?.propertiesNumber;
		},
		[postActivateIntegrationWithProject.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},

		[deleteDeactivateIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[deleteDeactivateIntegration.fulfilled]: () => {
			return initialState;
		},
		[deleteDeactivateIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
		[getActiveIntegration.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[getActiveIntegration.fulfilled]: (state, { payload }) => {
			if (payload?.contactIntegration?.id && !payload?.contactIntegration?.isDeleted) {
				state.isIntegrationActive = true;
			} else {
				state.isIntegrationActive = false;
			}
			state.loading = false;
		},
		[getActiveIntegration.rejected]: (state) => {
			state.loading = false;
			state.error = SERVER_SIDE_ERROR;
		},
	},
});

export const {
	finishIntegration,
	resetState,
	setActiveCompany,
	setActiveProject,
	setError,
	setIsIntegrationActive,
	setLoading,
	setShowCompaniesModal,
	setUrl,
	setFormData,
} = sincoContactIntegration.actions;

export default sincoContactIntegration.reducer;
