import React, { useEffect } from 'react';
import { Button, Helmet, Icon } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { GO_BACK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { ARROW_GO_BACK_LINE } from '../../../../../shared/application/constants/icons';
import './ConnectFBPage.scss';
import { META_LOGO } from '../../../application/constants/logos';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { generateHex16, removeValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { FACEBOOK_POP_UP } from '../../../application/constants/facebook';
import { FRONT_URL } from '../../../../../shared/application/constants/env';
import { integrationsRoute } from '../../../../integrations/infrastructure/routes';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';
import { setInitialState } from '../../../application/slices/facebook';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';

const ConnectFBPage = () => {
	const { slug } = useParams();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const prevSlug = history.location.state?.slug;
	const historyState = { slug };

	useEffect(() => {
		if (prevSlug !== slug) {
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(setInitialState());
			history.push(opportunityRoute(slug));
		}
	}, [prevSlug, slug, dispatch, history]);

	const handleBack = () => history.push(integrationsRoute(slug), historyState);

	const handleConectFB = () => {
		const id = generateHex16();
		const redirectUri = `${FRONT_URL}proyectos/cuenta-fb/seleccionar`;
		const state = { id, slug };
		const facebookPopUpUrl = FACEBOOK_POP_UP(redirectUri, state);
		window.open(facebookPopUpUrl, '_blank');
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Facebook Leads - Integración'} />
			<Button variant="neutral" size="-small" onClick={handleBack}>
				<Icon icon={ARROW_GO_BACK_LINE} />
				{t(GO_BACK, { ns: DOMAIN_NAME_SHARED })}
			</Button>
			<div className="connect-fb-leads-container">
				<div className="header">
					<img src={META_LOGO} alt="meta logo" />
					<Button size="-small" onClick={handleConectFB}>
						{t(FACEBOOK.BUTTON_TEXT, { ns: DOMAIN_NAME })}
					</Button>
				</div>
				<div className="text">
					<p>{t(FACEBOOK.TEXT_INFO, { ns: DOMAIN_NAME })}</p>
				</div>
			</div>
		</>
	);
};

export default ConnectFBPage;
