import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Icon, TextTag, ToggleSwitch, Tooltip } from '@vecindario/vecindario-suite-components';
import {
	ACCOUNT_ID_ROW,
	ADVERTISIG_ACCOUNT_ROW,
	CONNECTED_BY_ROW,
	CONNECTION_DATE_ROW,
	CONNECTION_ROW,
	DELETE_ROW,
} from '../../../../application/constants/facebookAccounts';
import { ACCOUNTS_TABLE_HEADER, FACEBOOK } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import TableIntegration from '../../TableIntegration';
import { selectAccount } from '../../../../application/selector/facebook';
import { deleteAccountRoute, disconnectAccountRoute } from '../../../../infrastructure/routes';
import { setAccountSelected } from '../../../../application/slices/facebook';
import { generateHex16 } from '../../../../../../shared/application/helpers/common-functions';
import { FRONT_URL } from '../../../../../../shared/application/constants/env';
import { FACEBOOK_POP_UP } from '../../../../application/constants/facebook';

const TableAccounts = () => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const history = useHistory();
	const account = useSelector(selectAccount);
	const dispatch = useDispatch();
	const historyState = { slug };

	const headersTable = {
		[ADVERTISIG_ACCOUNT_ROW]: t(ACCOUNTS_TABLE_HEADER[ADVERTISIG_ACCOUNT_ROW], { ns: DOMAIN_NAME }),
		[ACCOUNT_ID_ROW]: t(ACCOUNTS_TABLE_HEADER[ACCOUNT_ID_ROW], { ns: DOMAIN_NAME }),
		[CONNECTED_BY_ROW]: t(ACCOUNTS_TABLE_HEADER[CONNECTED_BY_ROW], { ns: DOMAIN_NAME }),
		[CONNECTION_DATE_ROW]: t(ACCOUNTS_TABLE_HEADER[CONNECTION_DATE_ROW], { ns: DOMAIN_NAME }),
		[CONNECTION_ROW]: t(ACCOUNTS_TABLE_HEADER[CONNECTION_ROW], { ns: DOMAIN_NAME }),
		[DELETE_ROW]: t(ACCOUNTS_TABLE_HEADER[DELETE_ROW], { ns: DOMAIN_NAME }),
	};

	const dataTable = [
		{
			advertising_account: account?.name,
			account_id: account?.account_id,
			name: account?.user_creator_name,
			email: account?.user_creator_email,
			connection_date: account?.connection_date,
			connection: account?.is_active,
			delete: '',
		},
	];

	const handleConectFB = () => {
		const id = generateHex16();
		const redirectUri = `${FRONT_URL}proyectos/cuenta-fb/seleccionar`;
		const state = { id, slug };
		const facebookPopUpUrl = FACEBOOK_POP_UP(redirectUri, state);
		window.open(facebookPopUpUrl, '_blank');
	};

	const handleConnection = (select_account) => {
		if (select_account.connection) {
			dispatch(setAccountSelected(select_account));
			history.push(disconnectAccountRoute(slug), historyState);
		} else {
			handleConectFB();
		}
	};

	const handleDelete = (select_account) => {
		dispatch(setAccountSelected(select_account));
		history.push(deleteAccountRoute(slug), historyState);
	};

	const rowsDataTable = () => {
		if (account) {
			return dataTable?.map((item) => {
				const row = {
					[ADVERTISIG_ACCOUNT_ROW]: (
						<div className="advertising_account">
							<Tooltip position="right" text={t(FACEBOOK.ACCOUNTS_CONNECT_TOOLTIP, { ns: DOMAIN_NAME })}>
								<Icon icon="icon ri-error-warning-line" />
							</Tooltip>
							<TextTag tag="span" variant="-body-sm" fw="bold">
								{item?.advertising_account}
							</TextTag>
						</div>
					),
					[ACCOUNT_ID_ROW]: (
						<div className="account_id">
							<TextTag tag="span" variant="-body-sm">
								{item?.account_id}
							</TextTag>
						</div>
					),
					[CONNECTED_BY_ROW]: (
						<div className="connected_by">
							<TextTag tag="span" variant="-body-sm">
								{item?.name}
							</TextTag>
							<a href={`mailto: ${item?.email}`}>
								<TextTag tag="p" variant="-body-xs">
									{item?.email}
								</TextTag>
							</a>
						</div>
					),
					[CONNECTION_DATE_ROW]: (
						<div className="connection-date">
							<TextTag tag="span" variant="-body-sm">
								{item?.connection_date}
							</TextTag>
							{/* TODO: No remove */}
							{/* <TextTag tag="p" variant="-body-xs">
								8:56 p.m
							</TextTag> */}
						</div>
					),
					[CONNECTION_ROW]: (
						<ToggleSwitch id={`${item?.account_id}`} value={account.is_active} onChange={() => handleConnection(item)} />
					),
					[DELETE_ROW]: (
						<Button className="delete-cta" onClick={() => handleDelete(item)}>
							<Icon icon="ri-delete-bin-2-line" size="small" />
						</Button>
					),
				};

				return row;
			});
		}

		return [];
	};

	return <TableIntegration headersTable={headersTable} dataTable={dataTable} rowsDataTable={rowsDataTable} />;
};

export default TableAccounts;
