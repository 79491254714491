import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import {
	activeCampaign,
	connectAccount,
	connectCampaign,
	deleteAccount,
	deleteCampaign,
	disconnectAccount,
	listCampaigns,
	reconnectAccount,
	showAccountConnected,
	showCampaigns,
} from '../../infrastructure/api/account';
import { AUTH_EXCEPTION } from '../constants/facebookErrors';

export const initialState = {
	isLoading: false,
	account: {},
	accountSelected: {},
	loginConfig: {},
	campaigns: [],
	listCampaignsAds: [],
	campaignSelected: {},
	error: null,
};

export const postConnectAccount = createAsyncThunk(
	'request/facebook_leads/connect_account',
	async ({ slug, accountInstance, data }, { rejectWithValue }) => {
		try {
			return await connectAccount(slug, accountInstance, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getAccountConnected = createAsyncThunk(
	'request/facebook_leads/get_account_connected',
	async ({ slug, provider }, { rejectWithValue }) => {
		try {
			return await showAccountConnected(slug, provider);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const updateDisconnectAccount = createAsyncThunk(
	'request/facebook_leads/update_disconnect_account',
	async ({ slug, accountId }, { rejectWithValue, dispatch }) => {
		try {
			const response = await disconnectAccount(slug, accountId);
			dispatch(
				setMessageToast({
					type: 'success',
					message: 'Cambios guardados con éxito',
				}),
			);
			return response;
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: 'Error al desconectar la cuenta',
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const updateConnectionAccount = createAsyncThunk(
	'request/facebook_leads/update_connection_account',
	async ({ slug, accountId, data }, { rejectWithValue, dispatch }) => {
		try {
			const response = await reconnectAccount(slug, accountId, data);
			dispatch(
				setMessageToast({
					type: 'success',
					message: 'Cambios guardados con éxito',
				}),
			);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const deleteAccountSelected = createAsyncThunk(
	'request/facebook_leads/delete_account',
	async ({ slug, accountId }, { rejectWithValue, dispatch }) => {
		try {
			const response = await deleteAccount(slug, accountId);
			dispatch(
				setMessageToast({
					type: 'success',
					message: 'Cambios guardados con éxito',
				}),
			);
			return response;
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const postConnectCampaign = createAsyncThunk(
	'request/facebook_leads/connect_campaign',
	async ({ slug, provider_account_id, data }, { rejectWithValue, dispatch }) => {
		try {
			return await connectCampaign(slug, provider_account_id, data);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const getCampaigns = createAsyncThunk(
	'request/facebook_leads/get_campaigns',
	async ({ slug, provider_account_id }, { rejectWithValue }) => {
		try {
			return showCampaigns(slug, provider_account_id);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const getListCampaignsAds = createAsyncThunk(
	'request/facebook_leads/get_list_campaigns_ads',
	async ({ slug, account }, { rejectWithValue, dispatch }) => {
		const { provider, account_id, access_token, id: accountId } = account;
		try {
			return await listCampaigns(slug, provider, account_id, access_token);
		} catch (error) {
			if (error?.type === AUTH_EXCEPTION) {
				dispatch(deleteAccountSelected({ slug, accountId }));
			}
			return rejectWithValue(error);
		}
	},
);

export const updateConnectionCampaign = createAsyncThunk(
	'request/facebook_leads/update_connection_campaign',
	async ({ slug, provider_account_id, campaignId }, { rejectWithValue, dispatch }) => {
		try {
			const response = await activeCampaign(slug, provider_account_id, campaignId);
			dispatch(
				setMessageToast({
					type: 'success',
					message: 'Cambios guardados con éxito',
				}),
			);
			return response;
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const deleteCampaignSelected = createAsyncThunk(
	'request/facebook_leads/delete_campaign',
	async ({ slug, campaignId }, { rejectWithValue, dispatch }) => {
		try {
			const response = await deleteCampaign(slug, campaignId);
			dispatch(
				setMessageToast({
					type: 'success',
					message: 'Cambios guardados con éxito',
				}),
			);
			return response;
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

const Facebook = createSlice({
	name: 'facebook',
	initialState,
	reducers: {
		setLongToken: (state, { payload }) => {
			state.loginConfig = {
				longToken: payload.long_token,
				accessToken: payload.access_token,
				state: payload.state,
			};
		},
		setAccountSelected: (state, { payload }) => {
			state.accountSelected = payload;
		},
		setCampaignSelected: (state, { payload }) => {
			state.campaignSelected = payload;
		},
		setInitialState: (state) => {
			state.isLoading = false;
			state.account = {};
			state.accountSelected = {};
			state.loginConfig = {};
			state.campaigns = [];
			state.listCampaignsAds = [];
			state.campaignSelected = {};
			state.error = null;
		},
	},
	extraReducers: {
		[postConnectAccount.pending]: (state) => {
			state.isLoading = true;
		},
		[postConnectAccount.rejected]: (state) => {
			state.isLoading = false;
		},
		[postConnectAccount.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.account = payload;
		},
		[getAccountConnected.pending]: (state) => {
			state.isLoading = true;
		},
		[getAccountConnected.rejected]: (state) => {
			state.isLoading = false;
		},
		[getAccountConnected.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.account = payload;
		},
		[postConnectCampaign.pending]: (state) => {
			state.loading = true;
			state.error = '';
		},
		[postConnectCampaign.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.campaigns = [...state.campaigns, payload];
		},
		[postConnectCampaign.rejected]: (state) => {
			state.loading = false;
		},
		[getCampaigns.pending]: (state) => {
			state.isLoading = true;
		},
		[getCampaigns.rejected]: (state) => {
			state.isLoading = false;
		},
		[getCampaigns.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.campaigns = payload;
		},
		[getListCampaignsAds.pending]: (state) => {
			state.isLoading = true;
		},
		[getListCampaignsAds.rejected]: (state, { payload }) => {
			state.isLoading = false;
			state.error = payload?.type;
		},
		[getListCampaignsAds.fulfilled]: (state, { payload = {} }) => {
			state.isLoading = false;
			const { campaigns } = payload;
			state.listCampaignsAds = campaigns;
		},
		[updateDisconnectAccount.pending]: (state) => {
			state.isLoading = true;
		},
		[updateDisconnectAccount.rejected]: (state) => {
			state.isLoading = false;
		},
		[updateDisconnectAccount.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.campaigns = state.campaigns.map((campaign) => {
				campaign.is_active = false;
				return campaign;
			});
			const { is_active } = payload;
			state.account.is_active = is_active;
		},
		[updateConnectionAccount.pending]: (state) => {
			state.isLoading = true;
		},
		[updateConnectionAccount.rejected]: (state) => {
			state.isLoading = false;
		},
		[updateConnectionAccount.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.account = payload;
		},
		[deleteAccountSelected.pending]: (state) => {
			state.isLoading = true;
		},
		[deleteAccountSelected.rejected]: (state) => {
			state.isLoading = false;
		},
		[deleteAccountSelected.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.account = {};
		},
		[updateConnectionCampaign.pending]: (state) => {
			state.isLoading = true;
		},
		[updateConnectionCampaign.rejected]: (state) => {
			state.isLoading = false;
		},
		[updateConnectionCampaign.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			const { id } = payload;
			state.campaigns = state.campaigns.map((campaign) => {
				if (campaign.id === id) {
					campaign.is_active = !campaign.is_active;
				}
				return campaign;
			});
		},
		[deleteCampaignSelected.pending]: (state) => {
			state.isLoading = true;
		},
		[deleteCampaignSelected.rejected]: (state) => {
			state.isLoading = false;
		},
		[deleteCampaignSelected.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.campaigns = state.campaigns.filter((campaign) => campaign.id !== payload.id);
		},
	},
});

export const { setLongToken, setAccountSelected, setCampaignSelected, setInitialState } = Facebook.actions;

export default Facebook.reducer;
