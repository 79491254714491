import { combineReducers } from 'redux';
import { slices } from '@vecindario/suite-dashboard-layout-lib';
import opportunityHistory from '../../../domains/opportunityHistory/application/slices/history';
import opportunities from '../../../domains/opportunities/application/slices/opportunities';
import facebook from '../../../domains/facebook/application/slices/facebook';
import sincoContactIntegration from '../../../domains/integrations/application/slices/sincoContactIntegration';
import sicoContactIntegration from '../../../domains/integrations/application/slices/sicoContactIntegration';
import settings from '../../../domains/Settings/application/slices/settings';
import notes from '../../../domains/opportunityNotes/application/slices/notes';
import hubspotIntegration from '../../../domains/integrations/application/slices/hubspotIntegration';

export default combineReducers({
	...slices,
	opportunityHistory,
	opportunities,
	sincoContactIntegration,
	sicoContactIntegration,
	settings,
	notes,
	hubspotIntegration,
	facebook,
});
