import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import { ICON_INFORMATION_LINE } from '../../../../../shared/application/constants/icons';
import { history } from '../../../../../shared/application/helpers/history';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';
import {
	selectCurrentOptionSelected,
	selectOpportunityUserDetails,
	selectRequestHistory,
} from '../../../application/selector/history';
import { CONFIRM_PURCHASE } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { postConfirmPurchase } from '../../../infrastructure/api/history';
import {
	appendOpportunityHistories,
	setCurrentHistory,
	setCurrentOptionSelected,
} from '../../../application/slices/history';

import './ConfirmPurchasePage.scss';

const ConfirmPurchasePage = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const opportunity = useSelector(selectRequestHistory);
	const optionSnapshot = useSelector(selectCurrentOptionSelected);
	const userSnapshotDetail = useSelector(selectOpportunityUserDetails);
	const [isLoading, setIsLoading] = useState(false);

	const handleConfirmPurchase = async () => {
		setIsLoading(true);

		const data = {
			commercial_opportunity_id: opportunity.id,
			user_snapshot_detail: {
				email: optionSnapshot?.user_email,
				first_name: userSnapshotDetail?.first_name || userSnapshotDetail?.name,
				last_name: userSnapshotDetail?.last_name || userSnapshotDetail?.lastname,
				identification_type: optionSnapshot?.document_type,
				identification: optionSnapshot?.identification,
				mobile: userSnapshotDetail?.mobile,
			},
		};
		postConfirmPurchase(opportunity?.project_slug, optionSnapshot?.id, data)
			.then((resp) => {
				// Add separated by option opportunity history
				dispatch(appendOpportunityHistories(resp?.opportunity_history));

				// Update optioned_property opportunity history action_instance
				const historyWithOption = opportunity?.opportunity_histories?.map((elem) => {
					return elem?.action_instance?.id === resp?.option_snapshot?.id
						? { ...elem, action_instance: resp?.option_snapshot }
						: elem;
				});

				// Create new opportunity data with option updated in histories and separated_by_option history
				const opportunityData = {
					...opportunity,
					opportunity_histories: [...historyWithOption, resp?.opportunity_history],
				};

				// Disptach slice to update current opportunity
				dispatch(setCurrentHistory(opportunityData));

				// Set current option selected
				dispatch(setCurrentOptionSelected(resp?.option_snapshot));

				// Notify user and redirect
				dispatch(setMessageToast({ type: 'success', message: t(CONFIRM_PURCHASE.SUCCESS_MESSAGE, { ns: DOMAIN_NAME }) }));
				history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id));
			})
			.catch((err) => {
				dispatch(setMessageToast({ type: 'error', message: err }));
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	return (
		<div className="confirm-purchase-page">
			<TextTag tag="p" fw="bold" variant="-body-sm" className="header color-secondary">
				<Icon icon={ICON_INFORMATION_LINE} size="medium" />

				<TextTag tag="p" variant="-body" fw="bold">
					{t(CONFIRM_PURCHASE.TITLE, { ns: DOMAIN_NAME })}
				</TextTag>
			</TextTag>

			<div className="content">
				<TextTag tag="p" variant="-body-sm">
					{t(CONFIRM_PURCHASE.DESCRIPTION, { ns: DOMAIN_NAME })}
				</TextTag>

				<div className="buttons-wrapper">
					<Button variant="default" onClick={handleConfirmPurchase}>
						{t(CONFIRM_PURCHASE.YES_SURE, { ns: DOMAIN_NAME })}
					</Button>

					<Button
						disabled={isLoading}
						variant="bordered"
						onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
					>
						{t(CONFIRM_PURCHASE.CANCEL, { ns: DOMAIN_NAME })}
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmPurchasePage;
