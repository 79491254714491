import React, { useEffect, useState } from 'react';
import {
	setSideBarItems,
	getCurrentProject,
	getCurrentUser,
	getLanguage,
	setHideSidebar,
	getMyProjects,
} from '@vecindario/suite-dashboard-layout-lib';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Router from './shared/presentation/Router';
import sidebarOptions, {
	DASHBOARD_SIDEBAR_KEY,
	INTEGRATIONS_SIDEBAR_KEY,
	SETTINGS_SIDEBAR_KEY,
	TOOLS_SIDEBAR_KEY,
} from './shared/application/constants/sideBard';
import AlertVersionPlan from './shared/presentation/components/AlertVersionPlan';
import { capitalize, getFirstWord, removeValueFromSessionStorage } from './shared/application/helpers/common-functions';
import { LICENCE_FREE_PLAN, LAST_SEARCH_VALUE } from './shared/application/constants/application';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from './shared/infrastructure/i18n/locales';
import { DASHBOARD_SIDEBAR } from './shared/infrastructure/i18n/locales/translation_keys';
import { propertyOptionSnapshotsQueue } from './domains/property/infrastructure/routes';
import { initializeTagManager } from './shared/infrastructure/gtm/index';
import { GTM_ID_SUITE, GTM_NO_SCRIPT_ID, GTM_SCRIPT_ID } from './shared/application/constants/env';

const App = () => {
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const currentProject = useSelector(getCurrentProject);
	const myProjects = useSelector(getMyProjects);
	const currentUser = useSelector(getCurrentUser);
	const currentLanguage = useSelector(getLanguage);
	const [gtmInitialized, setGtmInitialized] = useState(false);
	const isPropertyOptionsQueueRoute = useRouteMatch(propertyOptionSnapshotsQueue());

	const setUserName = () => {
		return currentUser.user_detail?.name && currentUser.user_detail?.lastname
			? `${capitalize(currentUser.user_detail.name)} ${capitalize(getFirstWord(currentUser.user_detail.lastname))}`
			: '';
	};

	useEffect(() => {
		if (currentLanguage) {
			i18n.changeLanguage(currentLanguage);
		}
	}, [i18n, currentLanguage]);

	useEffect(() => {
		if (currentProject && myProjects.length) {
			const SIDEBAR_OPTIONS = {
				[DASHBOARD_SIDEBAR_KEY]: t(DASHBOARD_SIDEBAR[DASHBOARD_SIDEBAR_KEY], { ns: DOMAIN_NAME_SHARED }),
				[SETTINGS_SIDEBAR_KEY]: t(DASHBOARD_SIDEBAR[SETTINGS_SIDEBAR_KEY], { ns: DOMAIN_NAME_SHARED }),
				[INTEGRATIONS_SIDEBAR_KEY]: t(DASHBOARD_SIDEBAR[INTEGRATIONS_SIDEBAR_KEY], { ns: DOMAIN_NAME_SHARED }),
				[TOOLS_SIDEBAR_KEY]: t(DASHBOARD_SIDEBAR[TOOLS_SIDEBAR_KEY], { ns: DOMAIN_NAME_SHARED }),
			};
			dispatch(setSideBarItems(sidebarOptions(currentProject?.role, SIDEBAR_OPTIONS)));
		}
	}, [dispatch, currentProject, t, myProjects]);

	useEffect(() => {
		if (isPropertyOptionsQueueRoute) {
			dispatch(setHideSidebar(true));
		} else {
			dispatch(setHideSidebar(false));
		}
	}, [isPropertyOptionsQueueRoute, dispatch]);

	removeValueFromSessionStorage(LAST_SEARCH_VALUE);

	useEffect(() => {
		let suiteScript;
		let suiteNoscript;

		if (!gtmInitialized) {
			setGtmInitialized(true);
			[suiteScript, suiteNoscript] = initializeTagManager(GTM_ID_SUITE, GTM_SCRIPT_ID, GTM_NO_SCRIPT_ID);
		}

		return () => {
			suiteScript && document.head.contains(suiteScript) && document.head.removeChild(suiteScript);
			suiteNoscript && document.body.contains(suiteNoscript) && document.body.removeChild(suiteNoscript);
		};
	}, [currentProject, gtmInitialized]);

	return (
		<>
			<Router />
			{currentProject?.licence_type === LICENCE_FREE_PLAN && (
				<AlertVersionPlan
					countFreeOpportunities={currentProject?.remaining_free_opportunities}
					currentUser={setUserName()}
				/>
			)}
		</>
	);
};

export default App;
