import React, { useEffect } from 'react';
import { Helmet } from '@vecindario/vecindario-suite-components';
import { useParams } from 'react-router-dom';
import { SIMULATOR_EMBEDDED } from '../../../../../../shared/application/constants/env';

const SimulatioEmbeddedPage = () => {
	const { slug } = useParams();

	useEffect(() => {
		const script = document.createElement('script');

		script.src = SIMULATOR_EMBEDDED;
		script.async = true;

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, []);

	return (
		<div>
			<Helmet title={`Simular plan de pagos.`} />
			<div id="simulatorByVecindario" data-projectslug={slug}></div>
		</div>
	);
};

export default SimulatioEmbeddedPage;
