import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Badge, Button, Icon, Spinner, Table, TextTag } from '@vecindario/vecindario-suite-components';
import { getPropertyOptionsQueueService } from '../../../infrastructure/api';
import {
	TYPE_OF_VIEW_FULL,
	TYPE_OF_VIEW_RESUME,
	optionsQueueTableHeaders,
} from '../../../application/constants/optionsQueue';
import { OPTIONS_QUEUE_PAGE, PROPERTY_AVAILABILITIES } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { ARROW_GO_BACK_LINE, ICON_FOLDER } from '../../../../../shared/application/constants/icons';
import { sortBy, useGetQueryParam } from '../../../../../shared/application/helpers/common-functions';
import usePropertyQueue from '../../../application/hooks/usePropertyQueue';
import { GO_BACK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as SHARED_DOMAIN_NAME } from '../../../../../shared/infrastructure/i18n/locales/index';

import './PropetyOptionsQueuePage.scss';

const PropetyOptionsQueuePage = () => {
	const { t } = useTranslation();
	const { slug, propertyId } = useParams();
	const [property, setProperty] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [optionSnapshots, setOptionSnapshots] = useState([]);
	const [typeOfView, setTypeOfView] = useState(TYPE_OF_VIEW_RESUME);
	const queue = usePropertyQueue({ optionSnapshots });
	const returnUrl = useGetQueryParam('return_url');

	useEffect(() => {
		getPropertyOptionsQueueService(slug, propertyId)
			.then((resp) => {
				setProperty(resp?.property);
				setOptionSnapshots(sortBy(resp?.option_snapshots, 'option_order'));
			})
			.catch((err) => console.error(err))
			.finally(() => setIsLoading(false));
	}, [slug, propertyId]);

	const handleOnChangeTypeOfView = () => {
		setTypeOfView((prev) => (prev === TYPE_OF_VIEW_RESUME ? TYPE_OF_VIEW_FULL : TYPE_OF_VIEW_RESUME));
	};

	const handleOnGoBack = () => {
		window.location = returnUrl;
	};

	return (
		<div className="property-options-queue-page">
			{!returnUrl ? null : (
				<Button variant="bordered" className="go-back-cta" size="-small" onClick={handleOnGoBack}>
					<Icon icon={ARROW_GO_BACK_LINE} size="small" />
					{t(GO_BACK, { ns: SHARED_DOMAIN_NAME })}
				</Button>
			)}

			<TextTag fw="bold" variant="-body" className="color-secondary">
				{t(OPTIONS_QUEUE_PAGE.PAGE_TITLE, {
					ns: DOMAIN_NAME,
					towerName: property?.tower_name,
					propertyReference: property?.reference,
				})}
			</TextTag>

			<section className="history-section">
				<header className="header">
					<Icon icon={ICON_FOLDER} size="medium" />

					<TextTag variant="-body-sm" fw="bold" className="color-secondary title">
						{t(OPTIONS_QUEUE_PAGE.SECTION_TITLE, { ns: DOMAIN_NAME })}
					</TextTag>

					<Badge
						text={t(PROPERTY_AVAILABILITIES[property?.availability], { ns: DOMAIN_NAME })}
						className={'separated'}
						variant=""
					/>

					<Button variant="transparent" className="button" onClick={handleOnChangeTypeOfView}>
						{t(
							typeOfView === TYPE_OF_VIEW_RESUME
								? OPTIONS_QUEUE_PAGE.SHOW_ALL_COLUMNS_CTA
								: OPTIONS_QUEUE_PAGE.HIDE_ADVANCED_COLUMNS_CTA,
							{ ns: DOMAIN_NAME },
						)}
					</Button>
				</header>

				{isLoading && (
					<div className="loader-container">
						<Spinner size="x-large" />
					</div>
				)}

				<Table
					striped={false}
					tableKeys={Object.keys(optionsQueueTableHeaders({ typeOfView }))}
					tableHeaders={optionsQueueTableHeaders({ typeOfView })}
					tableData={queue}
					itemsPerPage={10}
					withPagination={true}
				/>
			</section>
		</div>
	);
};

export default PropetyOptionsQueuePage;
