import {
	NO_USER_PERFORMER_CRONTASK,
	NO_USER_PERFORMER_LINK_GENERATION,
	PROPERTY_AVAILABILITY_AVAILABLE,
	PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION,
	PROPERTY_AVAILABILITY_DISABLED,
	PROPERTY_AVAILABILITY_OPTIONED,
	PROPERTY_AVAILABILITY_RESERVED,
	PROPERTY_AVAILABILITY_SEPARATED,
} from '../../application/constants/property';

export const OPTIONS_QUEUE_PAGE = Object.freeze({
	PAGE_TITLE: 'OPTIONS_QUEUE_PAGE_PAGE_TITLE',
	SECTION_TITLE: 'OPTIONS_QUEUE_PAGE_SECTION_TITLE',
	SHOW_ALL_COLUMNS_CTA: 'OPTIONS_QUEUE_PAGE_SHOW_ALL_COLUMNS_CTA',
	HIDE_ADVANCED_COLUMNS_CTA: 'OPTIONS_QUEUE_PAGE_HIDE_ADVANCED_COLUMNS_CTA',
	VIEW_ACTIONS: 'OPTIONS_QUEUE_PAGE_VIEW_ACTIONS',
});

export const PROPERTY_QUEUE_TABLE_HEADERS = Object.freeze({
	ORDER: 'PROPERTY_QUEUE_TABLE_HEADERS_ORDER',
	OPPORTUNITY_NAME: 'PROPERTY_QUEUE_TABLE_HEADERS_NAME_IN_OPPORTUNITY',
	OPTION_STATE: 'PROPERTY_QUEUE_TABLE_HEADERS_OPTION_STATE',
	EXPIRATION_DATE: 'PROPERTY_QUEUE_TABLE_HEADERS_EXPIRATION_DATE',
	OPTION_CREATOR_NAME: 'PROPERTY_QUEUE_TABLE_HEADERS_OPTION_CREATOR_NAME',
	ADVISOR_NAME: 'PROPERTY_QUEUE_TABLE_HEADERS_ADVISOR_NAME',
	OPTION_DATE: 'PROPERTY_QUEUE_TABLE_HEADERS_OPTION_DATE',
	LAST_OPTION_ACTION_DATE: 'PROPERTY_QUEUE_TABLE_HEADERS_LAST_OPTION_ACTION_DATE',
	LAST_OPTION_ACTION_NAME: 'PROPERTY_QUEUE_TABLE_HEADERS_LAST_OPTION_ACTION_NAME',
	EXPIRATION_EXTENDED_NAME: 'PROPERTY_QUEUE_TABLE_HEADERS_EXPIRATION_EXTENDED_NAME',
	EXPIRATION_EXTENDED_DATE_ACTION: 'PROPERTY_QUEUE_TABLE_HEADERS_EXPIRATION_EXTENDED_DATE_ACTION',
	ACTIONS: 'PROPERTY_QUEUE_TABLE_HEADERS_ACTIONS',
});

export const PROPERTY_AVAILABILITIES = Object.freeze({
	[PROPERTY_AVAILABILITY_OPTIONED]: 'PROPERTY_AVAILABILITIES_OPTIONED',
	[PROPERTY_AVAILABILITY_AVAILABLE]: 'PROPERTY_AVAILABILITIES_AVAILABLE',
	[PROPERTY_AVAILABILITY_SEPARATED]: 'PROPERTY_AVAILABILITIES_SEPARATED',
	[PROPERTY_AVAILABILITY_RESERVED]: 'PROPERTY_AVAILABILITIES_RESERVED',
	[PROPERTY_AVAILABILITY_DISABLED]: 'PROPERTY_AVAILABILITIES_DISABLED',
	[PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION]: 'PROPERTY_AVAILABILITIES_BLOCKED_BY_TRANSACTION',
});

export const OPTION_NO_PERFORMER_USER = Object.freeze({
	[NO_USER_PERFORMER_CRONTASK]: 'OPTION_NO_PERFORMER_USER_CRONTASK',
	[NO_USER_PERFORMER_LINK_GENERATION]: 'OPTION_NO_PERFORMER_USER_LINK_GENERATION',
});

export const OPTION_ACTION_ACTION = Object.freeze({
	EXTENDED: 'OPTION_ACTION_ACTION_EXTENDED',
});
