import { createSelector } from '@reduxjs/toolkit';

export const settingsState = (state) => state.settings;

export const projectConfigurationSelector = createSelector(settingsState, (state = {}) => state?.projectConfiguration);
export const loadingSelector = createSelector(settingsState, (state = {}) => state?.isLoading);

export const financialConfiguration = createSelector(
	settingsState,
	(settings) => settings?.projectScoringConfiguration,
);
