import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import {
	urlActivateSicoIntegration,
	urlActivateSincoIntegration,
	urlActivateSincoIntegrationWithCompany,
	urlActivateSincoIntegrationWithProject,
	urlActivateWebhookIntegration,
	urlDeactivateSicoIntegration,
	urlDeactivateSincoIntegration,
	urlDeactivateWebhookIntegration,
	urlGenerateApiToken,
	urlGetActiveSicoIntegration,
	urlGetActiveSincoIntegration,
	urlGeteApiToken,
	urlPostValidationTokenHubspotIntegration,
	urlGetWebhookIntegration,
	urlSendMailIntegration,
	urlGetVecindarioVariables,
	urlGetHubspotVariables,
	urlCreateHubspotIntegration,
	urlGetHubspotIntegration,
	urlChangeTokenHubspotIntegration,
} from './backend-url';

export const activateWebhookIntegration = (projectSlug, url, webhookName) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ url }),
	};
	return fetch(urlActivateWebhookIntegration(projectSlug, webhookName), requestOptions).then(handleResponse);
};

export const sendMailIntegration = (projectSlug, service) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify({ service }),
	};
	return fetch(urlSendMailIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const getWebhookIntegrations = (projectSlug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetWebhookIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const deactivateWebhookIntegration = (projectSlug, webhookName) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
	};
	return fetch(urlDeactivateWebhookIntegration(projectSlug, webhookName), requestOptions).then(handleResponse);
};

export const activateSincoIntegration = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlActivateSincoIntegration(projectSlug)}?is_contact=true`, requestOptions).then(handleResponse);
};

export const activateSincoIntegrationWithCompany = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlActivateSincoIntegrationWithCompany(projectSlug)}?is_contact=true`, requestOptions).then(
		handleResponse,
	);
};

export const activateSincoIntegrationWithProject = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlActivateSincoIntegrationWithProject(projectSlug)}?is_contact=true`, requestOptions).then(
		handleResponse,
	);
};

export const deactivateSincoIntegration = (projectSlug) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlDeactivateSincoIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const getActiveSincoIntegration = (projectSlug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetActiveSincoIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const getApiPublicToken = (projectSlug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGeteApiToken(projectSlug), requestOptions).then(handleResponse);
};

export const generateApiToken = (projectSlug) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
	};
	return fetch(urlGenerateApiToken(projectSlug), requestOptions).then(handleResponse);
};

export const activateSicoIntegration = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlActivateSicoIntegration(projectSlug)}`, requestOptions).then(handleResponse);
};

export const deactivateSicoIntegration = (projectSlug) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlDeactivateSicoIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const activeSicoIntegration = (projectSlug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetActiveSicoIntegration(projectSlug), requestOptions).then(handleResponse);
};

export const validationTokenHubspotIntegration = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlPostValidationTokenHubspotIntegration(projectSlug)}`, requestOptions).then(handleResponse);
};

export const getVecindarioVariables = (lang) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetVecindarioVariables(lang), requestOptions).then(handleResponse);
};

export const getHubspotVariables = (slug, api_key) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetHubspotVariables(slug, api_key), requestOptions).then(handleResponse);
};

export const createHubspotIntegration = (projectSlug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(`${urlCreateHubspotIntegration(projectSlug)}`, requestOptions).then(handleResponse);
};

export const getHubspotIntegration = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetHubspotIntegration(slug), requestOptions).then(handleResponse);
};

export const changeTokenHubspotIntegration = (slug) => {
	const requestOptions = {
		method: 'DELETE',
		headers: authHeader(),
	};
	return fetch(urlChangeTokenHubspotIntegration(slug), requestOptions).then(handleResponse);
};
export default {};
