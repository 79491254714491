import {
	PENDING_TRANSACTIONS,
	PENDING_TRANSACTIONS_BY_PROPERTY,
} from '../../../../../domains/opportunityHistory/application/constants/transaction';
import {
	DASHBOARD_SIDEBAR_KEY,
	INTEGRATIONS_SIDEBAR_KEY,
	SETTINGS_SIDEBAR_KEY,
	TOOLS_SIDEBAR_KEY,
} from '../../../../application/constants/sideBard';
import {
	OPPORTUNITIES_TITLE,
	GO_BACK,
	SEPARATED,
	RESERVED,
	BUYER,
	TELEPHONE,
	PERSONAL_DOC,
	EMAIL,
	PROPERTY,
	SEE_PROPERTY,
	GENERATE_LINK,
	GENERATE,
	GENERATING_LINK,
	COPY,
	COPIED,
	NO_COPIED,
	PAY_LINK,
	SEND_PAY_LINK,
	FREE_PROPERTY,
	LOADING,
	COMFIRM_PAY,
	SEE_SIMULATION,
	CLIPBOARD_LINK,
	GENERATE_AGAIN,
	DASHBOARD_SIDEBAR,
	EMPTY_OPTION,
	FREE_PLAN_TEXT,
	TALK_ASSESOR,
	TRANSACTION_ERRORS,
	SEE_QUOTATION,
	SERVER_ERROR,
	FIDUCIARY_REFERENCE,
	CANCEL_BUTTON,
	DELETE_BUTTON,
} from '../translation_keys';

export default {
	[OPPORTUNITIES_TITLE]: 'Oportunidades de negocio',
	[GO_BACK]: 'Volver',
	[CANCEL_BUTTON]: 'Cancelar',
	[DELETE_BUTTON]: 'Eliminar',
	[SEPARATED]: 'Separó',
	[RESERVED]: 'Reservó',
	[BUYER]: 'Comprador',
	[TELEPHONE]: 'Teléfono',
	[PERSONAL_DOC]: 'Cédula',
	[EMAIL]: 'Correo',
	[PROPERTY]: 'Inmueble',
	[FIDUCIARY_REFERENCE]: 'Encargo fiduciario',
	[SEE_PROPERTY]: 'Ver inmueble',
	[GENERATE_LINK]: 'Generar link',
	[GENERATE_AGAIN]: 'Regenerar token',
	[GENERATE]: 'Generar',
	[GENERATING_LINK]: 'Generando',
	[COPY]: 'Copiar',
	[COPIED]: 'Copiado',
	[NO_COPIED]: 'No se pudo copiar',
	[PAY_LINK]: 'Link de pago',
	[SEND_PAY_LINK]: 'Enviar link de pago',
	[FREE_PROPERTY]: 'Liberar inmueble',
	[LOADING]: 'Cargando',
	[COMFIRM_PAY]: 'Confirmar pago',
	[SEE_SIMULATION]: 'Ver simulación',
	[SEE_QUOTATION]: 'Ver cotización',
	[CLIPBOARD_LINK]: 'Link copiado. Está en tu portapapeles',
	[DASHBOARD_SIDEBAR[DASHBOARD_SIDEBAR_KEY]]: 'Tablero',
	[DASHBOARD_SIDEBAR[SETTINGS_SIDEBAR_KEY]]: 'Configuración',
	[DASHBOARD_SIDEBAR[INTEGRATIONS_SIDEBAR_KEY]]: 'Integraciones',
	[DASHBOARD_SIDEBAR[TOOLS_SIDEBAR_KEY]]: 'Herramientas',
	[EMPTY_OPTION]: 'Debe seleccionar una opción',
	[FREE_PLAN_TEXT]:
		'Hola {{userName}} no cuentas con versión premium, por ende solo tendrás acceso a 20 negocios por mes - <b> Tienes {{opportunities}} disponibles</b>',
	[TALK_ASSESOR]: 'Conversar con un asesor',
	[TRANSACTION_ERRORS[PENDING_TRANSACTIONS]]: 'Este comprador tiene transacciones pendientes',
	[TRANSACTION_ERRORS[PENDING_TRANSACTIONS_BY_PROPERTY]]: 'Este inmueble tiene transacciones pendientes o aprobadas',
	[SERVER_ERROR]: 'An error has occurred. Please try again later',
};
