import React from 'react';
import { Button, Modal, TextTag } from '@vecindario/vecindario-suite-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UNAVAILABE_PROPERTY_MODAL_ILLUSTRATION } from '../../../../../shared/application/constants/images';
import './ModalUnavailableProperty.scss';
import { MODAL_UUNAVAILABLE_PROPERTY } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales/index';

const ModalUnavailableProperty = ({ showModal, setShowModal, onCloseModal }) => {
	const { t } = useTranslation();

	const modalContentStyle = {
		maxHeight: '90vh',
		height: '500px',
		padding: '7rem 8.7rem',
		width: '378px',
	};

	return (
		<Modal isOpen={showModal} showClose={true} contentStyle={modalContentStyle} onClose={() => setShowModal(false)}>
			<div className="unavailable-property-modal">
				<img src={UNAVAILABE_PROPERTY_MODAL_ILLUSTRATION} className="illustration" alt="¿Deseas bloquear el inmueble?" />
				<TextTag tag="p" className="description" fw="400">
					{t(MODAL_UUNAVAILABLE_PROPERTY.DESCRIPTION_MODAL, { ns: DOMAIN_NAME })}
				</TextTag>
				<Button onClick={onCloseModal} text={t(MODAL_UUNAVAILABLE_PROPERTY.BUTTON_MODAL, { ns: DOMAIN_NAME })} />
			</div>
		</Modal>
	);
};

ModalUnavailableProperty.propTypes = {
	showModal: PropTypes.bool,
	setShowModal: PropTypes.func,
	onCloseModal: PropTypes.func,
};

export default ModalUnavailableProperty;
