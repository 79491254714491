import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import {
	urlAvailabilityAsesor,
	urlDownloadReport,
	urlGetOpportunitiesByProject,
	urlPutStageOpportunities,
} from './backendUrls';

export const getOpportunitiesByProject = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};

	return fetch(urlGetOpportunitiesByProject(slug), requestOptions).then(handleResponse);
};

export const patchStageOpportunities = (slug, id, stage) => {
	const requestOptions = {
		method: 'PATCH',
		headers: authHeader(),
		body: JSON.stringify({ stage }),
	};
	return fetch(urlPutStageOpportunities(slug, id), requestOptions).then(handleResponse);
};

export const generateReportDashboard = (slug, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlDownloadReport(slug), requestOptions);
};

export const getAvailabilityAsesor = (slug, email) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlAvailabilityAsesor(slug, email), requestOptions).then(handleResponse);
};
