import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, InformativeNotice, Input, Select } from '@vecindario/vecindario-suite-components';
import { DELETE_ICON } from '../../../../../../../shared/application/constants/icons';
import './DynamicInputVariables.scss';
import { INTEGRATION_HUBSPOT } from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import {
	selectHubspotContactVaraibles,
	selectHubspotDealsVaraibles,
	selectVecindarioVaraibles,
} from '../../../../../application/selector/hubspotIntegration';
import { renameKeysListSelectors } from '../../../../../application/helpers/hubspotIntegration';

const DynamicInputVariables = ({ isContactVariables, inputValues, setInputValues }) => {
	const { t } = useTranslation();
	const vecindarioVaraibles = useSelector(selectVecindarioVaraibles);
	const [dateFormatMessage, setDateFormatMessage] = useState([]);
	const hubspotContactVariables = useSelector(selectHubspotContactVaraibles);
	const hubspotDealsVariables = useSelector(selectHubspotDealsVaraibles);
	const hubspotVariablesList = isContactVariables ? hubspotContactVariables : hubspotDealsVariables;
	const vecindarioVaraiblesList = useMemo(
		() => vecindarioVaraibles && renameKeysListSelectors(vecindarioVaraibles),
		[vecindarioVaraibles],
	);

	const handleInputChange = (index, inputName, value) => {
		const newInputValues = JSON.parse(JSON.stringify(inputValues));

		const variable = vecindarioVaraibles.find((variableData) => variableData.suite_variable === value);

		if (variable?.type === 'unix_time') {
			console.log({ vecindarioVaraiblesList, vecindarioVaraibles, variable });
			const newItems = [...dateFormatMessage, index];
			setDateFormatMessage(newItems);
		} else {
			const newItems = dateFormatMessage.filter((item) => item !== index);
			setDateFormatMessage(newItems);
		}

		newInputValues[index][inputName] = value;
		setInputValues(newInputValues);
	};

	const handleAddRow = () => {
		setInputValues([...inputValues, { vecindarioName: '', hubspotName: '' }]);
	};

	const handleRemoveRow = (index) => {
		const newInputValues = [...inputValues];
		newInputValues.splice(index, 1);
		setInputValues(newInputValues);
	};

	return (
		<div className="container-row-dynamic-inputs">
			{isContactVariables && (
				<div className="default-email-variables">
					<Input
						label={t(`${INTEGRATION_HUBSPOT.LABEL_INPUT_VECINDARIO_VARIABLE}`, { ns: DOMAIN_NAME })}
						defaultValue={'email'}
						disabled={true}
					/>
					<Input
						label={t(`${INTEGRATION_HUBSPOT.LABEL_INPUT_HUBSPOT_VARIABLE}`, { ns: DOMAIN_NAME })}
						defaultValue={'email'}
						disabled={true}
					/>
				</div>
			)}
			{inputValues &&
				inputValues.map((input, index) => {
					const isDeleteButtonVisible = (isContactVariables && inputValues.length > 1) || (!isContactVariables && index > 0);
					return (
						<>
							<div key={index} className="dynamic-inputs">
								<div className="container-selects">
									<Select
										label={t(`${INTEGRATION_HUBSPOT.LABEL_INPUT_VECINDARIO_VARIABLE}`, { ns: DOMAIN_NAME })}
										id={`select-vecindario-${index}-${isContactVariables}`}
										onSelectItem={({ value }) => handleInputChange(index, 'vecindarioName', value)}
										options={vecindarioVaraiblesList}
										defaultOptionValue={input?.vecindarioName}
										showFinder={true}
									/>
									<Select
										label={t(`${INTEGRATION_HUBSPOT.LABEL_INPUT_HUBSPOT_VARIABLE}`, { ns: DOMAIN_NAME })}
										id={`select-hubspot-${index}-${isContactVariables}`}
										onSelectItem={({ value }) => handleInputChange(index, 'hubspotName', value)}
										options={hubspotVariablesList}
										defaultOptionValue={input?.hubspotName}
										showFinder={true}
										disabled={!isContactVariables && index === 0}
									/>
								</div>

								{isDeleteButtonVisible && (
									<button onClick={() => handleRemoveRow(index)} className="delete-row">
										<Icon icon={DELETE_ICON} />
									</button>
								)}

								{index === inputValues.length - 1 && (
									<button onClick={handleAddRow} className="add-row">
										{t(`${INTEGRATION_HUBSPOT.ADD_VARIABLE_BUTTON}`, { ns: DOMAIN_NAME })}
									</button>
								)}
							</div>

							{dateFormatMessage.includes(index) && (
								<InformativeNotice
									text={t(`${INTEGRATION_HUBSPOT.DATE_VARIABLE_DISCLAIMER}`, { ns: DOMAIN_NAME })}
									variant="warning"
								/>
							)}
						</>
					);
				})}
		</div>
	);
};

DynamicInputVariables.propTypes = {
	isContactVariables: PropTypes.bool,
	inputValues: PropTypes.array,
	setInputValues: PropTypes.func,
};

export default DynamicInputVariables;
