export default {};

export const TOOLS_PAGE = Object.freeze({
	TITLE: 'title_tools_page',
	DESCRIPTION: 'description_tools_page',
	TITLE_CARD: 'title_card_tools_page',
	BUTTON_CARD: 'button_card_tools_page',
	ERROR_PROJECT_AGREEMENT: 'error_project_agreement_tools_page',
	SUCCESS_COPY_LINK: 'success_copy_link_tools_page',
	ERROR_COPY_LINK: 'error_copy_link_tools_page',
});
