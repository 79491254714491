import React from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { getCurrentProject, getCurrentUser, setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modulesByProjectActions } from '../../../../application/selectors/opportunities';
import './ListNewOpportunity.scss';
import { SHARE_LIST_ACTIONS, ROUTE_ACTION, LIST_ACTIONS } from '../../../../application/constants/opportunities';
import { ASESOR_ROLE } from '../../../../../integrations/application/constants/inventory';
import { removeValueFromLocalStorage } from '../../../../../../shared/application/helpers/common-functions';
import { BUYER_DATA } from '../../../../../../shared/application/constants/application';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { DROPDONW_BOARD } from '../../../../infrastructure/locales/translation_keys';
import { CLIPBOARD_LINK } from '../../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../../shared/infrastructure/i18n/locales';

const OptionTextTag = ({ item, handleAction, action }) => {
	const { t } = useTranslation();
	const itemName = t(`${DROPDONW_BOARD[item.name]}`, { ns: DOMAIN_NAME });
	const copyLink = `${t(`${DROPDONW_BOARD.COPY_LINK}`, { ns: DOMAIN_NAME })} ${itemName.toLowerCase()}`;

	return (
		<div onClick={() => handleAction(item.name)} className="action-item">
			<TextTag variant="-body-xs" font="DM-sans">
				{action !== 'target' ? copyLink : itemName}
			</TextTag>
		</div>
	);
};

OptionTextTag.propTypes = {
	action: PropTypes.string,
	item: PropTypes.object.isRequired,
	handleAction: PropTypes.func.isRequired,
};

function ListNewOpportunity({ actionType = 'newOpportunity', action = 'target' }) {
	const { slug } = useParams();
	const allListActions = actionType === 'newOpportunity' ? LIST_ACTIONS : SHARE_LIST_ACTIONS;
	const buttonsNewOpportunities = useSelector(modulesByProjectActions(Object.keys(allListActions)));
	const currentProject = useSelector(getCurrentProject);
	const currentUser = useSelector(getCurrentUser);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleAction = async (key) => {
		const route = ROUTE_ACTION(slug);
		const path = currentProject?.role === ASESOR_ROLE ? `${route[key]}?asesor_email=${currentUser?.email}` : route[key];
		removeValueFromLocalStorage(BUYER_DATA);

		if (action === 'target') {
			window.open(path, '_blank');
		} else {
			const url = `${window.location.origin}${path}`;
			await navigator.clipboard.writeText(url);
			dispatch(
				setMessageToast({
					type: 'success',
					message: t(CLIPBOARD_LINK, { ns: DOMAIN_NAME_SHARED }),
				}),
			);
		}
	};

	return (
		<div className="list-actions-container">
			<div className="list-header">
				<TextTag tag="p" variant="-body-sm" fw="bold" font="DM-sans">
					{t(`${DROPDONW_BOARD.SELECT_TITLE}`, { ns: DOMAIN_NAME })}
				</TextTag>
			</div>
			<div className="list-body">
				{buttonsNewOpportunities.map((item) => (
					<OptionTextTag key={item.name} item={item} handleAction={handleAction} action={action} />
				))}
			</div>
		</div>
	);
}

ListNewOpportunity.propTypes = {
	actionType: PropTypes.string,
	action: PropTypes.string,
};

export default ListNewOpportunity;
