import {
	PENDING_TRANSACTIONS,
	PENDING_TRANSACTIONS_BY_PROPERTY,
} from '../../../../../domains/opportunityHistory/application/constants/transaction';
import {
	DASHBOARD_SIDEBAR_KEY,
	INTEGRATIONS_SIDEBAR_KEY,
	SETTINGS_SIDEBAR_KEY,
	TOOLS_SIDEBAR_KEY,
} from '../../../../application/constants/sideBard';
import {
	OPPORTUNITIES_TITLE,
	GO_BACK,
	SEPARATED,
	RESERVED,
	BUYER,
	TELEPHONE,
	PERSONAL_DOC,
	EMAIL,
	PROPERTY,
	SEE_PROPERTY,
	GENERATE_LINK,
	GENERATE,
	GENERATING_LINK,
	COPY,
	COPIED,
	NO_COPIED,
	PAY_LINK,
	SEND_PAY_LINK,
	FREE_PROPERTY,
	LOADING,
	COMFIRM_PAY,
	SEE_SIMULATION,
	CLIPBOARD_LINK,
	GENERATE_AGAIN,
	DASHBOARD_SIDEBAR,
	EMPTY_OPTION,
	FREE_PLAN_TEXT,
	TALK_ASSESOR,
	TRANSACTION_ERRORS,
	SEE_QUOTATION,
	SERVER_ERROR,
	FIDUCIARY_REFERENCE,
	CANCEL_BUTTON,
	DELETE_BUTTON,
} from '../translation_keys';

export default {
	[OPPORTUNITIES_TITLE]: 'Prospect Pipeline',
	[GO_BACK]: 'Go Back',
	[CANCEL_BUTTON]: 'Cancel',
	[DELETE_BUTTON]: 'Delete',
	[SEPARATED]: 'Separated',
	[RESERVED]: 'Reserved',
	[BUYER]: 'Buyer',
	[TELEPHONE]: 'Telephone',
	[PERSONAL_DOC]: 'ID Card',
	[EMAIL]: 'Email',
	[PROPERTY]: 'Property',
	[FIDUCIARY_REFERENCE]: 'Fiduciary id',
	[SEE_PROPERTY]: 'View Property',
	[GENERATE_LINK]: 'Generate link',
	[GENERATE_AGAIN]: 'Regenerate token',
	[GENERATE]: 'Generate',
	[GENERATING_LINK]: 'Generating',
	[COPY]: 'Copy',
	[COPIED]: 'Copied',
	[NO_COPIED]: 'Copy Failed',
	[PAY_LINK]: 'Payment link',
	[SEND_PAY_LINK]: 'Send payment link',
	[FREE_PROPERTY]: 'Release property',
	[LOADING]: 'Loading',
	[COMFIRM_PAY]: 'Confirm payment',
	[SEE_SIMULATION]: 'View simulation',
	[SEE_QUOTATION]: 'View quotation',
	[CLIPBOARD_LINK]: `Link copied. It's in your clipboard`,
	[DASHBOARD_SIDEBAR[DASHBOARD_SIDEBAR_KEY]]: 'Dashboard',
	[DASHBOARD_SIDEBAR[SETTINGS_SIDEBAR_KEY]]: 'Settings',
	[DASHBOARD_SIDEBAR[INTEGRATIONS_SIDEBAR_KEY]]: 'Integrations',
	[DASHBOARD_SIDEBAR[TOOLS_SIDEBAR_KEY]]: 'Tools',
	[EMPTY_OPTION]: 'You must select an option.',
	[FREE_PLAN_TEXT]:
		'Hi {{userName}}, you do not have a premium version, therefore you will only have access to 20 businesses per month - <b>You have {{opportunities}} available</b>.',
	[TALK_ASSESOR]: 'Talk with an agent.',
	[TRANSACTION_ERRORS[PENDING_TRANSACTIONS]]: 'This buyer has pending transactions',
	[TRANSACTION_ERRORS[PENDING_TRANSACTIONS_BY_PROPERTY]]: 'This property has pending or approved transactions',
	[SERVER_ERROR]: 'Ha ocurrido un error. Intenta mas tarde',
};
