import urlBase from '../../../../shared/infrastructure/api/backend-urls';

export const urlPostConnectAccount = (slug = ':slug', accountInstanceId = ':account_instance_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${accountInstanceId}`;
export const urlCreateAndGetAccounts = (slug = ':slug') => `${urlBase}proyectos/${slug}/crear-cuenta-publicitaria`;
export const urlGetAccountConnected = (slug = ':slug', provider = ':provider') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias?provider=${provider}`;

export const urlPatchDisconnectAccount = (slug = ':slug', accountId = ':account_instance_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${accountId}/desconectar`;
export const urlPatchReconnectAccount = (slug = ':slug', accountId = ':provider_account_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${accountId}/reconectar`;
export const urlDeleteAccount = (slug = ':slug', accountId = ':account_instance_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${accountId}/eliminar`;

export const urlGetListCampaigns = (
	slug = ':slug',
	provider = ':provider',
	account_id = ':provider_account_id',
	access_token,
) =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${provider}/${account_id}/campanas?access_token=${access_token}`;
export const urlPostConnectCampaign = (slug = ':slug', provider_account_id = ':provider_account_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${provider_account_id}/campanas`;
export const urlGetCampaigns = (slug = ':slug', provider_account_id = ':provider_account_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/${provider_account_id}/campanas`;
export const urlPatchActiveCampaign = (
	slug = ':slug',
	provider_account_id = ':provider_account_id',
	campaignId = ':campaign_instance_id',
) => `${urlBase}proyectos/${slug}/cuentas-publicitarias/${provider_account_id}/campanas/${campaignId}/activar`;
export const urlDeleteCampaign = (slug = ':slug', campaignId = ':campaign_instance_id') =>
	`${urlBase}proyectos/${slug}/cuentas-publicitarias/campanas/${campaignId}`;
