import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { getCurrentProject } from '@vecindario/suite-dashboard-layout-lib';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';
import { history } from '../../../../../shared/application/helpers/history';
import { selectCurrentOptionSelected, selectRequestHistory } from '../../../application/selector/history';
import { SEPARATED_STATUS, STAGE_SEPARATED_BY_OPTION } from '../../../application/constants/opportunityHistory';
import { formatDate, getStateClass } from '../../../../../shared/application/helpers/common-functions';
import { OPORTUNITY_STATUS, SEPARATED_OPTION_DETAIL } from '../../../infrastructure/locales/translation_keys';
import { COMMON_DATE_WITH_12_HOUR } from '../../../../../shared/application/constants/dates';

import './SeparatedOptionPage.scss';

const SeparatedOptionPage = () => {
	const { t } = useTranslation();
	const project = useSelector(getCurrentProject);
	const opportunity = useSelector(selectRequestHistory);
	const optionSnapshot = useSelector(selectCurrentOptionSelected);

	return (
		<div className="separated-option-page">
			<TextTag tag="p" fw="bold" variant="-body-sm" className="header color-secondary">
				<div className={`history-state-badge ${getStateClass(STAGE_SEPARATED_BY_OPTION)}`}>
					<TextTag tag="span" variant="-body-xs" fw="bold">
						{t(OPORTUNITY_STATUS[SEPARATED_STATUS], { ns: DOMAIN_NAME })}
					</TextTag>
				</div>

				<Icon
					icon={ICON_CLOSE_LINE}
					onClick={() => history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id))}
				/>
			</TextTag>

			<TextTag tag="p" variant="-body-md" fw="bold" className="title">
				{t(SEPARATED_OPTION_DETAIL.TITLE, { ns: DOMAIN_NAME })}
			</TextTag>

			<div className="content">
				<div className="option-information">
					<div className="item">
						<TextTag tag="p" variant="-body-xs" className="header color-secondary-600t">
							{t(SEPARATED_OPTION_DETAIL.COLUMN_PROPERTY, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="text">
							{`${project?.title} - ${optionSnapshot?.property?.reference}`}
						</TextTag>
					</div>

					<div className="item">
						<TextTag tag="p" variant="-body-xs" className="header color-secondary-600t">
							{t(SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMATION_DATE, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="text">
							{formatDate(optionSnapshot?.history?.created_at, COMMON_DATE_WITH_12_HOUR)}
						</TextTag>
					</div>

					<div className="item">
						<TextTag tag="p" variant="-body-xs" className="header color-secondary-600t">
							{t(SEPARATED_OPTION_DETAIL.COLUMN_CONFIRMED_BY, { ns: DOMAIN_NAME })}
						</TextTag>
						<TextTag tag="p" variant="-body-sm" className="text">
							{`${optionSnapshot?.history?.performing_user?.first_name} ${optionSnapshot?.history?.performing_user?.last_name}`}
						</TextTag>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SeparatedOptionPage;
