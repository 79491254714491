import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { getStateClass } from '../../../../../../shared/application/helpers/common-functions';
import { STAGES } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { STAGE_EXTERNAL_CONTACT_ADS } from '../../../../application/constants/opportunityHistory';

const ColumnStateBadge = ({ stage, source }) => {
	const { t } = useTranslation();
	const stateClass = getStateClass(stage);

	if (stage === STAGE_EXTERNAL_CONTACT_ADS) {
		stage = STAGES[source];
	}

	return (
		<div className={`history-state-badge ${stateClass}`}>
			<TextTag variant="-body-xs" fw="bold" type="label">
				{t(`${STAGES[stage]}`, { ns: DOMAIN_NAME })}
			</TextTag>
		</div>
	);
};

ColumnStateBadge.propTypes = {
	stage: PropTypes.string,
	source: PropTypes.string,
};

export default ColumnStateBadge;
