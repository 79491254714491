import urlBase from '../../../../shared/infrastructure/api/backend-urls';

const urlNotes = (slug) => `${urlBase}proyectos/${slug}/notas-oportunidades`;
export const urlGetListNotes = (slug = ':slug', commercial_opportunity_id = ':commercial_opportunity_id') =>
	`${urlNotes(slug)}/${commercial_opportunity_id}`;

export const urlPostCreateNote = (slug = ':slug') => `${urlNotes(slug)}`;

export const urlPutEditNote = (slug = ':slug', opportunity_note_id = ':opportunity_note_id') =>
	`${urlNotes(slug)}/${opportunity_note_id}`;
