import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import DesistButton from '../../../DesistButton';
import { DESISTED } from '../../../../../infrastructure/locales/translation_keys';
import { STAGE } from '../../../../../../opportunities/application/constants/opportunities';
import SeeDetailsButton from '../../../SeeDetailsButton';
import { TRANSACTION_STAGE_DISPERSED } from '../../../../../application/constants/opportunityHistory';

const ColumnActionReserved = ({ opportunity, item, transactionHistories }) => {
	const { t } = useTranslation();

	const isDispersed = () => {
		return !!transactionHistories?.find((_history) => _history?.action_type === TRANSACTION_STAGE_DISPERSED);
	};

	return (
		<>
			<SeeDetailsButton item={item} />

			{!isDispersed && opportunity?.stage !== STAGE.DESISTED && <DesistButton />}

			{!isDispersed && opportunity?.stage === STAGE.DESISTED && <span>{t(DESISTED, { ns: DOMAIN_NAME })}</span>}
		</>
	);
};

ColumnActionReserved.propTypes = {
	opportunity: PropTypes.object,
	item: PropTypes.object,
	transactionHistories: PropTypes.array,
};

export default ColumnActionReserved;
