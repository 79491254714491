import {
	GENERIC_DESCRIPTION,
	GENERIC_INPUT_LABEL,
	GENERIC_WEBHOOK,
	HUBSPOT,
	HUBSPOT_DESCRIPTION,
	HUBSPOT_INPUT_LABEL,
	META,
	META_DESCRIPTION,
	SICO,
	SICO_DESCRIPTION,
	SICO_INPUT_LABEL,
	SINCO,
	SINCO_DESCRIPTION,
	SINCO_INPUT_LABEL,
	SUITE,
	SUITE_DESCRIPTION,
	SUITE_INPUT_LABEL,
	ZAPIER_DESCRIPTION,
	ZAPIER_INPUT_LABEL,
	ZAPIER_WEBHOOK,
	ZOHO,
	ZOHO_DESCRIPTION,
	ZOHO_INPUT_LABEL,
} from '../../../application/constants/inventory';
import { INTEGRATIONS, INTEGRATION_HUBSPOT, MODAL_EDIT_TOKEN_HUBSPOT, SYNC } from '../translation_keys';

export default {
	[INTEGRATIONS.TITLE]: `Integraciones`,
	[INTEGRATIONS.TEXT_INFO]: `Aquí podrás encontrar las opciones que tenemos para que puedas integrarte con la Suite`,
	[INTEGRATIONS[SUITE]]: `API Oportunidades de negocio`,
	[INTEGRATIONS[META]]: `Meta Business Manager`,
	[INTEGRATIONS[HUBSPOT]]: `Hubspot`,
	[INTEGRATIONS[ZAPIER_WEBHOOK]]: `Zapier`,
	[INTEGRATIONS[GENERIC_WEBHOOK]]: `Webhook`,
	[INTEGRATIONS[SINCO]]: `SINCO ERP`,
	[INTEGRATIONS[ZOHO]]: `Zoho`,
	[INTEGRATIONS[SICO]]: `Sico`,
	[INTEGRATIONS[
		SUITE_DESCRIPTION
	]]: `Con la API de Oportunidades de negocio de Suite podrás consultar en tiempo real los clientes que se han interesado en tu proyecto. Consulta la <a href="https://documenter.getpostman.com/view/8663633/UzBgup9x" target="_blank" rel="noreferrer">versión 1</a> o la <a href="https://documenter.getpostman.com/view/21508682/2s9YBxYbYb" target="_blank" rel="noreferrer">versión 2</a> de la documentación para aprender como integrar tus sistemas.`,
	[INTEGRATIONS[
		META_DESCRIPTION
	]]: `Con esta integración podrás crear tus facebook leads de campañas de pauta como oportunidades de negocio en el proyecto por medio de los IDs de tus campañas.`,
	[INTEGRATIONS[
		HUBSPOT_DESCRIPTION
	]]: `Esta integración permite conectar mediante webhooks todas las oportunidades comerciales generadas en Suite.`,
	[INTEGRATIONS[
		ZAPIER_DESCRIPTION
	]]: `Esta integración permite conectar de forma fácil y rápida las oportunidades comerciales con más de 2.000 aplicaciones en el mundo. Requiere una cuenta en Zapier.`,
	[INTEGRATIONS[
		GENERIC_DESCRIPTION
	]]: `Esta integración permite conectar mediante webhooks todas las oportunidades comerciales generadas en Suite.`,
	[INTEGRATIONS[
		SINCO_DESCRIPTION
	]]: `Con esta integración puedes sincronizar en tiempo real el inventario que tienes en SINCO y ponerlo a disposición de tus clientes a través de las soluciones de Vecindario Suite como el Cotizador y el Simulador.`,
	[INTEGRATIONS[ZOHO_DESCRIPTION]]: `Esta integración permite conectar todas las oportunidades de negocio a Zoho.`,
	[INTEGRATIONS[
		SICO_DESCRIPTION
	]]: `Esta integración permite conectar las oportunidades comerciales generadas a partir de los intereses de los compradores`,
	[INTEGRATIONS[SUITE_INPUT_LABEL]]: `Pega o escribe la URL de API de suite`,
	[INTEGRATIONS[HUBSPOT_INPUT_LABEL]]: `Copia acá la URL de Hubspot`,
	[INTEGRATIONS[ZAPIER_INPUT_LABEL]]: `Copia acá la URL del webhook de Zapier`,
	[INTEGRATIONS[GENERIC_INPUT_LABEL]]: `Copia acá la URL del webhook que deseas integrar`,
	[INTEGRATIONS[SINCO_INPUT_LABEL]]: `Copia acá la URL del ambiente Sinco CBR`,
	[INTEGRATIONS[ZOHO_INPUT_LABEL]]: ``,
	[INTEGRATIONS[SICO_INPUT_LABEL]]: `Pega acá el token para Sico`,
	[INTEGRATIONS.SINCO_USERNAME_LABEL]: `Copia acá el nombre de usuario del ambiente Sinco CBR`,
	[INTEGRATIONS.SINCO_PASSWORD_LABEL]: `Copia acá la contraseña del ambiente Sinco CBR`,
	[INTEGRATIONS.INTEGRATE_TEXT_BUTTON]: `Integrar`,
	[INTEGRATIONS.ACTIVATE_TEXT_BUTTON]: `Activar`,
	[INTEGRATIONS.ACTIVATE_TEXT_HUBSPOT_BUTTON]: `Activar integración`,
	[INTEGRATIONS.GENERATE_TOKEN_TEXT_BUTTON]: 'Generar token',
	[INTEGRATIONS.COPY_TO_CLIPBOARD]: `Copiar {{text}}`,
	[INTEGRATIONS.SET_UP_TEXT_BUTTON]: 'Configurar',
	[INTEGRATIONS.SEE_INTEGRATION_TEXT_BUTTON]: 'Ver integración',
	[INTEGRATIONS.COPY_TO_CLIPBOARD]: `Copiar al portapapeles`,
	[INTEGRATIONS.REQUEST_INTEGRATION]: `Integración solicitada`,
	[INTEGRATIONS.INTEGRATED_TEXT]: 'Integrated',
	[SYNC.DESACTIVATE]: 'Desactivar sincronización',
	[SYNC.SURE]: '¿Estas seguro que deseas desactivar la sincronización?',
	[SYNC.YES]: 'Aceptar',
	[SYNC.NO]: 'Cancelar',

	[INTEGRATION_HUBSPOT.ENTER_TOKEN]: 'Ingresa el token',
	[INTEGRATION_HUBSPOT.PLACEHOLDER_INPUT_TOKEN]: 'ingresa aquí el token',
	[INTEGRATION_HUBSPOT.VERIFY_BUTTON]: 'Verificar',
	[INTEGRATION_HUBSPOT.VERIFIED_STATE_BUTTON]: 'Verificado',
	[INTEGRATION_HUBSPOT.VERIFICATION_ERROR]: 'Token invalido, por favor verifica el token ingresado',
	[INTEGRATION_HUBSPOT.SELECT_PIPELINES_TITLE]:
		'Selecciona los pipelines y stages en los que quieres recibir las oportunidades',
	[INTEGRATION_HUBSPOT.OPPORTUNITY_TYPE]: 'Tipo de oportunidad',
	[INTEGRATION_HUBSPOT.PIPELINE]: 'Pipeline',
	[INTEGRATION_HUBSPOT.STAGE]: 'Stage',
	[INTEGRATION_HUBSPOT.SAVE_BUTTON]: 'Guardar',
	[INTEGRATION_HUBSPOT.SCHEDULE_TYPE]: 'Agendamientos',
	[INTEGRATION_HUBSPOT.QUOTER_TYPE]: 'Cotizaciones',
	[INTEGRATION_HUBSPOT.SIMULATION_TYPE]: 'Simulaciones',
	[INTEGRATION_HUBSPOT.CONTACT_REQUESTS_TYPE]: 'Solicitudes de contacto',
	[INTEGRATION_HUBSPOT.CANCEL_BUTTON]: 'Cancelar',
	[INTEGRATION_HUBSPOT.EDIT_TOKEN]: 'Editar token',
	[INTEGRATION_HUBSPOT.TITLE_CARD_CONTACT_VARIABLES]:
		'Selecciona los nombres de las variables de contacto que tienes en HubSpot para recibir la información',
	[INTEGRATION_HUBSPOT.TTILE_CARD_BUSINESS_VARIABLES]:
		'Selecciona los nombres de las variables de negocio que tienes en HubSpot para recibir la información',
	[INTEGRATION_HUBSPOT.SUBTITLE_CARD_CONTACT_VARIABLES]: 'Variables de contacto',
	[INTEGRATION_HUBSPOT.SUBTTILE_CARD_BUSINESS_VARIABLES]: 'Variables de negocio',
	[INTEGRATION_HUBSPOT.ADD_VARIABLE_BUTTON]: 'Agregar variable adicional',
	[INTEGRATION_HUBSPOT.LABEL_INPUT_VECINDARIO_VARIABLE]: 'Nombre variable en Vecindario',
	[INTEGRATION_HUBSPOT.LABEL_INPUT_HUBSPOT_VARIABLE]: 'Nombre variable en Hubspot',
	[INTEGRATION_HUBSPOT.FINIS_SETUP_BUTTON]: 'Finalizar configuración',
	[INTEGRATION_HUBSPOT.CARD_ERROR_TITLE]: 'La integración presenta los siguientes errores',
	[INTEGRATION_HUBSPOT.CARD_ERROR_BODY]: 'La propiedad {{error}} no existe',
	[INTEGRATION_HUBSPOT.CARD_ERROR_FOOTER]: 'Debes corregirlos para que la integración se configure con éxito',
	[INTEGRATION_HUBSPOT.SHOW_INTEGRATION_BUTTON_CARD]: 'Ver integración',
	[INTEGRATION_HUBSPOT.SUCCESS_INTEGRATION]: 'Integración exitosa',
	[INTEGRATION_HUBSPOT.UPDATE_INTEGRATION_BUTTON]: 'Actualizar integración',
	[INTEGRATION_HUBSPOT.SUCCESS_SAVE_DATA]: 'Datos guardados, recuerda finalizar la configuración',
	[INTEGRATION_HUBSPOT.SUBTITLE_EDIT_INTEGRATION]:
		'Recuerda guardar y finalizar la configuración, si realizas algún cambio en cualquiera de las secciones (pipelines, variables de contacto o variables de negocio).',

	[MODAL_EDIT_TOKEN_HUBSPOT.TITLE]: '¿Estás seguro que quieres editar el token?',
	[MODAL_EDIT_TOKEN_HUBSPOT.BODY]: '¿Al cambiarlo se borrará toda la configuración de la integración que has realizado',
	[MODAL_EDIT_TOKEN_HUBSPOT.GO_BACK_BUTTON]: 'Volver',
	[MODAL_EDIT_TOKEN_HUBSPOT.SUBMIT_BUTTON]: 'Continuar',
	[INTEGRATION_HUBSPOT.CARD_PROPERTY_DOESNT_EXIST_ERROR]: 'La propiedad {{error}} no existe',
	[INTEGRATION_HUBSPOT.CARD_READ_ONLY_VALUE_ERROR]: 'La propiedad {{error}} es de solo lectura',
	[INTEGRATION_HUBSPOT.CARD_INVALID_LONG_ERROR]: 'La propiedad {{error}} tiene un formato inválido',
	[INTEGRATION_HUBSPOT.DATE_VARIABLE_DISCLAIMER]:
		'Esta variable es de tipo fecha y debe coincidir con el mismo tipo de dato en HubSpot',
};
