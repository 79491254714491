import React from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch, Route, Switch } from 'react-router-dom';
import HistoryTablePage from '../presentation/pages/HistoryTablePage';
import UserIdentificationPage from '../presentation/pages/UserIdentificationPage';
import {
	OPTIONED_CONFIRM_PURCHASE_HASH,
	EXTEND_VALIDITY_TIME_SECTION_HASH,
	OPTIONED_DETAILS_PAGE,
	OPTIONED_PEOPLE_IN_THE_QUEUE_SECTION_HASH,
	OPTIONED_QUEUED_HASH,
	OPTIONED_USER_INFORMATION_SECTION_HASH,
	SEPARATED_BY_OPTION_DETAIL_SECTION_HASH,
	CANCEL_OPTION_SECTION_HASH,
	TRACKING_INFO_SECTION_HASH,
	CONTACT_REQUEST_DETAILS_HASH,
	EXTERNAL_CONTACT_REQUEST_DETAILS_HASH,
} from '../application/constants/optionedInformation';
import ConfirmationOptionsPage from '../presentation/pages/ConfirmationOptionsPage';
import OptionDetailsPage from '../presentation/pages/OptionDetailsPage';
import SeparatedOptionPage from '../presentation/pages/SeparatedOptionPage';
import ConfirmPurchasePage from '../presentation/pages/ConfirmPurchasePage';
import MessageOptionPage from '../presentation/pages/MessageOptionPage';
import ExtendValidityPage from '../presentation/pages/ExtendValidityPage';
import CancelOptionPage from '../presentation/pages/CancelOptionPage';
import WebAnalisis from '../presentation/pages/WebAnalisis';
import ContactRequestDetails from '../presentation/pages/ContactRequestDetails';
import ExternalContactRequestDetails from '../presentation/pages/ExternalContactRequestDetails';

const RouterHistory = ({ showSelectPropertyModal, setShowSelectPropertyModal }) => {
	const match = useRouteMatch();

	return (
		<div className="child-page">
			<Switch>
				<Route path={`${match.path}${OPTIONED_USER_INFORMATION_SECTION_HASH}`}>
					<UserIdentificationPage setShowSelectPropertyModal={setShowSelectPropertyModal} />
				</Route>
				<Route path={`${match.path}${OPTIONED_PEOPLE_IN_THE_QUEUE_SECTION_HASH}`}>
					<ConfirmationOptionsPage setShowSelectPropertyModal={setShowSelectPropertyModal} />
				</Route>
				<Route path={`${match.path}${OPTIONED_DETAILS_PAGE}`}>
					<OptionDetailsPage />
				</Route>
				<Route path={`${match.path}${SEPARATED_BY_OPTION_DETAIL_SECTION_HASH}`}>
					<SeparatedOptionPage />
				</Route>
				<Route path={`${match.path}${OPTIONED_CONFIRM_PURCHASE_HASH}`}>
					<ConfirmPurchasePage />
				</Route>
				<Route path={`${match.path}${OPTIONED_QUEUED_HASH}`}>
					<MessageOptionPage />
				</Route>
				<Route path={`${match.path}${EXTEND_VALIDITY_TIME_SECTION_HASH}`}>
					<ExtendValidityPage />
				</Route>
				<Route path={`${match.path}${CANCEL_OPTION_SECTION_HASH}`}>
					<CancelOptionPage />
				</Route>
				<Route path={`${match.path}${TRACKING_INFO_SECTION_HASH}`}>
					<WebAnalisis />
				</Route>
				<Route path={`${match.path}${CONTACT_REQUEST_DETAILS_HASH}`}>
					<ContactRequestDetails />
				</Route>
				<Route path={`${match.path}${EXTERNAL_CONTACT_REQUEST_DETAILS_HASH}`}>
					<ExternalContactRequestDetails />
				</Route>
				<Route path={match.path}>
					<HistoryTablePage
						showSelectPropertyModal={showSelectPropertyModal}
						setShowSelectPropertyModal={setShowSelectPropertyModal}
					/>
				</Route>
			</Switch>
		</div>
	);
};

RouterHistory.propTypes = {
	showSelectPropertyModal: PropTypes.bool,
	setShowSelectPropertyModal: PropTypes.func,
};

export default RouterHistory;
