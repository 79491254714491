import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button } from '@vecindario/vecindario-suite-components';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { MODAL } from '../../../../../infrastructure/locales/translation_keys';
import { setCurrentOptionSelected } from '../../../../../application/slices/history';
import { opportunityOptionDetailRoute } from '../../../../../infrastructure/routes';
import { history } from '../../../../../../../shared/application/helpers/history';

const ColumnActionSeparatedByOption = ({ opportunity, item }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const seeSeparatedOptionDetail = () => {
		dispatch(setCurrentOptionSelected({ ...item?.action_instance, history: item }));
		history.push(opportunityOptionDetailRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<Button variant="transparent" onClick={seeSeparatedOptionDetail}>
			{t(`${MODAL.DETAILS}`, { ns: DOMAIN_NAME })}
		</Button>
	);
};

ColumnActionSeparatedByOption.propTypes = {
	opportunity: PropTypes.object,
	item: PropTypes.object,
};

export default ColumnActionSeparatedByOption;
