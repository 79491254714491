import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SelectCampaign from './SelectCampaign';
import TableCampaigns from './TableCampaigns';
import './CampaignSection.scss';
import { selectAdsCampaigns } from '../../../application/selector/facebook';

const CampaignSection = ({ setBtnShowCampaign, setShowCampaign }) => {
	const campaigns = useSelector(selectAdsCampaigns);
	const [showCampaigns, setShowCampaigns] = useState(false);

	useEffect(() => {
		if (campaigns?.length > 0) {
			setShowCampaigns(true);
		}
	}, [campaigns]);

	const handleCloseCampaign = () => {
		if (campaigns?.length > 0) {
			setShowCampaigns(true);
		} else {
			setBtnShowCampaign(true);
			setShowCampaign(false);
		}
	};

	return (
		<div className="campaigns-fb-leads-container">
			{showCampaigns ? (
				<TableCampaigns setShowCampaigns={setShowCampaigns} />
			) : (
				<SelectCampaign handleCloseCampaign={handleCloseCampaign} setShowCampaigns={setShowCampaigns} />
			)}
		</div>
	);
};

CampaignSection.propTypes = {
	setBtnShowCampaign: PropTypes.func,
	setShowCampaign: PropTypes.func,
};

export default CampaignSection;
