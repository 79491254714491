import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, TextTag } from '@vecindario/vecindario-suite-components';
import './GeneralSelectorsCard.scss';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { INTEGRATION_HUBSPOT } from '../../../../infrastructure/locales/translation_keys';
import { history } from '../../../../../../shared/application/helpers/history';
import { integrationsRoute } from '../../../../infrastructure/routes';
import { resetState } from '../../../../application/slices/hubspotIntegration';

const GeneralSelectorsCard = ({ children, title, ifFinishConfig, isDisabled, actionSaveButton }) => {
	const { t } = useTranslation();
	const { slug } = useParams();
	const dispatch = useDispatch();

	const handleCancel = () => {
		dispatch(resetState());
		history.push(integrationsRoute(slug));
	};

	return (
		<div className="container-general-selectors-card fade-in-top">
			<TextTag fw="bold" className="title-card">
				{title}
			</TextTag>
			<section className="body-card">{children}</section>
			<section className="footer-card">
				<Button className="action-button" variant="bordered" onClick={handleCancel}>
					{t(INTEGRATION_HUBSPOT.CANCEL_BUTTON, { ns: DOMAIN_NAME })}
				</Button>
				<Button className="action-button" disabled={isDisabled} onClick={actionSaveButton}>
					{ifFinishConfig
						? t(INTEGRATION_HUBSPOT.FINIS_SETUP_BUTTON, { ns: DOMAIN_NAME })
						: t(INTEGRATION_HUBSPOT.SAVE_BUTTON, { ns: DOMAIN_NAME })}
				</Button>
			</section>
		</div>
	);
};

GeneralSelectorsCard.propTypes = {
	children: PropTypes.node,
	title: PropTypes.string,
	ifFinishConfig: PropTypes.bool,
	isDisabled: PropTypes.bool,
	actionSaveButton: PropTypes.func,
};

export default GeneralSelectorsCard;
