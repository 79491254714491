import React from 'react';
import { useSelector } from 'react-redux';
import { selectRequestHistory } from '../../../application/selector/history';
import CancelOption from '../../components/CancelOption';

const CancelOptionPage = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <CancelOption opportunity={opportunity} />;
};

export default CancelOptionPage;
