export const DOMAINS = [
	'facebook',
	'opportunities',
	'opportunityHistory',
	'Settings',
	'integrations',
	'opportunityNotes',
	'property',
	'tools',
];

export default {};
