import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import ConnectFBPage from '../presentation/pages/ConnectAccount';
import {
	accountFBRoute,
	connectFBRoute,
	deleteAccountRoute,
	deleteCampaignRoute,
	disconnectAccountRoute,
	disconnectCampaignRoute,
	selectAccountsFBRoute,
} from './routes';
import AccountsFBPage from '../presentation/pages/Accounts';
import SelectAccountPage from '../presentation/pages/SelectAccountPage';
import DeleteCampaignPage from '../presentation/pages/DeleteCampaign';
import DisconnectCampaignPage from '../presentation/pages/DisconnectCampaign';
import DisconnectAccountPage from '../presentation/pages/DisconnectAccount';
import DeleteAccountPage from '../presentation/pages/DeleteAccount';

const facebookRouter = {
	layout: AdminLayout,
	router: [
		{
			path: connectFBRoute(),
			page: ConnectFBPage,
			routeComponent: PrivateRoute,
		},
		{
			path: accountFBRoute(),
			page: AccountsFBPage,
			routeComponent: PrivateRoute,
		},
		{
			path: selectAccountsFBRoute(),
			page: SelectAccountPage,
			routeComponent: PrivateRoute,
		},
		{
			path: disconnectAccountRoute(),
			page: DisconnectAccountPage,
			routeComponent: PrivateRoute,
		},
		{
			path: deleteAccountRoute(),
			page: DeleteAccountPage,
			routeComponent: PrivateRoute,
		},
		{
			path: disconnectCampaignRoute(),
			page: DisconnectCampaignPage,
			routeComponent: PrivateRoute,
		},
		{
			path: deleteCampaignRoute(),
			page: DeleteCampaignPage,
			routeComponent: PrivateRoute,
		},
	],
};

export default facebookRouter;
