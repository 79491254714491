import { DOMAIN_NAME } from '../../infrastructure/locales';
import { EXTEND_VALIDITY_PAGE } from '../../infrastructure/locales/translation_keys';

export const DAYS = 'days';
export const HOURS = 'hours';
export const NUMBER_HOURS_DAY = 24;
export const VALIDITY_TIME_OPTIONS = ({ t }) => {
	return [
		{ value: DAYS, label: t(EXTEND_VALIDITY_PAGE.WORD_DAYS, { ns: DOMAIN_NAME }) },
		{ value: HOURS, label: t(EXTEND_VALIDITY_PAGE.WORD_HOURS, { ns: DOMAIN_NAME }) },
	];
};

export const extendValidityFields = {
	VALIDITY_TIME: 'validity_time',
	TIME: 'options_validity_hours',
};

export const GREATER_THAN_ZERO_REGEX = /^[1-9][0-9]*$/;
export const NUMBER_BETWEEN_1_7_REGEX = /^[1-7]$/;
export const NUMBER_BETWEEN_1_168_REGEX = /^(?:[1-9]|[1-9]\d|1[0-5]\d|168)$/;
