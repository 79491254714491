import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Button } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { STAGE_VERIFYING_PAYMENT } from '../../../application/constants/opportunityHistory';
import { selectLoadingPayment, selectLoadingReleaseProperty } from '../../../application/selector/history';
import {
	approveFiduciaryPayment,
	releaseProperty,
	requestOpportunityHistory,
} from '../../../application/slices/history';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import {
	COMFIRM_PAY,
	FREE_PROPERTY,
	LOADING,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';

const VerifyingPaymentActions = ({ historyItem = {}, onSuccess = () => {} }) => {
	const loadingPayment = useSelector(selectLoadingPayment);
	const loadingReleaseProperty = useSelector(selectLoadingReleaseProperty);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { slug, commercial_opportunity_id } = useParams();
	const { property } = historyItem?.action_instance || {};
	const disableActions = loadingPayment || loadingReleaseProperty;

	const handleSuccess = () => {
		onSuccess && onSuccess();
		dispatch(requestOpportunityHistory({ slug, commercial_opportunity_id }));
	};

	const handleReleaseProperty = () => {
		dispatch(
			releaseProperty({
				slug,
				propertyId: property?.id,
				data: {
					commercial_opportunity_id,
				},
			}),
		).then(() => handleSuccess());
	};

	const confirmPayment = () => {
		dispatch(
			approveFiduciaryPayment({
				slug,
				propertyId: property?.id,
				data: {
					commercial_opportunity_id,
				},
			}),
		).then(() => handleSuccess());
	};

	if (historyItem?.action_type !== STAGE_VERIFYING_PAYMENT) return null;

	return (
		<>
			<Button variant="bordered" style={{ width: '100%' }} onClick={handleReleaseProperty} disabled={disableActions}>
				{loadingReleaseProperty
					? `${t(`${LOADING}`, { ns: DOMAIN_NAME_SHARED })}...`
					: t(`${FREE_PROPERTY}`, { ns: DOMAIN_NAME_SHARED })}
			</Button>
			<Button style={{ width: '100%' }} onClick={confirmPayment} disabled={disableActions}>
				{loadingPayment
					? `${t(`${LOADING}`, { ns: DOMAIN_NAME_SHARED })}...`
					: t(`${COMFIRM_PAY}`, { ns: DOMAIN_NAME_SHARED })}
			</Button>
		</>
	);
};

VerifyingPaymentActions.propTypes = {
	historyItem: PropTypes.object,
	onSuccess: PropTypes.func,
};

export default VerifyingPaymentActions;
