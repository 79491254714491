import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { MOVED_BY, MOVED_IN } from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { getPerformerUserFullName } from '../../../../../application/helpers/commercialOpportunity';
import { formatDate } from '../../../../../../../shared/application/helpers/common-functions';
import { COMMON_DATE_WITH_12_HOUR } from '../../../../../../../shared/application/constants/dates';

const ColumnDetailMoved = ({ item, opportunity }) => {
	const { t } = useTranslation();

	return (
		<table className="detail-table">
			<tbody className="body">
				<tr className="row">
					<th className="label">{t(MOVED_BY, { ns: DOMAIN_NAME })}</th>
					<td className="value">{getPerformerUserFullName(opportunity, item)}</td>
				</tr>

				<tr className="row">
					<th className="label">{t(MOVED_IN, { ns: DOMAIN_NAME })}</th>
					<td className="value">{formatDate(item?.created_at, COMMON_DATE_WITH_12_HOUR)}</td>
				</tr>
			</tbody>
		</table>
	);
};

ColumnDetailMoved.propTypes = {
	item: PropTypes.object,
	opportunity: PropTypes.object,
};

export default ColumnDetailMoved;
