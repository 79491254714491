import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { i18nInstance } from '@vecindario/vecindario-suite-components';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { STAGE_SIMULATE } from '../../../application/constants/opportunityHistory';
import {
	SEE_QUOTATION,
	SEE_SIMULATION,
	SERVER_ERROR,
} from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME, DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';
import { getQuotationPdfUrl } from '../../../infrastructure/api/history';

const SeeSimulationOrQuotationButton = ({ item, type }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { slug } = useParams();

	const error = () => {
		dispatch(
			setMessageToast({
				type: 'error',
				message: t(SERVER_ERROR, { ns: DOMAIN_NAME }),
			}),
		);
	};

	const handleQuotationClick = () => {
		if (type === STAGE_SIMULATE) {
			window.open(item?.action_instance?.pdf_file, '_blank').focus();
		} else {
			getQuotationPdfUrl(slug, item?.action_instance?.id, i18nInstance?.language)
				.then((response) => {
					if (!response) {
						error();
					}
					const { pdf_file } = response;
					window.open(pdf_file, '_blank').focus();
				})
				.catch(() => {
					error();
				});
		}
	};

	return (
		<button className="see-simulation-button action-link" onClick={handleQuotationClick}>
			{t(`${type === STAGE_SIMULATE ? SEE_SIMULATION : SEE_QUOTATION}`, { ns: DOMAIN_NAME_SHARED })}
		</button>
	);
};

SeeSimulationOrQuotationButton.propTypes = {
	item: PropTypes.object,
	type: PropTypes.string,
};

export default SeeSimulationOrQuotationButton;
