import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import IntegrationsPage from '../presentation/pages/Integrations';
import { integrationsHubspot, integrationsHubspotShowIntegration, integrationsRoute } from './routes';
import HubspotIntegration from '../presentation/pages/HubspotIntegration';

const integrationsRouter = {
	layout: AdminLayout,
	router: [
		{
			path: integrationsRoute(),
			page: IntegrationsPage,
			routeComponent: PrivateRoute,
		},
		{
			path: integrationsHubspot(),
			page: HubspotIntegration,
			routeComponent: PrivateRoute,
		},
		{
			path: integrationsHubspotShowIntegration(),
			page: HubspotIntegration,
			routeComponent: PrivateRoute,
		},
	],
};

export default integrationsRouter;
