import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetPropertyOptionsQueue } from './backend-url';

export const getPropertyOptionsQueueService = (slug, propertyId) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetPropertyOptionsQueue(slug, propertyId), requestOptions).then(handleResponse);
};

export default {};
