export const TRANSACTION_STATUS_PENDING = 'pending';
export const TRANSACTION_STATUS_APPROVED = 'approved';
export const TRANSACTION_STATUS_REJECTED = 'rejected';
export const TRANSACTION_STATUS_FAILED = 'failed';
export const TRANSACTION_STATUS_REFUNDED = 'refunded';

export const PAYMENT_METHODS_OPTIONS = {
	pse: { name: 'pse', text: 'PSE' },
	credit_card: { name: 'credit_card', text: 'Tarjeta de crédito' },
	cash: { name: 'cash', text: 'Efectivo' },
	rappi_pay: { name: 'rappi_pay', text: 'Rappi' },
};

export const PENDING_TRANSACTIONS = 'Este comprador tiene transacciones pendientes';
export const PENDING_TRANSACTIONS_BY_PROPERTY = 'Este inmueble tiene transacciones pendientes o aprobadas';
