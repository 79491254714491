import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Button, MessageError, TextTag } from '@vecindario/vecindario-suite-components';
import { getProjectAgreementLogo } from '@vecindario/suite-dashboard-layout-lib';
import {
	postActivateIntegrationWithCompany as postActivateContactIntegrationWithCompany,
	setActiveCompany as setActiveCompanyContact,
	setError as setErrorContact,
} from '../../../../application/slices/sincoContactIntegration';
import {
	activeCompanySelector,
	companiesSelector,
	loadingSelector,
	urlSelector,
	errorSelector,
} from '../../../../application/selector/sincoIntegration';
import { ERROR_MESSAGES_SELECT_COMPANY } from '../../../../../../shared/application/constants/messages/error-messages';
import Divider from '../../../../../../shared/presentation/components/Divider';

const Step1 = ({ slug }) => {
	const dispatch = useDispatch();

	const url = useSelector(urlSelector);
	const loading = useSelector(loadingSelector);
	const companies = useSelector(companiesSelector);
	const activeCompany = useSelector(activeCompanySelector);
	const error = useSelector(errorSelector);
	const agreementLogo = useSelector(getProjectAgreementLogo);

	const handleClick = () => {
		if (!activeCompany) {
			dispatch(setErrorContact(ERROR_MESSAGES_SELECT_COMPANY));
		} else {
			dispatch(postActivateContactIntegrationWithCompany({ slug, url, activeCompany }));
		}
	};

	const handleCompanyClick = (company) => {
		dispatch(setActiveCompanyContact(company));
	};

	return (
		<>
			<TextTag tag="p" fw="bold" className="text-center">
				Elige la empresa que deseas integrar
			</TextTag>
			<Divider />
			{companies?.map((company) => (
				<div
					key={`company${company.IdEmpresa}`}
					className={`company-card ${company === activeCompany ? 'active' : ''}`}
					onClick={() => handleCompanyClick(company)}
				>
					<div>
						<TextTag className="color-secondary" variant="-body-sm" tag="p">
							{company.Nombre}
						</TextTag>
					</div>
					<div className="logo-container">
						{agreementLogo ? <img src={agreementLogo} className="image" /> : <i className="icon fas fa-warehouse"></i>}
					</div>
					<div className="button-container">
						{company === activeCompany ? <Button>Seleccionado</Button> : <Button variant="-bordered">Elegir</Button>}
					</div>
				</div>
			))}
			<div className="action-buttons">
				{loading ? (
					<Button variant="bordered" disabled>
						<i className="fal fa-spinner fa-spin"></i>
					</Button>
				) : (
					<>
						<Button variant="default" text="Continuar" onClick={() => handleClick()} />
					</>
				)}
				{error !== '' && <MessageError message={error} showIcon={true} />}
			</div>
		</>
	);
};

Step1.propTypes = {
	slug: PropTypes.string.isRequired,
};

export default Step1;
