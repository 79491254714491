import {
	CONTACT_REQUEST_KEY,
	COTIZADOR_KEY,
	INVENTORY_KEY,
	SCHEDULER_KEY,
} from '../../../../shared/application/constants/modules_keys';

export const BOARD_COLUMNS = Object.freeze({
	INTERESTED: 'interested',
	VISITED: 'visited',
	RESERVED: 'reserved',
	SEPARATED: 'separated',
	DISCARDED: 'discarded',
	DESISTED: 'desisted',
});

export const DROPDONW_BOARD = Object.freeze({
	SELECT_TEXT: 'selectText',
	SELECT_TITLE: 'selectTitle',
	[INVENTORY_KEY]: INVENTORY_KEY,
	[CONTACT_REQUEST_KEY]: CONTACT_REQUEST_KEY,
	[SCHEDULER_KEY]: SCHEDULER_KEY,
	[COTIZADOR_KEY]: COTIZADOR_KEY,
	COPY_LINK: 'copyLink',
});

export const PREMIUM_MODAL = Object.freeze({
	BODY_TEXT: 'bodyText',
	MAX_MONTHLY_OPPORTUNITIES: 'maxMonthlyOpportunities',
});

export const SEARCH_PLACEHOLDER = 'placeholder';
export const ERROR_INPUT_MESSAGE = 'errorMessage';
export const INVALID_ACTION = 'invalid_action';
export const TODAY = 'today';
