import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { SCHEDULER_EMBEDDED } from '../../../../../../shared/application/constants/env';
import {
	importResource,
	removeMultipleResources,
	removeResource,
	useGetQueryParam,
} from '../../../../../../shared/application/helpers/common-functions';
import { selectBuyerData } from '../../../../application/selector/history';

const SchedulerEmbeddedPage = () => {
	const { slug } = useParams();
	const asesor_email = useGetQueryParam('asesor_email');
	const buyerData = useSelector(selectBuyerData);

	useEffect(() => {
		setTimeout(() => {
			importResource({
				id: 'scheduler-embeded-by-vecindario',
				script: SCHEDULER_EMBEDDED,
			});
		}, 10);
		return () => {
			removeResource('#scheduler-embeded-by-vecindario');
			removeMultipleResources('.script-my-suite-scheduler');
			removeMultipleResources('.style-my-suite-scheduler');
		};
	}, []);

	return (
		<div>
			<div
				id="agendadorByVecindario"
				data-projectslug={slug}
				data-name={buyerData?.first_name}
				data-lastname={buyerData?.last_name}
				data-email={buyerData?.email}
				data-mobile={buyerData?.mobile}
				data-adviseremail={asesor_email}
			></div>
		</div>
	);
};

export default SchedulerEmbeddedPage;
