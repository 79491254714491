import Fuse from 'fuse.js';

export const DEFAULT_OPTIONS = {
	includeScore: true,
	threshold: 0.03,
	minMatchCharLength: 2,
};

export const searchPipeline = (list, keys, options = DEFAULT_OPTIONS) => {
	return new Fuse(list, { ...options, keys });
};
