import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { Button, TextTag, Icon, Tooltip } from '@vecindario/vecindario-suite-components';
import { ASESOR_ROLE, DIRECTOR_ROLE } from '../../../../integrations/application/constants/inventory';
import {
	DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT,
	TEXTS,
	WEB_ANALISIS,
} from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import {
	externalScoringQueries,
	opportunityOptionedPeopleInQueueRoute,
	opportunityOptionedUserInformationRoute,
	schedulePropertySlug,
	simulatePropertySlug,
	webAnalisisRoute,
} from '../../../infrastructure/routes';
import { extractUserSnapshotDetail } from '../../../application/helpers/opportunityHistory';
import { advisorAssignment, updateOptionedData } from '../../../application/slices/history';
import {
	extractPropertyToOptionFromOpportunity,
	extractUserIdentificationFromOpportunity,
} from '../../../application/helpers/optionsSystem';
import { history } from '../../../../../shared/application/helpers/history';
import {
	removeValueFromLocalStorage,
	setValueToLocalStorage,
} from '../../../../../shared/application/helpers/common-functions';
import {
	LOCAL_STORAGE_KEY_OPTIONED_PROPERTY,
	LOCAL_STORAGE_KEY_OPTIONED_USER,
	OPTION_STATE_ACTIVE,
	OPTION_STATE_PENDING,
} from '../../../application/constants/optionedInformation';
import { ICON_FOLDER, ICON_INFORMATION_LINE } from '../../../../../shared/application/constants/icons';
import { STAGE } from '../../../../opportunities/application/constants/opportunities';
import { selectOptionsConfiguration } from '../../../application/selector/history';
import { financialConfiguration } from '../../../../Settings/application/selector/settings';
import MoreOptionsButton from '../MoreOptionsButton';

const HistoryHeader = ({ opportunity, setShowSelectPropertyModal }) => {
	const { t } = useTranslation();
	const [showScoringButton, setshowScoringButton] = useState(false);
	const dispatch = useDispatch();
	const currentProject = useSelector(getCurrentProject);
	const currentUser = useSelector(getCurrentUser);
	const userSnapshotDetail = extractUserSnapshotDetail(opportunity);
	const optionsConfiguration = useSelector(selectOptionsConfiguration);
	const scoring = useSelector(financialConfiguration);

	useEffect(() => {
		if (currentProject?.city) {
			const { slug: city_slug } = currentProject.city;
			setshowScoringButton(city_slug?.includes('colombia') && scoring?.scoring_active);
		}
	}, [currentProject, scoring]);

	const formRoute = (route, obj) => {
		const params = new URLSearchParams();
		Object.entries(obj).forEach(([key, value]) => {
			value && params.append(key, value);
		});
		return `${route}?${params.toString()}`;
	};

	const objQueryParamsSimulator = {
		name: userSnapshotDetail?.first_name,
		'last-name': userSnapshotDetail?.last_name,
		email: userSnapshotDetail?.email,
		phone: userSnapshotDetail?.mobile,
		'document-type': userSnapshotDetail?.identification_type,
		'document-number': userSnapshotDetail?.identification,
		asesor_email: currentUser?.email,
	};

	const simulatorUrl = formRoute(simulatePropertySlug(opportunity?.project_slug), objQueryParamsSimulator);
	const schedulerUrl = `${schedulePropertySlug(opportunity?.project_slug)}?asesor_email=${currentUser?.email}`;
	const scoringUrl = `${externalScoringQueries(opportunity?.project_slug)}?userEmail=${
		userSnapshotDetail?.email
	}&fromOutside=1`;

	const opportunityHasOpenOptions = () => {
		const hasOpenOptions = opportunity?.opportunity_histories?.some((elem) => {
			return [OPTION_STATE_PENDING, OPTION_STATE_ACTIVE].includes(elem?.action_instance?.option_state);
		});

		return !hasOpenOptions;
	};

	const adviserCanOptionedOpportunity = () => {
		return (
			currentProject?.role === ASESOR_ROLE &&
			(opportunity?.asesor === null || opportunity?.asesor_email === currentUser?.email)
		);
	};

	const handleOnoption = async () => {
		let assigned = opportunity;

		// Reset local storage values
		removeValueFromLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_USER);
		removeValueFromLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_PROPERTY);

		if (!opportunity?.asesor_email && currentProject?.role === ASESOR_ROLE) {
			const assignedResponse = await dispatch(
				advisorAssignment({
					slug: opportunity?.project_slug,
					commercial_opportunity_id: opportunity?.id,
					data: {
						asesor_email: currentUser?.email,
					},
				}),
			);
			assigned = assignedResponse?.payload;
		}

		if (currentProject?.role === DIRECTOR_ROLE || assigned?.asesor_email === currentUser?.email) {
			const propertyToOption = extractPropertyToOptionFromOpportunity({ opportunity: assigned });
			const userIdentification = extractUserIdentificationFromOpportunity({ opportunity: assigned });

			if (userIdentification) {
				dispatch(updateOptionedData(userIdentification));
				setValueToLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_USER, JSON.stringify(userIdentification));
			}

			if (propertyToOption?.property) {
				dispatch(
					updateOptionedData({ property: { ...propertyToOption?.property, lastAction: propertyToOption?.lastAction } }),
				);
				setValueToLocalStorage(
					LOCAL_STORAGE_KEY_OPTIONED_PROPERTY,
					JSON.stringify({ ...propertyToOption?.property, lastAction: propertyToOption?.lastAction }),
				);
			} else {
				dispatch(updateOptionedData({ property: {} }));
				removeValueFromLocalStorage(LOCAL_STORAGE_KEY_OPTIONED_PROPERTY);
			}

			if (userIdentification && propertyToOption?.property) {
				history.push(opportunityOptionedPeopleInQueueRoute(opportunity?.project_slug, opportunity?.id));
				return;
			}

			if (!userIdentification) {
				history.push(opportunityOptionedUserInformationRoute(opportunity?.project_slug, opportunity?.id));
				return;
			}

			if (!propertyToOption?.property) {
				setShowSelectPropertyModal(true);
			}
		}
	};

	const canShowOptionsButton = () => {
		const isOpenOpportunity = [STAGE.INTERESTED, STAGE.VISITED].includes(opportunity?.stage);
		let userRoleValidation = currentUser?.role === DIRECTOR_ROLE;
		if (currentUser?.role === ASESOR_ROLE) {
			userRoleValidation = !opportunity?.asesor_email || opportunity?.asesor_email === currentUser?.email;
		}

		return optionsConfiguration?.is_enabled && isOpenOpportunity && userRoleValidation;
	};

	const optionsButtonIsEnabled =
		(adviserCanOptionedOpportunity() || currentProject?.role === DIRECTOR_ROLE) && opportunityHasOpenOptions();

	const renderOptionsButton = () => (
		<Button
			disabled={!optionsButtonIsEnabled}
			className="action-button"
			id="opcionar-oportunidd-comercial"
			onClick={handleOnoption}
		>
			{t(`${TEXTS.OPTION}`, { ns: DOMAIN_NAME })}
		</Button>
	);

	const showAnalisisWeb = () => {
		history.push(webAnalisisRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<div className="user-card-title">
			<div className="section section-left">
				<Icon icon={ICON_FOLDER} />
				<TextTag tag="small">{t(`${TEXTS.TITLE}`, { ns: DOMAIN_NAME })}</TextTag>
			</div>
			<div className="section section-right">
				<Button className="action-button" variant="bordered" onClick={showAnalisisWeb}>
					{t(`${WEB_ANALISIS.TITLE}`, { ns: DOMAIN_NAME })}
				</Button>
				{currentProject?.role !== ASESOR_ROLE && (
					<MoreOptionsButton
						showScoringButton={showScoringButton}
						simulatorUrl={simulatorUrl}
						schedulerUrl={schedulerUrl}
						scoringUrl={scoringUrl}
					/>
				)}
				{canShowOptionsButton() &&
					(optionsButtonIsEnabled ? (
						renderOptionsButton()
					) : (
						<Tooltip
							position="left"
							text={
								<>
									<Icon icon={ICON_INFORMATION_LINE} /> {t(DISABLED_OPTIONS_BUTTON_TOOLTIP_TEXT, { ns: DOMAIN_NAME })}
								</>
							}
						>
							{renderOptionsButton()}
						</Tooltip>
					))}
			</div>
		</div>
	);
};

HistoryHeader.propTypes = {
	opportunity: PropTypes.object,
	setShowSelectPropertyModal: PropTypes.func,
};

export default HistoryHeader;
