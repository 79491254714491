import {
	MANUAL_ASSIGNMENT_DESCRIPTION,
	MANUAL_ASSIGNMENT_TITLE,
	RANDOM_ASSIGNMENT_DESCRIPTION,
	RANDOM_ASSIGNMENT_TITLE,
} from '../../../application/constants/projectConfiguration';
import { ERROR_ASESORS, SETTINGS_PAGE } from '../translation_keys';

export default {
	[SETTINGS_PAGE.TITLE]: `Configuración de asignación de oportunidades de negocio`,
	[SETTINGS_PAGE.TEXT_INFO]: `¿Cómo quieres que realice la asignación de las oportunidades de negocio que se crean a través del simulador y formulario de contacto?<br/>Los agendamientos tienen asignación por defecto`,
	[SETTINGS_PAGE[RANDOM_ASSIGNMENT_TITLE]]: 'Asignación aleatoria',
	[SETTINGS_PAGE[RANDOM_ASSIGNMENT_DESCRIPTION]]:
		'Las oportunidades de negocio serán asignadas aleatoriamente entre todos los asesores del proyecto',
	[SETTINGS_PAGE[MANUAL_ASSIGNMENT_TITLE]]: 'Asignación Manual',
	[SETTINGS_PAGE[MANUAL_ASSIGNMENT_DESCRIPTION]]:
		'Los asesores podrán asignarse manualmente las oportunidades que se encuentran en la columna interesado en el pipeline',
	[ERROR_ASESORS]: 'No se puede activar la opción porque no hay asesores del proyecto',
};
