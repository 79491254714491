import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import Tools from '../../presentacion/pages';
import { toolsRoute } from './routes';

const toolsRouter = {
	layout: AdminLayout,
	router: [
		{
			path: toolsRoute(),
			page: Tools,
			routeComponent: PrivateRoute,
		},
	],
};

export default toolsRouter;
