import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentProject, getCurrentUser } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import { ICON_INFORMATION_LINE } from '../../../../../shared/application/constants/icons';
import { history } from '../../../../../shared/application/helpers/history';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { CANCEL_OPTION_PAGE } from '../../../infrastructure/locales/translation_keys';
import { opportunityOptioneddetailsRoute } from '../../../infrastructure/routes';
import './CancelOption.scss';
import { ASESOR_ROLE } from '../../../../integrations/application/constants/inventory';
import { selectCurrentOptionSelected, selectIsLoading } from '../../../application/selector/history';
import { requestCancelOption } from '../../../application/slices/history';

const CancelOption = ({ opportunity }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const optionSnapshot = useSelector(selectCurrentOptionSelected);
	const currentUser = useSelector(getCurrentUser);
	const currentProject = useSelector(getCurrentProject);
	const isLoading = useSelector(selectIsLoading);

	const handleCancelOption = async () => {
		const data = {};
		if (currentProject?.role === ASESOR_ROLE) {
			data.asesor_email = currentUser?.email;
		}
		const optionSnapshotId = optionSnapshot?.id;
		const slug = opportunity?.project_slug;
		data.commercial_opportunity_id = opportunity?.id;
		const response = await dispatch(requestCancelOption({ slug, optionSnapshotId, data }));
		if (!response.error) history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<div className="container-cancel_option">
			<div className="header">
				<Icon aditionalClassName="icon-close" icon={ICON_INFORMATION_LINE} />
				<TextTag variant="-body-sm" fw="bold" type="label">
					{t(CANCEL_OPTION_PAGE.TITLE, { ns: DOMAIN_NAME })}
				</TextTag>
			</div>
			<div className="body">
				<TextTag variant="-body-sm" type="label">
					{t(CANCEL_OPTION_PAGE.BODY, { ns: DOMAIN_NAME })}
				</TextTag>
			</div>
			<div className="actions">
				<Button type="submit" className="button-action" onClick={handleCancelOption} disabled={isLoading}>
					{isLoading ? <Spinner /> : t(CANCEL_OPTION_PAGE.BUTTON_ACTION_1, { ns: DOMAIN_NAME })}
				</Button>
				<Button
					type="submit"
					className="button-action"
					onClick={() => history.push(opportunityOptioneddetailsRoute(opportunity?.project_slug, opportunity?.id))}
					variant="bordered"
				>
					{t(CANCEL_OPTION_PAGE.BUTTON_ACTION_2, { ns: DOMAIN_NAME })}
				</Button>
			</div>
		</div>
	);
};

CancelOption.propTypes = {
	opportunity: PropTypes.object,
};

export default CancelOption;
