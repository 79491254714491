import React, { useEffect, lazy, Suspense } from 'react';
import { Button, Icon, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ICON_NOTES_LINES } from '../../../../../shared/application/constants/icons';
import { requestListNotes, setAction } from '../../../application/slices/notes';
import { actionSelector } from '../../../application/selector/notes';
import './DetailNotesPages.scss';
import { ACTIONS } from '../../../application/constants/notes';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { OPPORTUNITY_NOTE_CARD } from '../../../infrastructure/locales/translation_keys';
import { GO_BACK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';

const DetailNote = lazy(() => import('../../components/DetailNote'));
const ListNotes = lazy(() => import('../../components/ListNotes'));
const DeleteNoteModal = lazy(() => import('../../components/DeleteNoteModal'));

const DetailNotesPages = () => {
	const dispatch = useDispatch();
	const { slug, commercial_opportunity_id } = useParams();
	const action = useSelector(actionSelector);
	const history = useHistory();
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(requestListNotes({ slug, commercial_opportunity_id }));
	}, [dispatch, slug, commercial_opportunity_id]);

	const handleNewNote = () => dispatch(setAction(ACTIONS.NEW));

	const handleBack = () => history.goBack();

	return (
		<div className="container-detail-notes">
			<Button variant="neutral" size="-small" onClick={handleBack}>
				{t(GO_BACK, { ns: DOMAIN_NAME_SHARED })}
			</Button>
			<div className="container-notes">
				<div className="header-detail">
					<div className="title">
						<Icon icon={ICON_NOTES_LINES} size="medium" />
						<TextTag variant="-body-sm" fw="bold" font="DM-sans">
							{t(OPPORTUNITY_NOTE_CARD.TITLE, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
					{action !== ACTIONS.NEW && (
						<Button size="-small" onClick={handleNewNote}>
							{t(OPPORTUNITY_NOTE_CARD.NEW_NOTE_BUTTON, { ns: DOMAIN_NAME })}
						</Button>
					)}
				</div>
				<div className="body-detail">
					<Suspense fallback={<Spinner size="x-large" className="loading" />}>
						<ListNotes />
						<DetailNote />
						<DeleteNoteModal />
					</Suspense>
				</div>
			</div>
		</div>
	);
};

export default DetailNotesPages;
