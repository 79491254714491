import {
	CONTACT_REQUEST_KEY,
	COTIZADOR_KEY,
	INVENTORY_KEY,
	SCHEDULER_KEY,
} from '../../../../shared/application/constants/modules_keys';
import {
	contactSlug,
	externalCotizadorDetailRoute,
	schedulePropertySlug,
	simulatePropertySlug,
} from '../../../opportunityHistory/infrastructure/routes';

export const STAGE = {
	INTERESTED: 'interested',
	VISITED: 'visited',
	RESERVED: 'reserved',
	DISCARDED: 'discarded',
	DESISTED: 'desisted',
	SEPARATED: 'separated',
};

export const SEARCH_KEYS = [
	'user_snapshot_detail.email',
	'user_snapshot_detail.identification',
	'user_snapshot_detail.mobile',
	'user_snapshot_detail.first_name',
	'user_snapshot_detail.last_name',
	'new_user_snapshot_detail.fullname',
	'asesor',
	'asesor_email',
];

export const LIST_ACTIONS = {
	[INVENTORY_KEY]: 'Simulador',
	[SCHEDULER_KEY]: 'Agendador',
	[CONTACT_REQUEST_KEY]: 'Formulario',
	[COTIZADOR_KEY]: 'Cotizador',
};

export const SHARE_LIST_ACTIONS = {
	[INVENTORY_KEY]: 'Simulador',
	[SCHEDULER_KEY]: 'Agendador',
	[CONTACT_REQUEST_KEY]: 'Formulario',
};

export const ROUTE_ACTION = (slug) => ({
	[INVENTORY_KEY]: simulatePropertySlug(slug),
	[SCHEDULER_KEY]: schedulePropertySlug(slug),
	[CONTACT_REQUEST_KEY]: contactSlug(slug),
	[COTIZADOR_KEY]: externalCotizadorDetailRoute(slug),
});

export const MAXIMUM_NUMBER_OPPORTUNITIES = parseInt(process.env.REACT_APP_MAXIMUM_NUMBER_OPPORTUNITIES);
