import {
	NO_USER_PERFORMER_CRONTASK,
	NO_USER_PERFORMER_LINK_GENERATION,
	PROPERTY_AVAILABILITY_AVAILABLE,
	PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION,
	PROPERTY_AVAILABILITY_DISABLED,
	PROPERTY_AVAILABILITY_OPTIONED,
	PROPERTY_AVAILABILITY_RESERVED,
	PROPERTY_AVAILABILITY_SEPARATED,
} from '../../../application/constants/property';
import {
	OPTIONS_QUEUE_PAGE,
	OPTION_ACTION_ACTION,
	OPTION_NO_PERFORMER_USER,
	PROPERTY_AVAILABILITIES,
	PROPERTY_QUEUE_TABLE_HEADERS,
} from '../translation_keys';

export default {
	[OPTIONS_QUEUE_PAGE.PAGE_TITLE]: 'Options line History for Property {{towerName}} - {{propertyReference}}',
	[OPTIONS_QUEUE_PAGE.SECTION_TITLE]: 'Property Status',
	[OPTIONS_QUEUE_PAGE.SHOW_ALL_COLUMNS_CTA]: 'Show all columns',
	[OPTIONS_QUEUE_PAGE.HIDE_ADVANCED_COLUMNS_CTA]: 'Show less columns',
	[OPTIONS_QUEUE_PAGE.VIEW_ACTIONS]: 'See options',
	[PROPERTY_QUEUE_TABLE_HEADERS.ORDER]: 'Line Position',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPPORTUNITY_NAME]: 'Opportunity Name',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_STATE]: 'Option State',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_DATE]: 'Option Valid Until',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_CREATOR_NAME]: 'Name of Option Creator',
	[PROPERTY_QUEUE_TABLE_HEADERS.ADVISOR_NAME]: 'Assigned agent',
	[PROPERTY_QUEUE_TABLE_HEADERS.OPTION_DATE]: 'Date of Option',
	[PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_DATE]: 'Last State Update Date',
	[PROPERTY_QUEUE_TABLE_HEADERS.LAST_OPTION_ACTION_NAME]: 'Updated by State',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_NAME]: 'Extended Validity by',
	[PROPERTY_QUEUE_TABLE_HEADERS.EXPIRATION_EXTENDED_DATE_ACTION]: 'Validity Extension Date',
	[PROPERTY_QUEUE_TABLE_HEADERS.ACTIONS]: 'Actions',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_OPTIONED]]: 'Optioned',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_AVAILABLE]]: 'Available',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_SEPARATED]]: 'Separated',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_RESERVED]]: 'Reserved',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_DISABLED]]: 'Disabled',
	[PROPERTY_AVAILABILITIES[PROPERTY_AVAILABILITY_BLOCKED_BY_TRANSACTION]]: 'Blocked by transaction',
	[OPTION_NO_PERFORMER_USER[NO_USER_PERFORMER_CRONTASK]]: 'System',
	[OPTION_NO_PERFORMER_USER[NO_USER_PERFORMER_LINK_GENERATION]]: 'Payment link',
	[OPTION_ACTION_ACTION.EXTENDED]: 'extended',
};
