import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrentProject, setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { Helmet, TextTag } from '@vecindario/vecindario-suite-components';
import { useDispatch, useSelector } from 'react-redux';
import CardButtonTools from '../components/CardButtonTools';
import './Tools.scss';
import { GENERATE_LINK_AGREEMENT } from '../../application/constants/tools';
import { TOOLS_PAGE } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';

const Tools = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const currentProject = useSelector(getCurrentProject);

	const handleCopy = async () => {
		try {
			if (!currentProject?.agreement?.slug) {
				dispatch(
					setMessageToast({
						type: 'error',
						message: t(TOOLS_PAGE.ERROR_PROJECT_AGREEMENT, { ns: DOMAIN_NAME }),
					}),
				);
			} else {
				const url = GENERATE_LINK_AGREEMENT(currentProject?.agreement?.slug);
				await navigator.clipboard.writeText(url);

				dispatch(
					setMessageToast({
						type: 'success',
						message: t(TOOLS_PAGE.SUCCESS_COPY_LINK, { ns: DOMAIN_NAME }),
					}),
				);
			}
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: t(TOOLS_PAGE.ERROR_COPY_LINK, { ns: DOMAIN_NAME }),
				}),
			);
		}
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Separación y reserva - Herramientas'} />
			<div className="tools-container">
				<div className="tools-container-title">
					<TextTag tag="h2" fw="bold" className="tower-container-text">
						{t(TOOLS_PAGE.TITLE, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag fw="normal" variant="-body-sm" className="integratios-subtitle-text">
						{t(TOOLS_PAGE.DESCRIPTION, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>
				<div className="tools-container-body">
					<CardButtonTools
						title={t(TOOLS_PAGE.TITLE_CARD, { ns: DOMAIN_NAME })}
						subtitle=""
						buttonText={t(TOOLS_PAGE.BUTTON_CARD, { ns: DOMAIN_NAME })}
						variant="-icon-check"
						actionCallback={handleCopy}
					/>
				</div>
			</div>
		</>
	);
};

export default Tools;
