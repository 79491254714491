import React from 'react';
import './UserNotificationCard.scss';
import MinimalNotePages from '../../../../opportunityNotes/presentation/pages/MinimalNotePages';

const UserNotificationCard = () => {
	return (
		<div className="user-notification">
			<MinimalNotePages />
		</div>
	);
};

export default UserNotificationCard;
