import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { useTranslation } from 'react-i18next';
import { PAY_LINK, CLIPBOARD_LINK } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../../../shared/infrastructure/i18n/locales';

const PaymentLinkButton = ({ historyItem = {} }) => {
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const handleClick = () => {
		navigator.clipboard.writeText(historyItem?.action_instance?.process_url);
		dispatch(setMessageToast({ type: 'success', message: t(`${CLIPBOARD_LINK}`, { ns: DOMAIN_NAME_SHARED }) }));
	};

	return (
		<button onClick={handleClick} className="action-link">
			{t(`${PAY_LINK}`, { ns: DOMAIN_NAME_SHARED })}
		</button>
	);
};

PaymentLinkButton.propTypes = {
	historyItem: PropTypes.object,
};

export default PaymentLinkButton;
