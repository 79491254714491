import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import {
	urlGeneratePaymentLink,
	urlGetOpportunityHistory,
	urlGetProjectAvailableProperties,
	urlGetProjectReservation,
	urlGetQuotationPdfUrl,
	urlGetUserHistory,
	urlPostAdvisorAssignment,
	urlPostApproveFiduciaryPayment,
	urlPostReleaseProperty,
	urlUpdateAppointmentUserSnapshot,
	urlUpdateContactAnswerSnapshot,
	urlUpdateQuotationUserSnapshot,
	urlUpdateSimulationUserSnapshot,
	urlPostCreateOption,
	urlPostConfirmPurchase,
	urlPostExtendValidityTime,
	urlPostCancelOption,
	urlGetOptionsConfiguration,
	urlGetOpportunityTrackingOns,
	UrlUpdateExternalContactSnapshot,
} from './backend-url';

export const getUserHistory = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetUserHistory(slug), requestOptions).then(handleResponse);
};

export const getOpportunityHistory = (slug, commercial_opportunity_id) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetOpportunityHistory(slug, commercial_opportunity_id), requestOptions).then(handleResponse);
};

export const getProjectReservationBySlug = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetProjectReservation(slug), requestOptions).then(handleResponse);
};

export const getProjectAvailablePropertiesBySlug = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetProjectAvailableProperties(slug), requestOptions).then(handleResponse);
};

export const updateAppointmentUserSnapshot = (id, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdateAppointmentUserSnapshot(id), requestOptions).then(handleResponse);
};

export const updateExternalContactSnapshot = (id, data) => {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(UrlUpdateExternalContactSnapshot(id), requestOptions).then(handleResponse);
};

export const updateContactAnswerSnapshot = (id, data) => {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdateContactAnswerSnapshot(id), requestOptions).then(handleResponse);
};

export const updateSimulationUserSnapshot = (id, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdateSimulationUserSnapshot(id), requestOptions).then(handleResponse);
};

export const updateQuotationUserSnapshot = (slug, quotationSnapshotId, data, extraData) => {
	data = {
		...data,
		...extraData,
	};
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlUpdateQuotationUserSnapshot(slug, quotationSnapshotId), requestOptions).then(handleResponse);
};

export const postGeneratePaymentLink = (slug, propertyId, transactionData) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(transactionData),
	};

	return fetch(urlGeneratePaymentLink(slug, propertyId), requestOptions).then(handleResponse);
};

export const postApproveFiduciaryPayment = (slug, propertyId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostApproveFiduciaryPayment(slug, propertyId), requestOptions).then(handleResponse);
};

export const postReleaseProperty = (slug, propertyId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostReleaseProperty(slug, propertyId), requestOptions).then(handleResponse);
};

export const postAdvisorAssignment = (slug, commercial_opportunity_id, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostAdvisorAssignment(slug, commercial_opportunity_id), requestOptions).then(handleResponse);
};

export const getQuotationPdfUrl = (slug, quotationId, lang = 'es') => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetQuotationPdfUrl(slug, quotationId, lang), requestOptions).then(handleResponse);
};

export const postCreateOption = (slug, propertyId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostCreateOption(slug, propertyId), requestOptions).then(handleResponse);
};

export const postConfirmPurchase = (slug, optionSnapshotId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostConfirmPurchase(slug, optionSnapshotId), requestOptions).then(handleResponse);
};

export const postExtendValidityTime = (slug, optionSnapshotId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostExtendValidityTime(slug, optionSnapshotId), requestOptions).then(handleResponse);
};

export const postCancelOption = (slug, optionSnapshotId, data) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
		body: JSON.stringify(data),
	};
	return fetch(urlPostCancelOption(slug, optionSnapshotId), requestOptions).then(handleResponse);
};

export const getOptionsConfiguration = (slug, quotationId, lang = 'es') => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetOptionsConfiguration(slug, quotationId, lang), requestOptions).then(handleResponse);
};

export const getOpportunityTrackingOns = (slug, quotationId, lang = 'es') => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};
	return fetch(urlGetOpportunityTrackingOns(slug, quotationId, lang), requestOptions).then(handleResponse);
};
