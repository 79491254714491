export const connectFBRoute = (slug = ':slug') => `/proyectos/${slug}/cuenta-fb/conectar`;
export const accountFBRoute = (slug = ':slug') => `/proyectos/${slug}/cuenta-fb`;

export const disconnectAccountRoute = (slug = ':slug') => `/proyectos/${slug}/cuenta-fb/desconectar`;
export const deleteAccountRoute = (slug = ':slug') => `/proyectos/${slug}/cuenta-fb/eliminar`;

// Dejar sin parámetros porque FB maneja las rutas dinámicas de una forma específica
export const selectAccountsFBRoute = () => `/proyectos/cuenta-fb/seleccionar`;

export const disconnectCampaignRoute = (slug = ':slug') => `/proyectos/${slug}/desconectar-campaing`;
export const deleteCampaignRoute = (slug = ':slug') => `/proyectos/${slug}/eliminar-campaing`;
