import React from 'react';
import PropTypes from 'prop-types';
import { Card, Icon, TextTag, ToggleSwitch } from '@vecindario/vecindario-suite-components';
import './ConfigurationCard.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { putRequestConfiguration } from '../../../application/slices/settings';
import { projectConfigurationSelector } from '../../../application/selector/settings';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { SETTINGS_PAGE } from '../../../infrastructure/locales/translation_keys';

function ConfigurationCard({ icon, title, description, idToggle, valueToggle, defaultChecked = false }) {
	const dispatch = useDispatch();
	const projectConfiguration = useSelector(projectConfigurationSelector);
	const { t } = useTranslation();

	const handleChange = (element) => {
		const { checked } = element.target;
		const value = checked ? valueToggle : '';

		const names = document.getElementsByClassName('toggle-switch-checkbox');
		for (let i = 0; i < names.length; i++) {
			if (names[i].id !== `toggle-switch-${idToggle}`) {
				names[i].click();
			}
		}
		if (value)
			dispatch(
				putRequestConfiguration({
					id: projectConfiguration?.id,
					slug: projectConfiguration?.project_slug,
					data: {
						asignation_type: value,
					},
				}),
			);
	};

	return (
		<Card classNameCardBody="configuration-card-container">
			<Icon icon={icon} size="large" />
			<div className="info-container">
				<TextTag tag="h6" fw="bold" variant="-body" font="DM-sans">
					{t(`${SETTINGS_PAGE[title]}`, { ns: DOMAIN_NAME })}
				</TextTag>
				<ToggleSwitch id={idToggle} onChange={(item) => handleChange(item)} defaultChecked={defaultChecked} />
			</div>
			<TextTag fw="normal" variant="-body" className="color-secondary-600t">
				{t(`${SETTINGS_PAGE[description]}`, { ns: DOMAIN_NAME })}
			</TextTag>
		</Card>
	);
}

ConfigurationCard.propTypes = {
	icon: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	idToggle: PropTypes.string.isRequired,
	valueToggle: PropTypes.any.isRequired,
	defaultChecked: PropTypes.bool,
};

export default ConfigurationCard;
