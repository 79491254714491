import React, { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '@vecindario/vecindario-suite-components';

import HeaderMinimalNote from '../../components/HeaderMinimal';
import { actionSelector } from '../../../application/selector/notes';
import { requestListNotes } from '../../../application/slices/notes';
import './MinimalNotePages.scss';
import { ACTIONS } from '../../../application/constants/notes';

const LastNote = lazy(() => import('../../components/LastNote'));
const NoteEditor = lazy(() => import('../../components/NoteEditor'));

const MinimalNotePages = () => {
	const dispatch = useDispatch();
	const action = useSelector(actionSelector);
	const { slug, commercial_opportunity_id } = useParams();

	useEffect(() => {
		dispatch(requestListNotes({ slug, commercial_opportunity_id }));
	}, [dispatch, slug, commercial_opportunity_id]);

	return (
		<div className="container-minimal-pages">
			<HeaderMinimalNote />
			<div className="container-note-body">
				<Suspense fallback={<Spinner size="large" className="loading" />}>
					{action === ACTIONS.NEW ? <NoteEditor /> : <LastNote />}
				</Suspense>
			</div>
		</div>
	);
};

export default MinimalNotePages;
