import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Icon, TextTag } from '@vecindario/vecindario-suite-components';
import InformativeNotice from '../InformativeNotice';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import TableAccounts from './TableAccounts';
import { selectAccount } from '../../../application/selector/facebook';

const AccountsSection = ({ btnShowCampaign, setShowCampaign, handleShowCampaign }) => {
	const { t } = useTranslation();
	const account = useSelector(selectAccount);

	return (
		<div className="accounts-fb-leads-container">
			<div className="header">
				<TextTag tag="h5">{t(FACEBOOK.INTEGRATION_NAME, { ns: DOMAIN_NAME })}</TextTag>
			</div>
			<div className="accounts-fb-card">
				<div className="accounts-table">
					<TableAccounts setShowCampaign={setShowCampaign} />
				</div>

				{!account.is_active && (
					<InformativeNotice>
						<Icon icon="ri-alert-line" size="small" />
						<TextTag tag="span" variant="-body-sm">
							{t(FACEBOOK.ACCOUNTS_CONNECT_INFORMATIVE_NOTICE, { ns: DOMAIN_NAME })}
						</TextTag>
					</InformativeNotice>
				)}

				{btnShowCampaign && account.is_active && (
					<div className="connect-campaign-cta">
						<div className="text">
							<Icon icon="ri-git-merge-line" size="medium" />
							<TextTag>{t(FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_TEXT, { ns: DOMAIN_NAME })}</TextTag>
						</div>
						<Button size="-small" onClick={handleShowCampaign}>
							{t(FACEBOOK.ACCOUNTS_CONNECT_CAMPAIGN_BUTTON, { ns: DOMAIN_NAME })}
						</Button>
					</div>
				)}
			</div>
		</div>
	);
};

AccountsSection.propTypes = {
	btnShowCampaign: PropTypes.bool,
	setShowCampaign: PropTypes.func,
	handleShowCampaign: PropTypes.func,
};

export default AccountsSection;
