import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import * as i18nKeys from '../../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../../infrastructure/locales';
import { SCHEDULE_APPOINTMENT_TYPES } from '../../../../../application/constants/opportunityHistory';
import { formatDate } from '../../../../../../../shared/application/helpers/common-functions';
import { COMMON_DATE_WITH_12_HOUR } from '../../../../../../../shared/application/constants/dates';

const ColumnDetailSchedule = ({ item }) => {
	const { t } = useTranslation();
	const appointmentTypeKey =
		item?.action_instance?.appointment_type === SCHEDULE_APPOINTMENT_TYPES.video_call
			? i18nKeys.APPOINTMENT_DIGITAL_TYPE_TEXT
			: i18nKeys.APPOINTMENT_PHYSICAL_TYPE_TEXT;

	return (
		<TextTag variant="-body-sm" className="detail-movedto-text">
			<table className="detail-table">
				<tbody className="body">
					<tr className="row">
						<th className="label">{t(i18nKeys.TOOLTIP_MSG_DATE_PROGRAMED, { ns: DOMAIN_NAME })}</th>
						<td className="value">{formatDate(item?.action_instance?.appointment_date, COMMON_DATE_WITH_12_HOUR)}</td>
					</tr>
					<tr className="row">
						<th className="label">{t(i18nKeys.TOOLTIP_MSG_APPOINTMENT_TYPE, { ns: DOMAIN_NAME })}</th>
						<td className="value">{t(appointmentTypeKey, { ns: DOMAIN_NAME })}</td>
					</tr>
				</tbody>
			</table>
		</TextTag>
	);
};

ColumnDetailSchedule.propTypes = {
	item: PropTypes.object,
};

export default ColumnDetailSchedule;
