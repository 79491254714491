import React from 'react';
import PropTypes from 'prop-types';
import SeeDetailsButton from '../../../SeeDetailsButton';

const ColumnActionSeparated = ({ item }) => {
	return (
		<div className="reserve-actions">
			<SeeDetailsButton item={item} />
		</div>
	);
};

ColumnActionSeparated.propTypes = {
	item: PropTypes.object,
};

export default ColumnActionSeparated;
