import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import {
	DOCUMENT_TYPE_REGEX,
	INVALID_DOCUMENT_CE_FORMAT,
	INVALID_DOCUMENT_P_FORMAT,
	userInformationFields,
} from '../../constants/userInformation';
import { EMPTY_OPTION } from '../../../../../shared/infrastructure/i18n/locales/translation_keys';
import { MODAL_USER_IDENTIFICATION } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';

const InvalidLengthValueRange = ({ minValue, maxValue }) => {
	const { t } = useTranslation();
	return <>{t(MODAL_USER_IDENTIFICATION.INVALID_VALUE_LENGTH_RANGE, { ns: DOMAIN_NAME, minValue, maxValue })}</>;
};

InvalidLengthValueRange.propTypes = {
	minValue: PropTypes.number,
	maxValue: PropTypes.number,
};

const EmptyField = () => {
	const { t } = useTranslation();
	return <>{t(MODAL_USER_IDENTIFICATION.EMPTY_FIELD, { ns: DOMAIN_NAME })}</>;
};

export const userInformationValidation = (actionType) => {
	const fieldsValidation = {};
	const fieldsNames = userInformationFields(actionType);
	fieldsValidation[fieldsNames.DOCUMENT_TYPE] = yup.string().nullable().required(EMPTY_OPTION);
	fieldsValidation[fieldsNames.DOCUMENT] = yup
		.string()
		.nullable()
		.required(<EmptyField />)
		.when(fieldsNames.DOCUMENT_TYPE, (document_type, schema) => {
			switch (document_type) {
				case 'CC':
					return schema.matches(DOCUMENT_TYPE_REGEX.CC, {
						excludeEmptyString: true,
						message: <InvalidLengthValueRange minValue={6} maxValue={10} />,
					});
				case 'NIT':
					return schema.matches(DOCUMENT_TYPE_REGEX.NIT, {
						excludeEmptyString: true,
						message: <InvalidLengthValueRange minValue={6} maxValue={10} />,
					});
				case 'CE':
					return schema.matches(DOCUMENT_TYPE_REGEX.CE, { excludeEmptyString: true, message: INVALID_DOCUMENT_CE_FORMAT });
				case 'TI':
					return schema.matches(DOCUMENT_TYPE_REGEX.TI, {
						excludeEmptyString: true,
						message: <InvalidLengthValueRange minValue={5} maxValue={12} />,
					});
				case 'P':
					return schema.matches(DOCUMENT_TYPE_REGEX.P, { excludeEmptyString: true, message: INVALID_DOCUMENT_P_FORMAT });
				default:
					return schema;
			}
		});

	return fieldsValidation;
};

export const userInformationSchema = (actionType) => {
	return yup.object().shape(userInformationValidation(actionType));
};

export const SET_VALUE_OPTIONS = {
	shouldValidate: true,
	shouldDirty: true,
};

export const setManualError = (message) => {
	return { type: 'manual', message };
};
