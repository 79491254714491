import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BUYER_DATA } from '../../../../shared/application/constants/application';
import { getValueFromLocalStorage } from '../../../../shared/application/helpers/common-functions';
import {
	getOpportunityHistory,
	getUserHistory,
	postApproveFiduciaryPayment,
	postReleaseProperty,
	postAdvisorAssignment,
	postCreateOption,
	postExtendValidityTime,
	postCancelOption,
	getOpportunityTrackingOns,
} from '../../infrastructure/api/history';

const buyerDataLocal = JSON.parse(getValueFromLocalStorage(BUYER_DATA));

export const initialState = {
	history: {},
	userHistory: [],
	isLoading: false,
	errors: {},
	projectReservation: null,
	loadingPayment: false,
	loadingReleaseProperty: false,
	buyerData: buyerDataLocal || {},
	optionedData: {},
	currentOptionSelected: null,
	optionsConfiguration: null,
	opportunityTracking: {},
};

export const requestOpportunityHistory = createAsyncThunk(
	'request/opportunity/cohistory',
	async ({ slug, commercial_opportunity_id }, { rejectWithValue }) => {
		try {
			return await getOpportunityHistory(slug, commercial_opportunity_id);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const requestUserHistory = createAsyncThunk(
	'request/opportunity/userhistory',
	async ({ slug }, { rejectWithValue }) => {
		try {
			return await getUserHistory(slug);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const approveFiduciaryPayment = createAsyncThunk(
	'request/approveFiduciaryPayment',
	async ({ slug, propertyId, data }, { rejectWithValue }) => {
		try {
			return await postApproveFiduciaryPayment(slug, propertyId, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const releaseProperty = createAsyncThunk(
	'request/releaseProperty',
	async ({ slug, propertyId, data }, { rejectWithValue }) => {
		try {
			return await postReleaseProperty(slug, propertyId, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const advisorAssignment = createAsyncThunk(
	'request/advisorAssignment',
	async ({ slug, commercial_opportunity_id, data }, { rejectWithValue }) => {
		try {
			return await postAdvisorAssignment(slug, commercial_opportunity_id, data);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

export const createOption = createAsyncThunk(
	'request/createOption',
	async ({ slug, propertyId, data }, { rejectWithValue, dispatch }) => {
		try {
			return await postCreateOption(slug, propertyId, data);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const requestExtendValidityTime = createAsyncThunk(
	'request/extendValidityTime',
	async ({ slug, optionSnapshotId, newData }, { rejectWithValue, dispatch }) => {
		try {
			return await postExtendValidityTime(slug, optionSnapshotId, newData);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const requestCancelOption = createAsyncThunk(
	'request/cancelOption',
	async ({ slug, optionSnapshotId, data }, { rejectWithValue, dispatch }) => {
		try {
			return await postCancelOption(slug, optionSnapshotId, data);
		} catch (error) {
			dispatch(
				setMessageToast({
					type: 'error',
					message: error,
				}),
			);
			return rejectWithValue(error);
		}
	},
);

export const requestOpportunityTracking = createAsyncThunk(
	'request/opportunity/trackin-ons',
	async ({ slug, commercial_opportunity_id }, { rejectWithValue }) => {
		try {
			return await getOpportunityTrackingOns(slug, commercial_opportunity_id);
		} catch (error) {
			return rejectWithValue(error);
		}
	},
);

const History = createSlice({
	name: 'history',
	initialState,
	reducers: {
		setCurrentHistory(state, { payload }) {
			state.history = payload;
		},
		setProjectReservation(state, { payload }) {
			state.projectReservation = payload;
		},
		setOpportunityHistoryUserSnapshotDetail(state, { payload }) {
			state.history.opportunity_histories = state.history.opportunity_histories.map((item) => {
				if (item?.id === payload?.history_id) {
					item.action_instance.user_snapshot_detail = payload?.snapshot;
				}
				return item;
			});
		},
		appendTransaction(state, { payload }) {
			state.history.transactions = [...state.history.transactions, payload];
		},
		setBuyerData(state, { payload }) {
			state.buyerData = payload;
		},
		updateOptionedData(state, { payload }) {
			state.optionedData = { ...state.optionedData, ...payload };
		},
		resetOptionedData(state) {
			state.optionedData = {};
		},
		setCurrentOptionSelected(state, { payload }) {
			state.currentOptionSelected = payload;
		},
		appendOpportunityHistories(state, { payload }) {
			state.history.opportunity_histories = [...state.history.opportunity_histories, payload];
		},
		setOptionsConfiguration(state, { payload }) {
			state.optionsConfiguration = payload;
		},
		resetCurrentOptionSelected(state) {
			state.currentOptionSelected = {};
		},
	},
	extraReducers: {
		[requestUserHistory.pending](state) {
			state.isLoading = true;
		},
		[requestUserHistory.rejected](state) {
			state.isLoading = false;
		},
		[requestUserHistory.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.userHistory = payload;
		},
		[requestOpportunityHistory.pending](state) {
			state.isLoading = true;
		},
		[requestOpportunityHistory.rejected](state) {
			state.isLoading = false;
		},
		[requestOpportunityHistory.fulfilled](state, { payload }) {
			state.isLoading = false;
			state.history = payload;
		},
		[approveFiduciaryPayment.pending]: (state) => {
			state.loadingPayment = true;
		},
		[approveFiduciaryPayment.rejected]: (state) => {
			state.loadingPayment = false;
		},
		[approveFiduciaryPayment.fulfilled]: (state, { payload }) => {
			state.loadingPayment = false;
		},
		[releaseProperty.pending]: (state) => {
			state.loadingReleaseProperty = true;
		},
		[releaseProperty.rejected]: (state) => {
			state.loadingReleaseProperty = false;
		},
		[releaseProperty.fulfilled]: (state) => {
			state.loadingReleaseProperty = false;
		},
		[advisorAssignment.pending]: (state) => {
			state.isLoading = true;
		},
		[advisorAssignment.rejected]: (state) => {
			state.isLoading = false;
		},
		[advisorAssignment.fulfilled]: (state, { payload }) => {
			state.isLoading = false;
			state.history = payload;
		},
		[createOption.pending]: (state) => {
			state.isLoading = true;
		},
		[createOption.rejected]: (state) => {
			state.isLoading = false;
		},
		[createOption.fulfilled]: (state, { payload }) => {
			state.isLoading = false;

			const history_added = [...state.history.opportunity_histories, payload?.opportunity_history];
			state.history = { ...state.history, opportunity_histories: history_added };
			state.currentOptionSelected = payload?.option_snapshot;
		},
		[requestExtendValidityTime.pending]: (state) => {
			state.isLoading = true;
		},
		[requestExtendValidityTime.rejected]: (state) => {
			state.isLoading = false;
		},
		[requestExtendValidityTime.fulfilled]: (state, { payload }) => {
			const histories = [...state.history.opportunity_histories]?.map((elem) => {
				return elem?.id === payload.opportunity_history?.id ? payload?.opportunity_history : elem;
			});

			state.isLoading = false;
			state.history.opportunity_histories = histories;
			state.history.commercial_opportunity = {
				...state.history.commercial_opportunity,
				...payload.commercial_opportunity,
			};
			state.currentOptionSelected = { ...state.currentOptionSelected, ...payload.option_snapshot };
		},
		[requestCancelOption.pending]: (state) => {
			state.isLoading = true;
		},
		[requestCancelOption.rejected]: (state) => {
			state.isLoading = false;
		},
		[requestCancelOption.fulfilled]: (state, { payload }) => {
			const histories = [...state.history.opportunity_histories]?.map((elem) => {
				return elem?.id === payload.opportunity_history?.id ? payload?.opportunity_history : elem;
			});

			state.isLoading = false;
			state.history.opportunity_histories = histories;
			state.currentOptionSelected = { ...state.currentOptionSelected, ...payload.option_snapshot };
		},
		[requestOpportunityTracking.fulfilled]: (state, { payload }) => {
			state.opportunityTracking = payload;
		},
		[requestOpportunityTracking.pending]: (state) => {
			state.isLoading = true;
		},
		[requestOpportunityTracking.rejected]: (state) => {
			state.isLoading = false;
		},
	},
});

export const {
	setCurrentHistory,
	setProjectReservation,
	setOpportunityHistoryUserSnapshotDetail,
	appendTransaction,
	setBuyerData,
	updateOptionedData,
	resetOptionedData,
	appendOpportunityHistories,
	setCurrentOptionSelected,
	setOptionsConfiguration,
	resetCurrentOptionSelected,
} = History.actions;

export default History.reducer;
