import React, { useEffect } from 'react';
import { Helmet } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { accountFBRoute } from '../../../infrastructure/routes';
import { selectAccount } from '../../../application/selector/facebook';
import AlertCard from '../../components/AlertCard';
import { FACEBOOK } from '../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import { setInitialState, updateDisconnectAccount } from '../../../application/slices/facebook';
import { removeValueFromLocalStorage } from '../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../application/constants/adsAccount';
import { opportunityRoute } from '../../../../opportunities/infrastructure/routes';

const DisconnectAccountPage = () => {
	const { slug } = useParams();
	const history = useHistory();
	const prevSlug = history.location.state?.slug;
	const { t } = useTranslation();
	const account = useSelector(selectAccount);
	const dispatch = useDispatch();
	const historyState = { slug };

	useEffect(() => {
		if (prevSlug !== slug) {
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(setInitialState());
			history.push(opportunityRoute(slug));
		}
	}, [prevSlug, slug, dispatch, history]);

	const handleCancelDisconnect = async () => {
		history.push(accountFBRoute(slug), historyState);
	};

	const handleDisconnectAccount = () => {
		dispatch(
			updateDisconnectAccount({
				slug,
				accountId: account?.id,
			}),
		);
		history.push(accountFBRoute(slug), historyState);
	};

	return (
		<>
			<Helmet title={'Vecindario Suite - Facebook Leads - Desconectar cuenta'} />
			<AlertCard
				cardTitle={t(FACEBOOK.DISCONNECT_ACCOUNT_TITLE, { ns: DOMAIN_NAME, accountName: account?.name })}
				buttonActionText={t(FACEBOOK.DISCONNECT_CAMPAIGN_BUTTON, { ns: DOMAIN_NAME })}
				handleCancel={handleCancelDisconnect}
				handleAction={handleDisconnectAccount}
			>
				<p>{t(FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_0, { ns: DOMAIN_NAME })}</p>
				<p>{t(FACEBOOK.DISCONNECT_ACCOUNT_TEXT_DESC_1, { ns: DOMAIN_NAME })}</p>
				<br />
				<ul>
					<li>{t(FACEBOOK.DISCONNECT_ACCOUNT_ITEM_0, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DISCONNECT_ACCOUNT_ITEM_1, { ns: DOMAIN_NAME })}</li>
					<li>{t(FACEBOOK.DISCONNECT_ACCOUNT_ITEM_2, { ns: DOMAIN_NAME })}</li>
				</ul>
			</AlertCard>
		</>
	);
};

export default DisconnectAccountPage;
