import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { TextTag, Icon } from '@vecindario/vecindario-suite-components';
import { ICON_CLOSE_LINE } from '../../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import './ErrorCard.scss';
import { INTEGRATION_HUBSPOT } from '../../../../infrastructure/locales/translation_keys';

const ErrorCard = ({ errors }) => {
	const { t } = useTranslation();
	return (
		<div className="container-error-card">
			<TextTag variant="-body-sm" fw="bold">
				{t(INTEGRATION_HUBSPOT.CARD_ERROR_TITLE, { ns: DOMAIN_NAME })}
			</TextTag>
			{errors.map((error, index) => {
				if (error?.message) {
					<div key={index} className="error-row">
						<Icon icon={ICON_CLOSE_LINE} aditionalClassName="icon-error" />
						<TextTag variant="-body-sm">{error?.message}</TextTag>
					</div>;
				}
				return (
					<div key={index} className="error-row">
						<Icon icon={ICON_CLOSE_LINE} aditionalClassName="icon-error" />
						<TextTag variant="-body-sm">{t(error?.key, { ns: DOMAIN_NAME, error: error?.property })}</TextTag>
					</div>
				);
			})}
			<TextTag variant="-body-sm">{t(INTEGRATION_HUBSPOT.CARD_ERROR_FOOTER, { ns: DOMAIN_NAME })}</TextTag>
		</div>
	);
};

ErrorCard.propTypes = {
	errors: PropTypes.array,
};

export default ErrorCard;
