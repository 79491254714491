import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table } from '@vecindario/vecindario-suite-components';

const TableIntegration = ({ headersTable, rowsDataTable }) => {
	const tableHeaders = useMemo(() => {
		const headers = headersTable;
		return headers;
	}, [headersTable]);

	const getAccountsTableData = useMemo(() => {
		return rowsDataTable();
	}, [rowsDataTable]);

	return (
		<Table
			tableHeaders={tableHeaders}
			tableKeys={Object.keys(tableHeaders)}
			tableData={getAccountsTableData || []}
			withPagination={false}
			stripedColor={'white'}
		/>
	);
};

TableIntegration.propTypes = {
	headersTable: PropTypes.object,
	rowsDataTable: PropTypes.func,
};

export default TableIntegration;
