import { Helmet, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ObjectConfigurationCard } from '../../application/constants/projectConfiguration';
import { loadingSelector, projectConfigurationSelector } from '../../application/selector/settings';
import { getRequestConfiguration } from '../../application/slices/settings';
import ConfigurationCard from '../components/ConfigurationCard';
import './Settings.scss';
import { DOMAIN_NAME } from '../../infrastructure/locales';
import { SETTINGS_PAGE } from '../../infrastructure/locales/translation_keys';

function Settings() {
	const dispatch = useDispatch();
	const { slug } = useParams();
	const projectConfiguration = useSelector(projectConfigurationSelector);
	const loading = useSelector(loadingSelector);
	const { t } = useTranslation();

	useEffect(() => {
		dispatch(getRequestConfiguration(slug));
	}, [slug, dispatch]);

	const defaultChecked = (value) => projectConfiguration?.asignation_type === value;

	return (
		<>
			<Helmet title={'Vecindario Suite - Oportunidades de negocio - Configuración'} />
			<div className="configuration-container">
				<div className="configuration-container-title">
					<TextTag tag="h2" fw="bold" className="configuration-container-text">
						{t(`${SETTINGS_PAGE.TITLE}`, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag fw="normal" variant="-body" className="configuration-subtitle-text">
						<span
							dangerouslySetInnerHTML={{
								__html: t(`${SETTINGS_PAGE.TEXT_INFO}`, { ns: DOMAIN_NAME }),
							}}
						/>
					</TextTag>
				</div>
				{loading && Object.keys(projectConfiguration).length === 0 ? (
					<Spinner size="x-large" />
				) : (
					<div className="configuration-container-body">
						{ObjectConfigurationCard.map((item) => (
							<ConfigurationCard
								key={item.id}
								idToggle={item.id}
								title={item.title}
								description={item.description}
								icon={item.icon}
								valueToggle={item.value}
								defaultChecked={defaultChecked(item.value)}
							/>
						))}
					</div>
				)}
			</div>
		</>
	);
}

export default Settings;
