import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { Button, Icon, Select, Spinner, TextTag } from '@vecindario/vecindario-suite-components';
import InformativeNotice from '../../InformativeNotice';
import { FACEBOOK } from '../../../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { selectAccount, selectAdsCampaigns, selectListCampaignsAds } from '../../../../application/selector/facebook';
import { postConnectCampaign, setInitialState } from '../../../../application/slices/facebook';
import { AUTH_EXCEPTION } from '../../../../application/constants/facebookErrors';
import { connectFBRoute } from '../../../../infrastructure/routes';
import { removeValueFromLocalStorage } from '../../../../../../shared/application/helpers/common-functions';
import { ADS_ACCOUNT } from '../../../../application/constants/adsAccount';

const InformativeNoticeSection = () => {
	const { t } = useTranslation();

	return (
		<InformativeNotice>
			<div className="content-info">
				<TextTag tag="p" variant="-body-sm">
					{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_1, { ns: DOMAIN_NAME })}
				</TextTag>
				<ul className="list-values">
					<li>
						<TextTag tag="p" variant="-body-sm">
							{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_2, { ns: DOMAIN_NAME })}
						</TextTag>
					</li>
					<li>
						<TextTag tag="p" variant="-body-sm">
							{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_3, { ns: DOMAIN_NAME })}
						</TextTag>
					</li>
					<li>
						<TextTag tag="p" variant="-body-sm">
							{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_4, { ns: DOMAIN_NAME })}
						</TextTag>
					</li>
					<li>
						<TextTag tag="p" variant="-body-sm">
							{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_5, { ns: DOMAIN_NAME })}
						</TextTag>
					</li>
					<li>
						<TextTag tag="p" variant="-body-sm">
							{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_6, { ns: DOMAIN_NAME })}
						</TextTag>
					</li>
				</ul>
				<TextTag tag="p" variant="-body-sm">
					{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_7, { ns: DOMAIN_NAME })}{' '}
					<a
						href="https://mesadeayuda.vecindario.com/es/base-de-conocimiento/como-recibir-tus-leads-de-meta-en-vecindario"
						target="_blank"
						rel="noreferrer"
					>
						{t(FACEBOOK.CAMPAIGNS_LIST_INFORMATIVE_8, { ns: DOMAIN_NAME })}
					</a>
				</TextTag>
			</div>
		</InformativeNotice>
	);
};

const SelectCampaign = ({ handleCloseCampaign, setShowCampaigns }) => {
	const { slug } = useParams();
	const { t } = useTranslation();
	const history = useHistory();
	const account = useSelector(selectAccount);
	const campaigns = useSelector(selectAdsCampaigns);
	const { listCampaignsAds, isLoading, error } = useSelector(selectListCampaignsAds);
	const dispatch = useDispatch();
	const [showBtnConnect, setShowBtnConnect] = useState(true);
	const [selectedCampaigns, setSelectedCampaigns] = useState({});
	const historyState = useMemo(() => ({ slug }), [slug]);

	useEffect(() => {
		if (error && error === AUTH_EXCEPTION) {
			dispatch(setInitialState());
			removeValueFromLocalStorage(ADS_ACCOUNT);
			dispatch(
				setMessageToast({
					type: 'error',
					message: t(FACEBOOK.SESSION_EXPIRATION, { ns: DOMAIN_NAME }),
				}),
			);
			history.push(connectFBRoute(slug), historyState);
		}
	}, [error, history, slug, dispatch, t, historyState]);

	const handleSelectCampaign = (campaign) => {
		const campaignSelected = listCampaignsAds.find((item) => item.id === campaign.value);
		setSelectedCampaigns(campaignSelected);
		if (campaigns?.length < 5) {
			setShowBtnConnect(false);
		}
	};

	const optionCampaigns = () => {
		const listCampaigns = listCampaignsAds.filter(
			(campaign) => !campaigns?.some((value) => value.campaign_id === campaign.id),
		);
		return listCampaigns?.map((item) => ({
			label: item?.name,
			value: item?.id,
		}));
	};

	const handleConnectCampaign = () => {
		const data = {
			campaign_name: selectedCampaigns?.name,
			campaign_status: selectedCampaigns?.status,
			campaign_start_time: selectedCampaigns?.start_time,
			campaign_stop_time: selectedCampaigns?.stop_time,
			campaign_id: selectedCampaigns?.id,
		};
		dispatch(postConnectCampaign({ slug, provider_account_id: account?.id, data }));
		setShowCampaigns(true);
	};

	const handleCancelConnectCampaign = () => {
		setSelectedCampaigns({});
		setShowBtnConnect(true);
		setShowCampaigns(true);
	};

	return (
		<div className="select-campaign-container">
			<div className="header">
				<TextTag tag="h5">{t(FACEBOOK.CAMPAIGNS_TITLE_V1, { ns: DOMAIN_NAME })}</TextTag>
				<Button className="btn-close" size="-small" onClick={handleCloseCampaign}>
					<Icon icon="ri-close-line" size="medium" />
				</Button>
			</div>
			<div className="campaigns-fb-card">
				{isLoading ? (
					<div className="loading-container">
						<Spinner size="x-large" />
					</div>
				) : (
					<>
						{listCampaignsAds?.length > 0 ? (
							<div className="campaigns-list-secttion">
								<div className="campaigns-list">
									<TextTag tag="p" variant="-body-sm">
										{t(FACEBOOK.CAMPAIGNS_LIST_TITLE, { ns: DOMAIN_NAME })}
									</TextTag>
									<Select
										defaultOptionValue={selectedCampaigns?.id}
										id="select-campaign"
										onSelectItem={handleSelectCampaign}
										options={optionCampaigns()}
										showFinder
									/>
								</div>

								<InformativeNoticeSection />

								<div className="connect-campaign-cta">
									<div className="btn-group">
										<Button size="-small" variant="bordered" onClick={handleCancelConnectCampaign}>
											{t(FACEBOOK.CAMPAIGNS_LIST_CANCEL_BUTTON, { ns: DOMAIN_NAME })}
										</Button>
										<Button size="-small" disabled={showBtnConnect} onClick={handleConnectCampaign}>
											{t(FACEBOOK.CAMPAIGNS_LIST_CONNECT_BUTTON, { ns: DOMAIN_NAME })}
										</Button>
									</div>
								</div>
							</div>
						) : (
							<div className="campaigns-fb-no-found">
								<Icon icon="ri-alert-line" size="medium" />
								<TextTag tag="span" variant="-body-sm">
									{t(FACEBOOK.CAMPAIGNS_FB_NO_FOUND, { ns: DOMAIN_NAME })}
								</TextTag>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};

SelectCampaign.propTypes = {
	handleCloseCampaign: PropTypes.func,
	setShowCampaigns: PropTypes.func,
};

export default SelectCampaign;
