import { AdminLayout, PrivateRoute } from '@vecindario/suite-dashboard-layout-lib';
import BoardPage from '../presentation/pages/Board';
import { opportunityRoute } from './routes';

const opportunitiesRouter = {
	layout: AdminLayout,
	router: [
		{
			path: opportunityRoute(),
			page: BoardPage,
			routeComponent: PrivateRoute,
		},
	],
};

export default opportunitiesRouter;
