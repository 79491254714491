import { helpers, language, ga4 } from '@vecindario/suite-library';
import { urlRegex } from '../../../domains/integrations/application/constants/inventory';
import { CURRENT_PROJECT_SLUG } from '../constants/application';
import { STAGE_CLASSES, STAGES } from '../../../domains/opportunityHistory/application/constants/opportunityHistory';

export const {
	formatCurrency,
	getValueFromLocalStorage,
	importResource,
	removeResource,
	removeMultipleResources,
	numberWithDots,
	setValueToLocalStorage,
	filterData,
	removeValueFromLocalStorage,
	getFirstWord,
	capitalize,
	sortBy,
	getCurrerntPath,
	locationToParams,
	removeQuery,
	checkCookieExistence,
	getValueFromSessionStorage,
	removeValueFromSessionStorage,
	setValueToSessionStorage,
	importDynamic,
	saveUtmIntoLocalStorage,
	removeUtmFromLocalStorage,
} = helpers.commonFunctions;
export const { getGAEventLocation } = ga4;

export const { isSameDay, getDatesDifference, DIFFERENCE_FORMATS, formatDate, MOMENT_FORMATS } = helpers.dateFunctions;

export const getCurrentProjectSlug = () => {
	return checkCookieExistence(CURRENT_PROJECT_SLUG);
};

export const getStateText = (stage) => {
	return STAGES[stage];
};

export const getStateClass = (stage) => {
	return STAGE_CLASSES[stage] || 'badge-success';
};

export const isUrl = (url) => (url ? urlRegex.test(url) : url);

export const getFullName = (user) => {
	return `${user?.first_name || user?.name || ''} ${user?.last_name || user?.lastname || ''}`;
};

export const formattedTimeInMinutes = (totalMinutes) => {
	const minutes = totalMinutes % 60;
	const minutesText = 'Min';

	const hours = Math.floor(totalMinutes / 60);
	const hoursText = 'Hr';

	if (totalMinutes > 60) {
		return minutes > 0 ? `${hours} ${hoursText} y ${minutes} ${minutesText}` : `${hours} ${hoursText}`;
	}

	if (hours > 0) return `${hours} ${hoursText}`;

	return `${minutes} ${minutesText}`;
};

export const useGetQueryParam = (queryName) => {
	const query = locationToParams(window.location.search);
	return query.get(queryName);
};

export const { LOCALES, getConfigI18n } = language;

export const generateHex16 = () => {
	const randomNumber = Math.random() * 16 ** 16;
	return randomNumber.toString(16).padStart(16, '0');
};
