import {
	MANUAL_ASSIGNMENT_DESCRIPTION,
	MANUAL_ASSIGNMENT_TITLE,
	RANDOM_ASSIGNMENT_DESCRIPTION,
	RANDOM_ASSIGNMENT_TITLE,
} from '../../application/constants/projectConfiguration';

export const SETTINGS_PAGE = Object.freeze({
	TITLE: 'title',
	TEXT_INFO: 'textInfo',
	[RANDOM_ASSIGNMENT_TITLE]: RANDOM_ASSIGNMENT_TITLE,
	[RANDOM_ASSIGNMENT_DESCRIPTION]: RANDOM_ASSIGNMENT_DESCRIPTION,
	[MANUAL_ASSIGNMENT_TITLE]: MANUAL_ASSIGNMENT_TITLE,
	[MANUAL_ASSIGNMENT_DESCRIPTION]: MANUAL_ASSIGNMENT_DESCRIPTION,
});

export const ERROR_ASESORS = 'error_asesors';
