import { TOOLS_PAGE } from '../translation_keys';

export default {
	[TOOLS_PAGE.TITLE]: 'Tools',
	[TOOLS_PAGE.DESCRIPTION]:
		'Use this link for clients to access the Suite and make payment for reservation or down payment.',
	[TOOLS_PAGE.TITLE_CARD]: 'General access link to Buyer Suite',
	[TOOLS_PAGE.BUTTON_CARD]: 'Generate link',
	[TOOLS_PAGE.ERROR_PROJECT_AGREEMENT]: 'Project without agreement',
	[TOOLS_PAGE.SUCCESS_COPY_LINK]: 'Link copied to clipboard',
	[TOOLS_PAGE.ERROR_COPY_LINK]: 'Error copying link',
};
