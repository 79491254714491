import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextTag } from '@vecindario/vecindario-suite-components';
import {
	OPTION_ACTION_CREATE_OPTION,
	OPTION_ACTION_EXTEND_EXPIRATION,
	OPTION_STATE_ACTIVE,
	OPTION_STATE_PENDING,
} from '../../../opportunityHistory/application/constants/optionedInformation';
import * as i18nKeys from '../../../opportunityHistory/infrastructure/locales/translation_keys';
import { DOMAIN_NAME as OPPORTUNITY_HISTORY_DOMAIN } from '../../../opportunityHistory/infrastructure/locales';
import { formatDate, getFullName } from '../../../../shared/application/helpers/common-functions';
import { OPTION_ACTION_ACTION, OPTION_NO_PERFORMER_USER } from '../../infrastructure/locales/translation_keys';
import { DOMAIN_NAME } from '../../infrastructure/locales';
import { OPTIONS_QUEUE_DATE_FORMAT } from '../constants/optionsQueue';
import ActionOption from '../../../opportunityHistory/presentation/components/ActionOption';

const usePropertyQueue = ({ optionSnapshots }) => {
	const { t } = useTranslation();
	const [queue, setQueue] = useState([]);

	const isOpenOption = (option) => {
		return [OPTION_STATE_ACTIVE, OPTION_STATE_PENDING].includes(option?.option_state);
	};

	const extractPerformingUserFullName = (action) => {
		return action?.performing_user
			? `${action?.performing_user?.user_detail?.name} ${action?.performing_user?.user_detail?.lastname}`
			: '';
	};

	const extractPerformingUserRole = (action) => {
		return action?.performing_user?.role ? `(${action?.performing_user?.role})` : '';
	};

	const optionCreateAction = (option) => {
		return option?.option_actions?.find((elem) => elem?.action === OPTION_ACTION_CREATE_OPTION);
	};

	const optionExtendAction = (option) => {
		return option?.option_actions?.find((elem) => elem?.action === OPTION_ACTION_EXTEND_EXPIRATION);
	};

	const lastOptionAction = (option) => {
		return option?.option_actions[option?.option_actions?.length - 1];
	};

	useEffect(() => {
		const mappedRows = [];

		optionSnapshots.forEach((option) => {
			const filteredActions = {
				create: optionCreateAction(option),
				extend: optionExtendAction(option),
				last: lastOptionAction(option),
			};

			const names = {
				create: `${extractPerformingUserFullName(filteredActions?.create)} ${extractPerformingUserRole(
					filteredActions?.create,
				)}`,
				extend: `${extractPerformingUserFullName(filteredActions?.extend)} ${extractPerformingUserRole(
					filteredActions?.extend,
				)}`,
				last: `${extractPerformingUserFullName(filteredActions?.last)} ${extractPerformingUserRole(filteredActions?.last)}`,
			};

			const dates = {
				create: formatDate(option?.option_order, OPTIONS_QUEUE_DATE_FORMAT),
				expiration:
					isOpenOption(option) && option?.expiration_date
						? formatDate(option?.expiration_date, OPTIONS_QUEUE_DATE_FORMAT)
						: 'N/A',
				extend: filteredActions?.extend?.created_at
					? formatDate(filteredActions?.extend?.created_at, OPTIONS_QUEUE_DATE_FORMAT)
					: 'N/A',
				last: filteredActions?.last?.created_at
					? formatDate(filteredActions?.last?.created_at, OPTIONS_QUEUE_DATE_FORMAT)
					: 'N/A',
			};

			const renderExpirationDate = () => (
				<>
					{dates?.expiration}{' '}
					{!isOpenOption(option) || !filteredActions?.extend ? (
						''
					) : (
						<TextTag variant="-body-xs">({t(OPTION_ACTION_ACTION.EXTENDED, { ns: DOMAIN_NAME })})</TextTag>
					)}
				</>
			);

			const toAdd = {
				order: isOpenOption(option) ? option.order : 'N/A',
				opportunity_name: getFullName(option?.commercial_opportunity?.user_snapshot_detail),
				option_state: t(i18nKeys.OPTIONS_STATE[option?.option_state?.toUpperCase()], { ns: OPPORTUNITY_HISTORY_DOMAIN }),
				expiration_date: renderExpirationDate(),
				option_creator_name: names?.create,
				opportunity_advisor_name: option?.commercial_opportunity?.asesor || 'N/A',
				option_date: dates?.create,
				last_option_action_date: dates?.last,
				last_option_action_name:
					names?.last?.trim() !== ''
						? names?.last
						: t(OPTION_NO_PERFORMER_USER[filteredActions?.last?.no_user_performer], { ns: DOMAIN_NAME }),
				extend_expiration_name: names?.extend?.trim() ? names?.extend : 'N/A',
				extend_expiration_date_action: dates?.extend,
				actions: <ActionOption option={option} />,
			};
			mappedRows.push(toAdd);
		});

		setQueue(mappedRows);
	}, [optionSnapshots, t]);

	return queue;
};

export default usePropertyQueue;
