import React from 'react';
import PropTypes from 'prop-types';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { useTranslation } from 'react-i18next';
import { ICON_CLOSE_LINE } from '../../../../../shared/application/constants/icons';
import { DOMAIN_NAME } from '../../../infrastructure/locales';
import {
	ADDITIONAL_FIELDS_KEYS,
	CONTACT_ADDITIONAL_FIELDS,
	ECONOMIC_ACTIVITIES_KEYS,
	EXTERNAL_CONTACT,
	FORM_CONTACT,
} from '../../../infrastructure/locales/translation_keys';
import { opportunityHistoryRoute } from '../../../infrastructure/routes';
import { history } from '../../../../../shared/application/helpers/history';
import {
	getStateClass,
	getValueFromLocalStorage,
	removeValueFromLocalStorage,
} from '../../../../../shared/application/helpers/common-functions';
import { ADDITIONAL_FIELDS, ECONOMIC_ACTIVITY_KEY } from '../../../application/constants/contactInformation';
import { STAGE_CONTACT } from '../../../application/constants/opportunityHistory';
import './ContactRequestDetails.scss';

const ContactRequestDetailsInfo = ({ opportunity }) => {
	const { t } = useTranslation();
	const currentOpportunityHistory = JSON.parse(getValueFromLocalStorage('history_item'));
	const { answers } = currentOpportunityHistory?.action_instance || {};

	const additionalFields = Object.keys(answers || {}).filter((answer) => !!ADDITIONAL_FIELDS[answer]);

	const handleHistoryRequest = () => {
		removeValueFromLocalStorage('history_item');
		history.push(opportunityHistoryRoute(opportunity?.project_slug, opportunity?.id));
	};

	return (
		<div className="contact-request-details-container">
			<div className="contact-request-details-header">
				<div className="crd-header-title">
					<div className={`history-state-badge ${getStateClass(currentOpportunityHistory?.action_type)}`}>
						<TextTag variant="-body-xs" tag="p" fw="bold">
							{t(`${STAGE_CONTACT}`, { ns: DOMAIN_NAME })}
						</TextTag>
					</div>
					<TextTag variant="-body-sm" tag="label" fw="bold">
						{t(`${EXTERNAL_CONTACT.DETAIL}`, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>
				<div className="crd-header-close">
					<Icon icon={ICON_CLOSE_LINE} size="small" onClick={handleHistoryRequest} />
				</div>
			</div>
			<div className="contact-request-details-body">
				{currentOpportunityHistory?.action_type === 'contact' && (
					<>
						<div className="row">
							<div className="row-item">
								<div className="utm-item">
									<TextTag variant="-body-sm" tag="label">
										{t(FORM_CONTACT.REQUEST_TYPE, { ns: DOMAIN_NAME })}{' '}
									</TextTag>
								</div>
							</div>
							<div className="row-item">
								<TextTag variant="-body-sm" tag="label" className="crd-value">
									{t(FORM_CONTACT.CONTACT_FORM, { ns: DOMAIN_NAME })}{' '}
								</TextTag>
							</div>
						</div>
						{additionalFields.map((field) => {
							const value =
								field === ECONOMIC_ACTIVITY_KEY
									? t(ECONOMIC_ACTIVITIES_KEYS?.[answers?.[field]], { ns: DOMAIN_NAME })
									: answers?.[field];
							return (
								<div className="row" key={`${field}`}>
									<div className="row-item">
										<div className="utm-item">
											<TextTag variant="-body-sm" tag="label">
												{t(ADDITIONAL_FIELDS_KEYS?.[field], { ns: DOMAIN_NAME })}{' '}
											</TextTag>
										</div>
									</div>
									<div className="row-item">
										<TextTag variant="-body-sm" tag="label" className="crd-value">
											{value}
										</TextTag>
									</div>
								</div>
							);
						})}
					</>
				)}

				{currentOpportunityHistory?.action_type === 'external_contact_request' && (
					<div className="row">
						<div className="row-item">
							<div className="utm-item">
								<TextTag variant="-body-sm" tag="label">
									{t(ADDITIONAL_FIELDS_KEYS?.external_contact_request_label, { ns: DOMAIN_NAME })}{' '}
								</TextTag>
							</div>
						</div>
						<div className="row-item">
							<TextTag variant="-body-sm" tag="label" className="crd-value">
								{t(CONTACT_ADDITIONAL_FIELDS?.external_contact_request_text, { ns: DOMAIN_NAME })}{' '}
							</TextTag>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

ContactRequestDetailsInfo.propTypes = {
	opportunity: PropTypes.object.isRequired,
};

export default ContactRequestDetailsInfo;
