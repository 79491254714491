export const CHECK_IMAGE_RESLUT = 'https://suite-inventory-frontend.s3.us-east-2.amazonaws.com/ok-result.jpeg';
export const PROFILE_IMAGE = 'https://suite-frontend-sso.s3.us-east-2.amazonaws.com/default_avatar.svg';
export const SIMULATOR_EXIT_LOAD =
	'https://simulator-by-vecindario.s3-us-west-2.amazonaws.com/static-content/wizard/carga-exitosa-image.jpg';
export const PAYMENT_MODAL_ILLUSTRATION =
	'https://suite-static-files.s3.us-east-2.amazonaws.com/opportunities/modal-illustration.png';
export const UNAVAILABE_PROPERTY_MODAL_ILLUSTRATION =
	'https://suite-static-files.s3.us-east-2.amazonaws.com/opportunities/unavailable-property.png';

// Desist
export const DESIST_MAIN_IMAGE = 'https://suite-static-files.s3.us-east-2.amazonaws.com/common/informative-error.png';
