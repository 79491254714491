import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setMessageToast } from '@vecindario/suite-dashboard-layout-lib';
import { TextTag } from '@vecindario/vecindario-suite-components';
import { DOMAIN_NAME } from '../../../../infrastructure/locales';
import { INTEGRATION_HUBSPOT } from '../../../../infrastructure/locales/translation_keys';
import PipelineStageRow from '../PipelineStageRow';
import { filterByActivePipeline } from '../../../../application/helpers/hubspotIntegration';
import './PipelinesStagesSelector.scss';
import GeneralSelectorsCard from '../GeneralSelectorsCard';
import {
	selectHubspotTokenPipelines,
	selectJsonDataIntegrationPipelines,
} from '../../../../application/selector/hubspotIntegration';
import { OPPORTUNITIES_TYPE } from '../../../../application/constants/hubspotIntegration';
import { resetObjPipelinesStages, setObjPipelinesStages } from '../../../../application/slices/hubspotIntegration';

const PipelinesStagesSelector = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { showIntegration } = useParams();
	const pipelinesData = useSelector(selectHubspotTokenPipelines);
	const pipelineListSelector = filterByActivePipeline(pipelinesData);
	const [arrayPipelines, setArrayPipelines] = useState([]);
	const isDisableButtonSave = arrayPipelines.length === 0;
	const hasIntegratedPipelines = useSelector(selectJsonDataIntegrationPipelines);

	const actionSaveButton = async () => {
		if (showIntegration) {
			dispatch(resetObjPipelinesStages());
		}
		await dispatch(setObjPipelinesStages(arrayPipelines));
		await dispatch(
			setMessageToast({
				type: 'success',
				message: t(INTEGRATION_HUBSPOT.SUCCESS_SAVE_DATA, { ns: DOMAIN_NAME }),
			}),
		);
	};

	const filterByTypeAndPipeline = (opportunityType) => {
		// Filtrar el array para incluir solo objetos con pipeline_id
		const filterByPipeline = hasIntegratedPipelines.filter((objeto) => objeto.pipeline_id);

		// Filtrar el nuevo array para incluir solo objetos con el opportunityType en el group
		const result = filterByPipeline.filter((objeto) => objeto?.group?.includes(opportunityType));

		return result.length > 0 ? result[0] : null;
	};

	return (
		<GeneralSelectorsCard
			title={t(INTEGRATION_HUBSPOT.SELECT_PIPELINES_TITLE, { ns: DOMAIN_NAME })}
			isDisabled={isDisableButtonSave}
			actionSaveButton={actionSaveButton}
		>
			<section className="container-pipelines-stages">
				<div className="header">
					<TextTag fw="bold" variant="-body-sm">
						{t(INTEGRATION_HUBSPOT.OPPORTUNITY_TYPE, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag fw="bold" variant="-body-sm">
						{t(INTEGRATION_HUBSPOT.PIPELINE, { ns: DOMAIN_NAME })}
					</TextTag>
					<TextTag fw="bold" variant="-body-sm">
						{t(INTEGRATION_HUBSPOT.STAGE, { ns: DOMAIN_NAME })}
					</TextTag>
				</div>
				<div className="body">
					<PipelineStageRow
						checkboxText={t(INTEGRATION_HUBSPOT.SCHEDULE_TYPE, { ns: DOMAIN_NAME })}
						opportunityTypeVale={OPPORTUNITIES_TYPE.SCHEDULES_TYPE}
						pipelineList={pipelineListSelector}
						pipelinesData={pipelinesData}
						setArrayPipelines={setArrayPipelines}
						integratedDataRow={filterByTypeAndPipeline(OPPORTUNITIES_TYPE.SCHEDULES_TYPE)}
					/>
					<PipelineStageRow
						checkboxText={t(INTEGRATION_HUBSPOT.QUOTER_TYPE, { ns: DOMAIN_NAME })}
						opportunityTypeVale={OPPORTUNITIES_TYPE.QUOTATIONS_TYPE}
						pipelineList={pipelineListSelector}
						pipelinesData={pipelinesData}
						setArrayPipelines={setArrayPipelines}
						integratedDataRow={filterByTypeAndPipeline(OPPORTUNITIES_TYPE.QUOTATIONS_TYPE)}
					/>
					<PipelineStageRow
						checkboxText={t(INTEGRATION_HUBSPOT.SIMULATION_TYPE, { ns: DOMAIN_NAME })}
						opportunityTypeVale={OPPORTUNITIES_TYPE.SIMULATIONS_TYPE}
						pipelineList={pipelineListSelector}
						pipelinesData={pipelinesData}
						setArrayPipelines={setArrayPipelines}
						integratedDataRow={filterByTypeAndPipeline(OPPORTUNITIES_TYPE.SIMULATIONS_TYPE)}
					/>
					<PipelineStageRow
						checkboxText={t(INTEGRATION_HUBSPOT.CONTACT_REQUESTS_TYPE, { ns: DOMAIN_NAME })}
						opportunityTypeVale={OPPORTUNITIES_TYPE.CONTACT_REQUESTS_TYPE}
						pipelineList={pipelineListSelector}
						pipelinesData={pipelinesData}
						setArrayPipelines={setArrayPipelines}
						integratedDataRow={filterByTypeAndPipeline(OPPORTUNITIES_TYPE.CONTACT_REQUESTS_TYPE)}
					/>
				</div>
			</section>
		</GeneralSelectorsCard>
	);
};

export default PipelinesStagesSelector;
