import React from 'react';
import { useSelector } from 'react-redux';
import OptionDetails from '../../components/OptionDetails';
import { selectRequestHistory } from '../../../application/selector/history';

const OptionDetailsPage = () => {
	const opportunity = useSelector(selectRequestHistory);
	return <OptionDetails opportunity={opportunity} />;
};

export default OptionDetailsPage;
