import {
	CONTACT_REQUEST_KEY,
	COTIZADOR_KEY,
	INVENTORY_KEY,
	SCHEDULER_KEY,
} from '../../../../../shared/application/constants/modules_keys';
import {
	BOARD_COLUMNS,
	DROPDONW_BOARD,
	SEARCH_PLACEHOLDER,
	PREMIUM_MODAL,
	ERROR_INPUT_MESSAGE,
	INVALID_ACTION,
	TODAY,
} from '../translation_keys';

export default {
	[BOARD_COLUMNS.INTERESTED]: `Interesado`,
	[BOARD_COLUMNS.VISITED]: `Visitó`,
	[BOARD_COLUMNS.RESERVED]: `Reservó`,
	[BOARD_COLUMNS.SEPARATED]: `Separó`,
	[BOARD_COLUMNS.DISCARDED]: `Descartados`,
	[BOARD_COLUMNS.DESISTED]: `Desistidos`,
	[DROPDONW_BOARD.SELECT_TEXT]: `Crear oportunidad`,
	[DROPDONW_BOARD.SELECT_TITLE]: `Selecciona uno`,
	[DROPDONW_BOARD[INVENTORY_KEY]]: `Simulador`,
	[DROPDONW_BOARD[SCHEDULER_KEY]]: `Agendador`,
	[DROPDONW_BOARD[CONTACT_REQUEST_KEY]]: `Formulario`,
	[DROPDONW_BOARD[COTIZADOR_KEY]]: 'Cotizador',
	[DROPDONW_BOARD.COPY_LINK]: `Copiar link`,
	[DROPDONW_BOARD.FORM]: `Formulario`,
	[SEARCH_PLACEHOLDER]: `Buscar`,
	[PREMIUM_MODAL.BODY_TEXT]: `Los mecanismos de conversión (simulador, agendador y formulario de contacto) dejarán de visualizarse en los lugares donde tengas incrustados tus landing page, URL directa o QR`,
	[PREMIUM_MODAL.MAX_MONTHLY_OPPORTUNITIES]: `20/20 <br/>Alcanzaste el número<br/>máximo mensual de oportunidades`,
	[ERROR_INPUT_MESSAGE]: 'No se encontraron coincidencias para la búsqueda',
	[INVALID_ACTION]: 'Acción no permitida',
	[TODAY]: 'Hoy',
};
