import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Icon, TextTag } from '@vecindario/vecindario-suite-components';
import { ARROW_GO_BACK_LINE } from '../../../application/constants/icons';
import { GO_BACK } from '../../../infrastructure/i18n/locales/translation_keys';
import { DOMAIN_NAME } from '../../../infrastructure/i18n/locales';
import { history } from '../../../application/helpers/history';
import './GoBackButton.scss';

const GoBackButton = ({ routeBack, classNames, isExternal = false, openInNewTab = false }) => {
	const { t } = useTranslation();

	const handleBack = () => {
		if (routeBack && isExternal && openInNewTab) {
			window.open(routeBack);
			return;
		}

		if (routeBack && isExternal && !openInNewTab) {
			window.location.href = routeBack;
			return;
		}

		if (routeBack) {
			history.push(routeBack);
		} else {
			history.goBack();
		}
	};

	return (
		<div className={`go-back-button ${classNames}`} onClick={handleBack}>
			<Icon icon={ARROW_GO_BACK_LINE} />
			<TextTag variant="-body-sm" className="text">
				{t(`${GO_BACK}`, { ns: DOMAIN_NAME })}
			</TextTag>
		</div>
	);
};

GoBackButton.propTypes = {
	routeBack: PropTypes.string,
	classNames: PropTypes.string,
	isExternal: PropTypes.bool,
	openInNewTab: PropTypes.bool,
};

export default GoBackButton;
