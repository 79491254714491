import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import HistoryHeader from '../../components/HistoryHeader';
import HistoryTable from '../../components/HistoryTable';
import { selectProjectReservation, selectRequestHistory } from '../../../application/selector/history';
import {
	ALLOWED_TRANSACTION_STAGES,
	TRANSACTION_APPROVED_STATE,
	TRANSACTION_DESISTED_STATE,
} from '../../../application/constants/opportunityHistory';
import { resetCurrentOptionSelected } from '../../../application/slices/history';

const HistoryTablePage = ({ showSelectPropertyModal, setShowSelectPropertyModal }) => {
	const opportunity = useSelector(selectRequestHistory);
	const projectReservation = useSelector(selectProjectReservation);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(resetCurrentOptionSelected());
	}, [dispatch]);

	const transactionHistories = useMemo(() => {
		const transaction = opportunity?.transactions?.find((_transaction) =>
			[TRANSACTION_APPROVED_STATE, TRANSACTION_DESISTED_STATE].includes(_transaction.status?.toLowerCase()),
		);

		const histories = transaction?.transaction_histories || [];
		return histories
			.filter((_history) => ALLOWED_TRANSACTION_STAGES.includes(_history.action_type?.toLowerCase()))
			.map((_history) => {
				return {
					..._history,
					action_instance: {
						..._history.action_instance,
						property: transaction?.property,
						tower: transaction?.property?.tower,
					},
				};
			});
	}, [opportunity]);

	return (
		<div className="user-card-container">
			<HistoryHeader opportunity={opportunity} setShowSelectPropertyModal={setShowSelectPropertyModal} />
			<HistoryTable
				opportunity={opportunity}
				projectReservation={projectReservation}
				transactionHistories={transactionHistories}
			/>
		</div>
	);
};
HistoryTablePage.propTypes = {
	showSelectPropertyModal: PropTypes.bool,
	setShowSelectPropertyModal: PropTypes.func,
};

export default HistoryTablePage;
