import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TextTag } from '@vecindario/vecindario-suite-components';
import PropTypes from 'prop-types';
import './AlertVersionPlan.scss';
import { LINK_GO_PREMIUM } from '../../../application/constants/application';
import { DOMAIN_NAME as DOMAIN_NAME_SHARED } from '../../../infrastructure/i18n/locales';
import { FREE_PLAN_TEXT, TALK_ASSESOR } from '../../../infrastructure/i18n/locales/translation_keys';

const AlertVersionPlan = ({ currentUser, countFreeOpportunities }) => {
	const { t } = useTranslation();
	const handleClick = () => {
		window.open(LINK_GO_PREMIUM, '_blank');
	};

	return (
		<div className="container-alert-plan">
			<TextTag className="text">
				<span
					dangerouslySetInnerHTML={{
						__html: t(FREE_PLAN_TEXT, {
							ns: DOMAIN_NAME_SHARED,
							userName: currentUser,
							opportunities: countFreeOpportunities,
						}),
					}}
				/>
			</TextTag>
			<Button size="small" variant="tertiary" className="action-button" onClick={handleClick}>
				{t(TALK_ASSESOR, { ns: DOMAIN_NAME_SHARED })}
			</Button>
		</div>
	);
};

AlertVersionPlan.propTypes = {
	currentUser: PropTypes.string,
	countFreeOpportunities: PropTypes.number,
};

export default AlertVersionPlan;
