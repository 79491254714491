import {
	PENDING_TRANSACTIONS,
	PENDING_TRANSACTIONS_BY_PROPERTY,
} from '../../../../domains/opportunityHistory/application/constants/transaction';
import {
	DASHBOARD_SIDEBAR_KEY,
	INTEGRATIONS_SIDEBAR_KEY,
	SETTINGS_SIDEBAR_KEY,
	TOOLS_SIDEBAR_KEY,
} from '../../../application/constants/sideBard';

export const OPPORTUNITIES_TITLE = 'opportunitiesTitle';
export const GO_BACK = 'goBack';
export const SEPARATED = 'separated';
export const RESERVED = 'reserved';
export const BUYER = 'buyer';
export const TELEPHONE = 'telephone';
export const PERSONAL_DOC = 'personalDoc';
export const EMAIL = 'email';
export const PROPERTY = 'property';
export const SEE_PROPERTY = 'seeProperty';
export const GENERATE_LINK = 'generateLink';
export const GENERATE_AGAIN = 'generateLinkAgain';
export const GENERATE = 'generateLink';
export const GENERATING_LINK = 'generatingLink';
export const COPY = 'copy';
export const COPIED = 'copied';
export const NO_COPIED = 'noCopied';
export const PAY_LINK = 'payLink';
export const SEND_PAY_LINK = 'sendPayLink';
export const FREE_PROPERTY = 'freeProperty';
export const LOADING = 'loading';
export const COMFIRM_PAY = 'comfirmPay';
export const SEE_SIMULATION = 'seeSimulation';
export const SEE_QUOTATION = 'seeQuotation';
export const CLIPBOARD_LINK = 'clipboardLink';
export const EMPTY_OPTION = 'emptyOption';
export const FREE_PLAN_TEXT = 'freePlanText';
export const TALK_ASSESOR = 'talkAssesor';
export const SERVER_ERROR = 'serverError';
export const FIDUCIARY_REFERENCE = 'fiduciaryReference';
export const CANCEL_BUTTON = 'cancelerButton';
export const DELETE_BUTTON = 'deleteButton';

export const DASHBOARD_SIDEBAR = Object.freeze({
	[DASHBOARD_SIDEBAR_KEY]: DASHBOARD_SIDEBAR_KEY,
	[SETTINGS_SIDEBAR_KEY]: SETTINGS_SIDEBAR_KEY,
	[INTEGRATIONS_SIDEBAR_KEY]: INTEGRATIONS_SIDEBAR_KEY,
	[TOOLS_SIDEBAR_KEY]: TOOLS_SIDEBAR_KEY,
});

export const TRANSACTION_ERRORS = Object.freeze({
	[PENDING_TRANSACTIONS]: 'pendingTransactions',
	[PENDING_TRANSACTIONS_BY_PROPERTY]: 'pendingTransactionsByProperty',
});
