import { authHeader, handleResponse } from '../../../../shared/infrastructure/api/apiHandler';
import { urlGetConfigurationByProject, urlPutConfiguration, urlScoringProject } from './backendUrls';

export const getConfigurationByProject = (slug) => {
	const requestOptions = {
		method: 'POST',
		headers: authHeader(),
	};

	return fetch(urlGetConfigurationByProject(slug), requestOptions).then(handleResponse);
};

export const putConfigurationByProject = (slug, id, payload) => {
	const requestOptions = {
		method: 'PUT',
		headers: authHeader(),
		body: JSON.stringify(payload),
	};

	return fetch(urlPutConfiguration(slug, id), requestOptions).then(handleResponse);
};

export const getScoringConfigurationByProject = (slug) => {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	};

	return fetch(urlScoringProject(slug), requestOptions).then(handleResponse);
};
