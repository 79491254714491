import { STAGE_QUOTED } from './opportunityHistory';

export const DOCUMENT_TYPE_CC = 'citizenIdentificationCard';
export const DOCUMENT_TYPE_NIT = 'nit';
export const DOCUMENT_TYPE_CE = 'foreignerIdentificationCard';
export const DOCUMENT_TYPE_TI = 'Tarjeta de identidad';
export const DOCUMENT_TYPE_RC = 'Registro civil';
export const DOCUMENT_TYPE_P = 'pasport';
export const DOCUMENT_TYPE_PEDP = 'Permiso especial de permanencia';
export const DOCUMENT_TYPE_CD = 'Carnet diplomático';
export const DOCUMENT_TYPE_OTHER_TYPE = 'Otro tipo';

export const DOCUMENT_TYPE_CC_VALUE = 'CC';
export const DOCUMENT_TYPE_NIT_VALUE = 'NIT';
export const DOCUMENT_TYPE_CE_VALUE = 'CE';
export const DOCUMENT_TYPE_P_VALUE = 'Pasaporte';

export const DOCUMENT_TYPE_REGEX = {
	CC: /^[1-9][0-9]{3,9}$/,
	NIT: /^[1-9]\d{6,9}$/,
	CE: /^([a-zA-Z]{1,5})?[1-9][0-9]{3,7}$/,
	TI: /^[1-9][0-9]{4,11}$/,
	P: /^[a-zA-Z0-9_]{4,16}$/,
};

// DOCUMENT TYPES
export const invalidLengthValueRange = (minValue, maxValue) =>
	`*El campo debe comenzar con un número diferente a 0 y tener entre ${minValue} y ${maxValue}`;
export const INVALID_DOCUMENT_CE_FORMAT =
	'*El documento puede comenzar con hasta 5 caracteres de texto, luego debe tener entre 4 y 8 caracteres numericos con el primero diferente a 0';
export const INVALID_DOCUMENT_P_FORMAT = '*El campo debe contenter entre 4 y 16 caracteres';

export const DOCUMENT_TYPES_OPTIONS = [
	{
		label: 'Cédula de Ciudadanía',
		value: 'CC',
	},
	{
		label: 'Cédula de Extranjería',
		value: 'CE',
	},
	{
		label: 'Nit',
		value: 'NIT',
	},
	{
		label: 'Pasaporte',
		value: 'P',
	},
];

export const userInformationFields = (actionType) => ({
	DOCUMENT_TYPE: actionType === STAGE_QUOTED ? 'document_type' : 'identification_type',
	DOCUMENT: 'identification',
});
